<div class="page">
    <div class="mainContent organizations" *ngIf="allStudies">
        <nav class="breadcrumb breadcrumbs" aria-label="breadcrumb">
            <ol class="breadcrumbs__list">
<!--                Not focused on Study -->
                <li class="breadcrumb-item breadcrumbItem" [attr.aria-current]="(!this.focusedStudyId) ? 'page' : null" (click)="focusOnStudy()">
                    Manage
                </li>
<!--                Focused on Study -->
                <li class="breadcrumb-item breadcrumbItem" *ngIf="this.focusedStudyId && !!currentStudy" [attr.aria-current]="(!!this.focusedStudyId && !!currentStudy) ? 'page' : null">
                    {{currentStudy.title || 'n/a'}}
                </li>
            </ol>
        </nav>

        <!--  List of Research Studies-->
        <ng-container *ngIf="!focusedStudyId">
            <h4>Please select a Study</h4>
            <div class="facilityList">
                <article class="facilityArea blueOnHover" *ngFor="let study of allStudies" (click)="focusOnStudy(study._id)">
                    <div class="header">
                        <div class="text-area">
                            <h4 class="facilityName">{{currentStudy?.title || "Loading Name"}}</h4>
                            <p class="studyDate">Start date: {{currentStudy?.startDate | date: 'longDate'}} </p>
                            <p class="studyDate">End date: {{currentStudy?.endDate | date: 'longDate'}} </p>
                            <quill-view *ngIf="currentStudy?.description" [content]="currentStudy?.description" format="html" theme="snow" class="studyDescription"></quill-view>
                        </div>
                    </div>
                    <div class="facilityInfoCount">
                        <h6>Research Coordinators: <span>({{study?.researchCoordinators?.length || 0}})</span></h6>
                        <h6>Researchers: <span>({{study?.researchers?.length || 0}})</span></h6>
                        <h6>Participants: <span>({{study?.participants?.length || 0}})</span></h6>
                    </div>
                </article>
            </div>
        </ng-container>

<!--        Focused on Study    -->
        <ng-container *ngIf="focusedStudyId && !!currentStudy">
            <article class="facilityDetails">
                <div class="header">
                    <div class="text-area">
                        <h4 class="facilityName">{{currentStudy?.title || "Loading Name"}}</h4>
                    </div>
                </div>

                <div class="facilityMembers">
                    <div class="numMembers">
                        Facilities ({{currentStudy?.facilities?.length || 0}})
                    </div>
                    <div class="facilityMembers__list">
                        <div class="memberInfo" *ngFor="let facility of currentStudy.facilities">
                            <div class="memberInfo__name">{{facility.name}}</div>
                        </div>
                    </div>
                </div>

                <div class="facilityMembers">
                    <div class="numMembers" *ngIf="currentStudy.principleInvestigators">Principle Investigators
                        ({{currentStudy?.principleInvestigators?.length || 0}})</div>
                    <div class="facilityMembers__list">
                        <div class="memberInfo" *ngFor="let principleInvestigator of currentStudy.principleInvestigators">
                            <div class="memberInfo__name">{{principleInvestigator.email}}</div>
                            <div class="memberInfo__role">Principle Investigator</div>
                        </div>
                    </div>
                </div>

                <div class="facilityMembers">
                    <div class="numMembers" *ngIf="currentStudy.researchCoordinators">Research Coordinators
                        ({{currentStudy?.researchCoordinators?.length || 0}})</div>
                    <div class="facilityMembers__list">
                        <div class="memberInfo" [ngClass]="{'memberInfo': true, 'notVerified': !rc?.invitationAccepted}" *ngFor="let rc of currentStudy.researchCoordinators">
                            <div class="memberInfo__name">{{rc.email}}</div>
                            <div class="memberInfo__role">Research Coordinator</div>
                            <p *ngIf="!rc?.invitationAccepted">(needs verification)</p>
                        </div>
                    </div>
                </div>


                <div class="facilityMembers">
                    <div class="numMembers">Researchers
                        ({{currentStudy?.researchers?.length || 0}})
                    </div>
                    <div class="facilityMembers__list">
                        <div [ngClass]="{'memberInfo': true, 'notVerified': !researcher?.invitationAccepted}" *ngFor="let researcher of currentStudy.researchers">
                            <div class="memberInfo__name">{{researcher.email}}</div>
                            <div class="memberInfo__role">Researcher</div>
                            <p *ngIf="!researcher?.invitationAccepted">(needs verification)</p>
                        </div>
                    </div>
                </div>

                <div class="facilityMembers">
                    <div class="numMembers">Participants
                        ({{currentStudy?.participants?.length || 0}})</div>
                    <div class="facilityMembers__list">
                        <div class="memberInfo" [ngClass]="{'memberInfo': true, 'notVerified': !partcipant?.invitationAccepted}" *ngFor="let partcipant of currentStudy.participants">
                            <div class="memberInfo__name">{{partcipant.anonymizedName}}</div>
                            <div class="memberInfo__role">Participant</div>
                            <p *ngIf="!partcipant?.invitationAccepted">(needs verification)</p>
                        </div>
                    </div>
                </div>
            </article>
        </ng-container>

    </div>

    <div class="rightPanel">

    </div>
</div>
