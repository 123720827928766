import { Component, Input } from '@angular/core';

@Component({
    selector: 'settingsPage',
    templateUrl: './settings.html',
    styleUrls: ['./settings.scss'],
})

export class SettingsPageComponent {
    @Input()
    viewAs: string = 'user';

}
