import {
    ChangeDetectionStrategy,
    Component, Input, OnChanges,
    OnDestroy, OnInit, SimpleChanges, EventEmitter, Output
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {faCalendarAlt} from '@fortawesome/free-regular-svg-icons';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {UserType, UserService} from '../../auth/user.service';
import {activityTypes, granularityOptions} from '../../../../server/config/environment/shared';
import {ActivityAnalyticResponse, AnalyticQuery, AnalyticsService} from '../../../services/analytics/analytics.service';
import {BarVerticalStackedComponent} from '@swimlane/ngx-charts';
import {OrganizationService} from '../../../services/organization/organization.service';
import {
    convertHoursToMillis,
    convertMillisToHours,
    mapColorKeys,
    convertMillisToHrsMinutes,
    capitalizeFirstLetter,
    convertMillisToMinutes,
    onlyUniqueFilter,
    isValidDateRange
} from '../../util';
import moment from 'moment';
import {FacilityService} from '../../../services/facility/facility.service';
import _ from 'lodash';

type validGraphDataInput = {
        name: string,
        series: {
                name: string,
                value: number
            }[]
    }[];

type AnalyticType = {
    name: string,
    duration: number,
    startTime: string
};


@Component({
    selector: 'stacked-bar-chart',
    templateUrl: './stacked-bar-chart.html',
    providers: [BarVerticalStackedComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./stacked-bar-chart.scss']
})


export class StackedBarChartComponent implements OnInit, OnDestroy, OnChanges {
    @Input('graphTitle') graphTitle: string = 'StackedVerticalBarChart';
    @Input('data') data?: any = undefined;
    @Input('dataSummary') dataSummary?: any = undefined;
    //TODO: we can determine the necessary query based on ActivatedRoute context
    @Input('dataName') dataName: string = undefined;
    @Input('queryDateRange') queryDateRange: [Date, Date];
    @Input('selectedGranularity') selectedGranularity?: string = granularityOptions[1]; // 'daily'
    @Input('targetUser') targetUser?: UserType = undefined;
    @Input('anonymized') anonymized?: boolean = true;

    @Output('selectedGranularityChange') selectedGranularityChange = new EventEmitter<string>();

    facilityId: string = undefined;
    studyId: string = undefined;
    roleView: string = 'user';

    showAlert = false;
    alertType = '';
    faCalendarAlt = faCalendarAlt;
    dataReady: boolean = false;
    errOccurred: boolean = false;
    activityTypes = activityTypes;

    //Date picker
    currentDate = new Date();
    bsConfig: Partial<BsDatepickerConfig>;


    //Time Unit selector variables
    granularityOptions = granularityOptions;
    _prevGranularitySelected = this.granularityOptions[1].toLowerCase();
    _granularitySelected = this.granularityOptions[1].toLowerCase();
    public onTouch: Function;
    public onModelChange: Function;


    //see the full list of options at: https://swimlane.gitbook.io/ngx-charts/examples/bar-charts/stacked-vertical-bar-chart
    chartOptions = {
        view: undefined,
        results: this.data,
        scheme: undefined,
        schemeType: 'ordinal', //default
        showXAxis: true,
        showYAxis: true,
        gradient: false,
        showLegend: false,
        showXAxisLabel: true,
        xAxisLabel: 'Day',
        showYAxisLabel: true,
        yAxisLabel: 'Time (hrs)',
        roundDomains: true,
        animations: true,
        colorScheme: {
            domain: ['#7188F4', '#F8906E', '#9EE3FF', '#FDD660', '#EBEBEB']
        },

        //when a segment of the graph is clicked
        onSelect(event) {
            console.log(event);
        }
    };

    currentUser: UserType;
    users: any;

    parsedTotalUsageData;
    overallTotalTime: { hours: any, minutes: any };
    dailyTotals: any[];

    overallActivityUsageTime: any = {};

    paramDailyTotals: any[];
    paramActivityTotals;

    uniqueDates: string[];

    static parameters = [Router, ActivatedRoute, AnalyticsService, OrganizationService, FacilityService, UserService, BarVerticalStackedComponent];
    constructor(public router: Router, public route: ActivatedRoute, public analyticService: AnalyticsService,
     public organizationService: OrganizationService, public facilityService: FacilityService, public userService: UserService, public barVerticalStacked: BarVerticalStackedComponent) {
        this.router = router;
        this.route = route;
        this.analyticService = analyticService;
        this.userService = userService;
        this.barVerticalStacked = barVerticalStacked;

        //can get the facilityId from router params
        this.route.queryParamMap.subscribe(queryParams => {
            if (queryParams.has('facilityId')) {
                this.facilityId = queryParams.get('facilityId');
            }
            if (queryParams.has('studyId')) {
                this.studyId = queryParams.get('studyId');
            }
        });

        //get the roleView from the url
        this.roleView = this.router.url.split('/')[1].toLowerCase(); //roleView

        // get the current user from the token
        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserType;

        //init overallActivityUsageTime key/values
        this.activityTypes.forEach(activityName => {
            this.overallActivityUsageTime[activityName.toLowerCase()] = {hours: undefined, minutes: undefined};
        });
    }

    public ngOnInit(): void {
        if (!this.dataName) throw new Error('The \'dataName\' attribute is required!');
        if (!this.queryDateRange) throw new Error('The \'queryDateRange\' attribute is required!');
        this.initBarVerticallyStacked();
        this.chartOptions.results = this.data;

        this.overallTotalTime = { hours: 0, minutes: 0};
    }

    triggerQuery() {
        //ensure we have a valid set of dates
        if (!isValidDateRange(...this.queryDateRange)) return;
        if (this.dataName === 'GameMetricsMany') {
            this.getGameMetricsDataForAllUsers();
            this.chartOptions.results = this.data.sort((a, b) => a.name - b.name);
        } else if (this.dataName === 'ActivityMetricsMany') { //for multiple users
            this.getActivityMetricsForManyUsers();
        } else if (['ActivityMetricsMe', 'ActivityMetricsOne'].includes(this.dataName)) {// for a single user
            this.getActivityMetricsForSingleUser();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('queryDateRange')) {
            this.dateRangeUpdate(changes.queryDateRange.currentValue);
        }
        if (changes.hasOwnProperty('selectedGranularity')) {
            this.handleGranularityChange(changes.selectedGranularity.currentValue);
        }
        if (changes.hasOwnProperty('data')) {
            this.updateBarVerticallyStackedData(changes.data.currentValue);
            this.dataReady = true;
        }
        if (changes.hasOwnProperty('dataSummary')) {
            this.overallActivityUsageTime = this.calcTotalActivityUsageTimes(changes.dataSummary.currentValue);
        }
        if (changes.hasOwnProperty('targetUser')) {
            this.triggerQuery();
        }
    }

    initBarVerticallyStacked() {
        this.barVerticalStacked.view = undefined;
        this.barVerticalStacked.results = this.data;
        // this.barVerticalStacked.customColors = this.mapColorKeys;
        // this.barVerticalStacked.schemeType = ScaleType.Ordinal;
        this.barVerticalStacked.xAxis = true;
        this.barVerticalStacked.yAxis = true;
        this.barVerticalStacked.gradient = false;
        this.barVerticalStacked.legend = false;
        this.barVerticalStacked.showXAxisLabel = true;
        this.barVerticalStacked.xAxisLabel = 'Days';
        this.barVerticalStacked.showYAxisLabel = true;
        this.barVerticalStacked.yAxisLabel = 'Time (hrs)';
        this.barVerticalStacked.animations = true;
        this.barVerticalStacked.barPadding = 2;
    }

    /**
     * updateBarVerticallyStackedData: updates the graph when new data is received
     * @param newData: pre-formatted data, ready to be loaded into the graph
     */
    updateBarVerticallyStackedData(newData: validGraphDataInput) {
        this.formatLabelsForGraph();
        this.barVerticalStacked.results = [...newData];
        this.data = [...newData];
        this.chartOptions.results = [...newData];
        this.barVerticalStacked.update();
    }

    // TODO: determine if this graph will ever be used to display game data, if so, finish implementation of this function
    getGameMetricsDataForAllUsers() {
        this.dataReady = false;
        this.data = [];
        this.chartOptions.results = [];

        //prepare the queries for AnalyticsService
        let queries: AnalyticQuery[] = [];
        if (this.roleView !== 'user'
                && this.currentUser.caregiverOf
                && this.currentUser.caregiverOf.length > 0) {
            queries = this.currentUser.caregiverOf.map((user) => ({
                startTime: this.queryDateRange[0],
                endTime: this.queryDateRange[1],
                granularity: this._granularitySelected,
                email: user,
                role: this.roleView,
            }));
        } else if (this.roleView === 'user') {
            queries.push({
                startTime: this.queryDateRange[0],
                endTime: this.queryDateRange[1],
                granularity: this._granularitySelected,
                email: this.currentUser.email,
                role: this.roleView,
            });
        }

        if (this.studyId) {
            queries = queries.map(q => ({...q, role: 'researcher', study: this.studyId}));
        }

        const FlowGameData = new Promise<any>((resolve, reject) => {
            let promises = queries.map((query) => new Promise<any>((resol, rej) => {
                this.analyticService.getFlowGameDataForUser(query)
                    .then((res: {analytics: {detailedGameStats:any[], summaryResults:any[]}}) => {
                        if (res.analytics.detailedGameStats.length > 0) {
                            let parsed = this.parseDataByDay(res.analytics.detailedGameStats, 'FlowGame')
                                .then((parsedRes) =>
                                    parsedRes
                                );
                            resol(parsed);
                        } else {
                            resol([]);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        this.errOccurred = true;
                        rej(err);
                    });
            }));
            Promise.all(promises)
                .then((res: [[{name:string, startTime: string, duration: number}]]) => {
                    resolve(res);
                })
                .catch((err) => {
                    console.error(err);
                    this.errOccurred = true;
                    reject(err);
                });
        });

        const TicTacToeData = new Promise<any>((resolve, reject) => {
            let promises = queries.map((query) => new Promise<any>((resol, rej) => {
                this.analyticService.getTicTacToeDataForUser(query)
                    .then((res: {analytics: any[]}) => {
                        if (res.analytics.length > 0) {
                            let parsed = this.parseDataByDay(res.analytics, 'TicTacToe')
                                .then((parsedRes) => parsedRes);
                            resol(parsed);
                        } else {
                            resol([]);
                        }
                    })
                    .catch((err) => {
                        this.errOccurred = true;
                        console.error(err);
                    });
            }));

            Promise.all(promises)
                .then((res: [[{name:string, startTime: string, duration: number}]]) => {
                    const res2 = _.flattenDeep(res);
                    resolve(res2);
                })
                .catch((err) => {
                    console.error(err);
                    this.errOccurred = true;
                    reject(err);
                });
        });

        //TODO: add other games
        Promise.all([FlowGameData, TicTacToeData])
            .then((res) => _.flattenDeep(res))
            .then((allAnalytics) => {
                // Find the unique dates for the analytics
                const uniqueDates = allAnalytics
                    .map(elem => elem.startTime)
                    .filter(onlyUniqueFilter).sort();
                this.uniqueDates = uniqueDates;

                return {uniqueDates, allAnalytics};
            })
            .then((res2: {allAnalytics: AnalyticType[], uniqueDates: string[]}) =>
                res2.uniqueDates.sort().map((date) => {
                    const formattedSeries = res2.allAnalytics.filter((elem, index, arr) => elem.startTime === date).reduce((r, e) => {
                        //check if an elements with the same startTime and name exists in the array...
                        const existsInArr = r.find(elem => elem.startTime == date && elem.name == e.name);
                        if (existsInArr === undefined) {
                            //if it doesn't,
                            r.push({name: e.name, startTime: e.startTime, duration: e.duration});
                        } else {
                            let pos = r.findIndex(elem => elem.startTime === date);
                            const prevDuration = r[pos].duration;
                            r[pos] = {...r[pos], startTime: date, duration: prevDuration + e.duration};
                        }
                        return r;
                    }, [])
                        .map((elem) => ({
                            name: elem.name,
                            value: convertMillisToHours(elem.duration)
                        }));
                    return {
                        name: date,
                        series: formattedSeries
                    };
                }))
            .then((formattedData) => {
                this.data = [...formattedData];
                this.chartOptions.results = [...formattedData];
                this.dataReady = true;
                this.barVerticalStacked.update();
            })
            .catch(err => {
                this.errOccurred = true;
                console.error(err);
            });
    }

    /**
     * getActivityMetricsForSingleUser: retrieves ActivityData for specified user or 'me'
     */
    getActivityMetricsForSingleUser() {
        //if there is a target user, get data for them
        let query: AnalyticQuery = {
            startTime: this.queryDateRange[0],
            endTime: this.queryDateRange[1],
            granularity: this._granularitySelected,
            email: this.targetUser ? this.targetUser.anonymizedName : 'me',
            role: this.roleView
        };
        if (this.studyId) {
            query = {...query, role: 'researcher', study: this.studyId};
        }
        this.analyticService.getActivityAnalyticsForSingleUser(query)
            .toPromise()
            .then((res: ActivityAnalyticResponse) => {
                this.handleActivityAnalysisResponse(res);
            })
            .catch((err) => {
                console.error(err);
                this.errOccurred = true;
            });
    }

    /**
     * getActivityMetricsForManyUsers: retrieves Activity Metrics for multiple users
     *      -   functional as caregiver
     *      -   facilityAdmin functionality work in progress
     */
    getActivityMetricsForManyUsers() {
        if (!this.facilityId) this.facilityId = this.route.snapshot.queryParams['facilityId'];
        if (!this.studyId) this.studyId = this.route.snapshot.queryParams['studyId'];
        if (!this.roleView) this.roleView = this.router.url.split('/')[1].toLowerCase();

        var query: AnalyticQuery = {
            startTime: this.queryDateRange[0],
            endTime: this.queryDateRange[1],
            granularity: this._granularitySelected,
            role: this.roleView
        };
        if (this.facilityId) {
            query.facility = this.facilityId;
        }
        if (this.studyId) {
            query.role = 'researcher';
            query.study = this.studyId;
        }

        this.analyticService.getActivityAnalyticsForMultipleUsers(query)
            .toPromise()
            .then((res: ActivityAnalyticResponse) => {
                this.handleActivityAnalysisResponse(res);
            })
            .catch((err) => {
                console.error(err);
                this.errOccurred = true;
            });
    }

    /**
     * handleActivityAnalysisResponse: takes in the Activity Metric data, calculates total activity usage times,
     *                      sorts the data by timestamp, and re-formats the dates to be timezone correct
     * @param { ActivityAnalyticResponse } analytics
     */
    handleActivityAnalysisResponse(analytics: ActivityAnalyticResponse) {
        //TODO: map "ProgramRMedia" -> "Conversation Media"
        return new Promise((resolve, reject) => {
            this.overallTotalTime = convertMillisToHrsMinutes(analytics.TotalTimeForAllActivities);
            this.overallActivityUsageTime = this.calcTotalActivityUsageTimes(analytics.Summary);
            resolve(analytics.Details);
        })
            .then((formattedData: [{name: Date, series: [{name: string, value: number}]}]) => {
                const finalFormatted = formattedData
                    // sort the data by timestamp
                    .sort((a, b) => {
                        const aDate = new Date(a.name);
                        const bDate = new Date(b.name);
                        return aDate.getTime() - bDate.getTime();
                    })
                    .map((elem) => ({
                        name: this.formatDate(elem.name, this._granularitySelected),
                        series: elem.series.map((e) => ({
                            name: e.name,
                            //For hourly granularity, display values in units of minutes
                            value: this.selectedGranularity === 'hourly'
                                ? convertMillisToMinutes(e.value)
                                : convertMillisToHours(e.value)
                        }))
                            .sort((a, b) =>
                                //stack activities bottom->top in the order of the legend left->right
                                // (expected ranking of usage times)
                                this.activityTypes.indexOf(a.name) - this.activityTypes.indexOf(b.name)
                            )
                    }));
                this.updateBarVerticallyStackedData(finalFormatted);
                this.dataReady = true;
            })
            .catch((err) => {
                console.error(err);
                this.errOccurred = true;
            });
    }

    /**
     * parseDataByDay: a soon-to-deprecated function used in this.getGameMetricsDataForAllUsers() to format dates
     * @param { any[] } data
     * @param { string } name
     */
    async parseDataByDay(data: any[], name: string): Promise<AnalyticType[]> {
        if (!data) return [];

        if (data[0].totalDurationInMilliseconds) {
            data = data.map((log) => ({...log, duration: log.totalDurationInMilliseconds}));
        }
        if (data[0].totalDuration) {
            data = data.map((log) => ({...log, duration: log.totalDuration}));
        }

        return new Promise<AnalyticType[]>(async(resolve, reject) => {
            let parsedData = await data.sort((a, b) => {
                //sort by startTime
                const aStartTime = new Date(a.startTime);
                const bStartTime = new Date(b.startTime);
                return aStartTime.getTime() - bStartTime.getTime();
            })
                .map((log) => ({
                    name: name,
                    duration: log.duration,
                    startTime: log.startTime.substring(0, 10)
                }))
                .reduce((r, e) => {
                    const date = e.startTime.match(/(\d+-\d+-\d+)/)[0];
                    const existsInArr = r.find(elem => elem.startTime == date);
                    if (existsInArr == undefined) {
                        r.push({name: name, startTime: date, duration: e.duration});
                    } else {
                        let pos = r.findIndex(elem => elem.startTime == date);
                        const prevDuration = r[pos].duration;
                        r[pos] = {...r[pos], startTime: date, duration: prevDuration + e.duration};
                    }
                    return r;
                }, []) as AnalyticType[];
            resolve(parsedData);
        })
            .then((res) => res)
            .catch((err) => {
                console.error(err);
                this.errOccurred = true;
                return [] as AnalyticType[];
            });
    }

    /**
     * calcTotalUsageTimes: used to calculate the aggregated total times for all data provided
     * @param { any[] } data
     */
    public calcTotalActivityUsageTimes(data: any[]) {
        // if (data.length <= 0) return [];
        if (data[0]?.totalDurationInMilliseconds) {
            data = data.map((log) => ({...log, duration: log.totalDurationInMilliseconds}));
        }
        if (data[0]?.totalDuration) {
            data = data.map((log) => ({...log, duration: log.totalDuration}));
        }
        if (data[0]?.activity) {
            data = data.map((log) => ({...log, name: log.activity}));
        }

        const times = data.reduce((val, curr) => {
            const actName = curr.name.toLowerCase();
            if (val[actName] === undefined) {
                val[actName] = 0;
            }
            val[actName] += curr.duration;
            return val;
        }, {});

        //iterate over the keys, set appropriate values
        for (const key in this.overallActivityUsageTime) {
            // noinspection JSUnfilteredForInLoop
            this.overallActivityUsageTime[key] = convertMillisToHrsMinutes(times[key] || 0);
        }
        return this.overallActivityUsageTime;
    }

    /**
     * handleGranularityChange: handle changes to the granularity (Hourly, Daily, Weekly, etc.) for the graph
     * @param { string } granularity
     */
    handleGranularityChange(granularity: string) {
        granularity = granularity.toLowerCase();
        // if the granularity selected is different from the one previously selected, update and re-run the query
        if (granularity !== this._granularitySelected) {
            this._prevGranularitySelected = this._granularitySelected;
            this._granularitySelected = granularity;
            this.selectedGranularity = granularity;
            this.selectedGranularityChange.emit(granularity);
            this.triggerQuery();
        }
    }

    /**
     * dateRangeUpdate: handles RECEIVED updates to the date range
     *                  (as designed, updates originate from queryDateRangeControl, and are forwarded
     *                      along to stacked-bar-chart via the mutual parent component)
     * @param { [Date, Date] } newDates
     */
    dateRangeUpdate(newDates: [Date, Date]) {
        //Ensure valid dates
        if (!isValidDateRange(...newDates)) return;
        //format the dates to be the absolute beginning and absolute end of a local day
        //  ex: 2021-02-15T00:00:00.000Z  -  2021-03-20T23:59:59.999Z
        const newStart = moment(newDates[0]).startOf('day').toDate();
        const newEnd = moment(newDates[1]).endOf('day').toDate();
        this.queryDateRange = [newStart, newEnd];
        this.triggerQuery();
    }

    /**
     * formatDate: re-formats a Date object for graph labels depending on granularity as follows:
     *          granularity = 'daily' -> dayOfWeek, monthName date+ordinal_suffix (ex: Fri, June 4th)
     *          granularity = 'weekly' -> Week of dayOfWeek, monthName date+ordinal_suffix
     *                                      (ex: Week of Thurs, June 3rd)
     *          granularity = 'monthly' ->   monthName fullYear (ex: June 2021)
     *          granularity = 'yearly' ->  fullYear (ex: 2021)
     * @param { Date } d: the Date to be formatted
     * @param { string } granularity: the selected granularity
     */
    formatDate(d: Date, granularity: string) {
        granularity = granularity.toLowerCase();
        switch (granularity) {
        case 'hourly':
            //format by hour
            return moment.utc(d).format('MMM D, LT');
        case 'daily':
            //format by day
            return moment.utc(d).format('ddd, MMM D');
        case 'weekly':
            //format by day
            return moment.utc(d).format('ddd, MMM D');
        case 'monthly':
            //format by Month
            return moment.utc(d).format('MMM YYYY');
        }
    }

    /**
     * formatLabelsForGraph: format the X- and Y-axis labels according to the selected granularity
     * @private
     */
    private formatLabelsForGraph(): void {
        if (this._granularitySelected === 'hourly') {
            this.chartOptions.xAxisLabel = 'Hour';
            this.chartOptions.yAxisLabel = 'Time (Minutes)';
        }
        if (this._granularitySelected === 'daily') {
            this.chartOptions.xAxisLabel = 'Day';
            this.chartOptions.yAxisLabel = 'Time (Hours)';
        }
        if (this._granularitySelected === 'weekly') {
            this.chartOptions.xAxisLabel = 'Week Of';
            this.chartOptions.yAxisLabel = 'Time (Hours)';
        }
        if (this._granularitySelected === 'monthly') {
            this.chartOptions.xAxisLabel = 'Month';
            this.chartOptions.yAxisLabel = 'Time (Hours)';
        }
        // this.chartOptions.yAxisLabel = 'Time (Hours)';
    }

    /**
     * mapColorKeys: used by the graph to ensure each category is properly color-coded in both the graph itself
     *                  and in the legend
     * @param value
     */
    mapColorKeys = (value): string => mapColorKeys(value);

    capitalizeFirstLetter = (str: string): string => capitalizeFirstLetter(str);

    /**
     * getTooltipTemplateTime: used by the graph's tooltip to reformat the number of hours (as a float) into
     *                          a more legible `##h ##m` string (ex: 0.5000777777777777 -> `0h 30m`)
     * @param tooltipModel: a float number representing a number of hours
     */
    getTooltipTemplateTime(tooltipModel) {
        const timeHrsMin = convertMillisToHrsMinutes(convertHoursToMillis(tooltipModel.value));
        const timeMin = Math.round(tooltipModel.value);
        return this._granularitySelected === 'hourly' ? `${timeMin} min` : `${timeHrsMin.hours}h ${timeHrsMin.minutes}m`;
    }

    public ngOnDestroy(): void { }
}
