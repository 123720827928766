<div class="viewEvent" *ngIf="!state.editing">
    <div class="titleArea">
        <h3 class="title">Event Details</h3>
        <fa-icon [icon]="icon.delete" size="2x" class="icon" (click)="clearActiveEvent()"></fa-icon>
    </div>
    <hr>
    <h3 *ngIf="!event">Loading Event...</h3>
    <div *ngIf="event && event.title" class="detailsArea">
        <p class="detailCaption"><small>Title: </small></p>
        <h4>
            {{event.title}}
        </h4>
    </div>
    <div *ngIf="event && event.caption" class="detailsArea">
        <p class="detailCaption"><small>Description: </small></p>
        <h5>
            {{event.caption}}
        </h5>
    </div>
    <div *ngIf="event && event.eventType" class="detailsArea">
        <p class="detailCaption"><small>Event Type: </small></p>
        <h5>
            {{event.eventType}}
        </h5>
    </div>
    <div *ngIf="event && event.begin && !event.recurring" class="detailsArea">
        <p class="detailCaption"><small>Event Start: </small></p>
        <h5>
            {{getFormattedDateTime(event.begin)}}
        </h5>
    </div>
    <div *ngIf="event && event.end && !event.recurring" class="detailsArea">
        <p class="detailCaption"><small>Event End: </small></p>
        <h5>
            {{getFormattedDateTime(event.end)}}
        </h5>
    </div>
    <div *ngIf="event && event.recurring && event.recurring.rule" class="detailsArea">
        <p class="detailCaption"><small>Recurrence: </small></p>
        <h5>
            {{event.recurring.rule}}
        </h5>
    </div>
    <div *ngIf="event && event.recurring && event.recurring.dow" class="detailsArea">
        <p class="detailCaption"><small>Recurs Every: </small></p>
        <h5>
            {{event.recurring.dow.join(", ")}}
        </h5>
    </div>
    <div *ngIf="event && event.recurring && event.begin" class="detailsArea">
        <p class="detailCaption"><small>Recurrence Start: </small></p>
        <h5>
            {{event.begin | date:'shortDate'}}
        </h5>
    </div>
    <div *ngIf="event && event.recurring && event.duration" class="detailsArea">
        <p class="detailCaption"><small>Duration: </small></p>
        <h5>
            {{event.duration}}
        </h5>
    </div>
    <div *ngIf="event && event.recurring && event.end" class="detailsArea">
        <p class="detailCaption"><small>Recurrence End: </small></p>
        <h5>
            {{event.end | date:'shortDate'}}
        </h5>
    </div>



    <div class="viewEvent__buttonsArea" *ngIf="!state.confirmDelete">
        <button type="submit" class="btn btn-primary" (click)="toggleEdit(true)">Edit Event</button>
        <button type="button" class="btn btn-danger" (click)="toggleConfirmDelete(true)">Delete Event</button>
        <!-- <button type="button" class="btn btn-default" (click)="clearActiveEvent()">Close</button> -->
    </div>

    <h2 class="viewEvent__confirmDelete" *ngIf="!!state.confirmDelete">Are you sure? </h2>
    <div class="viewEvent__buttonsArea" *ngIf="!!state.confirmDelete">
        <button type="button" class="btn btn-danger" (click)="handleDelete()">Confirm Delete</button>
        <button type="button" class="btn btn-default" (click)="toggleConfirmDelete(false)">Cancel Delete</button>
    </div>
</div>

<div class="viewEvent" *ngIf="!!state.editing">
    <edit-event [user]="userId" [viewEventMethods]="{
        eventToEdit: event,
        handleEdit: handleEdit.bind(this),
        cancelEdit: toggleEdit.bind(this),
        closeEditEvent: clearActiveEvent.bind(this)
    }"></edit-event>
</div>
