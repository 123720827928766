<background></background>
<div class="passwordResetPage">
    <form class="passwordResetForm" name="form" #form="ngForm" (ngSubmit)="handleSubmit(form)">

        <div class="logoBox">
            <img class="passwordReset-overlay__logoDiv__img logo__img" src="/bcfcc9791c4b4bb0b3224c16fe6cd3ff.png" alt="DreamFace Technologies Logo">
        </div>

        <div class="title">
            <h4 class="passwordReset-overlay__textDiv__text text--white">
                Reset My Password
            </h4>
        </div>

        <div class="inputContainer">
            <label for="email">Email</label>
            <input id="email" name="email" class="email-input" placeholder="Email" email [(ngModel)]="email" #name="ngModel" required disabled="disabled">
        </div>

        <div class="inputContainer">
            <label for="password">Password</label>
            <input type="password" id="password" name="password" class="email-input" placeholder="Email" password [(ngModel)]="password" #name="ngModel" required>
        </div>

        <div class="inputContainer">
            <label for="repeatPassword">Confirm Password</label>
            <input type="password" id="repeatPassword" name="repeatPassword" class="email-input" placeholder="Confirm Password" password [(ngModel)]="repeatPassword" #name="ngModel" required>
        </div>

        <div class="alert-container" *ngIf="alert.show">
            <alert type="{{alert.type}}">{{alert.message}}</alert>
        </div>

        <div class="buttonsArea">
            <button type="submit" class="passwordReset-overlay__btnDiv btnDiv__btn">
                Reset my Password
            </button>
        </div>

        <hr>

        <div class="bottomInfo">
            <p class="noAcct__text text--grey">
                Forgot your Password?
                <a class="create-acct" routerLink="/account/forgotpassword">Forgot Password</a>
            </p>
        </div>

        <div class="bottomInfo">
            <p class="noAcct__text text--grey">
                Don't have an account?
                <a class="create-acct" routerLink="/account/create">Create an account</a>
            </p>
        </div>

    </form>
    <powered-by-dft class="logoBottom"></powered-by-dft>
</div>
<!--  <footer></footer>-->