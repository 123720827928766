<div class="modal-content orgClaimModal">
    <form id="assignRyan" name="assignRyan" role="form" class="form-horizontal">
        <div class="modal-header">
            <h4 id="myModalLabel" class="modal-title">Assign a Ryan to {{org.name}}</h4>
            <button type="button" (click)="bsModalRef.hide()" class="close"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </div>
        <div class="modal-body">
            <div role="alert" [hidden]="!formError" class="alert alert-danger">{{ formError }}</div>
            <div role="alert" [hidden]="!formInfo" class="alert alert-info">{{ formInfo }}</div>

            <div class="form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <table class="table">
                        <thead class="table__header">
                            <tr>
                                <th>ID</th>
                                <th>Status</th>
                                <th>Community</th>
                            </tr>
                        </thead>
                        <tbody class="table__body" *ngIf="allRyans">
                            <tr class="table__data" *ngFor="let bot of allRyans" [class.selected]="bot === assignedRyan" (click)="selectBot(bot)">
                                <td class="id">
                                    <span class="bg-logo-blue">{{bot._id}}</span>
                                </td>
                                <td class="status">{{bot.ryanState}}</td>
                                <td class="community">{{bot.organization?.name || 'n/a'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
        <div class="modal-footer" [hidden]="formInfo">
            <button (click)="bsModalRef.hide()" type="button" class="btn btndefault">
                Cancel</button>
            <button type="submit" class="btn btn-primary" (click)="submitInventoryAssignment()">Save</button>
        </div>
    </form>
</div>
