import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../components/auth/auth.service';
import { OrganizationService } from '../../services/organization/organization.service';
import { RouterModule, Routes } from '@angular/router';
import { VerifyComponent } from './verify/verify.component';
import { CreateAccountComponent } from './createaccount/createAccount.component';
import { AccountModule } from '../account/account.module';
import { AlertModule } from 'ngx-bootstrap/alert';

const facilityRoutes: Routes = [
    {
        path: ':facilityId/:role/:userId/verify',
        component: VerifyComponent,
    },
    {
        path: ':facilityId/:role/:userId/createaccount',
        component: CreateAccountComponent,
    },

];

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule.forChild(facilityRoutes),
        AlertModule,
        AccountModule,
    ],
    declarations: [
        VerifyComponent,
        CreateAccountComponent,
    ],
    providers: [
        OrganizationService,
        AuthService,
    ]
})
export class FacilityModule { }
