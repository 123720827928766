import {Component, Input} from '@angular/core';

@Component({
    selector: 'facility-icon',
    templateUrl: './facility-icon.svg',
    styleUrls: ['./facility-icon.scss']
})
export class FacilityIconComponent {
    @Input() ellipse:boolean;
    fillColor = '#3D73C5';
}
