<div class="modal-content">
    <form id="addUser" name="addUser" role="form" class="form-horizontal" (ngSubmit)="confirmSubmit()">
        <div class="modal-header">
            <h4 id="myModalLabel" class="modal-title">Add user to Facility: {{ addTo.name }}</h4>
        </div>

        <div class="modal-body" *ngIf="!createMode">
            <div role="alert" [hidden]="!formError" class="alert alert-danger">{{ formError }}</div>
            <div role="alert" [hidden]="!formInfo" class="alert alert-info">{{ formInfo }}</div>
            <h4>Create New User or Add Existing User?</h4>
            <div class="buttons-area" *ngIf="!createMode">
                <button type="button" class="btn btn-primary" (click)="setCreateMode('newUser')">Create New
                    User</button>
                <button type="button" class="btn btn-info" (click)="setCreateMode('existingUser')">Add Existing
                    User</button>
            </div>
        </div>


        <div class="modal-body" *ngIf="createMode === 'existingUser'">
            <div role="alert" [hidden]="!formError" class="alert alert-danger">{{ formError }}</div>
            <div role="alert" [hidden]="!formInfo" class="alert alert-info">{{ formInfo }}</div>

            <div class="form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <label for="orgName" class="control-label">User's Email Address</label>
                    <input id="orgName" type="email" required name="email" required placeholder="User Email" class="form-control" [(ngModel)]="email" #name="ngModel" minlength="3">
                </div>
            </div>
        </div>

        <div class="modal-body" *ngIf="createMode === 'newUser'">
            <div role="alert" [hidden]="!formError" class="alert alert-danger">{{ formError }}</div>
            <div role="alert" [hidden]="!formInfo" class="alert alert-info">{{ formInfo }}</div>

            <div class="form-group">
                <div>
                    <label for="email" class="control-label">Email Address</label>
                    <input id="email" type="email" required name="email" required placeholder="User Email" class="form-control" [(ngModel)]="newUser.email" #name="ngModel" minlength="3">
                </div>
            </div>

            <div class="form-group">
                <div>
                    <label for="firstName" class="control-label">First Name</label>
                    <input class="email-input" id="firstName" name="firstName" [(ngModel)]="newUser.firstName" #firstName="ngModel" placeholder="First Name" required>

                </div>
            </div>

            <div class="form-group">
                <div>
                    <label for="lastName" class="control-label">Last Name</label>
                    <input class="email-input" id="lastName" name="lastName" [(ngModel)]="newUser.lastName" #lastName="ngModel" placeholder="Last Name" required>
                </div>
            </div>


            <div class="form-group">
                <div>
                    <label for="password" class="control-label">Password</label>
                    <input class="pwd-input" id="password" name="password" disabled="disabled" type="text" [(ngModel)]="newUser.password" placeholder="Password" #password="ngModel" required>

                </div>
            </div>
        </div>

        <div class="modal-footer" [hidden]="formInfo">
            <button (click)="bsModalRef.hide()" type="button" class="btn btn-default">
                Cancel</button>
            <button type="submit" class="btn btn-primary" *ngIf="createMode === 'newUser'">Create
                User</button>
            <button type="submit" class="btn btn-primary" *ngIf="createMode === 'existingUser'">Add
                User</button>
        </div>
    </form>
</div>