import { Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { AuthService } from '../../../components/auth/auth.service';
import { UserType } from '../../../components/auth/user.service';
import { CalendarEventService } from '../../../services/calendar-event/calendar-event.service';
import { CalendarOptions, DateSelectArg, EventClickArg, EventInput, EventApi, FullCalendarComponent } from '@fullcalendar/angular'; // useful for typechecking
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'guardian-calendar',
    templateUrl: './guardianCalendar.html',
    styleUrls: ['./guardianCalendar.scss'],
})

export class GuardianCalendarComponent implements OnInit {
    userId = 'me';
    userIdSubscription = new BehaviorSubject(this.userId);

    state = {
        createEvent: false,
        activeEventId: undefined,
    }

    alert = {
        showFor: '',
        type: '',
        message: '',
    };

    currentUser: UserType;
    eventsList = [];
    usersInCare = [];
    static parameters = [AuthService, CalendarEventService, Router];
    constructor(authService: AuthService, public eventService: CalendarEventService, public router: Router) {
        this.eventService = eventService;
        this.router = router;

        authService.currentUserChanged.subscribe(user => {
            this.currentUser = user;
            this.usersInCare = authService.getUsersInCare();
        });
        this.userIdSubscription.subscribe({
            next: (newValue) => this.handleNewUserId(newValue),
            error: (e) => console.log(e),
        });
    }

    handleNewUserId(newUserId) {
        console.log('retrieving for...');
        console.log(newUserId);
        this.getEventsForUserId(newUserId);
    }

    changeUserFocusTo(newUserId?) {
        console.log(`Changin user focus to ${newUserId}`);
        this.userIdSubscription.next(newUserId || 'me');
        return;
    }

    ngOnInit() {
    }

    getEventsForUserId(userId) {
        return this.eventService.query(userId)
            .then((events) => {
                this.userId = userId;
                this.eventsList = events;
                this.showAlertTemporary('calendar', 'info', `Successfully retrieved ${events.length} events for ${userId}`, 3000);
                return;
            })
            .catch((err) => {
                console.error(err);
                this.showAlertTemporary('calendar', 'warning', `Unable to get events for ${userId}`, 3000);
            });
    }

    toggleCreateEvent() {
        this.state = { ...this.state, createEvent: !this.state.createEvent };
    }

    showAlertTemporary(showFor: string, type: string, message: string, milliseconds: number) {
        this.alert = {
            showFor,
            type,
            message,
        };
        window.setTimeout(() => {
            this.clearAlert();
        }, milliseconds);
    }

    clearAlert() {
        this.alert = {
            showFor: '',
            type: '',
            message: '',
        };
    }

    handleCreateEvent(eventToCreate) {
        this.eventService.create(eventToCreate, this.userId)
            .then((eventCreated) => {
                this.eventsList = [...this.eventsList, eventCreated];
            })
            .catch((err) => {
                console.log(err);
            });

        this.state = { ...this.state, createEvent: false };
    }

    handleEditEvent(editedEvent) {
        this.eventsList = this.eventsList.map(e => {
            if (editedEvent._id === e._id) {
                return editedEvent;
            }
            return e;
        });
    }

    handleDeleteEvent(eventId) {
        this.eventsList = this.eventsList.filter(e => e._id !== eventId);
    }
}
