<div class="verifyPage">
    <background></background>
    <div class="verifyForm">
        <div *ngIf="!alert.show">
            <h4>Do you confirm that you are</h4>
            <h4>a {{routeParams.role}} of</h4>
            <h4>Facility: {{facility.name || 'n/a'}}</h4>
        </div>
        <alert class="fullWidth" type="{{alert.type}}" *ngIf="alert.show">
            {{alert.message}}
        </alert>
        <div class="buttonsArea" *ngIf="!alert.show">
            <button (click)="handleSubmit()" class="btn btn-primary">Confirm</button>
            <button (click)="goToLogin()" class="btn btn-warning">Cancel</button>
        </div>
        <div class="buttonsArea" *ngIf="alert.show">
            <button (click)="goToLogin()" class="btn btn-warning">Go to Login</button>
        </div>
    </div>

</div>
