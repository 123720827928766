import {NgModule, SimpleChange} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { RouterModule } from '@angular/router';
import { AuthModule} from '../auth/auth.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatButtonModule } from '@angular/material/button';
import { StackedBarChartComponent} from './stacked-bar-chart/stacked-bar-chart.component';
import { VerticalBarChartComponent } from './vertical-bar-chart/vertical-bar-chart.component';
import { ConversationGraphComponent } from './conversation-graph/conversation-graph.component';
import {HardwareGraphComponent} from './hardware-graph/hardware-graph.component';
import { AdvancedPieChartComponent } from './adv-pie-chart/advanced-pie-chart.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatTableModule } from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {DirectivesModule} from '../directives.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';

@NgModule({
    imports: [
        CommonModule,
        CollapseModule,
        AuthModule,
        RouterModule,
        AccordionModule,
        MatExpansionModule,
        FontAwesomeModule,
        MatIconModule,
        CdkTreeModule,
        MatButtonModule,
        BsDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
        NgxChartsModule,
        MatTableModule,
        AlertModule,
        MatProgressSpinnerModule,
        ProgressbarModule,
        TooltipModule,
        MatPaginatorModule,
        MatSortModule,
        DirectivesModule,
        MatInputModule,
        MatFormFieldModule
    ],
    declarations: [
        StackedBarChartComponent,
        VerticalBarChartComponent,
        ConversationGraphComponent,
        AdvancedPieChartComponent,
        HardwareGraphComponent,
    ],
    exports: [
        StackedBarChartComponent,
        VerticalBarChartComponent,
        ConversationGraphComponent,
        AdvancedPieChartComponent,
        HardwareGraphComponent,
    ]
})
export class GraphsModule { }
