import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from "../../auth/auth.service";
import {UserType, UserService} from "../../auth/user.service";
import {ActivityAnalyticResponse, AnalyticQuery, AnalyticsService} from "../../../services/analytics/analytics.service";
import {BarVerticalComponent, BarVerticalStackedComponent} from "@swimlane/ngx-charts";
import {OrganizationService} from "../../../services/organization/organization.service";
import {sum} from "d3-array";
import _ from 'lodash';


@Component({
    selector: 'vertical-bar-chart',
    templateUrl: './vertical-bar-chart.html',
    providers: [BarVerticalComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./vertical-bar-chart.scss']
})


export class VerticalBarChartComponent implements OnInit, OnDestroy {
    @Input() graphTitle: string;
    @Input() data: any = undefined;
    @Input() dataName: string = undefined;

    Router;

    //see the ful list of options at: https://swimlane.gitbook.io/ngx-charts/examples/bar-charts/vertical-bar-chart
    chartOptions = {
        // options
        view: undefined,
        results: this.data,
        schemeType: 'ordinal', //default
        showXAxis: true, //show xAxis
        showYAxis: true, //show yAxis
        gradient: false,
        showLegend: false, //show the legend
        showXAxisLabel: true,
        xAxisLabel: 'Day',
        showYAxisLabel: true,
        yAxisLabel: 'Time (hrs)',
        yAxisTicks: undefined,
        animations: true,
        yScaleMax: undefined,
        yScaleMin: 0,
        colorScheme: { //color scheme of the chart
            domain: ['#58C2EE']
        },

        onSelect(ev){
            console.log(ev);
        }
    };

    parsedTotalUsageData;
    dailyTotals: any[];


    uniqueDates: string[];

    static parameters = [Router, AuthService, BarVerticalComponent];
    constructor(public router: Router, public authService: AuthService, public barVertical: BarVerticalComponent) {
        this.Router = router;
        this.authService = authService;
        this.barVertical = barVertical;
    }

    ngOnInit() {
        console.log(this.data);
        this.initBarVertical();
    }

    initBarVertical(){
        console.log(this.barVertical);
        this.chartOptions.results = this.data;

        //pre-define the labels of the y-Axis to be [0-sumOfValues] in increments of 5
        const sumOfVals = this.data
            .map(v => v.value)
            .reduce((sum, val) =>{
                return sum += val;
            }, 0);
        console.log(sumOfVals);
        if(sumOfVals < 5){
            this.chartOptions.yAxisTicks = _.range(0, 7, 2).map(String) as string[];
            this.chartOptions.yScaleMax = 7;
        }
        else if(sumOfVals < 10){
            this.chartOptions.yAxisTicks = _.range(0, 11, 2).map(String) as string[];

            this.chartOptions.yScaleMax = 10;
        } else {
            var nearestIncremOfFive = Math.ceil(sumOfVals/5)*5;
            console.log(nearestIncremOfFive);
            this.chartOptions.yAxisTicks = _.range(0, (nearestIncremOfFive+1), 5).map(String) as string[];
            this.chartOptions.yScaleMax = nearestIncremOfFive;
        }

        this.chartOptions.yScaleMin = 0;
        console.log(this.chartOptions.yAxisTicks);
        console.log(this.barVertical.yAxisTicks);
        this.chartOptions.yAxisLabel = 'Num. Users';
        console.log(this.barVertical);
        this.barVertical.update();
    }

    // getTooltipTemplateTime(tooltipModel){
    //     const time = this.msToTime(convertHoursToMillis(tooltipModel.value));
    //     return `${time.hours}h ${time.minutes}m`;
    // }

    public ngOnDestroy(): void { }

}
