<div class="modal-content">
    <form id="addAdmin" name="addAdmin" role="form" class="form-horizontal" (ngSubmit)="submitResearcherAssignment()">
        <div class="modal-header">
            <h4 id="myModalLabel" class="modal-title">Please select a {{role | formatRole}} to be added to this {{entity | formatEntityTypeName:'normalized'}}</h4>
            <button type="button" (click)="bsModalRef.hide()" class="close"><span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>

        <div class="modal-body">
            <ul class="facilityOptionList" *ngIf="optionResearchers.length > 0">
                <li class="facilityListing" *ngFor="let researcher of optionResearchers" [class.isHighlighted]="isSelected(researcher)" (click)="toggleResearcherMembership(researcher)">
                    <div>
                        <h5>{{researcher}}</h5>
                    </div>
                </li>
            </ul>
            <ng-container *ngIf="optionResearchers.length === 0">
                <h5 class="center">Sorry, there are no eligible {{role | formatRole | lowercase}}s whom are not already in this study</h5>
            </ng-container>
        </div>

        <!--  ======  Alert  ======  -->
        <alert *ngIf="!!alert && alert?.show && alert?.message" [type]="alert.type" [hidden]="!alert || !alert?.show" [dismissible]="true" (onClosed)="alert.show = false">
            <strong *ngIf="(alert.type | lowercase) === 'success'">Success: </strong>
            <strong *ngIf="(alert.type | lowercase) === 'danger'">Error: </strong>
            {{ alert.message }}
        </alert>

        <div class="modal-footer">
            <button (click)="bsModalRef.hide()" type="reset" class="btn btn-default">Cancel</button>
            <button type="submit" class="btn btn-primary" [disabled]="alert?.show">Confirm</button>
        </div>
    </form>
</div>
