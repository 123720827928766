// // @flow
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrganizationService } from '../organization/organization.service';
import { Inventory, InventoryData } from '../../components/interfaces/Inventory';

@Injectable()
export class InventoryService {
    public baseUrl = '/api/inventory';
    static parameters = [HttpClient, OrganizationService];
    constructor(public http: HttpClient, public organizationService: OrganizationService) {
        this.http = http;
        this.organizationService = organizationService;
    }

    query() {
        return this.http.get(this.baseUrl)
            .toPromise()
            .then((data: any) => {
                // all inventory => data.ryans
                console.log(data);
                return data.ryans.map(ryan => (ryan.value));
            })
            .then((inventoryData) => {
                return Promise.resolve(
                    this.assignOrganizationToRyans(inventoryData)
                );
            })
            .catch((err) => (err));
    }

    getAllInventory() {
        return this.http.get(this.baseUrl, {
            params: {
                state: 'all'
            }
        })
            .toPromise()
            .then((data: InventoryData) => Promise.resolve(data))
            .catch((err) => Promise.reject(err));
    }

    getRyanStatus(id) {
        return this.http.get(`${this.baseUrl}/device/status/${id}`)
            .toPromise();
    }

    getActiveInventory() {
        return this.http.get(this.baseUrl, {
            params: {
                state: 'active'
            }
        })
            .toPromise()
            .then((data: any) => {
                // inventory => data.ryans
                return data;
            })
            .catch((err) => {
                return (err)
            });
    }

    getSuspendedInventory() {
        return this.http.get(this.baseUrl, {
            params: {
                state: 'suspended'
            }
        })
            .toPromise()
            .then((data: any) => {
                // inventory => data.ryans
                return data;
            })
            .catch((err) => {
                return (err)
            });
    }

    getDisabledInventory() {
        return this.http.get(this.baseUrl, {
            params: {
                state: 'disabled'
            }
        })
            .toPromise()
            .then((data: any) => {
                // inventory => data.ryans
                return data;
            })
            .catch((err) => {
                return (err)
            });
    }

    getInventoryById(id) {
        // returns ryan in an array
        return this.http.get(this.baseUrl + '/' + id, id)
            .toPromise()
            .then((data) => {
                return (data)
            })
            .catch((err) => {
                return (err)
            });
    }

    create(inventory: Inventory) {
        return this.http.post(this.baseUrl, inventory)
            .toPromise()
            .then((data) => {
                return (data)
            })
            .catch((err) => {
                return (err)
            })
    }

    upsert(inventory: Inventory) {
        return this.http.put(this.baseUrl, inventory)
            .toPromise()
            .then((data) => {
                return (data)
            })
            .catch((err) => {
                return (err)
            })
    }

    remove(inventory: Inventory) {
        return this.http.delete(this.baseUrl)
            .toPromise()
            .then((data) => {
                return (data)
            })
            .catch((err) => {
                return (err)
            })
    }

    assignOrganizationToRyans(ryanList: any[]) {
        return this.organizationService.getAllOrganizations()
            .then(({ organizations }) => {
                ryanList.forEach((ryan) => {
                    const orgOfThisRyan =
                        organizations.map(o => o.value).find((org) => {
                            const ryanIdsOfOrg = org.ryans ? org.ryans.map(r => r._id) : null;
                            return ryanIdsOfOrg ? ryanIdsOfOrg.includes(ryan._id) : false;
                        })
                    if (orgOfThisRyan) {
                        ryan.organization = {
                            _id: orgOfThisRyan._id,
                            name: orgOfThisRyan.name,
                            address: orgOfThisRyan.address,
                            city: orgOfThisRyan.city,
                            administrators: orgOfThisRyan.administrators,
                        };
                    }

                })
                return ryanList;
            })
            .catch((e) => (e))
    }
}
