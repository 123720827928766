import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { UserService } from '../../../components/auth/user.service';
import {
    faCloudDownloadAlt,
    faFilter,
    faMinusCircle,
    faPlus,
    faSearch,
    faSortAmountDown,
    faUserCircle,
    faRobot
} from '@fortawesome/free-solid-svg-icons';
import { faBell, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { InventoryService } from '../../../services/inventory/inventory.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OrganizationService } from '../../../services/organization/organization.service';
import {
    instanceOfOrganization,
    OrgAdminData,
    Organization,
    OrganizationRoleName
} from '../../../components/interfaces/Organization';
import {Subscription} from 'rxjs';
import {AppState} from '../../../components/interfaces/AppState';
import {Inventory} from '../../../components/interfaces/Inventory';
import {ActivityAnalyticResponse, AnalyticQuery, AnalyticsService} from '../../../services/analytics/analytics.service';
import {activityTypes, granularityOptions} from '../../../../server/config/environment/shared';
import moment from 'moment';
import {GranularityOptions} from '../../../components/graphs/granularityOptions';
import {
    getAge,
    mapColorKeys,
    convertMillisToMinutes,
    convertMillisToHoursMinutesSecondsAndMillis, isValidDate, convertMillisToHrsMinutes
} from '../../../components/util';


@Component({
    selector: 'orgadmin-ryanData',
    templateUrl: './orgadminRyanData.html',
    styleUrls: ['../../../assets/sharedStyles/pages/ryandata.scss', './orgadminRyanData.scss'],
})


export class OrgAdminRyanDataComponent implements OnInit, OnDestroy, OnChanges {
    queryParamSubscription: Subscription;
    focusedOrgId: string;
    focusedUserId: string;
    focusedRyanId: string;
    @Input('state') appState: AppState;
    currentUser;
    selectedRange: Date[];
    @Input()
    selectedGranularity: GranularityOptions = 'daily';
    granularityOptions = granularityOptions;
    state = {
        isOrgAdmin: false,
        org_id: undefined,
        ryan_id: undefined
    };
    eventDataForCurrentRobot: any;
    eventDataForCurrentRobotReady: boolean = false;
    eventDataForAllRobotsInOrganization: any;
    activityGraphMultipleRobots: any;
    icon = {
        userCircle: faUserCircle,
        bell: faBell,
        search: faSearch,
        sort: faSortAmountDown,
        calendar: faCalendarAlt,
        filter: faFilter,
        download: faCloudDownloadAlt,
        add: faPlus,
        delete: faMinusCircle,
        robot: faRobot
    };
    pieChartColorScheme = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
    };
    colorScheme = {
        domain: ['#08DDC1', '#FFDC1B', '#FF5E3A']
    };

    data = [
        {
            name: 'Microsoft Speech Engine',
            series: [
                {
                    name: 'Aug',
                    value: 0,
                    tooltipText: 'Microsoft Speech Engine: Unavailable'
                },
                {
                    name: 'Sep',
                    value: 0,
                    tooltipText: 'Microsoft Speech Engine: Unavailable'
                },
                {
                    name: 'Oct',
                    value: 0,
                    tooltipText: 'Microsoft Speech Engine: Unavailable'
                }
            ]
        },
        {
            name: 'Microsoft Speech Engine',
            series: [
                {
                    name: 'Nov',
                    value: 1,
                    tooltipText: 'Microsoft Speech Engine: available'
                }
            ]
        },
        {
            name: 'Microsoft Speech Engine',
            series: [
                {
                    name: 'Dec',
                    value: 0,
                    tooltipText: 'Microsoft Speech Engine: Unavailable'
                },
                {
                    name: 'Jan',
                    value: 0,
                    tooltipText: 'Microsoft Speech Engine: Unavailable'
                }
            ]
        },
        {
            name: 'Program R',
            series: [
                {
                    name: 'Aug',
                    value: 0.1,
                    tooltipText: 'Program R: Unavailable'
                },
                {
                    name: 'Sep',
                    value: 0.1,
                    tooltipText: 'Program R: Unavailable'
                }
            ]
        },
        {
            name: 'Program R',
            series: [
                {
                    name: 'Oct',
                    value: 1.1,
                    tooltipText: 'Program R: Available'
                },
                {
                    name: 'Nov',
                    value: 1.1,
                    tooltipText: 'Program R: Available'
                }
            ]
        },
        {
            name: 'Program R',
            series: [
                {
                    name: 'Dec',
                    value: 0.1,
                    tooltipText: 'Program R: Unavailable'
                },
                {
                    name: 'Jan',
                    value: 0.1,
                    tooltipText: 'Program R: Unavailable'
                }
            ]
        },
        {
            name: 'Johnny Server',
            series: [
                {
                    name: 'Aug',
                    value: 1.2,
                    tooltipText: 'Johnny Server: Available'
                },
                {
                    name: 'Sep',
                    value: 1.2,
                    tooltipText: 'Johnny Server: Available'
                },
                {
                    name: 'Oct',
                    value: 1.2,
                    tooltipText: 'Johnny Server: Available'
                },

                {
                    name: 'Nov',
                    value: 1.2,
                    tooltipText: 'Johnny Server: Available'
                }
            ]
        },
        {
            name: 'Johnny Server',
            series: [
                {
                    name: 'Dec',
                    value: 0.2,
                    tooltipText: 'Johnny Server: Unavailable'
                },
                {
                    name: 'Jan',
                    value: 0.2,
                    tooltipText: 'Johnny Server: Unavailable'
                }
            ]
        }
    ];
    // options
    showXAxis = true;
    showYAxis = true;
    showLegend = true;
    showXAxisLabel = true;
    xAxisLabel = 'Country';
    showYAxisLabel = true;
    yAxisLabel = 'Population';
    view: any[] = [700, 400];

    gradient = false;
    distinctBots: any;
    bots = {
        all: [] as Inventory[],
        active: [] as Inventory[],
        suspended: [] as Inventory[],
        disabled: [] as Inventory[]
    };
    botsLoaded: boolean = false;
    currentOrganization: Organization = null;

    allOrgs: Organization[] = [];
    status: any = {};

    static parameters = [Router, ActivatedRoute, UserService, InventoryService, OrganizationService, BsModalService, AnalyticsService];

    constructor(public router: Router, public route: ActivatedRoute, public userService: UserService, public inventoryService: InventoryService,
                public organizationService: OrganizationService, public modalService: BsModalService, public analyticService: AnalyticsService,) {
        this.router = router;
        this.route = route;
        this.userService = userService;
        this.inventoryService = inventoryService;
        this.organizationService = organizationService;
        this.modalService = modalService;
        this.analyticService = analyticService;
        this.activityGraphMultipleRobots = {};
        this.selectedRange = [
            moment(new Date(new Date().setDate(new Date().getDate() - 14))).startOf('day').toDate(),
            moment().endOf('day').toDate()];
        this.queryParamSubscription = this.route.queryParamMap.subscribe(queryParams => {
            this.focusedOrgId = queryParams.has('orgId') ? queryParams.get('orgId') : undefined;
            this.focusedUserId = queryParams.has('userId') ? queryParams.get('userId') : undefined;
            this.focusedRyanId = queryParams.has('ryanId') ? queryParams.get('ryanId') : undefined;
        });
    }

    ngOnInit(): void {
        this.fullyRefreshOrgs();
    }

    fullyRefreshOrgs() {
        this.refreshOrgs()
            .then(() => {
                if (this.focusedOrgId) {
                    this.currentOrganization = this.getCurrentOrganization();
                    if (this.currentOrganization.ryans && this.currentOrganization.ryans.length > 0) {
                        this.bots.all = this.currentOrganization.ryans;
                        this.botsLoaded = true;
                        this.triggerQuery();
                        //sample data
                        this.activityGraphMultipleRobots.PieChart = [
                            {
                                name: 'Germany',
                                value: 8940000
                            },
                            {
                                name: 'USA',
                                value: 5000000
                            },
                            {
                                name: 'France',
                                value: 7200000
                            },
                            {
                                name: 'UK',
                                value: 6200000
                            }
                        ];
                    }
                }
            })
            .catch(err => console.error(err));
    }
    focusOnOrganization(orgId: string = undefined) {
        this.focusedRyanId = undefined;
        this.router.navigate([], {queryParams: { orgId: orgId}});
        this.currentOrganization = this.getOrganization(orgId);
        if (this.currentOrganization.ryans && this.currentOrganization.ryans.length > 0) {
            this.bots.all = this.currentOrganization.ryans;
            this.botsLoaded = true;
            this.getEventDataForMultipleRobots();
            this.bots.all.forEach(bot => {
                bot.lastKnownEvent = this.eventDataForAllRobotsInOrganization.HardwareEvents.filter(hw => hw.robot === bot._id)[0];
            });
            //sample data
            this.activityGraphMultipleRobots.PieChart = [
                {
                    name: 'Germany',
                    value: 8940000
                },
                {
                    name: 'USA',
                    value: 5000000
                },
                {
                    name: 'France',
                    value: 7200000
                },
                {
                    name: 'UK',
                    value: 6200000
                }
            ];
        }
    }

    focusOnRyan(ryanId: string = undefined) {
        const focusedOrganization = this.getCurrentOrganization();
        this.router.navigate([], {queryParams: {ryanId: ryanId, orgId: focusedOrganization._id}})
            .then(() => this.triggerQuery());
    }
    getCurrentOrganization() {
        return this.allOrgs.find(o => o._id === this.focusedOrgId);
    }

    getCurrentRyan() {
        return this.bots.all.find(o => o._id === this.focusedRyanId);
    }

    getRyanStatus(ryanId?: string) {
       this.inventoryService.getRyanStatus(ryanId)
            .then(res => {
                console.log(this.status);
                this.status = res;
            })
            .catch(err => {
                console.error(err);
            });
    }

    getOrganization(orgId?: string) {
        return this.allOrgs.find(o => o._id === orgId) || null;
    }
    changeGranularity(newValue: string) {
        newValue = newValue.toLowerCase();
        if (granularityOptions.includes(newValue) && newValue !== this.selectedGranularity) {
            this.selectedGranularity = newValue as GranularityOptions;
            this.triggerQuery();
        }
    }
    changeSelectedRange(newDates: Date[]) {
        //check the year of the first date to handle the bsDateRangePicker init value of Dec 31, 1969
        if (!isValidDate(newDates[0]) || !isValidDate(newDates[1]) || newDates[0].getFullYear() < 1971) return;
        //prevent redundant updates
        if (newDates[0].getTime() === this.selectedRange[0].getTime() && newDates[1].getTime() === this.selectedRange[1].getTime()) return;
        this.selectedRange = [...newDates];
        this.triggerQuery();
    }
    triggerQuery() {
        if (!this.focusedOrgId) return;
        if (this.focusedRyanId) {
            this.getSingleRyanData(this.focusedRyanId);
            this.getRyanStatus(this.focusedRyanId);
        } else {
            this.getMultipleRyanData();
        }
    }

    getSingleRyanData(ryanId?: string) {
        this.getEventDataForSingleRobot(ryanId);
    }


    getMultipleRyanData() {
        this.getEventDataForMultipleRobots();
    }
    botSelect(evt) {
        console.log(evt);
        //    TODO: when user select is updated, change the query and re-filter/parse/format data
    }
    checkForFocusedOrganization() {
        if (this.focusedOrgId) this.focusOnOrganization(this.focusedOrgId);
    }


    checkIfOnlyOneOrgThenFocus(orgArr: any[]) {
        if (orgArr.length === 1) {
            this.focusOnOrganization(orgArr[0]._id);
            this.currentOrganization = orgArr[0];
        }
    }

    refreshOrgs() {
        return this.organizationService.getOrganizationsForAdmin().toPromise()
            .then((orgs: OrgAdminData) => {
                const { organizations } = orgs;
                this.allOrgs = [...organizations];
                // remove this when api is changed to show whole facilityObject
                return organizations as Organization[];
            })
            .catch(e => console.error(e));
    }
    /**
     * get available information on an Org's Admin
     * @param orgAdmin
     */
    getUser(orgAdmin: string) {
        let test;
        this.userService.getUserByEmail(orgAdmin).subscribe((user) => {
            test = user;
        });
    }
    makePatch() {
        console.log('make patch');
    }

    private setupQueryForMultipleRobots(): AnalyticQuery {
        const currentOrganization = this.getCurrentOrganization();
        let start = new Date(this.selectedRange[0]);
        let end = new Date(this.selectedRange[1]);
        return {
            startTime: start,
            endTime: end,
            role: 'organizationadmin',
            granularity: this.selectedGranularity,
            organization: currentOrganization._id
        };
    }

    private setupQueryForSingleRobot(ryanId : string): AnalyticQuery {
        const currentRyan = this.getCurrentRyan();
        const currentOrganization = this.getCurrentOrganization();
        let start = new Date(this.selectedRange[0]);
        let end = new Date(this.selectedRange[1]);
        return {
            startTime: start,
            endTime: end,
            role: 'organizationadmin',
            granularity: this.selectedGranularity,
            ryanId: ryanId || currentRyan._id,
            organization: currentOrganization._id
        };
    }

    getEventDataForSingleRobot(ryanId?: string) {
        let query = this.setupQueryForSingleRobot(ryanId);
        this.analyticService.getHardWareEventsForSingleRobot(query)
            .then((analytics:any) => {
                this.eventDataForCurrentRobot = analytics.HardwareEvents;
                this.eventDataForCurrentRobotReady = this.eventDataForCurrentRobot.length > 0;
            })
            .catch(err => console.error(err));
    }

    getEventDataForMultipleRobots() {
        let query = this.setupQueryForMultipleRobots();
        this.analyticService.getHardWareEventsForMultipleRobotsInOrganization(query)
            .then((analytics:any) => {
                this.eventDataForAllRobotsInOrganization = analytics;
            })
            .catch(err => console.error(err));
    }


    onSelect(event) {
        console.log(event);
    }

    userSelect(evt) {
        console.log(evt);
    }

    mapColorKeys(value) {
        return mapColorKeys(value);
    }

    formatPieChartValues = (value: number) : string => {
        const dur = moment.duration(value);
        const durHrMin = convertMillisToHrsMinutes(value);
        return dur.asMinutes() <= 60
            ? `${Math.round(dur.asMinutes() * 100.0) / 100.0}m`
            : `${durHrMin.hours}h ${durHrMin.minutes}m`;
    }

    //TODO: Map 'ProgramRMedia' to "Conversation media"
    formatPieChartNames = (name: string) : string => name;

    /**
     * getTooltipTemplateTime: used by the graph's tooltip to reformat the number of hours (as a float) into
     *                          a more legible `##h ##m` string (ex: 0.5000777777777777 -> `0h 30m`)
     * @param tooltipModel: a float number representing a number of hours
     */
    getTooltipTemplateTime(tooltipModel) {
        const dur = moment.duration(tooltipModel.value);
        const durHrMin = convertMillisToHrsMinutes(tooltipModel.value);

        return dur.asMinutes() <= 60
            ? `${Math.round(dur.asMinutes() * 100.0) / 100.0}min`
            : `${durHrMin.hours}hr ${durHrMin.minutes}min`;
    }
    ngOnChanges(changes: SimpleChanges): void {
        const { previousValue, currentValue } = changes['state'];
    }

    ngOnDestroy(): void { }
}
