import { Component } from '@angular/core';
import { AuthService } from '../../../components/auth/auth.service';
import { Router } from '@angular/router';
import { emailValidation } from '../../../components/util';
import { genderTypes } from '../../../../server/config/environment/shared';

interface User {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    repeatPassword: string;
    birthday: string;
    gender: string;
    weight: number;
}

@Component({
    selector: 'createAccount',
    templateUrl: './createAccount.html',
    styleUrls: ['./createAccount.scss']
})
export class CreateAccountComponent {
    user: User = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        repeatPassword: '',
        birthday: '',
        gender: '',
        weight: undefined,
    };

    genderTypes = genderTypes;

    privacyAgreement: false;
    cookiesPolicy: false

    return = '/user/dashboard';
    // signupQueryParams = '';

    alert = {
        show: false,
        type: '',
        message: '',
    }

    currentDate: Date;
    public authService: AuthService;

    static parameters = [Router, AuthService];

    constructor(public router: Router, authService: AuthService) {
        this.router = router;
        this.authService = authService;
        this.currentDate = new Date();
    }

    ngOnInit() { }

    register(form) {
        this.clearAlert();
        const { firstName, lastName, email, password, repeatPassword, birthday, weight, gender, cookiesPolicy, privacyAgreement } = form.value;
        if (form.invalid) {
            this.alert = {
                show: true,
                type: 'danger',
                message: 'Please complete all fields',
            };
            return;
        }
        let message = '';
        if (!cookiesPolicy || !privacyAgreement) message = 'Please agree to our policies.';
        if (password !== repeatPassword) message = 'Passwords do not match';
        if (!emailValidation(email)) message = 'Please submit a valid email.';
        if (message) {
            this.alert = {
                show: true,
                type: 'danger',
                message: message,
            };
            return;
        }

        const newUser = {
            firstName,
            lastName,
            email: email.toLowerCase(),
            password,
            repeatPassword,
            birthday,
            weight,
            gender,
            // TODO: when users model is updated
            // cookiesPolicy,
            // privacyAgreement,
        };
        return this.authService.createUser(newUser)
            .then(() =>
                //Account created, log in
                this.authService.login({ email: this.user.email.toLowerCase(), password: this.user.password })
            )
            .then(() => {
                //logged in, redirect to query param "return" or /home
                this.router.navigateByUrl(this.return);
            })
            .catch(({ error }) => {
                this.alert = {
                    show: true,
                    type: 'danger',
                    message: error.message && typeof error.message === 'string' ? error.message : 'An error occurred.',
                };
            });
    }

    clearAlert() {
        this.alert = {
            show: false,
            type: '',
            message: '',
        };
    }
}
