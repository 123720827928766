import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserType } from '../../auth/user.service';
import { Facility } from '../../interfaces/Facility';
import {CDK_DRAG_CONFIG, CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

const DragConfig = {
    zIndex: 10000
};

@Component({
    selector: 'viewEditCaregiverAssignments',
    templateUrl: './viewEditCaregiverAssignments.html',
    styleUrls: ['./viewEditCaregiverAssignments.scss'],
    providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }]
})
export class ViewEditCaregiverAssignmentsComponent implements OnInit {

    @Input() submitting: boolean = false;
    @Output() confirmChanges: EventEmitter<any> = new EventEmitter();
    @Input()
    alert: { show: boolean, type: string, message: string } = {
        show: false,
        type: '',
        message: '',
    }

    facility: Facility; //the facility in which this is all taking place
    caregiverEmail: string; //the email of the caregiver being assigned to the user
    assignedUsers: UserType[] = [];
    notAssignedUsers: UserType[] = [];

    assignedUsers_original: UserType[] = [];
    notAssignedUsers_original: UserType[] = [];

    static parameters = [BsModalRef];

    constructor(public bsModalRef: BsModalRef) {
        this.bsModalRef = bsModalRef;
    }

    ngOnInit(): void {
        this.assignedUsers_original = [...this.assignedUsers];
        this.notAssignedUsers_original = [...this.notAssignedUsers];
    }

    getEmailVerifiedCaregivers(caregivers: any[]) {

    }

    reset() { }

    drop(event: CdkDragDrop<UserType[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }

    //Detect if the list of assigned & unassigned users has changed, regardless of order
    changesMade(): boolean {
        var assignedOrig = new Set(this.assignedUsers_original);
        var assignedNow = new Set(this.assignedUsers);
        var notAssignedOrig = new Set(this.notAssignedUsers_original);
        var notAssignedNow = new Set(this.notAssignedUsers);
        // const assignedChanged = (this.assignedUsers_original.map(a => a.email).sort()) === (this.assignedUsers.map(a => a.email).sort());
        // const notAssignedChanged = (this.notAssignedUsers_original.map(a => a.email).sort()) === (this.notAssignedUsers.map(a => a.email).sort());
        return (!this.eqSet(assignedOrig, assignedNow) || !this.eqSet(notAssignedOrig, notAssignedNow));
    }
    eqSet(as, bs): boolean {
        return as.size === bs.size && this.all(this.isIn(bs), as);
    }

    all(pred, as): boolean {
        for (var a of as) if (!pred(a)) return false;
        return true;
    }

    isIn(as) {
        return function(a) {
            return as.has(a);
        };
    }

    handleSubmit(e) {
        e.preventDefault();
        console.log(e);
        // if (!this.caregiverEmail) {
        //     this.alert = { show: true, type: 'warning', message: 'Caregiver cannot be empty.' };
        //     return;
        // }
        //
        // else this.alert = { show: false, type: '', message: '' };

        this.confirmChanges.emit({ assignedUsers: this.assignedUsers, notAssignedUsers: this.notAssignedUsers });
    }
}
