<div>
    <div class="modal-header">
        <h1 class="modal-title">Edit File</h1>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{getCleanFileName(file.id) || 'no Id'}}</p>
        <img class="imageIcon" [src]="file.imgUrl" *ngIf="!!file.imgUrl">
        <fa-icon [icon]="icon.image" size="4x" *ngIf="!file.imgUrl"></fa-icon>
    </div>
    <div class="modal-footer">
        <div class="buttonsArea" *ngIf="state === 'editing'">
            <button class="btn btn-warning" (click)="confirmEdit()">
                Edit
            </button>
            <button class="btn btn-light" (click)="close()">
                Cancel
            </button>
            <button class="btn btn-danger" (click)="changeStateTo('deleting')">
                Delete
            </button>
        </div>
        <div class="buttonsArea" *ngIf="state === 'deleting'">
            <button class="btn btn-danger" (click)="confirmDelete()">
                Conifrm Delete
            </button>
            <button class="btn btn-light" (click)="changeStateTo('editing')">
                Cancel Delete
            </button>
        </div>
    </div>
</div>
