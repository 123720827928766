import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as d3 from 'd3';
import * as d3Scale from 'd3';
import * as d3Shape from 'd3';
import * as d3Array from 'd3';
import * as d3Axis from 'd3';

type DataPoint = {
    timestamp: Date;
    sentiment: number;
}

@Component({
    selector: 'line-graph',
    template: `<svg width="900" height="500"></svg>`,
})

export class LineGraphComponent implements OnInit {

    public title = 'Line Chart';
    public data: any[] = [
        {
            timestamp: new Date(2020,10,22,9,29,13),
            sentiment: 0.0850488841455
        },
        {
            timestamp: new Date(2020,10,22,9,30,16),
            sentiment: null
        },
        {
            timestamp: new Date(2020,10,22,9,30,36),
            sentiment: null
        },
        {
            timestamp: new Date(2020,10,22,9,31,45),
            sentiment: 0.28020429861133
        },
        {
            timestamp: new Date(2020,10,22,9,31,56),
            sentiment: null
        },
        {
            timestamp: new Date(2020,10,22,9,33,51),
            sentiment: 0.98026802
        },
        {
            timestamp: new Date(2020,10,22,9,35,16),
            sentiment: null
        },
    ];

    public margin = {top: 20, right: 20, bottom: 30, left: 50};
    public width: number;
    public height: number;
    public x: any;
    public y: any;
    public svg: any;
    public line: d3Shape.Line<[number, number]>; // this is a line definition

    public parameters = [];
    constructor() {
        // configure margins and width/height of the graph
        this.width = 960 - this.margin.left - this.margin.right;
        this.height = 500 - this.margin.top - this.margin.bottom;
    }

    public buildSvg() {
        this.svg = d3.select('svg') // svg element from html
            .append('g')   // appends 'g' element for graph design
            .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
    }

    public addXandYAxis() {
        // range of data configuring
        this.x = d3Scale.scaleTime().range([0, this.width]);
        this.y = d3Scale.scaleLinear().range([this.height, 0]);
        this.x.domain(d3Array.extent(this.data, (d) => d.date ));
        this.y.domain(d3Array.extent(this.data, (d) => d.value ));
        // Configure the X Axis
        this.svg.append('g')
            .attr('transform', 'translate(0,' + this.height + ')')
            .call(d3Axis.axisBottom(this.x));
        // Configure the Y Axis
        this.svg.append('g')
            .attr('class', 'axis axis--y')
            .call(d3Axis.axisLeft(this.y));
    }

    public drawLineAndPath() {
        this.line = d3Shape.line()
            .x( (d: any) => this.x(d.date) )
            .y( (d: any) => this.y(d.value) );
        // Configuring line path
        this.svg.append('path')
            .datum(this.data)
            .attr('class', 'line')
            .attr('d', this.line);
    }

    public ngOnInit(): void {
        this.buildSvg();
        this.addXandYAxis();
        this.drawLineAndPath();
    }
}
