/**
 * The Util service is for thin, globally reusable, utility functions
 */

import {
    isFunction,
    noop,
    String,
} from 'lodash';
import {activityColorKeys} from '../../server/config/environment/shared';
import moment from 'moment';
import {instanceOfOrganization, Organization, OrganizationRoleName} from './interfaces/Organization';
import {Facility, FacilityRoleName, instanceOfFacility} from './interfaces/Facility';
import {instanceOfResearchStudy, ResearchRoleName, ResearchStudy} from './interfaces/ResearchStudy';

/**
 * Return a callback or noop function
 *
 * @param  {Function|*} cb - a 'potential' function
 * @return {Function}
 */
export function safeCb(cb) {
    return isFunction(cb) ? cb : noop;
}

/**
 * Parse a given url with the use of an anchor element
 *
 * @param  {String} url - the url to parse
 * @return {Object}     - the parsed url, anchor element
 */
export function urlParse(url) {
    var a = document.createElement('a');
    a.href = url;

    // Special treatment for IE, see http://stackoverflow.com/a/13405933 for details
    if (a.host === '') {
        // eslint-disable-next-line no-self-assign
        a.href = a.href;
    }
    return a;
}

/**
 * Test whether or not a given url is same origin
 *
 * @param  {String}           url       - url to test
 * @param  {String|String[]}  [origins] - additional origins to test against
 * @return {Boolean}                    - true if url is same origin
 */
export function isSameOrigin(url, origins) {
    url = urlParse(url);
    origins = origins && [].concat(origins) || [];
    origins = origins.map(urlParse);
    origins.push(window.location);
    origins = origins.filter(function(o) {
        let hostnameCheck = url.hostname === o.hostname;
        let protocolCheck = url.protocol === o.protocol;
        // 2nd part of the special treatment for IE fix (see above):
        // This part is when using well-known ports 80 or 443 with IE,
        // when window.location.port==='' instead of the real port number.
        // Probably the same cause as this IE bug: https://goo.gl/J9hRta
        let portCheck = url.port === o.port || o.port === '' && (url.port === '80' || url.port === '443');
        return hostnameCheck && protocolCheck && portCheck;
    });
    return origins.length >= 1;
}

// ===== Common .filter(), .map(), and .sort() functions ====
/**
 * a filter function that filters out duplicate values in an array
 * @param value
 * @param index
 * @param { any[] }array
 */
export function onlyUniqueFilter(value, index, array: any[]) {
    return array.indexOf(value) === index;
}

/**
 * sortStudiesByTitle: helper to sort ResearchStudies alphabetically by title
 * @param { ResearchStudy } a
 * @param { ResearchStudy } b
 */
export function sortStudiesByTitle(a: ResearchStudy, b: ResearchStudy) {
    return a.title.localeCompare(b.title);
}


/**
 * checkIfExistsInRole
 * @param roleToCheck: the role we want to check if the user exists within
 * @param idToCheck: the id (email or anonymizedName) of the target user
 * @param entity: the Organization, Facility, or Study in which to check
 */
export function checkIfExistsInRole(
    roleToCheck: OrganizationRoleName | FacilityRoleName | ResearchRoleName,
    idToCheck: string,
    entity: Organization | Facility | ResearchStudy): boolean {
    const mappedProp = {
        organizationadmin: 'administrators', //if Org
        organizationresearcher: 'researchers', // if Org
        facilityadmin: 'administrators', //if Facility
        user: 'users', //if Facility
        principleinvestigator: 'principleInvestigators', //if Facility or Study
        researchcoordinator: 'researchCoordinators', // if Study
        coordinator: 'researchCoordinators',
        researcher: 'researchers',
        participant: 'participants'
    }[roleToCheck];
    if (!entity[mappedProp]) return false;
    //also check 'u' itself in case it's only stored as an email / anonId
    return entity[mappedProp] && entity[mappedProp].some(u => {
        const entityPropsToCheck = [u];
        if (u.email) entityPropsToCheck.push(u.email);
        if (u.anonymizedName) entityPropsToCheck.push(u.anonymizedName);
        return entityPropsToCheck.includes(idToCheck);
    });
}


// NUMERIC / TIME CONVERSION OR PARSING FUNCTIONS
/**
 * ordinalSuffixOfNum: returns the ordinal suffix of a number (ex: 3 -> 3rd, 4 -> 4th, etc.)
 * @param { number } num
 */
export function ordinalSuffixOfNum(num: number) {
    const j = num % 10;
    const k = num % 100;
    if (j == 1 && k != 11) {
        return `${num}st`;
    }
    if (j == 2 && k != 12) {
        return `${num}nd`;
    }
    if (j == 3 && k != 13) {
        return `${num}rd`;
    }
    return `${num}th`;
}

/**
 * convertMillisToHours: converts a millisecond value into the number (floating point) of hours it represents
 * @param { number } timeMillis
 */
export function convertMillisToHours(timeMillis: number): number {
    return timeMillis / 3.6e+6;
}

/**
 * convertMillisToMinutes: converts a millisecond value into the number (floating point) of minutes it represents
 * @param ms
 */
export function convertMillisToMinutes(ms): number {
    // eslint-disable-next-line radix
    const millis = parseInt(ms);
    return moment.duration(millis).asMinutes();
}

export function convertMillisToHoursMinutesSecondsAndMillis(ms) {
    // eslint-disable-next-line radix
    const duration = moment.duration(parseInt(String(ms)));
    const hours = Math.trunc(duration.asHours());
    const minutes = Math.trunc(duration.minutes());
    const seconds = Math.trunc(duration.seconds());
    const millis = duration.milliseconds();
    return {hours: hours, minutes: minutes, seconds: seconds, millis: millis};
}

export function convertHoursToMillis(timeHours: number): number {
    return moment.duration(timeHours, 'hours').asMilliseconds();
}

export function convertMinutesToMillis(timeMinutes: number): number {
    return moment.duration(timeMinutes, 'minutes').asMilliseconds();
}

/**
 * msToTime: converts/formats a millisecond value to {hours, minutes} object where each value is an integer
 * @param millis
 */
export function convertMillisToHrsMinutes(millis: number): {hours: number | string, minutes: number | string} {
    const dur = moment.duration(millis);
    let hours = Math.trunc(dur.asHours());
    let minutes = Math.round(dur.minutes());
    hours = hours < 10 ? +'0' + hours : hours;
    minutes = minutes < 10 ? +'0' + minutes : minutes;
    return {hours: hours, minutes: minutes};
}

/**
 * mapColorKeys: used by graphs to ensure each category is properly and consistently
 *      color-coded in both the graph itself and in the legend
 * @param {string} value: the name of an analytic/game
 */
export function mapColorKeys(value: string): string {
    //renameSpecialActivityNames defaults unknown values to 'other',
    // this function just does the actual mapping to a color
    return activityColorKeys[renameSpecialActivityNames(value).toLowerCase()];
}

/**
 * for some activities (e.g. ProgramRMedia), their database name differs from
 *  what we want to display in the UI. This function maps the database names to their UI 'display' names
 * @param { string } activityName
 */
export function renameSpecialActivityNames(activityName: string): string {
    const activityNameLower = activityName.toLowerCase();
    const specialActivities = {
        programrmedia: 'Conversation Media'
    };
    //if known activity, simply return it
    if (activityColorKeys[activityNameLower]) return activityName;

    //otherwise, check if it is considered special (needs renamed),
    //  if so, map it to its specified renaming. If not, default to 'other'
    return specialActivities[activityNameLower] ? specialActivities[activityNameLower] : 'other';
}


// STRING NORMALIZATION FUNCTIONS

/**
 * formatRoleName:
 *      formats the name of a role as it is found in the URL, roleView, or UIRoleViews
 *              into the format we want to display it in the UI
 * @param { string } role
 */
export function formatRoleName(role: string): string {
    role = role.toLowerCase();
    const formattedRoleNames = {
        admin: 'Site Admin',
        organizationadmin: 'Organization Admin',
        facilityadmin: 'Facility Admin',
        caregiver: 'Caregiver',
        guardian: 'Guardian',
        organizationresearcher: 'Organization Researcher',
        principleinvestigator: 'Principle Investigator',
        researchcoordinator: 'Research Coordinator',
        coordinator: 'Research Coordinator',
        researcher: 'Researcher',
        participant: 'Participant',
        user: 'User'
    };
    return formattedRoleNames[role] ? formattedRoleNames[role] : 'Unknown';
}

export function getEntityTypeName(entity): string {
    if (instanceOfOrganization(entity)) return 'Organization';
    if (instanceOfFacility(entity)) return 'Facility';
    if (instanceOfResearchStudy(entity)) return 'ResearchStudy';
    return 'Unknown';
}

export function normalizeEntityTypeName(entityTypeName: string): string {
    entityTypeName = entityTypeName.toLowerCase();
    const normalizedTypeNames = {
        facility: 'Facility',
        organization: 'Organization',
        researchstudy: 'Study',
        unknown: 'Unknown'
    };
    return normalizedTypeNames[entityTypeName] ? normalizedTypeNames[entityTypeName] : 'Unknown';
}

/**
 *
 * @param {String} num  - inputted phone number from user
 * @return {String} - normalized phone number as string
 */
export function phoneNormalization(num: string) {
    let numArr: string[] = [];
    // scrub string into an array of only numbers
    let i = 0;
    while (i < num.length) {
        if (/^\d+$/.test(num.charAt(i))) {
            numArr[numArr.length] = num.charAt(i);
        }
        i++;
    }
    // base - just return the user input
    let normal = num;

    // first three digits is the area code
    if (numArr.length >= 3) {
        normal = `(${numArr[0]}${numArr[1]}${numArr[2]})`;
    }

    // add the next three digits if they exist
    if (numArr.length >= 4) {
        let j = 3;
        while (j < numArr.length && j < 6) {
            normal += numArr[j];
            j++;
        }
    }

    // add '-' after first 6 digits
    if (numArr.length >= 6) {
        normal += '-';
    }

    // add and limit the last 4 digits
    if (numArr.length >= 7) {
        let k = 6;
        while (k < numArr.length && k < 10) {
            normal += numArr[k];
            k++;
        }
    }

    return normal;
}

export function capitalizeFirstLetter(str: string): string {
    let first = str.charAt(0).toUpperCase();
    let rest = str.substring(1).toLowerCase();
    return first + rest;
}

export function capitalizeTitles(str: string): string {
    let arr = str.split(' ').map((e: string): string => this.capitalizeFirstLetter(e));
    return arr.join(' ');
}

/** camelize: converts a string (with or without spaces) into camelCase */
export function camelize(str: string): string {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
        if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}

export function emailValidation(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/;
    return emailRegex.test(email);
}

export function getAge(birthday: string): number {
    if (!birthday) return;
    const birthDate = moment(birthday);
    if (!birthDate.isValid()) return;
    return Math.trunc(moment().diff(birthDate, 'years'));
}

export function isValidDate(d): boolean {
    return d instanceof Date && !isNaN(d.getTime());
}

/**
 * isValidDateRange: ensures that two dates make up a valid range by verifying:
 *  1.) both are valid Dates
 *  2.) startDate comes before endDate
 * @param startDate
 * @param endDate
 */
export function isValidDateRange(startDate: Date, endDate: Date): boolean {
    return isValidDate(startDate) && isValidDate(endDate) && startDate.getTime() < endDate.getTime();
}

/**
 * formatConversationTopicNames: takes in a delimeter, and an array of conversation topic names
 *              returns a formatted list of topic names (delimiter replaced with a space, first letter of each word capitalized)
 * @param delimiter
 * @param topicNames
 */
export function formatConversationTopicNames(delimiter: string, topicNames: string[]): string[] {
    return topicNames.map(t => formatSingleConversationTopicName(delimiter, t));
}
/**
 * formatSingleConversationTopicName: given a delimiter and a topicName, replaces the delimiter with a space and capitalizes the first letter of each word
 * @param delimiter
 * @param topicName
 */
export function formatSingleConversationTopicName(delimiter: string, topicName: string): string {
    if (!topicName || topicName === '*') return 'Unspecified';
    if (topicName === 'you') return 'Ryan';
    return topicName.split(delimiter).map(w => capitalizeFirstLetter(w)).join(' ');
}

export function isAnonymizedName(name: string): boolean {
    return !(name.includes('@') || name.includes('%40'));
}
