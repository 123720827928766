import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AnalyticsRoutingModule } from './analytics-routing.module';
import { DirectivesModule } from '../../components/directives.module';
import { AnalyticsComponent } from './analytics/analytics.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AlertModule } from 'ngx-bootstrap/alert';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        AnalyticsRoutingModule,
        DirectivesModule,
        BsDatepickerModule,
        AlertModule,
    ],
    declarations: [
        AnalyticsComponent,
    ],
    exports: [
    ],
})
export class AnalyticsModule { }
