<div class="create-event">
    <h3 class="create-event__header">Create Event</h3>
    <hr>
    <form name="form" (ngSubmit)="handleSubmit(createEventForm)" novalidate #createEventForm="ngForm">
        <div class="create-event__formGroup">
            <input id="title" type="text" name="title" [(ngModel)]="event.title" placeholder="Add Event Title" required>
        </div>

        <div class="create-event__formGroup">
            <textarea id="description" name="description" rows="3" [(ngModel)]="event.caption" placeholder="Add Event Description" required></textarea>
        </div>

        <div class="create-event__formGroup__select">
            <label for="eventType">Choose an Event Type:</label>
            <select id="eventType" name="eventType" [(ngModel)]="event.eventType" required>
                <option *ngFor="let event of eventTypesAllowed" [value]="event">{{event}}</option>
            </select>
        </div>

        <div class="create-event__formGroup__eventTime" *ngIf="!recurringMode">
            <label>Event Start</label>
            <input id="begin" type="datetime-local" name="begin" [(ngModel)]="event.begin" value="event.begin" required>
        </div>

        <div class="create-event__formGroup__eventTime" *ngIf="!recurringMode">
            <label>Event End</label>
            <input id="end" type="datetime-local" name="end" [(ngModel)]="event.end" value="event.end" required>
        </div>

        <div class="create-event__formGroup__checkbox">
            <label for="recurringOption">Recurring Event</label>
            <input type="checkbox" id="recurringOption" name="recurringOption" [(ngModel)]="recurringMode">
        </div>

        <div *ngIf="recurringMode" class="recurringOptions">
            <div class="recurringOptions__recurrenceType">
                <h5 class="column">Recurrence: </h5>
                <div class="column">
                    <div class="radio-group" *ngFor="let recurrenceType of recurrenceTypesAllowed">
                        <input id="recurrenceType" type="radio" name="recurrence" [(ngModel)]="recurrenceTypeSelected" [value]="recurrenceType">
                        <label for="recurrenceType">{{recurrenceType}}</label>
                    </div>
                </div>
            </div>
            <div class="recurringOptions__recurrenceType" *ngIf="recurrenceTypeSelected === 'Weekly'">
                <h5 class="column">Select Days: </h5>
                <div class="column">
                    <div class="radio-group" *ngFor="let day of daysInWeek">
                        <input [id]="day" type="checkbox" [name]="day" (change)="updateSelectedDays($event)" [checked]="this.selectedDays.indexOf(day) > -1" [value]="day">
                        <label [for]="day">{{day}}</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="create-event__formGroup__eventTime" *ngIf="recurringMode">
            <label for="recurrenceStart">Recurrence Start</label>
            <input id="recurrenceStart" type="datetime-local" name="recurrenceStart" [(ngModel)]="recurrenceStart" required>
        </div>

        <div class="create-event__formGroup__duration" *ngIf="recurringMode">
            <label for="duration">Event Duration (Minutes)</label>
            <input id="duration" type="number" min="1" name="duration" [(ngModel)]="duration" required>
        </div>

        <div class="create-event__formGroup__eventTime" *ngIf="recurringMode">
            <label for="recurrenceEnd">Recurrence End</label>
            <input id="recurrenceEnd" type="datetime-local" name="recurrenceEnd" [(ngModel)]="recurrenceEnd" required>
        </div>

        <div class="create-event__buttonsArea">
            <button type="button" class="btn btn-default" (click)="this.calendarFunctions.closeCreateEvent()">Close</button>
            <button type="submit" class="btn btn-primary">Create Event</button>
        </div>

        <alert class="createEventAlert" type="{{alert.type}}" *ngIf="alert.showFor === 'createEvent'">{{alert.message}}
        </alert>

    </form>



</div>
