import { Component, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, UserType } from '../auth/user.service';
import {
    faUserCircle,
    faGamepad,
    faUserFriends,
    faCog,
    faTh,
    faRobot,
    faPhotoVideo,
    faCalendar,
    faStoreAlt,
    faSignOutAlt,
    faComments,
    faWarehouse,
} from '@fortawesome/free-solid-svg-icons';
import {faWikipediaW} from '@fortawesome/free-brands-svg-icons';
import { AuthService } from '../auth/auth.service';
import { getPagesForRole } from './rolePages';
import { AppState, RoleViewType } from '../interfaces/AppState';
import {Subscription} from 'rxjs';

@Component({
    selector: 'left-nav',
    templateUrl: './leftNav.html',
    providers: [],
    styleUrls: ['./leftNav.scss'],
})

export class LeftNavComponent implements OnInit, OnDestroy, OnChanges {
    icon = {
        userIcon: faUserCircle,
        dashboard: faTh,
        usersactivities: faUserFriends,
        calendar: faCalendar,
        ryandata: faRobot,
        gamemetrics: faGamepad,
        settings: faCog,
        media: faPhotoVideo,
        organizations: faStoreAlt,
        programr: faComments,
        manage: faWarehouse,
        logout: faSignOutAlt,
        wiki: faWikipediaW
    };

    currentUser;
    state: AppState = {
        roleView: 'user',
        navElements: getPagesForRole('user'),
        pageView: 'dashboard',
    };

    pageViewSubscription: Subscription;

    static parameters = [Router, ActivatedRoute, AuthService, UserService];
    constructor(public router: Router, public route: ActivatedRoute, public authService: AuthService,
                public userService: UserService) {
        this.router = router;
        this.authService = authService;
        this.userService = userService;
        this.route = route;

        //get the current user from the token
        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserType;

        var url = window.location.pathname.substr(1).split('/');

        this.state.roleView = url[0] as RoleViewType;
        this.state.pageView = url[1];
        this.state.navElements = getPagesForRole(this.state.roleView);

        //Listen for page change from external source
        this.pageViewSubscription = this.route.paramMap.subscribe(params => {
            this.state.pageView = params.get('pageView').toLowerCase();
        });
    }

    public ngOnInit(): void {}

    public ngOnDestroy(): void {
        this.pageViewSubscription.unsubscribe();
    }

    navigateTo(newPageName: string) {
        const allowedPageNames = this.state.navElements.map(p => p['link']);
        if (!allowedPageNames.includes(newPageName)) return false;
        this.state.pageView = newPageName;
        this.router.navigate([this.state.roleView, newPageName]);
    }

    handleLogout(e) {
        e.preventDefault();
        this.authService.logout()
            .then(() => {
                this.router.navigate(['/account/login'], {
                    queryParams: {
                        alert: 'logout'
                    }
                });
            });
        return;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.state && changes.state.currentValue !== this.state) {
            this.state = changes.state.currentValue;
        }
    }
}

