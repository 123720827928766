import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from "../../auth/auth.service";
import { Inventory, InventoryData } from "../../interfaces/Inventory";

import { UserService } from "../../auth/user.service";
import { Organization } from "../../interfaces/Organization";
import { InventoryService } from "../../../services/inventory/inventory.service";

@Component({
    selector: 'assign-ryan',
    templateUrl: './assign-inventory.html',
    styleUrls: ['./assign-inventory.scss']
})
export class AssignInventoryComponent {

    @Input()
    org: Organization;

    @Input()
    formError: String;

    @Input()
    formInfo: String;

    @Output()
    inventoryAssignment: EventEmitter<Inventory> = new EventEmitter<Inventory>();
    public assignedRyan: Inventory = undefined;

    allRyans: Inventory[];

    static parameters = [BsModalRef, AuthService, InventoryService];

    constructor(public bsModalRef: BsModalRef, public authService: AuthService, public inventoryService: InventoryService) {
        this.authService = authService;
        this.inventoryService = inventoryService;

        this.getAllRyans();
    }

    getAllRyans() {
        this.inventoryService.getAllInventory()
            .then((data: InventoryData) => {
                this.allRyans = data.ryans;
            });
        // this.allRyans = this.inventoryService.getAllInventory();
        // console.log(this.inventoryService.getAllInventory());
    }

    selectBot(bot: Inventory) {
        this.assignedRyan = bot;
    }

    submitInventoryAssignment() {
        console.log(this.assignedRyan);
        this.inventoryAssignment.emit(this.assignedRyan);
    }
}

