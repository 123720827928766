import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { FileService } from '../../services/file/file.service';

// import { GuardianMediaComponent } from '../guardian/guardianMedia/guardianMedia.component';
import { EditFileModalComponent } from './modals/editFile.component';
import { DirectivesModule } from '../directives.module';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        CollapseModule,
        RouterModule,
        AlertModule,
        DirectivesModule,
        ProgressbarModule,
    ],
    declarations: [
        // MediaPageComponent,
        EditFileModalComponent,
        // GuardianMediaComponent,
    ],
    exports: [
        // MediaPageComponent,
        EditFileModalComponent,
    ],
    providers: [
        FileService,
        AuthService,
    ],
    bootstrap: [
        EditFileModalComponent
    ],
})
export class MediaModule { }
