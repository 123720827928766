import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrganizationService } from '../../../services/organization/organization.service';
import {FacilityService} from '../../../services/facility/facility.service';

@Component({
    selector: 'verify',
    templateUrl: './verify.html',
    styleUrls: ['./verify.scss']
})
export class VerifyComponent {
    routeParams: {
        facilityId?: string,
        userId?: string,
        role?: string,
    };

    facility: {
        name?: string,
    }

    alert = {
        show: false,
        type: '',
        message: '',
    }

    static parameters = [Router, ActivatedRoute, OrganizationService, FacilityService];

    constructor(public router: Router, public activatedRoute: ActivatedRoute,
        public organizationService: OrganizationService, public facilityService: FacilityService
    ) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.organizationService = organizationService;
        this.facilityService = facilityService;
    }

    ngOnInit() {
        const routeParams = this.activatedRoute.snapshot.paramMap;
        const role = String(routeParams.get('role'));
        const facilityId = String(routeParams.get('facilityId'));
        const userId = String(routeParams.get('userId'));
        this.routeParams = {
            facilityId,
            role,
            userId,
        };
        this.facilityService.getPublicInfoFacility(facilityId).toPromise()
            .then((data) => {
                console.log(data);
                this.facility = data;
                this.alert = {
                    show: false,
                    type: '',
                    message: '',
                };
            })
            .catch((err) => {
                console.error(err);
                this.alert = {
                    show: true,
                    type: 'warning',
                    message: 'Facility does not exist.',
                };
            });
    }

    handleSubmit() {
        console.log(this.routeParams);
        const { facilityId, role, userId } = this.routeParams;
        this.facilityService.verifyUserOrCaregiver(facilityId, role, userId).toPromise()
            .then((data) => {
                console.log(data);
                this.router.navigateByUrl('/account/login?alert=verifySuccess');
            })
            .catch((err) => {
                console.error(err);
                this.alert = {
                    show: true,
                    type: 'info',
                    message: 'There was a problem with your verification.',
                };
            });
    }

    goToLogin() {
        this.router.navigateByUrl('/account/login');
    }
}
