import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class OrgAdminGuard implements CanActivate {
    static parameters = [UserService, Router];
    constructor(public userService: UserService, public router: Router) {
        this.userService = userService;
        this.router = router;
    }

    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64)
            .split('')
            .map(function(c) {
                return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
            })
            .join(''));

        return JSON.parse(jsonPayload);
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.userService.get()
            .toPromise()
            .then((res) => {
                const adminOfOrgs = JSON.parse(localStorage.getItem('user')).organizationAdminOf;
                const validOrgAdmin = !!adminOfOrgs && adminOfOrgs.length > 0;

                //TODO: check queryParams to ensure user is valid orgAdmin of focused org
                // let currentOrg = state.url.split('/')[2];

                if (res.role === 'admin' || validOrgAdmin) {
                    return true;
                } else {
                    this.router.navigate(['/user/dashboard'], {
                        queryParams: {
                            error: 'unauthorized'
                        }
                    });
                    return false;
                }
            })
            .catch((err) => {
                this.router.navigate(['/account/login'], {
                    queryParams: {
                        return: state.url,
                        alert: 'auth-required'
                    }
                });
                return false;
            });
    }
}
