<div class="confirm-delete">
    <div class="modal-header">
        <h4 id="myModalLabel" class="modal-title">Are you sure you want to delete this?</h4>
        <button type="button" (click)="bsModalRef.hide()" class="close"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
    </div>
    <div class="modal-body">
        <h6 *ngIf="deleteType === 'organization'">
            {{deleteFrom | formatEntityTypeName:'normalized'}}: {{toDelete.name}}
        </h6>
        <div *ngIf="deleteType === 'facility'">
            <h6>Removing {{toDelete | formatEntityTypeName:'normalized'}}: {{toDelete.name}}</h6>
            <h6>From {{deleteFrom | formatEntityTypeName:'normalized'}}: {{deleteFrom.name}}</h6>
        </div>
        <div *ngIf="deleteType === 'organizationresearcher' && toDelete">
            <h6>Removing researcher: {{toDelete.email || toDelete}}</h6>
            <h6>From {{deleteFrom | formatEntityTypeName:'normalized'}}: {{deleteFrom.name}}</h6>
        </div>
        <div *ngIf="['caregiver', 'organizationadmin', 'facilityadmin', 'user'].includes(deleteType) || isResearchRoleName(deleteType)">
            <h6>Removing {{deleteType | formatRole}}: {{ toDelete.email || toDelete}}</h6>
            <h6>From {{deleteFrom | formatEntityTypeName:'normalized'}}: {{deleteFrom.name || deleteFrom.title}}</h6>
        </div>
        <div *ngIf="deleteType === 'caregiver'">
            <h6>Removing {{deleteType | formatRole}}: {{!!toDelete.email ? toDelete.email : toDelete}}</h6>
            <h6>From {{deleteFrom | formatEntityTypeName:'normalized'}}: {{deleteFrom.name}}</h6>
        </div>
    </div>

    <div class="modal-footer">
        <div class="buttonsArea">
            <button class="btn btn-danger" (click)="handleDelete()">Confirm Delete</button>
            <button class="btn btn-outline-warning" (click)="bsModalRef.hide()">Cancel</button>
        </div>
    </div>
</div>
