import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {encodeEmail} from '../encoder/customEncoder';
import {Researcher, ResearchRoleName, ResearchStudy, StudyNote} from '../../components/interfaces/ResearchStudy';
import {Facility} from '../../components/interfaces/Facility';
import {onlyUniqueFilter} from '../../components/util';
import {tap} from "rxjs/operators";

export type ResearchStudyType = {
    _id?: string,
    title: string,
    irbNumber?: string,
    notes?: StudyNote[],
    description?: string, // a description of the study itself
    sponsors?: any,
    facilities?: any[], // facilities who've granted permission to the researchers
    keywords?: string[],
    startDate: Date, // the earliest date for which researchers can query data
    endDate: Date, // the latest date for which researchers can query data
    principleInvestigators: Researcher[],
    participants?: any[], //anonymized users participating in the study
    researchCoordinators?: Researcher[],
    researchers?: Researcher[],
    state: string,
    summary?: any, // a summary of the results of the study
}

@Injectable({
    providedIn: 'root'
})
export class ResearchService {
    public baseUrl = '/api/research';
    static parameters = [HttpClient];
    constructor(public http: HttpClient) { }

    create(study: ResearchStudyType) {
        return this.http.post(`${this.baseUrl}`, study);
    }


    updateFacility(study) {
        return this.http.put(`${this.baseUrl}/${study._id || study.id}`, study, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }).pipe(
            tap(_ => {
                console.log('updated study');
                console.log(study);
            })
        );
    }

    // === GET methods ===
    index() {
        return this.http.get(`${this.baseUrl}`);
    }

    getStudyById(id: string) {
        return this.http.get(`${this.baseUrl}/${id}`);
    }

    getPublicInfoStudy(id: string) {
        return this.http.get(`${this.baseUrl}/${id}/info`);
    }

    /**
     * getAllMyStudies: retrieves all of a user's studies for which they have the specified role
     * @param { ResearchRoleName } role
     */
    getAllMyStudies(role: ResearchRoleName) {
        var parsedRole = role.toString();
        //change role names to align with server-side routes
        if (role === 'researchcoordinator') parsedRole = 'coordinator';
        if (role === 'principleinvestigator') parsedRole = 'pi';
        return this.http.get(`${this.baseUrl}/${parsedRole}/me`).toPromise();
    }

    /**
     * getStudiesForParticipant: retrieves all studies for which this user is a participant
     */
    getStudiesForParticipant() {
        return this.http.get(`${this.baseUrl}/participant/me`).toPromise();
    }

    /**
     * getStudiesForResearchCoordinator: retrieves all studies for which this user is a Research Coordinator
     * @deprecated use getAllMyStudies('coordinator') instead
     * @auth must be researchCoordinator
     */
    getStudiesForResearchCoordinator() {
        return this.http.get(`${this.baseUrl}/coordinator/me`).toPromise();
    }

    /**
     * getStudiesForPrincipleInvestigator: retrieves all studies for which this user is a Principle Investigator
     * @deprecated use getAllMyStudies('principleinvestigator') instead
     * @auth must be principleInvestigator
     */
    getStudiesForPrincipleInvestigator() {
        return this.http.get(`${this.baseUrl}/pi/me`).toPromise();
    }

    /**
     * getStudiesForResearcher: retrieves all studies for which this user is a Researcher
     * @deprecated use getAllMyStudies('researcher') instead
     */
    getStudiesForResearcher() {
        return this.http.get(`${this.baseUrl}/researcher/me`).toPromise();
    }

    getParticipantsInStudy(studyId: string) {
        return this.http.get(`${this.baseUrl}/${studyId}/participants`).toPromise();
    }

    getEligibleParticipantsForStudy(study: ResearchStudy) {
        return this.http.get(`${this.baseUrl}/${study._id}/eligibleParticipants`).toPromise();
    }

    // addPrincipleInvestigatorToStudy(study: ResearchStudy, pi_email: string) {
    //     return this.getStudyById(study._id).toPromise()
    //         .then((studyData: ResearchStudy) => {
    //             const { principleInvestigators } = studyData;
    //             if (!principleInvestigators) {
    //                 return Promise.reject('Unable to add administrator to facility.');
    //             }
    //             const newPISet = new Set(principleInvestigators);
    //             if (pi_email) newPISet.add(pi_email);
    //             return Promise.resolve(this.updateFacility({ _id: facility._id || facility.id, administrators: Array.from(newAdministratorSet) }).toPromise());
    //         })
    //         .catch((e) => Promise.reject(String(e)));
    // }

    // ==== PUT methods ====
    addFacilityToStudy(study: ResearchStudyType, facilityId: string) {
        return this.http.put(`${this.baseUrl}/${study._id}/facility/add`, [facilityId]).toPromise();
    }

    addFacilitiesToStudy(study: ResearchStudyType, facilities: Facility[]) {
        const facilityIds = facilities.map(f => f._id).filter(onlyUniqueFilter);
        return this.http.put(`${this.baseUrl}/${study._id}/facility/add`, {
            facilities: facilityIds
        }).toPromise();
    }

    /**
     * addMemberToStudy: a consolidated replacement for addCoordinatorToStudy and addResearcherToStudy
     * @param roleName
     * @param { ResearchStudy } study
     * @param { string } memberEmail
     */
    addMemberToStudy(roleName: 'researcher' | 'coordinator' | 'researchcoordinator', study: ResearchStudy, memberEmail: string) {
        const encodedEmail = encodeEmail(memberEmail);
        if (roleName === 'researchcoordinator') roleName = 'coordinator';
        return this.http.put(`${this.baseUrl}/${study._id}/${roleName}/add/${encodedEmail}`, {}).toPromise();
    }
    /**
     * addCoordinatorToStudy
     * @deprecated Use addMemberToStudy() instead
     * @auth must be principleInvestigator
     * @param studyId
     * @param coordinatorEmail
     */
    addCoordinatorToStudy(studyId: string, coordinatorEmail: string) {
        const encodedEmail = encodeEmail(coordinatorEmail);
        return this.http.put(`${this.baseUrl}/${studyId}/coordinator/add/${encodedEmail}`, {});
    }

    /**
     * addResearcherToStudy
     * @deprecated Use addMemberToStudy() instead
     * @auth must be principleInvestigator
     * @param { ResearchStudy } study
     * @param { string } researcherEmail
     */
    addResearcherToStudy(study: ResearchStudy, researcherEmail: string) {
        const encodedEmail = encodeEmail(researcherEmail);
        return this.http.put(`${this.baseUrl}/${study._id}/researcher/add/${encodedEmail}`, {});
    }

    /**
     * addParticipantsToStudy
     * @auth can only be called by researchCoordinator
     * @param { ResearchStudy } study
     * @param { String[] } participantEmails
     */
    addParticipantsToStudy(study: ResearchStudy, participantEmails: string[]) {
        participantEmails = participantEmails.filter(onlyUniqueFilter);
        return this.http.put(`${this.baseUrl}/${study._id}/participant/add`, {
            participants: participantEmails
        });
    }

    /**
     * removeMemberFromStudy
     * @param { ResearchRoleName } roleName - the role of the user who is being removed
     * @param { ResearchStudy } study - the study from which they are being removed from
     * @param { string } memberId - the id (email or anonymizedName) of the user being removed
     */
    removeMemberFromStudy(roleName: ResearchRoleName, study: ResearchStudy, memberId: string) {
        if (roleName === 'principleinvestigator') throw new Error('removal of principleInvestigators disallowed');
        const encodedEmail = encodeEmail(memberId);
        if (roleName === 'researchcoordinator') roleName = 'coordinator';
        return this.http.put(`${this.baseUrl}/${study._id}/${roleName}/remove/${encodedEmail}`, {}).toPromise();
    }
    /**
     * removeCoordinatorFromStudy
     * @deprecated Use removeMemberFromStudy() instead
     * @auth must be principleInvestigator
     * @param studyId
     * @param coordinatorEmail
     */
    removeCoordinatorFromStudy(studyId: string, coordinatorEmail: string) {
        const encodedEmail = encodeEmail(coordinatorEmail);
        console.log(encodedEmail);
        return this.http.put(`${this.baseUrl}/${studyId}/coordinator/remove/${encodedEmail}`, {}).toPromise();
    }

    /**
     * removeResearcherFromStudy
     * @deprecated Use removeMemberFromStudy() instead
     * @auth must be principleInvestigator
     * @param studyId
     * @param researcherEmail
     */
    removeResearcherFromStudy(studyId: string, researcherEmail: string) {
        const encodedEmail = encodeEmail(researcherEmail);
        console.log(encodedEmail);
        return this.http.put(`${this.baseUrl}/${studyId}/researcher/remove/${encodedEmail}`, {}).toPromise();
    }

    /**
     * removeParticipantFromStudy
     * @deprecated Use removeMemberFromStudy() instead
     * @auth can only be called by researchCoordinator
     * @param studyId
     * @param participantId - the id (email or anonId) of the participant
     */
    removeParticipantFromStudy(studyId: string, participantId: string) {
        const encodedId = encodeEmail(participantId);
        return this.http.put(`${this.baseUrl}/${studyId}/participant/remove/${encodedId}`, {}).toPromise();
    }

    verifyMemberStatus(studyId: string, memberEmail: string, role: string) {
        if (!['coordinator', 'researcher', 'participant'].includes(role)) {
            throw new Error('invalid role');
        }
        const encodedEmail = encodeEmail(memberEmail);
        return this.http.put(`${this.baseUrl}/${studyId}/${role}/verify/${encodedEmail}`, {});
    }

    // /**
    //  * verifyCoordinatorStatus
    //  * @param studyId
    //  * @param coordinatorEmail
    //  */
    // verifyCoordinatorStatus(studyId: string, coordinatorEmail: string) {
    //     const encodedEmail = encodeEmail(coordinatorEmail);
    //     return this.http.put(`${this.baseUrl}/${studyId}/coordinator/verify/${encodedEmail}`, {});
    // }
    //
    // /**
    //  * verifyResearcherStatus
    //  * @param studyId
    //  * @param researcherEmail
    //  */
    // verifyResearcherStatus(studyId: string, researcherEmail: string) {
    //     const encodedEmail = encodeEmail(researcherEmail);
    //     return this.http.put(`${this.baseUrl}/${studyId}/researcher/verify/${encodedEmail}`, {});
    // }
    //
    // verifyParticipantStatus(studyId: string, participantEmail: string) {
    //     const encodedEmail = encodeEmail(participantEmail);
    //     console.log(encodedEmail);
    //     return this.http.put(`${this.baseUrl}/${studyId}/participant/verify/${encodedEmail}`, {});
    // }
}
