import { Component, Input } from '@angular/core';

@Component({
    selector: 'calendar-page',
    templateUrl: './calendarPage.html',
})

export class CalendarPageComponent {
    @Input()
    viewAs = 'user';
}
