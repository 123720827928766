<div class="page">
    <div *ngIf="allStudies" class="mainContent">
        <nav class="breadcrumb breadcrumbs" aria-label="breadcrumb">
            <ol class="breadcrumbs__list">
                <!--                Not focused on facility-->
                <li class="breadcrumb-item breadcrumbItem" [attr.aria-current]="!focusedStudyId ? 'page' : null" (click)="focusOnStudy()">
                    Users Activities
                </li>
                <!--                Focused on facility-->
                <li class="breadcrumb-item breadcrumbItem" *ngIf="focusedStudyId" [attr.aria-current]="(!!focusedStudyId && !focusedUserId) ? 'page' : null" (click)="focusOnStudy(focusedStudyId)">
                    {{getCurrentStudy().title || 'n/a'}}
                </li>
                <!--                Focused on facility AND User-->
                <li class="breadcrumb-item breadcrumbItem" *ngIf="focusedStudyId && focusedUserId" [attr.aria-current]="(focusedUserId) ? 'page' : null">
                    {{focusedUserId || 'n/a'}}
                </li>
            </ol>
        </nav>

        <!--        Study Selection  -->
        <ng-container *ngIf="!focusedStudyId">
            <h4>Please select a Study</h4>
            <div class="facilityList">
                <article class="facilityArea blueOnHover" *ngFor="let study of allStudies" (click)="focusOnStudy(study._id)">
                    <div class="header">
                        <div class="text-area">
                            <h3 class="facilityName">{{study?.title || "Loading Name"}}</h3>
                        </div>
                    </div>
                    <div class="facilityInfoCount">
                        <h6>Research Coordinators: <span>({{study?.researchCoordinators?.length || 0}})</span></h6>
                        <h6>Researchers: <span>({{study?.researchers?.length || 0}})</span></h6>
                        <h6>Participants: <span>({{study?.participants?.length || 0}})</span></h6>
                    </div>
                </article>
            </div>
        </ng-container>

        <!--        Focused on Study -->
        <ng-container *ngIf="!focusedUserId && focusedStudyId">
            <article class="mainContent__tile">
                <queryDateRangeControl title="{{getCurrentStudy().title}}'s User Activity Metrics" [queryDateRange]="selectedRange" [researchStudy]="getCurrentStudy()" downloadBtnLabel="Export Data" [downloadModalHeader]="'Download User data for ' + getCurrentStudy().title" (queryDatesUpdate)="changeSelectedRange($event)">
                </queryDateRangeControl>
            </article>

            <!--            Total User Activity -->
            <stacked-bar-chart graphTitle="Aggregated Users Activity" [dataSummary]="activityGraphMultipleUsers?.Summary || []" [queryDateRange]="selectedRange" [selectedGranularity]="selectedGranularity" dataName="ActivityMetricsMany" (selectedGranularityChange)="changeGranularity($event)">
            </stacked-bar-chart>

            <!--Pie Chart -->
            <article class="graph-area rowMain piechart-container">
                <div class="header">
                    <h4 class="graphTitle">Usage Analysis</h4>
                </div>
                <div class="graph piechart-graph-area">
                    <ngx-charts-advanced-pie-chart *ngIf="activityGraphMultipleUsers && activityGraphMultipleUsers.PieChart" [customColors]="this.mapColorKeys" [valueFormatting]="this.formatPieChartValues" [nameFormatting]="this.formatPieChartNames" [animations]="false" [scheme]="pieChartColorScheme" [results]="activityGraphMultipleUsers.PieChart" [gradient]="gradient" [label]="'Total'" (select)="onSelect($event)">
                        <ng-template #tooltipTemplate let-model="model">
                            <span class="tooltip-label">{{model.label}}</span>
                            <span class="tooltip-val">{{this.getTooltipTemplateTime(model)}}</span>
                        </ng-template>
                    </ngx-charts-advanced-pie-chart>
                    <div *ngIf="!activityGraphMultipleUsers || !activityGraphMultipleUsers.PieChart">
                        Sorry, no data within this timeframe
                    </div>
                </div>
            </article>

        </ng-container>

        <!--        Focused on Study AND User    -->
        <ng-container *ngIf="focusedUserId && focusedStudyId">
            <div class="rowMain">
                <div class="userMainInfo">
                    <div class="item1">
                        <fa-icon [icon]="icons.user" size="5x"></fa-icon>
                    </div>
                    <div class="item2">
                        <h2 class="capitalize">{{getCurrentUser()?.anonymizedName || ''}}</h2>
                    </div>
                    <div class="item3">
                    </div>
                </div>
                <div class="userBottomInfo">
                    <div class="facInfo">
                        <span>Assigned to</span>
                        <span>{{getCurrentStudy()?.title}}</span>
                    </div>
                </div>

            </div>

            <article class="mainContent__tile">
                <queryDateRangeControl title="User Activity" [queryDateRange]="selectedRange" [researchStudy]="getCurrentStudy()" downloadBtnLabel="Download" [downloadModalHeader]="'Download User data for ' + getCurrentUser().anonymizedName" (queryDatesUpdate)="changeSelectedRange($event)">
                </queryDateRangeControl>
            </article>


            <!--Average Game Activity -->
            <stacked-bar-chart graphTitle="Activities" [queryDateRange]="selectedRange" [targetUser]="getCurrentUser()" [selectedGranularity]="selectedGranularity" (selectedGranularityChange)="changeGranularity($event, 'SBC')" dataName="ActivityMetricsOne">
            </stacked-bar-chart>

            <!--Pie Chart -->
            <article class="graph-area rowMain piechart-container">
                <div class="header">
                    <h4 class="graphTitle">Usage Analysis</h4>
                </div>
                <div class="graph piechart-graph-area">
                    <ngx-charts-advanced-pie-chart *ngIf="activityGraphCurrentUser && activityGraphCurrentUser.PieChart" [scheme]="pieChartColorScheme" [customColors]="this.mapColorKeys" [valueFormatting]="this.formatPieChartValues" [nameFormatting]="this.formatPieChartNames" [animations]="false" [results]="activityGraphCurrentUser.PieChart" [gradient]="gradient" [label]="'Total'" (select)="onSelect($event)">
                        <ng-template #tooltipTemplate let-model="model">
                            <span class="tooltip-label">{{model.label}}</span>
                            <span class="tooltip-val">{{this.getTooltipTemplateTime(model)}}</span>
                        </ng-template>
                    </ngx-charts-advanced-pie-chart>
                </div>
            </article>
            <!--Conversation -->
            <article class="graph-area rowMain">
                <conversation-graph [graphTitle]="'Conversation'" [conversationDataReady]="conversationDataReady" [conversationSummary]="conversationSummary" [convoDetails]="convoDetails" [conversationTopics]="conversationTopics">
                </conversation-graph>
            </article>
            <!--Sentiment Analysis -->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Sentiment Analysis</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event, 'Sentiment Analysis')">
                    </granularity-select>
                </div>
                <div class="graph">
                    <div *ngIf="!sentimentAnalysisGraphCurrentUser || !sentimentAnalysisGraphCurrentUser[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-line-chart *ngIf="sentimentAnalysisGraphCurrentUser && !!sentimentAnalysisGraphCurrentUser[0]" #sentimentAnalysisGraph [scheme]="sentimentAnalysisGraphColorScheme" [results]="sentimentAnalysisGraphCurrentUser" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="false" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="false" [showRefLabels]="true" [showRefLines]="true" [xAxisLabel]="xAxisLineGraphLabel" [animations]="true" [showGridLines]="true" [yAxisLabel]="'&deg;'" [timeline]="true" [yScaleMin]="-1" [yScaleMax]="1" [autoScale]="autoScale" (select)="onSelect($event)">
                    </ngx-charts-line-chart>
                </div>
            </article>
            <!--FER -->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Face Presence and Facial Expression</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event, 'FER')">
                    </granularity-select>
                </div>
                <div class="graph">
                    <div *ngIf="!FERAnalysisGraphCurrentUser || !FERAnalysisGraphCurrentUser[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-line-chart *ngIf="FERAnalysisGraphCurrentUser && !!FERAnalysisGraphCurrentUser[0]" #FERAnalysisGraph [scheme]="FERAnalysisGraphColorScheme" [results]="FERAnalysisGraphCurrentUser" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="false" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="false" [showRefLabels]="true" [showRefLines]="true" [xAxisLabel]="xAxisLineGraphLabel" [showGridLines]="true" [yAxisLabel]="'&deg;'" [timeline]="true" [yScaleMin]="-1" [yScaleMax]="1" [autoScale]="autoScale" (select)="onSelect($event)">
                    </ngx-charts-line-chart>
                </div>
            </article>

            <!--FER SENTIMENT COMBINED -->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">FER x Sentiment</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event, 'FER x Sentiment')">
                    </granularity-select>
                </div>
                <div class="graph">
                    <div *ngIf="!FERSentimentCombinedGraphCurrentUser || !FERSentimentCombinedGraphCurrentUser[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-line-chart *ngIf="FERSentimentCombinedGraphCurrentUser && !!FERSentimentCombinedGraphCurrentUser[0]" #FERSentimentAnalysisGraph [scheme]="FERSentimentCombinedGraphColorScheme" [results]="FERSentimentCombinedGraphCurrentUser" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="true" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="false" [showRefLabels]="true" [showRefLines]="true" [xAxisLabel]="xAxisLineGraphLabel" [showGridLines]="true" [yAxisLabel]="'&deg;'" [timeline]="true" [yScaleMin]="-1" [yScaleMax]="1" [autoScale]="autoScale" (select)="onSelect($event)">
                    </ngx-charts-line-chart>
                </div>
            </article>

            <!--            Average Game Activity-->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Average Game Activity</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event, 'Avg Game Activity')">
                    </granularity-select>
                </div>
                <div class="graph">
                    <div *ngIf="!gameActivityCurrentUser || !gameActivityCurrentUser[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="gameActivityCurrentUser && !!gameActivityCurrentUser[0]" [scheme]="gameActivityColorScheme" [results]="gameActivityCurrentUser" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>
            </article>
        </ng-container>
    </div>
    <div class="rightPanel" *ngIf="allStudies && focusedStudyId">
        <div *ngIf="focusedUserId && focusedStudyId" class="usageData">
            <div class="infoHeader">
                <h6>Time line</h6>
            </div>
            <div class="graphSection">
                <!--                <ngx-charts-bar-horizontal *ngIf="gameUsageData"-->
                <!--                                           [view]="usageGraphView"-->
                <!--                                           [scheme]="usageGraphColorScheme"-->
                <!--                                           [results]="gameUsageData"-->
                <!--                                           [gradient]='false'-->
                <!--                                           [xAxis]='false'-->
                <!--                                           [yAxis]='true'-->
                <!--                                           [legend]='false'-->
                <!--                                           [showXAxisLabel]='false'-->
                <!--                                           [showYAxisLabel]='false'-->
                <!--                                           [barPadding]="15"-->
                <!--                                           [showDataLabel]='false'-->
                <!--                                           [dataLabelFormatting] = "formatUsageDataLabel"-->
                <!--                                           (select)="onSelect($event)">-->
                <!--                    <ng-template #tooltipTemplate let-model="model">-->
                <!--                        <h2>{{ model.value / 60000.0 | number:'1.0-0'}} min</h2>-->
                <!--                    </ng-template>-->
                <!--                </ngx-charts-bar-horizontal>-->
            </div>
        </div>
        <div class="userList">
            <div class="infoHeader">
                <h6>Residents</h6>
                <p>{{(getParticipantsInStudy() | filterAnonUsers:search).length}} Users</p>
                <div class="search">
                    <fa-icon [icon]="icons.search" class="search__icon"></fa-icon>
                    <input placeholder="Search Users" [(ngModel)]="search">
                </div>
            </div>
            <div class="userInfo" (click)="focusOnUser(user.anonymizedName)" *ngFor="let user of (getParticipantsInStudy() | filterAnonUsers:search)">
                <p>{{user.anonymizedName}}</p>
                <div class="birthdayInfo">
                    <span>Patient</span>
                </div>
            </div>
        </div>

        <div class="queryDateRangeHelper">
            <panel-date-range-display [queryDateRange]="selectedRange"></panel-date-range-display>
        </div>
    </div>
</div>
