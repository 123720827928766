import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CustomEncoder} from '../encoder/customEncoder';
import {isValidDate} from '../../components/util';
import {Observable} from 'rxjs';
import {isResearchRoleName} from '../../components/interfaces/ResearchStudy';


export type AnalyticQuery = {
    startTime: Date, //date UTC
    endTime: Date, //date UTC
    granularity?: string, //enum["daily", "weekly", "monthly"]
    analyticType?: string,
    email?: string, //the email OR anonymizedName of the Ryan user we want the analytics for
    facility?: string,
    organization?: string,
    ryanId?: string,
    study?: string,
    role: string, //the role of the user that is doing the query
}

export type ActivityAnalyticResponse = {
    User?: string,
    Details: {
        name: string, //the ISO timestamp of data
        series: {
            name: string, //the name of the activity
            value: number, //the duration (in milliseconds)
        }[]
    },
    Summary: { totalDurationInMilliseconds: number, activity: string, activityShareForTimePeriod: number }[],
    TotalTimeForAllActivities: number,
    NumberOfUsers?: number,
    PieChart: {name:string, value: number}[]
}

export type ConversationAnalysisResponse = {
    totalNumberOfQuestionsDuringTimePeriod: number,
    topicSummarization: [
        {
            numberOfQuestions: number,
            topic: string,
            percentShare: number
        }
    ],
    conversationDetails: [
        {
            userWordCount: number,
            ryanWordCount: 60,
            day: Date,
            topic: string,
            response: string
        }
    ],
    availableTopics: []
}

@Injectable()
export class AnalyticsService {
    public baseUrl = '/api/analytics';
    static parameters = [HttpClient];
    dateToday = (new Date()).toISOString();
    constructor(public http: HttpClient) {
        this.http = http;
    }

    private _getParamsForMultipleRobotsQuery(query: AnalyticQuery): HttpParams {
        query.role = query.role.toLowerCase();
        if (isResearchRoleName(query.role)) query.role = 'researcher';
        let params = new HttpParams({encoder: new CustomEncoder()})
            .set('startTime', query.startTime.toISOString())
            .set('endTime', query.endTime.toISOString())
            .set('granularity', query.granularity)
            .set('role', query.role);
        if (query.organization) {
            params = params.append('organization', query.organization);
        }
        return params;
    }

    private _getParamsForSingleRobotQuery(query: AnalyticQuery): HttpParams {
        query.role = query.role.toLowerCase();
        if (isResearchRoleName(query.role)) query.role = 'researcher';
        let params = new HttpParams({encoder: new CustomEncoder()})
            .set('startTime', query.startTime.toISOString())
            .set('endTime', query.endTime.toISOString())
            .set('granularity', query.granularity)
            .set('role', query.role);
        if (query.organization) {
            params = params.append('organization', query.organization);
        }
        if (query.ryanId) {
            params = params.append('ryanId', query.ryanId);
        }
        return params;
    }

    private _getParamsForMultipleUsersQuery(query: AnalyticQuery): HttpParams {
        query.role = query.role.toLowerCase();
        if (isResearchRoleName(query.role)) query.role = 'researcher';
        let params = new HttpParams({encoder: new CustomEncoder()})
            .set('startTime', query.startTime.toISOString())
            .set('endTime', query.endTime.toISOString())
            .set('granularity', query.granularity)
            .set('role', query.role);
        if (query.facility) {
            params = params.append('facility', query.facility);
        } else if (query.study && isResearchRoleName(query.role)) {
            params = params.append('study', query.study);
        } else {
            throw new Error('must specify a facility or study (by _id)');
        }
        return params;
    }

    private _getParamsForSingleUserQuery(query: AnalyticQuery): HttpParams {
        query.role = query.role.toLowerCase();
        if (isResearchRoleName(query.role)) query.role = 'researcher';
        let params = new HttpParams({encoder: new CustomEncoder()})
            .set('startTime', query.startTime.toISOString())
            .set('endTime', query.endTime.toISOString())
            .set('email', query.email)
            .set('role', query.role);
        if (query.granularity) {
            params = params.append('granularity', query.granularity);
        }
        if (query.study && isResearchRoleName(query.role)) {
            params = params.append('study', query.study);
        }
        return params;
    }

    private validateDates(query: AnalyticQuery) {
        if (!isValidDate(query.startTime) || !isValidDate(query.endTime)) throw new Error('Invalid Dates');
        // if the startTime comes before the end time
        if (query.startTime > query.endTime) {
            const temp = query.startTime;
            query.startTime = query.endTime;
            query.endTime = temp;
        }
    }

    getGameActivityAnalyticsForSingleUser(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForSingleUserQuery(query);
        return this.http.get(`${this.baseUrl}/gameActivityUser`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getGameActivityAnalyticsForMultipleUsers(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForMultipleUsersQuery(query);
        return this.http.get(`${this.baseUrl}/gameActivityUsers`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }


    getGameUsageAnalyticsForSingleUser(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForSingleUserQuery(query);
        return this.http.get(`${this.baseUrl}/gameUsageUser`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getGameUsageAnalyticsForMultipleUsers(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForMultipleUsersQuery(query);
        return this.http.get(`${this.baseUrl}/gameUsageUsers`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getAllAnalyticsOfType(analyticType: string) {
        return this.http.get(`${this.baseUrl}/${analyticType}`)
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getFlowGameDataForUser(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForSingleUserQuery(query);
        return this.http.get(`${this.baseUrl}/flowGameUser`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getFlowGameDataForUsers(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForMultipleUsersQuery(query);
        return this.http.get(`${this.baseUrl}/flowGameUsers`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getTicTacToeDataForUser(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForSingleUserQuery(query);
        return this.http.get(`${this.baseUrl}/tictactoeUser`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getTicTacToeDataForUsers(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForMultipleUsersQuery(query);
        return this.http.get(`${this.baseUrl}/tictactoeUsers`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getPicturePuzzleDataForUser(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForSingleUserQuery(query);
        return this.http.get(`${this.baseUrl}/picturePuzzleUser`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getPokerDataForUser(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForSingleUserQuery(query);
        return this.http.get(`${this.baseUrl}/pokerUser`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getPicturePuzzleDataForUsers(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForMultipleUsersQuery(query);
        return this.http.get(`${this.baseUrl}/picturePuzzleUsers`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getPokerDataForUsers(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForMultipleUsersQuery(query);
        return this.http.get(`${this.baseUrl}/pokerUsers`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getWordPuzzleDataForUser(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForSingleUserQuery(query);
        return this.http.get(`${this.baseUrl}/wordPuzzleUser`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getWordPuzzleDataForUsers(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForMultipleUsersQuery(query);
        return this.http.get(`${this.baseUrl}/wordPuzzleUsers`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getFlightDataForUser(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForSingleUserQuery(query);
        return this.http.get(`${this.baseUrl}/flightUser`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getCheckersDataForUser(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForSingleUserQuery(query);
        return this.http.get(`${this.baseUrl}/checkersUser`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getChessDataForUser(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForSingleUserQuery(query);
        return this.http.get(`${this.baseUrl}/chessUser`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getSolitaireDataForUser(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForSingleUserQuery(query);
        return this.http.get(`${this.baseUrl}/solitaireUser`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getSentimentAnalysisForUser(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForSingleUserQuery(query);
        return this.http.get(`${this.baseUrl}/sentimentAnalysisUser`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getFlightDataForUsers(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForMultipleUsersQuery(query);
        return this.http.get(`${this.baseUrl}/flightUsers`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getCheckersDataForUsers(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForMultipleUsersQuery(query);
        return this.http.get(`${this.baseUrl}/checkersUsers`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getChessDataForUsers(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForMultipleUsersQuery(query);
        return this.http.get(`${this.baseUrl}/chessUsers`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getSolitaireDataForUsers(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForMultipleUsersQuery(query);
        return this.http.get(`${this.baseUrl}/solitaireUsers`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getRyanRunsDataForUser(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForSingleUserQuery(query);
        return this.http.get(`${this.baseUrl}/ryanRunsUser`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getRyanRunsDataForUsers(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForMultipleUsersQuery(query);
        return this.http.get(`${this.baseUrl}/ryanRunsUsers`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getBirdCountingDataForUser(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForSingleUserQuery(query);
        return this.http.get(`${this.baseUrl}/birdCountingUser`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getBirdCountingDataForUsers(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForMultipleUsersQuery(query);
        return this.http.get(`${this.baseUrl}/birdCountingUsers`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getFERDataForUser(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForSingleUserQuery(query);
        return this.http.get(`${this.baseUrl}/ferUser`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getFERAndSentimentCombinedDataForUser(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForSingleUserQuery(query);
        return this.http.get(`${this.baseUrl}/ferAndSentimentUser`, {
            params: params
        })
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                console.error(err);
            });
    }

    getActivityAnalyticsForSingleUser(query: AnalyticQuery): Observable<ActivityAnalyticResponse> {
        this.validateDates(query);
        const params = this._getParamsForSingleUserQuery(query);
        return this.http.get(`${this.baseUrl}/activityUser`, {
            params: params
        }) as Observable<ActivityAnalyticResponse>;
    }

    getActivityAnalyticsForMultipleUsers(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForMultipleUsersQuery(query);
        return this.http.get(`${this.baseUrl}/activityUsers`, {
            params: params
        });
    }

    getAgeDataForFacility(query: {facility: string, role: string}) {
        query.role = query.role.toLowerCase();
        return this.http.get(`${this.baseUrl}/ageDataUsers`, {
            params: {
                facility: query.facility,
                role: query.role
            }
        });
    }

    getConversationAnalysisForSingleUser(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForSingleUserQuery(query);
        query.role = query.role.toLowerCase();
        return this.http.get(`${this.baseUrl}/conversationAnalysisUser`, {
            params: params
        });
    }

    getAnalyticDownloadForUser(query: AnalyticQuery) {
        this.validateDates(query);
        query.role = query.role.toLowerCase();
        if (isResearchRoleName(query.role)) query.role = 'researcher';
        var params = new HttpParams({encoder: new CustomEncoder()})
            .set('startTime', query.startTime.toISOString())
            .set('endTime', query.endTime.toISOString())
            .set('analyticType', query.analyticType)
            .set('email', query.email)
            .set('role', query.role);
        if (query.facility) {
            params = params.append('facility', query.facility);
        } else if (query.study && isResearchRoleName(query.role)) {
            params = params.append('study', query.study);
        }
        return this.http.get(`${this.baseUrl}/downloadUser`, {
            responseType: 'arraybuffer',
            headers: {
                Accept: 'text/csv',
                'Content-Type': 'text/csv; charset=utf-8'
            },
            params: params
        });
    }
    getAnalyticDownloadForFacilityOrStudy(query: AnalyticQuery) {
        this.validateDates(query);
        query.role = query.role.toLowerCase();
        if (isResearchRoleName(query.role)) query.role = 'researcher';
        var params = new HttpParams({encoder: new CustomEncoder()})
            .set('startTime', query.startTime.toISOString())
            .set('endTime', query.endTime.toISOString())
            .set('analyticType', query.analyticType)
            .set('role', query.role);
        if (query.facility) {
            params = params.append('facility', query.facility);
        } else if (query.study && isResearchRoleName(query.role)) {
            params = params.append('study', query.study);
        }
        return this.http.get(`${this.baseUrl}/downloadUsers`, {
            responseType: 'arraybuffer',
            headers: {
                Accept: 'text/csv',
                'Content-Type': 'text/csv; charset=utf-8'
            },
            params: params
        });
    }

    getHardWareEventsForSingleRobot(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForSingleRobotQuery(query);
        query.role = query.role.toLowerCase();
        return this.http.get(`${this.baseUrl}/eventRobot`, {
            params: params
        }).toPromise();
    }

    getHardWareEventsForMultipleRobotsInOrganization(query: AnalyticQuery) {
        this.validateDates(query);
        const params = this._getParamsForMultipleRobotsQuery(query);
        query.role = query.role.toLowerCase();
        return this.http.get(`${this.baseUrl}/eventRobots`, {
            params: params
        }).toPromise();
    }
}
