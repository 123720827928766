<background></background>
<div class="createAccountPage">
    <div class="container-fluid">
        <div class="row formRow">
            <div class="formContainer">
                <div class="logoBox">
                    <img class="login-overlay__logoDiv__img logo__img" src="/bcfcc9791c4b4bb0b3224c16fe6cd3ff.png" alt="DreamFace Technologies Logo">
                </div>
                <div class="title">
                    <h4 class="login-overlay__textDiv__text text--white">
                        Create your Account
                    </h4>
                </div>

                <form class="createAccountForm" name="form" (ngSubmit)="register(signupForm)" novalidate #signupForm="ngForm">
                    <div class="form-group row inputContainer">
                        <div class="col-sm-12 col-md-10 offset-md-1">
                            <input class="form-control email-input" id="firstName" name="firstName" [(ngModel)]="user.firstName" #firstName="ngModel" placeholder="First Name" required>
                        </div>
                    </div>

                    <div class="form-group row inputContainer">
                        <div class="col-sm-12 col-md-10 offset-md-1">
                            <input class="form-control email-input" id="lastName" name="lastName" [(ngModel)]="user.lastName" #lastName="ngModel" placeholder="Last Name" required>
                        </div>
                    </div>

                    <div class="form-group row inputContainer">
                        <div class="col-sm-12 col-md-10 offset-md-1">
                            <input class="form-control email-input" id="email" name="email" [(ngModel)]="user.email" placeholder="Email" #email="ngModel" required>
                        </div>
                    </div>

                    <div class="form-group row inputContainer">
                        <div class="col-sm-12 col-md-10 offset-md-1">
                            <input class="form-control pwd-input" id="password" name="password" type="password" [(ngModel)]="user.password" placeholder="Password" #password="ngModel" required>
                        </div>
                    </div>

                    <div class="form-group row inputContainer">
                        <div class="col-sm-12 col-md-10 offset-md-1">
                            <input class="form-control pwd-input" id="repeatPassword" name="repeatPassword" type="password" [(ngModel)]="user.repeatPassword" placeholder="Repeat Password" #repeatPassword="ngModel" required>
                        </div>
                    </div>

                    <div class="form-group row inputContainer">
                        <div class="col-sm-12 col-md-10 offset-md-1">
                            <input type="text" id="birthday" name="birthday" placeholder="Date of Birth (MM/DD/YYYY)" class="form-control" bsDatepicker [bsConfig]="{dateInputFormat: 'MM/DD/YYYY',
                                   selectWeekDateRange: false,
                                   selectFromOtherMonth: true,
                                   showWeekNumbers: false,
                                   maxDate: currentDate,
                                   containerClass: 'theme-blue' }" [(ngModel)]="user.birthday" #birthday="ngModel" required>
                        </div>
                    </div>

                    <div class="form-group row inputContainer">
                        <div class="col-sm-12 col-md-10 offset-md-1">
                            <select class="form-control pwd-input" name="gender" [(ngModel)]="user.gender" #gender="ngModel" required>
                                <option value="">Select a Gender</option>
                                <option *ngFor="let gender of genderTypes" value="{{gender}}">{{gender}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row inputContainer">
                        <div class="col-sm-12 col-md-10 offset-md-1">
                            <input class="form-control pwd-input" id="weight" name="weight" type="number" [(ngModel)]="user.weight" placeholder="Weight" #weight="ngModel" min="0" required>
                        </div>
                    </div>

                    <div class="agreementBox">
                        <input [(ngModel)]="privacyAgreement" type="checkbox" id="privacyAgreement" name="privacyAgreement">
                        <label class="form-check-label" for="privacyAgreement">
                            <span class="agreement">I agree to Dreamface's</span><a target="_blank" href="https://dreamface-privacy-agreement.s3.us-east-1.amazonaws.com/DreamFacePrivacyPolicy_2022-08-02.pdf">Privacy Agreement</a></label>
                    </div>

                    <div class="agreementBox">
                        <input [(ngModel)]="cookiesPolicy" type="checkbox" id="cookiesPolicy" name="cookiesPolicy">
                        <label class="form-check-label" for="cookiesPolicy">
                            <span class="agreement">I agree to Dreamface's</span><a>Cookie Policy</a></label>
                    </div>

                    <div class="fullWidth" *ngIf="alert.show">
                        <alert class="fullWidth" type="{{alert.type}}">
                            {{alert.message}}
                        </alert>
                    </div>

                    <div class="buttonsArea">
                        <button type="submit" class="btn filledBtn">
                            Create Account
                        </button>
                    </div>
                    <hr>
                </form>
                <div class="bottomInfo">
                    <p>
                        Already have an account?
                        <a class="create-acct" routerLink="/account/login">Login</a>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <powered-by-dft class="logoBottom"></powered-by-dft>

</div>
