<div class="page">
    <div class="mainContent organizations" *ngIf="allOrgs">
        <nav class="breadcrumb breadcrumbs" aria-label="breadcrumb">
            <ol class="breadcrumbs__list">
                <!--                Not focused on Org NOR facility-->
                <li class="breadcrumb-item breadcrumbItem" [attr.aria-current]="(!focusedOrgId) ? 'page' : null" (click)="focusOnOrganization()">
                    Ryan Data
                </li>
                <!--                Focused on Org-->
                <li class="breadcrumb-item breadcrumbItem" *ngIf="focusedOrgId && !!currentOrganization" [attr.aria-current]="(focusedOrgId && !!currentOrganization) ? 'page' : null" (click)="focusOnOrganization(currentOrganization._id)">
                    {{currentOrganization.name || "n/a"}}
                </li>
            </ol>
        </nav>

        <!--    Select Organization     -->
        <ng-container *ngIf="!focusedOrgId">
            <h4>Please select an Organization</h4>
            <div class="orgList">
                <article class="orgArea blueOnHover" *ngFor="let org of allOrgs" (click)="focusOnOrganization(org._id)">
                    <div class="header">
                        <div class="text-area">
                            <h3 class="orgName">{{org?.name || "Loading Name"}}</h3>
                            <address class="orgAddr">
                                {{org.address || ''}} <span *ngIf="org.city">, </span>
                                {{org.city || ''}}<span *ngIf="org.state">, </span>{{org.state || ''}} {{org.zip || ''}}
                            </address>
                        </div>
                    </div>
                    <div class="orgInfoCount">
                        <h6>Administrators: <span>({{org?.administrators?.length || 0}})</span></h6>
                        <h6>Robots: <span>({{org.ryans.length || 0}})</span></h6>
                        <h6>Facilities: <span>({{org?.facilities?.length || 0}})</span></h6>
                    </div>
                </article>
            </div>
        </ng-container>
        <!--        end select Organization     -->

        <!-- organization focus -->
        <ng-container *ngIf="focusedOrgId && currentOrganization && !focusedRyanId">
            <div class="ryanData">

                <article class="mainContent__tile map-container">
                    <div class="header">
                        <h4 class="graphTitle">Ryans</h4>
                        <small class="numRyans">Total
                            {{bots.all.length || '0'}} Ryans</small>
                    </div>
                    <img style="width: 100%" src="/1d6922ed915d9c986c477a324f423293.png" alt="Place holder map">
                    <div class="table-container">
                        <table class="table">
                            <thead class="table__header">
                            <tr>
                                <th>ID</th>
                                <th>Status</th>
                                <th>Facility</th>
                                <th>Most Recent Event</th>
                            </tr>
                            </thead>
                            <tbody class="table__body" *ngIf="bots.all.length > 0">
                            <tr class="table__data" style="cursor: pointer" *ngFor="let bot of bots.all" (click)="focusOnRyan(bot._id)">
                                <td class="id">
                                    <span [ngClass]="bot?.ryanState">{{bot._id}}</span>
                                </td>
                                <td class="status">{{bot.ryanState || "N/A"}}</td>
                                <td class="community">{{bot.activeFacility || "N/A"}}</td>
                                <td class="errors">{{bot.lastKnownEvent?.name || "N/A"}}</td>
                            </tr>
                            </tbody>
                            <tbody class="table__body" *ngIf="this.bots.all.length <= 0">
                            <tr>
                                <td colspan="3">No Ryans</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </article>


            </div>
        </ng-container>

        <!-- Focused on organization AND ryan-->
        <ng-container *ngIf="focusedRyanId && focusedOrgId && currentOrganization">
            <div class="rowMain">
                <div class="userMainInfo">
                    <div class="item1">
                        <fa-icon [icon]="icon.robot" size="2x"></fa-icon>
                    </div>
                    <div class="item2">
                        <h2 class="capitalize">{{getCurrentRyan()?._id || ''}}</h2>
                    </div>
                    <div style="color: #42A948" class="item3" *ngIf="status.potentiallyOnline">
                        Online: {{status.johnnyServerConnection}} <br>
                        <span *ngIf="status.lastLoggedInUser">Last logged in: {{status.lastLoggedInUser}}</span>
                    </div>
                    <div style="color: red" class="item3" *ngIf="!status.potentiallyOnline">
                        Offline: {{status.johnnyServerConnection}}<br>
                        <span *ngIf="status.lastLoggedInUser">Last logged in: {{status.lastLoggedInUser}}</span>
                    </div>
                </div>
                <div class="userBottomInfo" *ngIf="getCurrentOrganization().name">
                    <div class="facInfo">
                        <span>Assigned to</span>
                        <span> {{getCurrentOrganization()?.name}}</span>
                    </div>
                </div>

            </div>

            <article class="mainContent__tile">
                <queryDateRangeControl title="{{getCurrentRyan()._id}} Usage Data" [queryDateRange]="selectedRange" downloadBtnLabel="Export Data" [downloadModalHeader]="'Download User data for ' + getCurrentRyan()._id" (queryDatesUpdate)="changeSelectedRange($event)">
                </queryDateRangeControl>
            </article>

            <!--Hardware events table -->
            <article class="graph-area rowMain">
                <hardware-graph [graphTitle]="'Ryan Events'" [hardwareDataReady]="eventDataForCurrentRobotReady" [hardwareEvents]="eventDataForCurrentRobot">
                </hardware-graph>
            </article>

<!--            <article class="graph-area rowMain">-->
<!--                <div class="header">-->
<!--                    <h4 class="graphTitle">Robot Connectivity</h4>-->
<!--                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity"-->
<!--                                        [granularityOptions]="granularityOptions"-->
<!--                                        (granularityChange)="changeGranularity($event)">-->
<!--                    </granularity-select>-->
<!--                </div>-->
<!--                <div class="graph">-->
<!--                    <div *ngIf="!data" class="loadingData">-->
<!--                        Sorry, no data in this timeframe-->
<!--                    </div>-->
<!--                    <ngx-charts-line-chart-->
<!--                        [results]="data"-->
<!--                        [showGridLines]="false"-->
<!--                        [yScaleMin]="0"-->
<!--                        [yScaleMax]="1.3"-->
<!--                        [view]="view"-->
<!--                        [scheme]="colorScheme"-->
<!--                        [gradient]="gradient"-->
<!--                        [xAxis]="true"-->
<!--                        [yAxis]="false"-->
<!--                        [legend]="showLegend"-->
<!--                        [showXAxisLabel]="showXAxisLabel"-->
<!--                        [showYAxisLabel]="false"-->
<!--                        [xAxisLabel]="false"-->
<!--                        [yAxisLabel]="yAxisLabel"-->
<!--                        (select)="onSelect($event)">-->
<!--                        <ng-template #tooltipTemplate let-model="model">-->
<!--                            This is the single point tooltip template-->
<!--                            <pre>{{model|json}}</pre>-->
<!--                        </ng-template>-->
<!--                    </ngx-charts-line-chart>-->
<!--                </div>-->


<!--            </article>-->
        </ng-container>

    </div>
    <div class="rightPanel" *ngIf="focusedOrgId && currentOrganization">
        <div class="makePatch-btn" (click)="makePatch()">
            <refresh-icon class="btn-icon" [ellipse]="true"></refresh-icon>
            <span class="btn-title">Make Patch</span>
            <span class="btn-desc">Install a Patch for all Ryans in my Orgs</span>
        </div>
        <div class="weeklyActivity" *ngIf="!focusedRyanId">
            <!--Pie Chart -->
            <article class="">
                <div class="header">
                    <h4 class="graphTitle"> Average Weekly Activity</h4>
                </div>
<!--                <div class="piechart-graph-area">-->
<!--                    <ngx-charts-advanced-pie-chart *ngIf="activityGraphMultipleRobots && activityGraphMultipleRobots.PieChart"-->
<!--                                                   [customColors]="this.mapColorKeys"-->
<!--                                                   [valueFormatting]="this.formatPieChartValues"-->
<!--                                                   [nameFormatting]="this.formatPieChartNames"-->
<!--                                                   [animations]="false"-->
<!--                                                   [scheme]="pieChartColorScheme"-->
<!--                                                   [results]="activityGraphMultipleRobots.PieChart"-->
<!--                                                   [gradient]="gradient"-->
<!--                                                   [label]="'Total'"-->
<!--                                                   (select)="onSelect($event)">-->
<!--                        <ng-template #tooltipTemplate let-model="model">-->
<!--                            <span class="tooltip-label">{{model.label}}</span>-->
<!--                            <span class="tooltip-val">{{this.getTooltipTemplateTime(model)}}</span>-->
<!--                        </ng-template>-->
<!--                    </ngx-charts-advanced-pie-chart>-->
<!--                </div>-->
            </article>
        </div>
    </div>
</div>
