import { UIRoleViews } from '../../../server/config/environment/shared';

/**
 * The names, links, and icons of each of the possible pages for each roleView
 */
const DashboardPage = {name: 'Dashboard', link: 'dashboard', iconName: 'dashboard'};
const UsersActivitiesPage = {name: 'Users Activities', link: 'usersactivities', iconName: 'usersactivities'};
const RyanDataPage = {name: 'Ryan Data', link: 'ryandata', iconName: 'ryandata'};
const MediaPage = {name: 'Media', link: 'media', iconName: 'media'};
const CalendarPage = {name: 'Calendar', link: 'calendar', iconName: 'calendar'};
const ManagePages = {
    admin: {name: 'Manage Organizations', link: 'manage', iconName: 'manage'},
    orgadmin: {name: 'Manage Organization', link: 'manage', iconName: 'manage'},
    facilityadmin: {name: 'Manage Facility', link: 'manage', iconName: 'manage'},
    principleinvestigator: {name: 'Manage Studies', link: 'manage', iconName: 'manage'},
    researchcoordinator: {name: 'Manage Studies', link: 'manage', iconName: 'manage'},
    researcher: {name: 'View Studies', link: 'manage', iconName: 'manage'}
};
const GameMetricsPage = {name: 'Game Metrics', link: 'gamemetrics', iconName: 'gamemetrics'};
const ProgramRPage = {name: 'Program-R', link: 'programr', iconName: 'programr'};
const SettingsPage = {name: 'Settings', link: 'settings', iconName: 'settings'};
const WikiPage = {name: 'Wiki', link: 'wiki', iconName: 'wiki'};

/**
 * Depending on the selected role of the user, which pages to show as options
 */
const UserPages = [DashboardPage, CalendarPage, MediaPage, ProgramRPage, SettingsPage];

const CaregiverPages = [DashboardPage, UsersActivitiesPage, GameMetricsPage, CalendarPage, MediaPage, SettingsPage];
const GuardianPages = [DashboardPage, UsersActivitiesPage, CalendarPage, MediaPage, SettingsPage];

//TODO: add RyanData.facilityadmin page
const FacilityAdminPages = [DashboardPage, UsersActivitiesPage,
    // RyanDataPage,
    GameMetricsPage, ManagePages.facilityadmin, SettingsPage];

const PrincipleInvestigatorPages = [DashboardPage, UsersActivitiesPage, GameMetricsPage, ManagePages.principleinvestigator, SettingsPage];
const ResearchCoordinatorPages = [DashboardPage, UsersActivitiesPage, GameMetricsPage, ManagePages.researchcoordinator, SettingsPage];
const ResearcherPages = [DashboardPage, UsersActivitiesPage, GameMetricsPage, ManagePages.researcher, SettingsPage];

//TODO: Do we want Organization-wide calendars?
const OrgAdminPages = [DashboardPage, RyanDataPage, ManagePages.orgadmin, SettingsPage];
const SiteAdminPages = [DashboardPage, RyanDataPage, ManagePages.admin, SettingsPage];

export function getPagesForRole(roleView: string) {
    roleView = roleView.toLowerCase();
    const rolePageKeys = {
        user: UserPages,
        caregiver: CaregiverPages,
        guardian: GuardianPages,
        principleinvestigator: PrincipleInvestigatorPages,
        researchcoordinator: ResearchCoordinatorPages,
        researcher: ResearcherPages,
        facilityadmin: FacilityAdminPages,
        organizationadmin: OrgAdminPages,
        admin: SiteAdminPages,
    };
    return rolePageKeys[roleView] ? rolePageKeys[roleView] : UserPages;
}
