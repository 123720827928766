<div class="modal-content" tabindex="-1" role="dialog">
    <form (ngSubmit)="handleSubmit($event)">
        <div class="modal-header">
            <div class="title">
                <h4 id="myModalLabel" class="modal-title">Assign Caregiver/Guardian to User</h4>
                <button type="button" (click)="bsModalRef.hide()" class="close"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
            </div>
            <div>
                <p>Name: {{user.firstName}} {{user.lastName}}</p>
                <p>Email: {{user.email || ""}}</p>
            </div>
            <div class="caregiverInfo">
                <p>Caregivers {{user.caregivers.length || 0}}:</p>
                <div *ngFor="let caregiver of user.caregivers" class="caregiverTile">
                    {{caregiver}}
                </div>
            </div>
            <div class="caregiverInfo">
                <p>Guardians {{user.guardians.length || 0}}: </p>
                <div *ngFor="let guardian of user.guardians" class="caregiverTile">
                    {{guardian}}
                </div>
            </div>

        </div>
        <ng-container *ngIf="!submitting">
            <div class="modal-body" *ngIf="!addType">
                <p>What do you want to add for user?</p>
                <div class="buttonsArea">
                    <button (click)="addType = 'caregiver'" class="btn btn-outline-primary">Add Caregiver</button>
                    <button (click)="addType = 'guardian'" class="btn btn-outline-success">Add Guardian</button>
                </div>
            </div>

            <div class="modal-body" *ngIf="addType === 'caregiver'">
                <p>Choose a caregiver</p>
                <div class="caregiverList">
                    <div [ngClass]="{'btn': true, 'btn-primary': caregiver.email === assignee, 'btn-outline-secondary': caregiver.email !== assignee}" (click)="assignee = caregiver.email" *ngFor="let caregiver of getEmailVerifiedCaregivers(facility.caregivers)">{{caregiver.email}}
                    </div>
                </div>
                <div class="buttonsArea">
                    <button (click)="reset()" class="btn btn-outline-warning">Cancel</button>
                    <button type="submit" [disabled]="!assignee" class="btn btn-outline-primary">Confirm Caregiver</button>
                </div>
            </div>
            <div class="modal-body" *ngIf="addType === 'guardian'">
                <div class="inputArea">
                    <label for="guardianEmail">Guardian's Email:</label>
                    <input id="guardianEmail" name="email" placeholder="Guardian's email" [(ngModel)]="assignee">
                </div>
                <div class="buttonsArea">
                    <button (click)="reset()" class="btn btn-outline-warning">Cancel</button>
                    <button type="submit" [disabled]="!assignee" class="btn btn-outline-success">Confirm Guardian</button>
                </div>
            </div>
        </ng-container>
        <div class="fullWidth" *ngIf="alert.show">
            <alert class="fullWidth" type="{{alert.type}}">
                {{alert.message}}
            </alert>
        </div>
    </form>
</div>
