import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { RouterModule, Routes } from '@angular/router';
import { GraphsModule } from '../../components/graphs/graphs.module';
import { DirectivesModule } from '../../components/directives.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatTableModule } from '@angular/material/table';
import { AuthGuard } from '../../components/auth/auth-guard.service';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarSetModule } from '../../components/calendar/calendar.module';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProgramRSetModule } from '../programr/programr.module';
import { SettingsSetModule } from '../settings/settings.module';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {MatPaginatorModule} from '@angular/material/paginator';
import { ResearchCoordinatorPagesComponent } from './researchCoordinatorPages.component';
import { ResearchCoordinatorManageComponent } from './RC-Manage/RC-Manage.component';
import { ResearchCoordinatorDashboardComponent } from './RC-Dashboard/RC-Dashboard.component';
import { ResearchCoordinatorGameMetricsComponent } from './RC-GameMetrics/RC-GameMetrics.component';
import {ResearchCoordinatorUsersActivitiesComponent} from './RC-UsersActivities/RC-usersActivities.component';
import {WikiModule} from "../wiki/wiki.module";
import {QuillModule} from "ngx-quill";


const ROUTES: Routes = [
    {
        path: ':pageView',
        component: ResearchCoordinatorPagesComponent,
        canActivate: [AuthGuard] //TODO: create/add PI AuthGuard
    },
];
@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        FullCalendarModule,
        CalendarSetModule,
        CommonModule,
        CollapseModule,
        RouterModule,
        AccordionModule,
        MatExpansionModule,
        FontAwesomeModule,
        MatIconModule,
        CdkTreeModule,
        MatButtonModule,
        BsDropdownModule,
        BsDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
        NgxChartsModule,
        MatTableModule,
        AlertModule,
        GraphsModule,
        DirectivesModule,
        ProgressbarModule,
        MatPaginatorModule,
        ProgramRSetModule,
        SettingsSetModule,
        TooltipModule,
        WikiModule,
        QuillModule,
    ],
    declarations: [
        ResearchCoordinatorPagesComponent,
        ResearchCoordinatorDashboardComponent,
        ResearchCoordinatorManageComponent,
        ResearchCoordinatorGameMetricsComponent,
        ResearchCoordinatorUsersActivitiesComponent,
    ],
    exports: [
        ResearchCoordinatorPagesComponent,
        ResearchCoordinatorDashboardComponent,
        ResearchCoordinatorManageComponent,
        ResearchCoordinatorGameMetricsComponent,
        ResearchCoordinatorUsersActivitiesComponent,
    ],
    providers: [BsModalService],
    schemas: [],
})
export class ResearchCoordinatorPagesModule { }
