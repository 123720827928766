// @flow
import {EventEmitter, Injectable, Output } from '@angular/core';
import { UserService, UserType } from './user.service';
import { HttpClient } from '@angular/common/http';
import { safeCb } from '../util';
import { take } from 'rxjs/operators';
import { Organization, OrgAdminData } from '../interfaces/Organization';
import { Facility, FacAdminData } from '../interfaces/Facility';
import { ResearchStudy } from '../interfaces/ResearchStudy';
import constants from '../../app/app.constants';
import _ from 'lodash';

class User {
    _id = '';
    id = '';
    firstName = '';
    lastName = '';
    birthday = '';
    email = '';
    role: string;
    gender: undefined;
    weight: undefined;
    ryanName: string;
    guardianOf: any[];
    caregiverOf: any[];
    organizationAdminOf: Organization[];
    facilityAdminOf: Facility[];
    principleInvestigatorIn?: Facility[];
    researcherOf?: ResearchStudy[];
    researchCoordinatorOf?: ResearchStudy[];
    invitationAccepted?: boolean;
    lastLoginTime?: any;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _currentUser: UserType = undefined;
    private _token: string = '';
    @Output() currentUserChanged = new EventEmitter(true);
    userRoles = constants.userRoles || [];
    UserService;

    static parameters = [HttpClient, UserService];

    constructor(public http: HttpClient, public userService: UserService) {
        this.http = http;
        this.UserService = userService;
        if (localStorage.getItem('id_token')) {
            this.UserService.get().toPromise()
                .then((user: UserType) => {
                    this.currentUser = user;
                    return this.updateCurrentUserFromData(user);
                })
                .catch(err => {
                    this.logout();
                    console.error(err);
                });
        }
        this.currentUserChanged.subscribe((user) => {
            localStorage.setItem('user', JSON.stringify(this.currentUser));
        });
    }

    get currentUser() {
        return this._currentUser;
    }

    set currentUser(user) {
        this._currentUser = user;
        this.currentUserChanged.emit(user);
    }

    get token() {
        return this._token;
    }
    set token(token) {
        localStorage.setItem('id_token', token);
        this._token = token;
    }

    refreshCurrentUser() {
        if (localStorage.getItem('id_token')) {
            this.UserService.get().toPromise()
                .then((user: UserType) => {
                    this.currentUser = user;
                    return this.updateCurrentUserFromData(user);
                })
                .catch(err => {
                    this.logout();
                    console.error(err);
                });
        }
    }

    updateCurrentUserFromData(user: UserType, cb?: Function) {
        this.applyIdToUserFromEmail(user);
        this.currentUser = user;
        this.currentUserChanged.emit(this.currentUser);
        this.getOrganizationAdminInfo();
        this.getFacilityAdminInfo();
        this.getFacilityPrincipleInvestigatorInfo();
        this.getResearchCoordinatorInfo();
        this.getResearcherInfo();
        return this.currentUser;
    }

    /**
     * Authenticate user and save token
     *
     * @param  {Object}   user     - login info
     * @param  {Function} [callback] - function(error, user)
     * @return {Promise}
     */
    //TODO: update user.lastLoginTime
    login({ email, password }, callback?) {
        return this.http.post('/api/auth/local',
            { email: email.toLowerCase(), password }
        ).toPromise()
            .then((data: { token: string }) => {
                this.token = data.token;
                return this.UserService.get().toPromise()
                    .then((user: User) => {
                        this.updateCurrentUserFromData(user, callback);
                        return user;
                    }).catch((err) => Promise.reject(err));
            })
            .catch((err) => {
                console.error(err);
                this.logout();
                safeCb(callback)(err);
                return Promise.reject(err);
            });
    }

    /**
     * Create a new user
     *
     * @param  {Object}   user     - user info
     * @param  {Function} callback - optional, function(error, user)
     * @return {Promise}
     */
    createUser(user, callback?) {
        return this.UserService.create(user).toPromise()
            .then(data => {
                this.token = data.token;
                return Promise.resolve(data);
            })
            .catch((err) => {
                this.logout();
                safeCb(callback)(err);
                return Promise.reject(err);
            });
    }

    /**
     * Delete access token and user info
     * @return {Promise}
     */
    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('id_token');
        this.currentUser = undefined;
        return Promise.resolve();
    }

    /**
     * Check if userRole is >= role
     * @param {String} userRole - role of current user
     * @param {String} role - role to check against
     */
    static hasRole(userRole: string, role: string) {
        return constants.userRoles.indexOf(userRole) >= constants.userRoles.indexOf(role);
    }

    /**
     * Change password
     *
     * @param  {String}   oldPassword
     * @param  {String}   newPassword
     * @param  {String}   newPasswordRepeated
     * @param  {Function} [callback] - function(error, user)
     * @return {Promise}
     */
    changePassword(oldPassword: string, newPassword: string, newPasswordRepeated: string, callback?) {
        return this.userService.changePassword({ id: this.currentUser._id }, oldPassword, newPassword, newPasswordRepeated)
            .toPromise()
            .then(() => {
                safeCb(callback)(null);
                return Promise.resolve();
            })
            .catch(err => {
                safeCb(callback)(err);
                return Promise.reject(err);
            });
    }

    /**
     * Gets all available info on a user
     *
     * @param  {Function} [callback] - function(user)
     * @return {Promise}
     */
    getCurrentUser(callback?) {
        safeCb(callback)(this.currentUser);
        return Promise.resolve(this.currentUser);
    }

    /**
     * Gets all available info on a user
     *
     * @return {Object}
     */
    getCurrentUserSync() {
        return this.currentUser;
    }

    /**
     * Checks if user is logged in
     * @param {function} [callback]
     * @returns {Promise}
     */
    isLoggedIn(callback?) {
        let is = !!this.currentUser._id;
        safeCb(callback)(is);
        return Promise.resolve(is);
    }

    /**
     * Checks if user is logged in
     * @returns {Boolean}
     */
    isLoggedInSync() {
        return !!this.currentUser._id;
    }

    /**
     * Check if a user is an admin
     *
     * @param  {Function|*} [callback] - optional, function(is)
     * @return {Promise}
     */
    isAdmin(callback?) {
        return this.getCurrentUser().then(user => {
            var is = user.role.includes('admin');
            safeCb(callback)(is);
            return is;
        });
    }

    isAdminSync() {
        return this.currentUser.role.includes('admin');
    }

    updateUser(user: User, userIdToUpdate?: string, callback?: any) {
        if (user.email) user.email = user.email.toLowerCase();
        return this.UserService.upsert(user, userIdToUpdate || 'me')
            .toPromise()
            .then((data) => this.UserService.get(userIdToUpdate || 'me').toPromise())
            .then((data: UserType) => {
                // if "me" data.rows[0].value
                // if some user data => retrievedUser
                const updatedUser = data;
                this.applyIdToUserFromEmail(updatedUser);
                if (userIdToUpdate === 'me') {
                    this.currentUser = updatedUser;
                    this.getOrganizationAdminInfo();
                    this.getFacilityAdminInfo();
                }
                safeCb(callback)(null, updatedUser);
                return Promise.resolve(updatedUser);
            })
            .catch((err) => Promise.reject(err));
    }

    getUsersInCare() {
        if (!this.currentUser) return [];
        return _.union(this.currentUser.caregiverOf, this.currentUser.guardianOf);
    }

    getOrganizationAdminInfo() {
        return this.http.get('api/organization/me/admin').pipe(take(1)).subscribe({
            next: (data: OrgAdminData) => {
                const { organizations } = data;
                if (organizations && organizations.length > 0) {
                    this.currentUser.organizationAdminOf = organizations;
                    this.currentUserChanged.emit(this.currentUser);
                } else {
                    this.currentUser.organizationAdminOf = [];
                    this.currentUserChanged.emit(this.currentUser);
                }
            },
            error: (e) => console.error(e),
        });
    }

    getFacilityAdminInfo() {
        return this.http.get('api/facility/me/admin').pipe(take(1)).subscribe({
            next: (data: FacAdminData) => {
                const { facilities } = data;
                if (facilities && facilities.length > 0) {
                    this.currentUser.facilityAdminOf = facilities;
                    this.currentUserChanged.emit(this.currentUser);
                } else {
                    this.currentUser.facilityAdminOf = [];
                    this.currentUserChanged.emit(this.currentUser);
                }
            },
            error: (e) => console.error(e),
        });
    }

    // retrieve facilities that a principle investigator (owner of a research study) is allowed to create studies for.
    getFacilityPrincipleInvestigatorInfo() {
        return this.http.get('api/facility/me/principleInvestigator').pipe(take(1)).subscribe({
            next: (data: FacAdminData) => {
                const { facilities } = data;
                if (facilities && facilities.length > 0) {
                    this.currentUser.principleInvestigatorIn = facilities; // indicates the facilities they are allowed to add to their studies
                    this.currentUserChanged.emit(this.currentUser);
                } else {
                    this.currentUser.principleInvestigatorIn = [];
                    this.currentUserChanged.emit(this.currentUser);
                }
            },
            error: (e) => console.error(e),
        });
    }

    // retrieve studies that this User is a Research Coordinator for
    getResearchCoordinatorInfo() {
        return this.http.get('api/research/coordinator/me').pipe(take(1)).subscribe({
            next: (data: {studies: ResearchStudy[]}) => {
                const { studies } = data;
                // const { facilities } = data;
                if (studies && studies.length > 0) {
                    this.currentUser.researchCoordinatorOf = studies; // indicates the Studies they are allowed to view + add users to
                    this.currentUserChanged.emit(this.currentUser);
                } else {
                    this.currentUser.researchCoordinatorOf = [];
                    this.currentUserChanged.emit(this.currentUser);
                }
            },
            error: (e) => console.error(e),
        });
    }

    // retrieve studies that this User is a Researcher for
    getResearcherInfo() {
        return this.http.get('api/research/researcher/me').pipe(take(1)).subscribe({
            next: (data: {studies: ResearchStudy[]}) => {
                const { studies } = data;
                if (studies && studies.length > 0) {
                    this.currentUser.researcherOf = studies; // indicates the studies they are a part of
                    this.currentUserChanged.emit(this.currentUser);
                } else {
                    this.currentUser.researcherOf = [];
                    this.currentUserChanged.emit(this.currentUser);
                }
            },
            error: (e) => console.error(e),
        });
    }

    applyIdToUserFromEmail(user: UserType) {
        user.id = user.id ? user.id : user.email;
        user._id = user._id ? user._id : user.email;
    }
}
