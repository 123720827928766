import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UserService, UserType } from '../../../components/auth/user.service';
import { AuthService } from '../../../components/auth/auth.service';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CalendarEvent } from '../../../components/interfaces/CalendarEvent';
import { CalendarEventService } from '../../../services/calendar-event/calendar-event.service';
import { featureFlags } from '../../../../server/config/environment/shared';
import _ from 'lodash';
import {isValidDate} from '../../../components/util';
import moment from 'moment';

@Component({
    selector: 'user-dashboard',
    templateUrl: './userDashboard.html',
    styleUrls: ['../../../assets/sharedStyles/pages/dashboard.scss'],
    providers: [BsModalService]
})


export class UserDashboardComponent implements OnInit, OnDestroy {
    @Output() queryDateRangeChange = new EventEmitter<[Date, Date]>();

    currentUser;
    currentDate = new Date();

    featureFlags = featureFlags;

    state = {
        org_id: undefined,
        ryan_id: undefined
    };

    loaded: boolean = true; //determines if all necessary content is loaded ("all" defined by permissions)
    usersLoaded: boolean = false; //determines if all user content is loaded
    eventsLoaded: boolean = false; //determines if all Events have been loaded

    icon = {
        bell: faBell,
        userCircle: faUserCircle,
        search: faSearch
    };

    allUsers: UserType[] = [];
    numUsers: number;

    numBots = {
        all: 0,
        active: 0,
        suspended: 0,
        disabled: 0
    };

    queryDateRange: [Date, Date];

    myEvents: CalendarEvent[] = [];
    numEvents: number;

    static parameters = [Router, AuthService, UserService, CalendarEventService,
        BsModalService, HttpClient];

    constructor(public router: Router, authService: AuthService,
        public userService: UserService, public eventService: CalendarEventService,
        public modalService: BsModalService, public http: HttpClient) {
        this.router = router;
        this.modalService = modalService;
        this.userService = userService;
        this.eventService = eventService;

        this.loaded = false;

        //get the current user from the token
        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserType;
        this.initDatePicker();
    }

    ngOnInit(): void {
        this.getUserGroup();
        this.getMyEvents();
        this.loaded = this.isLoaded();
    }

    ngOnDestroy(): void {}

    /**
     * isLoaded
     *      this method will determine whether or not all of the necessary parts of the page have been loaded
     */
    isLoaded(): boolean {
        this.loaded = this.usersLoaded && this.eventsLoaded;
        return this.usersLoaded && this.eventsLoaded;
    }

    /**
     * getUserGroup
     *      method to be used by users to retrieve everyone in their "group"
     *          (guardians, caregivers)
     */
    getUserGroup() {
        const contacts = _.union(this.currentUser.caregivers, this.currentUser.guardians);
        //    TODO: query for caregiver and guardian contact info
        this.usersLoaded = true;
    }


    getMyEvents() {
        this.eventService.query(this.currentUser.email)
            .then((res) => {
                this.myEvents = res as CalendarEvent[];
            })
            .then(() => {
                this.eventsLoaded = true;
                this.isLoaded();
            })
            .catch((err) => console.error(err));
    }


    navToCalendar() {
        // this.router.navigate(['/calendar']);
    }

    queryDateUpdate(ev: [Date, Date]) {
        if (isValidDate(ev[0]) && isValidDate(ev[1])) {
            this.queryDateRange = [ev[0], ev[1]];
            this.queryDateRangeChange.emit(this.queryDateRange);
        }
    }

    downloadData() {
        console.log('downloadData');
    }

    /**
     * initDatePicker: initialize the values used to control the datePicker
     */
    public initDatePicker() {
        this.currentDate = moment().toDate();
        //set date params without timestamp
        const queryMinDate = moment().set('date', moment().get('date') - 14).startOf('day').toDate();
        const queryMaxDate = moment().endOf('date').toDate();
        this.queryDateRange = [queryMinDate, queryMaxDate];
    }
}
