import { Component } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'verify',
    templateUrl: './verify.html',
    styleUrls: ['./verify.scss']
})
export class VerifyComponent {
    routeParams: {
        userId?: string,
        token?: string,
    };

    email: String;

    alert = {
        show: false,
        message: '',
        type: '',
    }

    static parameters = [HttpClient, Router, ActivatedRoute];
    constructor(public http: HttpClient, public router: Router, public activatedRoute: ActivatedRoute) {
        this.http = http;
        this.router = router;
        this.activatedRoute = activatedRoute;
    }
    ngOnInit() {
        const routeParams = this.activatedRoute.snapshot.paramMap;
        const token = String(routeParams.get('token'));
        const userId = String(routeParams.get('userId'));
        this.routeParams = {
            token,
            userId,
        };
    }

    showAlertTemporary(type: string, message: string, milliseconds: number = 3000) {
        this.alert = {
            show: true,
            type,
            message,
        };
        window.setTimeout(() => {
            this.alert = {
                show: false,
                message: '',
                type: '',
            };
        }, milliseconds);
    }

    handleConfirm() {
        const { userId, token } = this.routeParams;
        this.http.put(`api/users/verify/${userId}/${token}`, {}).subscribe({
            next: (data) => {
                this.router.navigateByUrl('/account/login?alert=verifySuccess');
            },
            error: (error: HttpErrorResponse) => {
                let message = 'Error';
                if (error.statusText) message = error.statusText;
                if (error.error && error.error.message) message = error.error.message;
                if (error && error.status) {
                    this.alert = {
                        show: true,
                        message: String(message),
                        type: 'danger',
                    };
                }
            },
        });
    }
}
