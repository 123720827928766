import { Component, OnDestroy, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UserService, UserType } from '../../../components/auth/user.service';
import { AuthService } from '../../../components/auth/auth.service';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { OrganizationService } from '../../../services/organization/organization.service';
import { Organization } from '../../../components/interfaces/Organization';
import { InventoryService } from '../../../services/inventory/inventory.service';
import { Inventory } from '../../../components/interfaces/Inventory';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CalendarEvent } from '../../../components/interfaces/CalendarEvent';
import { CalendarEventService } from '../../../services/calendar-event/calendar-event.service';


@Component({
    selector: 'guardian-dashboard',
    templateUrl: './guardianDashboard.html',
    styleUrls: ['../../../assets/sharedStyles/pages/dashboard.scss'],
})


export class GuardianDashboardComponent implements OnInit, OnDestroy {
    currentUser;

    state = {
        isOrgAdmin: false,
        org_id: undefined,
        ryan_id: undefined
    };

    pageLoaded: Promise<boolean>;
    loaded: boolean = true; //determines if all necessary content is loaded ("all" defined by permissions)
    usersLoaded: boolean = false; //determines if all user content is loaded
    orgsLoaded: boolean = false; //determines if all organizations are loaded
    botsLoaded: boolean = false; //determines if all Ryan information is loaded
    eventsLoaded: boolean = false; //determines if all Events have been loaded

    icon = {
        bell: faBell,
        userCircle: faUserCircle,
        search: faSearch
    };

    allUsers: UserType[] = [];
    numUsers: number;

    allOrgs: Organization[] = [];
    numOrgs: number;

    bots = {
        all: [] as Inventory[],
        active: [] as Inventory[],
        suspended: [] as Inventory[],
        disabled: [] as Inventory[]
    };

    myEvents: CalendarEvent[] = [];
    numEvents: number;

    errOccurred: boolean = false;

    static parameters = [Router, AuthService, UserService, OrganizationService, InventoryService, CalendarEventService, BsModalService, HttpClient];

    constructor(public router: Router, authService: AuthService,
        public userService: UserService, public organizationService: OrganizationService,
        public inventoryService: InventoryService, public eventService: CalendarEventService,
        public modalService: BsModalService, public http: HttpClient) {
        this.router = router;
        this.modalService = modalService;
        this.userService = userService;
        this.organizationService = organizationService;
        this.inventoryService = inventoryService;
        this.eventService = eventService;

        this.loaded = false;

        //get the current user from the token
        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserType;
    }

    ngOnInit(): void {
        if (this.currentUser.guardianOf.length > 0) {
            console.log('this user is a guardian');
            // this.getGuardianUsers()
            //     .then((res:UserType[]) => {
            //         console.log(res);
            //         this.allUsers = res;
            //         return res;
            //     })
            //     .then(() => {
            //         console.log(this.allUsers);
            //     })
            //     .catch(err => {console.error(err)});
            this.allUsers = this.getGuardianUsers();
            console.log(this.allUsers);
        } else {
            this.errOccurred = true;
            console.error('This user is not a guardian');
        }
        // console.log(this.currentUser);
        // console.log(this.authService.currentUser);
        // console.log(this.authService._currentUser);
        // this.authService.getCurrentUser()
        //     .then((user) => {
        //         console.log(user);
        //     })
        //     .catch((err) => {console.error(err)});
        // console.log(this.authService.getCurrentUserSync());

        this.loaded = this.isLoaded();
    }

    ngOnDestroy(): void {

    }

    /**
     * isLoaded
     *      this method will determine whether or not all of the necessary parts of the page have been loaded
     */
    isLoaded(): boolean {
        // console.log(this.currentUser);

        //ensure guardian
        if (this.currentUser.guardianOf.length > 0) {
            this.loaded = this.usersLoaded && this.botsLoaded && this.orgsLoaded;
            if (this.usersLoaded && this.botsLoaded && this.orgsLoaded) {
                this.pageLoaded = Promise.resolve(true);
            }
            // console.log(this.loaded);
            return this.usersLoaded && this.botsLoaded && this.orgsLoaded;
        }
    }

    getGuardianUsers(): UserType[] {
        console.log(this.currentUser);
        // var users = this.currentUser.guardianOf.map((userEmail) => {
        //     console.log(userEmail);
        //     this.userService.getUserByEmail(userEmail).subscribe({
        //         next(usr:UserType){
        //             console.log(usr);
        //             return usr as UserType;
        //         },
        //         error(err){console.error(err);}
        //     });
        // });

        // return new Promise<UserType[]>((resolve, reject) => {
        //     return this.currentUser.guardianOf.map((userEmail) => {
        //         this.userService.getUserByEmail(userEmail).subscribe({
        //             next(usr:UserType){
        //                 console.log(usr);
        //                 return usr as UserType;
        //             },
        //             error(err){console.error(err);}
        //         });
        //     }) as UserType[];
        // })
        //     .then((users: UserType[]) => {
        //         console.log(users);
        //         return Promise.resolve(users as UserType[]);
        //     })
        //     .then((res) => {
        //         console.log(res);
        //         return res;
        //     })
        //     .catch((err) => {
        //         console.error(err);
        //         return Promise.reject();
        //     });

        var users = [] as UserType[];
        users = this.currentUser.guardianOf.map((userEmail) => {
            console.log(userEmail);
            return this.userService.getUserByEmail(userEmail).subscribe({
                next(usr: UserType) {
                    return usr as UserType;
                },
                error(err) {
                    console.error(err);
                }
            });
        });

        console.log(users);

        return users;

        // console.log(users);
    }

    getOrgsFromIds(ids: string[]): Promise<Organization[]> {
        console.log(ids);
        return new Promise<Organization[]>((resolve, reject) => {
            let orgList = [] as Organization[];
            ids.map(orgId => {
                this.organizationService.getOrganizationById(orgId)
                    .toPromise()
                    .then((org: Organization) => {
                        orgList.push(org);
                        console.log(org);
                    })
                    .catch(err => console.error(err));
            });
            console.log(orgList);
            resolve(orgList);
        });
    }

    /**
     * getAllUsers
     *      method to be used by admins to retrieve ALL users
     */
    getOrgUsers() {
        this.userService.query()
            .then((userList) => {
                this.allUsers = userList.users.map((u) => u.value);
                console.log(this.allUsers);
                this.numUsers = userList.numUsers;
            })
            .then(() => {
                console.log(this.allUsers);
                this.usersLoaded = true;
            })
            .catch(err => {
                this.usersLoaded = false;
                console.error(err);
            });
    }

    getMyEvents() {
        this.eventService.query(this.currentUser.email)
            .then((res) => {
                // console.log('events:');
                // console.log(res);
                this.myEvents = res as CalendarEvent[];
                // this.numEvents = res.length;
            })
            .then(() => {
                console.log(this.myEvents);
                this.eventsLoaded = true;
                this.isLoaded();
            })
            .catch((err) => console.log(err));
    }
    /**
     * refreshOrgs
     *      helper method to update the list of organizations (and their data) when
     *      CRUD operations are performed on them (or upon initialization)
     */
    refreshOrgs() {
        this.organizationService.getAllOrganizations()
            .then(({ organizations, numOrganizations }) => {
                this.allOrgs = organizations.map(o => o.value);
                this.numOrgs = numOrganizations;
            })
            .then(() => {
                this.orgsLoaded = true;
            })
            .catch((err) => {
                this.orgsLoaded = false;
                console.error(err);
            });
    }

    navToBotFocus(botId: string) {
        // this.router.navigate(['/home/ryandata/' + botId]);
    }

    navToRyanDataPage() {
        // this.router.navigate(['/home/ryandata']);
    }

    navToAllOrgPage() {
        // this.router.navigate(['/home/organizations']);
    }

    navToOrgFocus(orgId: string) {
        console.log(`nav to org-focus page for org${orgId}`);
    }

    navToCalendar() {
        // this.router.navigate(['/home/calendar']);
    }
}
