<div class="page">
    <!--  ======  Loading view  ======  -->
    <div *ngIf="(!this.loaded || !isLoaded()) && !this.errOccurred">
        Loading...
    </div>

    <!--  ======  Error view  ======  -->
    <alert type="danger" *ngIf="this.errOccurred">
        <strong>Whoops!</strong> We're sorry, an error occurred trying to load this page.
    </alert>

    <!--  ======  Facility Admin view ====== -->
    <div class="mainContent dash" *ngIf="this.state.isFacAdmin && (this.loaded || isLoaded())">

        <!-- ============= Facility Table ============= -->
        <article class="mainContent__tile facilityTable">
            <div class="header">
                <div class="text-area">
                    <h4 class="header__title graphTitle">Facilities</h4>
                    <small class="lastUpdated">Total Facilities: {{numFacilities || 0}}</small>
                </div>

                <div class="buttons">
                    <button class="emptyBtn" (click)="navToAllFacilitiesPage()">
                        Show All
                    </button>
                </div>
            </div>
            <div class="table-container">
                <table class="table">
                    <thead class="table__header">
                    <tr>
                        <th class="orgName-header">Name</th>
                        <th>City</th>
                        <th>State</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody class="table__body">
                    <tr class="table__data" *ngFor="let facility of allFacilities.slice(0,4); let i = index;" (click)="navToFacilityFocus(facility._id)">
                        <td class="orgName">
                            {{facility.name}}
                        </td>
                        <td class="orgCity">{{facility.city}}</td>
                        <td class="orgState">{{facility.state}}</td>
                        <td class="more" (click)="navToFacilityFocus(facility._id)">more</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </article>

<!--        <article class="mainContent__tile">-->
<!--            <queryDateRangeControl [title]="'Control'"  [queryMinDate]="queryMinDate" [queryMaxDate]="queryMaxDate" (queryDatesUpdate)="queryDateUpdate($event)">-->
<!--            </queryDateRangeControl>-->
<!--        </article>-->

<!--        <stacked-bar-chart [graphTitle]="'User Activities'"-->
<!--                           dataName="ActivityMetricsMany"-->
<!--                           [queryDateRange]="queryDateRange"-->
<!--                           [roleView]="'facilityAdmin'">-->
<!--        </stacked-bar-chart>-->
    </div>

    <div class="rightPanel">
        <userlistpanel *ngIf="state.isFacAdmin && (isLoaded() || loaded)" [users]="allUsers" [title]="'Residents'"></userlistpanel>
    </div>
</div>
