<div class="page">
    <div class="mainContent">
        <div class="breadcrumbs">
            <div class="navInfoFocusButton" *ngIf="userId">
                Media for {{this.userId}}
            </div>

        </div>
        <alert *ngIf="alert.showFor === 'media'" type="{{this.alert.type}}">{{this.alert.message}}</alert>
        <div class="rowArea" *ngIf="this.getFilesByType('image').length > 0">
            <h2>Images ({{this.getFilesByType('image').length}})</h2>
            <div class="filesContainer">
                <div class="fileItem" *ngFor="let f of this.getFilesByType('image')">
                    <img class="imageIcon" [src]="f.imgUrl" *ngIf="!!f.imgUrl">
                    <fa-icon [icon]="icon.image" size="4x" *ngIf="!f.imgUrl"></fa-icon>
                    <p>{{getCleanFileName(f.value.awsLocation)}}</p>
                    <fa-icon (click)="openEditModal(f)" class="edit" [icon]="icon.edit" size="2x"></fa-icon>
                </div>

            </div>


        </div>
        <!-- Exclude Videos, Audio, and other types of files -->
        <!-- <div class="rowArea" *ngIf="this.getFilesByType('video').length > 0">
            <h2>Videos</h2>
            <div class="filesContainer">
                <div class="fileItem" *ngFor="let f of this.getFilesByType('video')">
                    <fa-icon [icon]="icon.video" size="4x"></fa-icon>
                    <p>{{getCleanFileName(f.value.awsLocation)}}</p>
                    <fa-icon (click)="openEditModal(f)" class="edit" [icon]="icon.edit" size="2x"></fa-icon>
                </div>
            </div>
        </div>
        <div class="rowArea" *ngIf="this.getFilesByType('audio').length > 0">
            <h2>Audio Files</h2>
            <div class="filesContainer">
                <div class="fileItem" *ngFor="let f of this.getFilesByType('audio')">
                    <fa-icon [icon]="icon.audio" size="4x"></fa-icon>
                    <p>{{getCleanFileName(f.value.awsLocation)}}</p>
                    <fa-icon (click)="openEditModal(f)" class="edit" [icon]="icon.edit" size="2x"></fa-icon>
                </div>
            </div>
        </div>
        <div class="rowArea" *ngIf="this.getFilesByType('others').length > 0">
            <h2>Other Files</h2>
            <div class="filesContainer">
                <div class="fileItem" *ngFor="let f of this.getFilesByType('others')">
                    <fa-icon [icon]="icon.others" size="4x"></fa-icon>
                    <p>{{getCleanFileName(f.value.awsLocation)}}</p>
                    <fa-icon (click)="openEditModal(f)" class="edit" [icon]="icon.edit" size="2x"></fa-icon>
                </div>
            </div>
        </div> -->
        <div class="rowArea" *ngIf="(!this.getFilesByType('image') || this.getFilesByType('image').length === 0) && this.userId">
            <h2>There are no files yet for {{this.userId}}</h2>
        </div>
        <div class="rowArea" *ngIf="!userId">
            <h2>Please choose a user</h2>
        </div>

    </div>
    <div class="rightPanel">
        <div class="addFile" (click)="uploader.click()" *ngIf="(this.progress === undefined || this.progress === null) && this.userId">
            <h4>Upload Image</h4>
        </div>
        <input hidden type="file" #uploader (change)="uploadFile($event)" accept="image/png, image/jpeg, image/jpg, image/tif, image/tiff, image/gif">
        <p *ngIf="this.progress !== undefined && this.progress !== null ">
            <progressbar type="success" textType="white" [value]="this.progress" [striped]="true" [animate]="true">
            </progressbar>
        </p>
        <alert *ngIf="alert.showFor === 'uploader'" type="{{this.alert.type}}">{{this.alert.message}}</alert>

        <userlistpanel *ngIf="!!allUsers" [users]="allUsers" [clickCb]="this.changeUserFocusTo.bind(this)">
        </userlistpanel>

    </div>
</div>
