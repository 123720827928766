<background></background>
<div class="createAccountPage">
    <form class="createAccountForm" name="form" (ngSubmit)="register(signupForm)" novalidate #signupForm="ngForm">
        <div class="logoBox">
            <img src="/bcfcc9791c4b4bb0b3224c16fe6cd3ff.png" alt="RYAN by DreamFace Technologies Logo">
        </div>

        <div class="title">
            <h4>
                Create your Account
            </h4>
            <h4>
                for {{facility?.name || "(Loading Facility)"}}
            </h4>
        </div>
        <div class="inputContainer">
            <input id="firstName" name="firstName" [(ngModel)]="user.firstName" #firstName="ngModel" placeholder="First Name" required>
        </div>

        <div class="inputContainer">
            <input id="lastName" name="lastName" [(ngModel)]="user.lastName" #lastName="ngModel" placeholder="Last Name" required>
        </div>

        <div class="inputContainer">
            <input id="email" name="email" [(ngModel)]="user.email" placeholder="Email" #email="ngModel" required disabled="disabled">
        </div>

        <div class="inputContainer">
            <input id="password" name="password" type="password" [(ngModel)]="user.password" placeholder="Password" #password="ngModel" required>
        </div>

        <div class="inputContainer">
            <input id="repeatPassword" name="repeatPassword" type="password" [(ngModel)]="user.repeatPassword" placeholder="Repeat Password" #repeatPassword="ngModel" required>
        </div>

        <div class="inputContainer">
            <select class="form-control pwd-input" name="gender" [(ngModel)]="user.gender" #gender="ngModel" required>
                <option value="">Select a Gender</option>
                <option *ngFor="let gender of genderTypes" value="{{gender}}">{{gender}}</option>
            </select>
        </div>

        <div class="inputContainer">
            <input id="weight" name="weight" type="number" [(ngModel)]="user.weight" placeholder="Weight (lbs)" #weight="ngModel" min="0" required>
        </div>

        <div class="agreementBox">
            <input [(ngModel)]="privacyAgreement" type="checkbox" id="privacyAgreement" name="privacyAgreement">
            <label for="privacyAgreement"><span class="agreement">I agree to
                    Dreamface's</span><a>Privacy
                    Agreement</a></label>
        </div>

        <div class="agreementBox">
            <input [(ngModel)]="cookiesPolicy" type="checkbox" id="cookiesPolicy" name="cookiesPolicy">
            <label for="cookiesPolicy"><span class="agreement">I agree to
                    Dreamface's</span><a>Cookie
                    Policy</a></label>
        </div>
        <div class="fullWidth" *ngIf="alert.show">
            <alert class="fullWidth" type="{{alert.type}}">
                {{alert.message}}
            </alert>
        </div>
        <div class="buttonsArea">
            <button type="submit">
                Create Account
            </button>
        </div>
    </form>
    <powered-by-dft class="logoBottom" [pixelWidth]="40"></powered-by-dft>

</div>