import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from '../../../components/auth/auth.service';
import { UserService, UserType } from '../../../components/auth/user.service';
import { ProfileService} from '../../../services/profile/profile.service';
import { faUserCircle, faPen } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { genderTypes } from '../../../../server/config/environment/shared';

@Component({
    selector: 'userSettings',
    templateUrl: './userSettings.html',
    styleUrls: ['./userSettings.scss'],
})


export class UserSettingsComponent {
    @Input()
    viewAs: string = 'user';

    AuthService;
    UserService;
    ProfileService;

    userId = 'me';
    user = undefined;
    userIdSubscription = new BehaviorSubject(this.userId);

    static parameters = [AuthService, UserService, ProfileService];

    genderTypes = genderTypes;
    editableProperties = [
        {
            name: 'firstName',
            label: 'First Name',
            type: 'text',
        },
        {
            name: 'lastName',
            label: 'Last Name',
            type: 'text',
        },
        {
            name: 'birthday',
            label: 'Birthday (YYYY-MM-DD)',
            type: 'date'
        },
        {
            name: 'gender',
            label: 'Gender',
            type: 'text',
        },
        {
            name: 'weight',
            label: 'Weight (lbs)',
            type: 'number',
        },
        {
            name: 'job',
            label: 'Job',
            type: 'text',
        },
        {
            name: 'birthplace',
            label: 'Birthplace',
            type: 'text',
        },
        {
            name: 'location',
            label: 'Location',
            type: 'date'
        },
        {
            name: 'hobbies',
            label: 'Hobbies',
            type: 'text',
        },
        {
            name: 'mother',
            label: 'Mother',
            type: 'text',
        },

        {
            name: 'father',
            label: 'Father',
            type: 'text',
        },
        {
            name: 'partner',
            label: 'Partner',
            type: 'text',
        },
        {
            name: 'pets',
            label: 'Pets',
            type: 'date'
        },
        {
            name: 'nickname',
            label: 'Hobbies',
            type: 'text',
        },
        {
            name: 'children',
            label: 'Children',
            type: 'text',
        },
        {
            name: 'have_children',
            label: 'Do you have Children?',
            type: 'text',
        },
        {
            name: 'num_children',
            label: 'Number of Children',
            type: 'number',
        },
        {
            name: 'went_to_college',
            label: 'Did you go to college?',
            type: 'text',
        },
        {
            name: 'college',
            label: 'College',
            type: 'text',
        },
    ];

    pwFields = {
        oldPassword: '',
        newPassword: '',
        newPasswordRepeated: '',
    }
    icon = {
        userIcon: faUserCircle,
        edit: faPen,
    }

    state = {
        editing: false,
    }

    alert = {
        showFor: '',
        type: '',
        message: '',
    }


    constructor(public authService: AuthService, public userService: UserService, public profileService: ProfileService) {
        this.AuthService = authService;
        this.UserService = userService;
        this.ProfileService = profileService;
        this.userIdSubscription.subscribe({
            next: (newValue) => this.handleNewUserId(newValue),
            error: (e) => console.error(e),
        });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.userIdSubscription.unsubscribe();
    }

    setEditing(property) {
        this.state = { ...this.state, editing: property };
    }

    handleEdit(event, formValue) {
        event.preventDefault();
        let updatedUserValues = formValue;
        let programRProfile = formValue;
        this.setEditing(false);
        //update the profile first
        if (programRProfile.firstName) {
            programRProfile.first_name = programRProfile.firstName;
            Reflect.deleteProperty(programRProfile, 'firstName');
        }
        if (programRProfile.lastName) {
            programRProfile.last_name = programRProfile.lastName;
            Reflect.deleteProperty(programRProfile, 'lastName');
        }
        return this.profileService.upsertMyProfile(programRProfile)
            .then(() => {
                //then update the user document without all the profile fields
                if (updatedUserValues.first_name) {
                    updatedUserValues.firstName = updatedUserValues.first_name;
                    Reflect.deleteProperty(updatedUserValues, 'first_name');
                }
                if (updatedUserValues.last_name) {
                    updatedUserValues.lastName = updatedUserValues.last_name;
                    Reflect.deleteProperty(updatedUserValues, 'last_name');
                }
                if (updatedUserValues.job) Reflect.deleteProperty(updatedUserValues, 'job');
                if (updatedUserValues.birthplace) Reflect.deleteProperty(updatedUserValues, 'birthplace');
                if (updatedUserValues.location) Reflect.deleteProperty(updatedUserValues, 'location');
                if (updatedUserValues.hobbies) Reflect.deleteProperty(updatedUserValues, 'hobbies');
                if (updatedUserValues.mother) Reflect.deleteProperty(updatedUserValues, 'mother');
                if (updatedUserValues.father) Reflect.deleteProperty(updatedUserValues, 'father');
                if (updatedUserValues.partner) Reflect.deleteProperty(updatedUserValues, 'partner');
                if (updatedUserValues.pets) Reflect.deleteProperty(updatedUserValues, 'pets');
                if (updatedUserValues.nickname) Reflect.deleteProperty(updatedUserValues, 'nickname');
                if (updatedUserValues.children) Reflect.deleteProperty(updatedUserValues, 'children');
                if (updatedUserValues.have_children) Reflect.deleteProperty(updatedUserValues, 'have_children');
                if (updatedUserValues.num_children) Reflect.deleteProperty(updatedUserValues, 'num_children');
                if (updatedUserValues.went_to_college) Reflect.deleteProperty(updatedUserValues, 'went_to_college');
                if (updatedUserValues.college) Reflect.deleteProperty(updatedUserValues, 'college');
                return this.AuthService.updateUser(updatedUserValues, this.userId);
            })
            .then((updatedUser) => {
                this.showAlertTemporary('profile', 'info', 'User changed successfully.', 3000);
                this.user = updatedUser;
            })
            .catch((err) => {
                console.log(err);
                this.showAlertTemporary('profile', 'warning', 'Editing unsuccessful', 3000);
                return;
            });
    }

    handlePasswordChange(event, form) {
        event.preventDefault();
        const { oldPassword, newPassword, newPasswordRepeated } = form.value;
        if (form.value.newPassword !== form.value.newPasswordRepeated) {
            this.showAlertTemporary('password', 'danger', 'Password Comfirmation did not match', 3000);
            return;
        }
        // todo make call to server from form.value
        if (this.userId === 'me') {
            this.AuthService.changePassword(oldPassword, newPassword, newPasswordRepeated)
                .then(() => {
                    this.showAlertTemporary('password', 'info', 'Successfully changed password', 3000);
                })
                .catch((e) => {
                    console.log(e);
                    this.showAlertTemporary('password', 'warning', 'Password change unsuccessful', 3000);
                });
        }
        if (this.userId !== 'me') {
            this.UserService.changePassword({ id: this.userId }, oldPassword, newPassword, newPasswordRepeated)
                .toPromise()
                .then(() => {
                    this.showAlertTemporary('password', 'info', 'Successfully changed password', 3000);
                })
                .catch((e) => {
                    console.log(e);
                    this.showAlertTemporary('password', 'warning', 'Password change unsuccessful', 3000);
                });
        }

        form.reset();
    }

    showAlertTemporary(showFor: string, type: string, message: string, milliseconds: number) {
        this.alert = {
            showFor,
            type,
            message,
        };
        window.setTimeout(() => {
            this.clearAlert();
        }, milliseconds);
    }

    clearAlert() {
        this.alert = {
            showFor: '',
            type: '',
            message: '',
        };
    }

    handleNewUserId(newUserId: string) {
        console.log('New User Id', newUserId);
        this.UserService.get(newUserId).toPromise()
            .then(data => {
                const retrievedUser = data;
                //returned User does not contain id
                retrievedUser.id = retrievedUser.email;
                retrievedUser._id = retrievedUser.email;
                this.userId = newUserId;
                console.log(`retrieved for ${this.userId}`);
                this.user = retrievedUser;
                return this.ProfileService.getMyProfile();
            })
            .then(foundProfile => {
                if (foundProfile) {
                    if (foundProfile._id) Reflect.deleteProperty(foundProfile, '_id');
                    if (foundProfile._rev) Reflect.deleteProperty(foundProfile, '_rev');
                    if (foundProfile.documentType) Reflect.deleteProperty(foundProfile, 'documentType');
                    if (foundProfile.first_name) Reflect.deleteProperty(foundProfile, 'first_name');
                    if (foundProfile.gender) Reflect.deleteProperty(foundProfile, 'gender');
                    if (foundProfile.last_name) Reflect.deleteProperty(foundProfile, 'last_name');
                    if (foundProfile.topic) Reflect.deleteProperty(foundProfile, 'topic');
                    // console.log(foundProfile);
                    this.user = Object.assign(foundProfile, this.user);
                }

            })
            .catch(e => {
                console.log(e);
                this.showAlertTemporary('profile', 'warning', `unable to retrieve user: ${newUserId}`, 3000);
            });
    }

    changeUserFocusTo(newUserId?) {
        this.userIdSubscription.next(newUserId ? newUserId : 'me');
        return;
    }
}
