import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    getAge,
    mapColorKeys,
    convertMillisToMinutes,
    convertMillisToHoursMinutesSecondsAndMillis, isValidDate, convertMillisToHrsMinutes
} from '../../../components/util';
import {activityTypes, granularityOptions} from '../../../../server/config/environment/shared';
import {faPhone, faSearch, faUserCircle} from '@fortawesome/free-solid-svg-icons';
import {
    ActivityAnalyticResponse,
    AnalyticQuery,
    AnalyticsService,
    ConversationAnalysisResponse
} from '../../../services/analytics/analytics.service';
import moment from 'moment';
import {CustomLinerChartService} from '../../../services/custom-dots-ngx-line-chart/CustomLinerChartService';
import {FacilityService} from '../../../services/facility/facility.service';
import {Subscription} from 'rxjs';
import {GranularityOptions} from '../../../components/graphs/granularityOptions';
import {ResearchStudy} from '../../../components/interfaces/ResearchStudy';
import {ResearchService} from '../../../services/research/research.service';

@Component({
    selector: 'researcher-usersActivities',
    templateUrl: './researcherUsersActivities.html',
    styleUrls: ['../../../assets/sharedStyles/pages/usersActivities.scss', '../researcherPages.scss', './researcherUsersActivities.scss'],
})

export class ResearcherUsersActivitiesComponent implements OnInit {
    @Input() selectedGranularity: GranularityOptions = 'daily';
    granularityOptions = granularityOptions;

    icons = {
        search: faSearch,
        user: faUserCircle,
        phone: faPhone,
    }

    allStudies: ResearchStudy[];
    focusedStudyId: string;
    focusedUserId: string;
    search: string;
    activityTypes = activityTypes;

    currentDate = new Date();

    // options
    legend: boolean = true;
    showLabels: boolean = true;
    xAxis: boolean = true;
    yAxis: boolean = true;
    timeline: boolean = true;
    selectedRange: Date[];

    activityGraphMultipleUsers: ActivityAnalyticResponse;
    activityGraphCurrentUser: ActivityAnalyticResponse;

    gameActivityCurrentUser: any;
    sentimentAnalysisGraphCurrentUser: any;
    FERAnalysisGraphCurrentUser: any;
    FERSentimentCombinedGraphCurrentUser: any[] = [];
    //Graph options
    view: any[] = [700, 400];
    // options for general graphs
    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = false;
    showXAxisLabel = true;
    xAxisLabel = 'Day';
    xAxisLineGraphLabel = 'Date';
    showYAxisLabel = true;
    showGridLines = true;
    roundDomains = false;
    yAxisLabel = 'Time (Minutes)';
    noBarWhenZero = false;
    schemeType= 'ordinal';
    animations = true;
    colorScheme: {
        domain: ['#7188F4', '#F8906E', '#9EE3FF', '#FDD660', '#EBEBEB']
    };
    gameActivityColorScheme = {
        domain: ['#FDD660']
    };
    sentimentAnalysisGraphColorScheme = {
        domain: ['#58C2EE']
    };
    FERAnalysisGraphColorScheme = {
        domain: ['#F8906E', '#A10A28', '#5AA454']
    };
    FERSentimentCombinedGraphColorScheme = {
        domain: ['#F8906E', '#58C2EE']
    };

    pieChartColorScheme = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
    };
    autoScale = true;
    overallActivityUsageTime: any = {};

    conversationDataReady: boolean = false;
    conversationTopics: string[] = ['Kids', 'pets', 'TVShows', 'Sciences', 'Music', 'Nature', 'Foods', 'Travel']

    conversationSummary: {numberOfQuestions: number, topic: string, percentShare: number}[] | any;
    convoDetails: {userWordCount: number, ryanWordCount: number,
        day: Date, topic: string, response: string}[] | any;

    queryParamSubscription: Subscription;

    static parameters = [Router, ActivatedRoute, AnalyticsService, CustomLinerChartService, FacilityService, ResearchService];

    constructor(public router: Router, public route: ActivatedRoute,
                public analyticService: AnalyticsService, public dotsLineChart: CustomLinerChartService,
                public facilityService: FacilityService, public researchService: ResearchService) {
        this.analyticService = analyticService;
        this.selectedRange = [
            moment(new Date(new Date().setDate(new Date().getDate() - 14))).startOf('day').toDate(),
            moment().endOf('day').toDate()];
        this.dotsLineChart = dotsLineChart;
        this.facilityService = facilityService;
        this.researchService = researchService;

        //init overallActivityUsageTime key/values
        this.activityTypes.forEach(activityName => {
            this.overallActivityUsageTime[activityName.toLowerCase()] = {hours: undefined, minutes: undefined};
        });
        this.queryParamSubscription = this.route.queryParamMap.subscribe(queryParams => {
            this.focusedStudyId = queryParams.has('studyId') ? queryParams.get('studyId') : undefined;
            this.focusedUserId = queryParams.has('userId') ? queryParams.get('userId') : undefined;
        });
    }

    ngOnInit() {
        this.getAllResearchStudies()
            .then((studies: ResearchStudy[]) => {
                this.allStudies = studies
                    .sort((a, b) => a.title.localeCompare(b.title)) as ResearchStudy[];
            })
            .then(() => this.triggerQuery())
            .catch(err => {
                console.error(err);
            });
    }

    triggerQuery() {
        if (!this.focusedStudyId) return;
        if (this.focusedUserId) this.getSingleUserData();
        else this.getMultipleUsersData();
    }

    getAllResearchStudies(): Promise<ResearchStudy[]> {
        return this.researchService.getStudiesForResearcher()
            .then((res: {studies: ResearchStudy[]}) => {
                const sortedStudies: ResearchStudy[] = res.studies
                    .map(s => ({...s, participants: s.participants.filter(p => p.invitationAccepted)}))
                    .sort((a, b) => a.title.localeCompare(b.title));
                return Promise.resolve(sortedStudies as ResearchStudy[]);
            })
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
    }

    millisToMinutesAndSeconds(ms) {
        const duration = convertMillisToHoursMinutesSecondsAndMillis(ms);
        let milliseconds = duration.millis;
        let seconds: string | number = duration.seconds;
        let minutes: string | number = duration.minutes;
        let hours: string | number = duration.hours;

        hours = hours < 10 ? `0${hours}` : hours;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        return `${hours}:${minutes}:${seconds}.${milliseconds}`;
    }

    millisToMinutes(ms) {
        return Math.round(convertMillisToMinutes(ms));
    }


    focusOnUser(userId: string = undefined) {
        this.focusedUserId = userId;
        if (!userId) {
            this.router.navigate([],
                {queryParams: {study: this.focusedStudyId}});
            return;
        }
        this.router.navigate([],
            {queryParams: {studyId: this.focusedStudyId, userId: userId}})
            .then(() => this.triggerQuery());
    }

    focusOnStudy(studyId: string = undefined) {
        this.focusedStudyId = studyId;
        this.focusedUserId = undefined;
        this.router.navigate([], {queryParams: {studyId: studyId}})
            .then(() => this.triggerQuery());
    }

    getParticipantsInStudy(anonymized: boolean = true) {
        const users = this.getCurrentStudy().participants.map(u => {
            if (anonymized) {
                delete u.email;
                return u;
            }
            delete u.anonymizedName;
            return u;
        })
            .sort((a, b) => a.dateAddedToStudy - b.dateAddedToStudy);
        return users || [];
    }

    getCurrentStudy() {
        const currStudy = this.allStudies.find(f => f._id === this.focusedStudyId);
        return this.allStudies.find(f => f._id === this.focusedStudyId);
    }

    getCurrentUser() {
        const currentStudy = this.getCurrentStudy();
        if (!currentStudy) return;
        return currentStudy.participants.find(u => [u.email, u.anonymizedName].includes(this.focusedUserId));
    }

    getUserAge(birthday: string) {
        if (!birthday) return '';
        return getAge(birthday);
    }

    changeSelectedRange(newDates: Date[]) {
        //check the year of the first date to handle the bsDateRangePicker init value of Dec 31, 1969
        if (!isValidDate(newDates[0]) || !isValidDate(newDates[1]) || newDates[0].getFullYear() < 1971) return;
        //prevent redundant updates
        if (newDates[0].getTime() === this.selectedRange[0].getTime() && newDates[1].getTime() === this.selectedRange[1].getTime()) return;
        this.selectedRange = [...newDates];
        this.triggerQuery();
    }

    changeGranularity(newValue: string) {
        newValue = newValue.toLowerCase();
        if (granularityOptions.includes(newValue) && newValue !== this.selectedGranularity) {
            this.selectedGranularity = newValue as GranularityOptions;
            this.triggerQuery();
        }
    }

    getMultipleUsersData() {
        this.formatLabelsForGraphs();
        this.getActivityAnalyticsForMultipleUsers();
    }

    getSingleUserData(user?: string) {
        this.formatLabelsForGraphs();
        this.getActivityAnalyticsForSingleUser(user);
        this.getAverageGameActivityDataForSingleUser(user);
        this.getSentimentAnalysisForSingleUser(user);
        this.getFERAnalysisForSingleUser(user);
        this.getFERAndSentimentAnalysisForSingleUser(user);
        this.getConversationAnalyticsForSingleUser(user);
    }

    private setupQueryForMultipleUsers(): AnalyticQuery {
        const currentStudy = this.getCurrentStudy();
        let start = new Date(this.selectedRange[0]);
        let end = new Date(this.selectedRange[1]);
        return {
            startTime: start,
            endTime: end,
            role: 'researcher',
            granularity: this.selectedGranularity,
            study: currentStudy._id
        };
    }

    private setupQueryForSingleUser(user? : any): AnalyticQuery {
        const currentUser = this.getCurrentUser();
        let start = new Date(this.selectedRange[0]);
        let end = new Date(this.selectedRange[1]);
        return {
            startTime: start,
            endTime: end,
            role: 'researcher',
            granularity: this.selectedGranularity,
            email: user || currentUser.anonymizedName,
            study: this.focusedStudyId
        };
    }

    private formatLabelsForGraphs(): void {
        if (this.selectedGranularity === 'hourly') {
            this.xAxisLabel = 'Hour';
        }
        if (this.selectedGranularity === 'daily') {
            this.xAxisLabel = 'Day';
        }
        if (this.selectedGranularity === 'weekly') {
            this.xAxisLabel = 'Week Of';
        }
        if (this.selectedGranularity === 'monthly') {
            this.xAxisLabel = 'Month';
        }
        this.yAxisLabel = 'Time (Minutes)';
    }

    private formatDataForActivityGraph(analytic: any) : void {
        if (this.selectedGranularity === 'hourly') {
            analytic.name = moment(analytic.name).format('MMM D, LT');
        }
        if (this.selectedGranularity === 'daily') {
            analytic.name = moment(analytic.name).add(1, 'days').format('ll');
        }

        if (this.selectedGranularity === 'weekly') {
            analytic.name = moment(analytic.name).add(1, 'days').format('ll');
        }

        if (this.selectedGranularity === 'monthly') {
            analytic.name = moment(analytic.name).format('MMM');
        }
        analytic.series.forEach(s => {
            s.value = moment.duration(s.value).asHours();
        });
    }

    getActivityAnalyticsForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getActivityAnalyticsForMultipleUsers(query)
            .toPromise()
            .then((res: any) => {
                let analytic = res;
                //FIXME: handle empty analytic values (forEach of undefined)
                analytic.Details.forEach(detail => {
                    this.formatDataForActivityGraph(detail);
                });
                // Keep duration values in Milliseconds
                // analytic.PieChart.forEach(item => {
                //     item.value = Math.round(item.value * 100.0 / 60000) / 100;
                // });
                for (const key in this.overallActivityUsageTime) {
                    // eslint-disable-next-line no-shadow
                    let activity = analytic.Summary.find((element: any) => element.activity.toLowerCase() === key);
                    if (activity) {
                        this.overallActivityUsageTime[key] = convertMillisToHrsMinutes(activity.totalDurationInMilliseconds || 0);
                    } else {
                        this.overallActivityUsageTime[key] = convertMillisToHrsMinutes(0);
                    }
                }
                this.activityGraphMultipleUsers = analytic;
            })
            .catch((err) => {
                console.error(err);
            });
    }

    getActivityAnalyticsForSingleUser(user?) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getActivityAnalyticsForSingleUser(query)
            .toPromise()
            .then((res: any) => {
                let analytic = res;
                analytic.Details.forEach(detail => {
                    this.formatDataForActivityGraph(detail);
                });
                //Keep duration values in Milliseconds
                // analytic.PieChart.forEach(item => {
                //     item.value = Math.round(item.value * 100.0 / 60000) / 100;
                // });
                for (const key in this.overallActivityUsageTime) {
                    // eslint-disable-next-line no-shadow
                    let activity = analytic.Summary.find((element: any) => element.activity.toLowerCase() === key);
                    if (activity) {
                        this.overallActivityUsageTime[key] = convertMillisToHrsMinutes(activity.totalDurationInMilliseconds || 0);
                    } else {
                        this.overallActivityUsageTime[key] = convertMillisToHrsMinutes(0);
                    }
                }
                this.activityGraphCurrentUser = analytic;
            })
            .catch((err) => {
                console.error(err);
            });
    }

    getAverageGameActivityDataForSingleUser(user?) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getGameActivityAnalyticsForSingleUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics.Breakdown;
                analytics_.forEach(analytic => {
                    if (this.selectedGranularity === 'hourly') {
                        analytic.name = moment(analytic.name).format('MMM D, LT');
                    }
                    if (this.selectedGranularity === 'daily') {
                        analytic.name = moment(analytic.name).add(1, 'days').format('ll');
                    }

                    if (this.selectedGranularity === 'weekly') {
                        analytic.name = moment(analytic.name).add(1, 'days').format('ll');
                    }

                    if (this.selectedGranularity === 'monthly') {
                        analytic.name = moment(analytic.name).format('MMM');
                    }
                    analytic.value = Math.round(analytic.value * 100.0 / 60000) / 100;
                });

                this.gameActivityCurrentUser = analytics_;
            })
            .catch(err => console.error(err));
    }

    getSentimentAnalysisForSingleUser(user?) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getSentimentAnalysisForUser(query)
            .then((analytics:any) => {
                let series = analytics.sentimentData[0].series;
                if (series && series.length > 0) {
                    series.forEach(analytic => {
                        analytic.value = Math.round(analytic.value * 100.0) / 100.0;
                        analytic.name = moment(analytic.name).toDate();
                    });
                    let expandedSeries = [];
                    let obj = {
                        name: 'Sentiment',
                        series: [series[0]]
                    };
                    expandedSeries.push(obj);
                    let currIndex = 0;
                    for (let i = 1; i < series.length - 1; i++) {
                        let currTime = moment(series[i].name);
                        let prevTime = moment(series[i - 1].name);
                        if (moment.duration(currTime.diff(prevTime)).asHours() > 2) {
                            currIndex++;
                            obj = {
                                name: 'Sentiment',
                                series: [series[i]]
                            };
                            expandedSeries.push(obj);
                        } else {
                            expandedSeries[currIndex].series.push(series[i]);
                        }
                    }
                    this.sentimentAnalysisGraphCurrentUser = expandedSeries;
                } else {
                    this.sentimentAnalysisGraphCurrentUser = [];
                }
            })
            .catch(err => console.error(err));
    }

    getFERAnalysisForSingleUser(user?) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getFERDataForUser(query)
            .then((analytics:any) => {
                let series = analytics.FERLineGraph[0].series;
                if (series && series.length > 0) {
                    series.forEach(analytic => {
                        analytic.value = Math.round(analytic.value * 100.0) / 100.0;
                        analytic.name = moment(analytic.name).toDate();
                    });
                    let expandedSeries = [];
                    let obj = {
                        name: 'FER',
                        series: [series[0]]
                    };
                    expandedSeries.push(obj);
                    let currIndex = 0;
                    for (let i = 1; i < series.length - 1; i++) {
                        let currTime = moment(series[i].name);
                        let prevTime = moment(series[i - 1].name);
                        if (moment.duration(currTime.diff(prevTime)).asHours() > 2) {//split points that are two hours apart
                            currIndex++;
                            obj = {
                                name: 'FER',
                                series: [series[i]]
                            };
                            expandedSeries.push(obj);
                        } else {
                            expandedSeries[currIndex].series.push(series[i]);
                        }
                    }
                    let offlineData = analytics.ConnectivityDetails;
                    if (offlineData && offlineData.length > 0) {
                        offlineData.forEach(analytic => {
                            expandedSeries.push({
                                name: 'Offline',
                                series: [
                                    {
                                        value: 0,
                                        name: moment(analytic.value).toDate()
                                    },
                                    {
                                        value: 0,
                                        name: moment(analytic.value).add(5, 'minutes').toDate()
                                    }
                                ]
                            });
                        });
                    }
                    this.FERAnalysisGraphCurrentUser = expandedSeries;
                    console.log(expandedSeries);
                } else {
                    this.FERAnalysisGraphCurrentUser = [];
                }
            })
            .catch(err => console.error(err));
    }

    getFERAndSentimentAnalysisForSingleUser(user?) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getFERAndSentimentCombinedDataForUser(query)
            .then((analytics:any) => {
                let ferSeries = analytics.FER[0].series;
                if (ferSeries && ferSeries.length > 0) {
                    ferSeries.forEach(analytic => {
                        analytic.value = Math.round(analytic.value * 100.0) / 100.0;
                        analytic.name = moment(analytic.name).toDate();
                    });
                    let expandedFERSeries = [];
                    let obj = {
                        name: 'FER',
                        series: [ferSeries[0]]
                    };
                    expandedFERSeries.push(obj);
                    let currIndex = 0;
                    for (let i = 1; i < ferSeries.length - 1; i++) {
                        let currTime = moment(ferSeries[i].name);
                        let prevTime = moment(ferSeries[i - 1].name);
                        if (moment.duration(currTime.diff(prevTime)).asHours() > 2) {//split points that are two hours apart
                            currIndex++;
                            obj = {
                                name: 'FER',
                                series: [ferSeries[i]]
                            };
                            expandedFERSeries.push(obj);
                        } else {
                            expandedFERSeries[currIndex].series.push(ferSeries[i]);
                        }
                    }
                    this.FERSentimentCombinedGraphCurrentUser = expandedFERSeries;
                } else {
                    this.FERSentimentCombinedGraphCurrentUser = [];
                }

                let sentimentSeries = analytics.Sentiment[0].series;
                if (sentimentSeries && sentimentSeries.length > 0) {
                    sentimentSeries.forEach(analytic => {
                        analytic.value = Math.round(analytic.value * 100.0) / 100.0;
                        analytic.name = moment(analytic.name).toDate();
                    });
                    let expandedSentimentSeries = [];
                    let obj = {
                        name: 'Sentiment',
                        series: [sentimentSeries[0]]
                    };
                    expandedSentimentSeries.push(obj);
                    let currIndex = 0;
                    for (let i = 1; i < sentimentSeries.length - 1; i++) {
                        let currTime = moment(sentimentSeries[i].name);
                        let prevTime = moment(sentimentSeries[i - 1].name);
                        if (moment.duration(currTime.diff(prevTime)).asHours() > 2) {//split points that are two hours apart
                            currIndex++;
                            obj = {
                                name: 'Sentiment',
                                series: [sentimentSeries[i]]
                            };
                            expandedSentimentSeries.push(obj);
                        } else {
                            expandedSentimentSeries[currIndex].series.push(sentimentSeries[i]);
                        }
                    }
                    this.FERSentimentCombinedGraphCurrentUser.push(...expandedSentimentSeries);
                } else {
                    this.FERSentimentCombinedGraphCurrentUser = [];
                }
            })

            .catch(err => console.error(err));
    }

    getConversationAnalyticsForSingleUser(user?) {
        let query = this.setupQueryForSingleUser(user);
        this.conversationSummary = {};
        this.conversationTopics = [];
        this.convoDetails = {};
        this.conversationDataReady = false;
        this.analyticService.getConversationAnalysisForSingleUser(query)
            .toPromise()
            .then((res: ConversationAnalysisResponse) => res)
            .then((res2) => {
                this.conversationSummary = res2.topicSummarization;
                // this.conversationTopics = formatConversationTopicNames('_', res2.availableTopics);
                this.conversationTopics = res2.availableTopics;
                this.conversationSummary = this.conversationTopics.map((topic) => {
                    const exists = this.conversationSummary.find((elem) => elem.topic === topic);
                    if (!exists) {
                        return {numberOfQuestions: 0, topic: topic, percentShare: 0};
                    }
                    return exists;
                })
                    .sort((a, b) => b.percentShare - a.percentShare);
                this.convoDetails = res2.conversationDetails.map((elem) => {
                    let newDate = new Date(elem.day);
                    newDate.setMinutes(2 * newDate.getTimezoneOffset());
                    return {...elem, day: newDate};
                })
                    .sort((a, b) => b.day.getTime() - a.day.getTime());
                this.conversationDataReady = true;
            })
            .catch((err) => console.error(err));
    }

    onSelect(event) {
        console.log(event);
    }

    userSelect(evt) {
        console.log(evt);
    }

    mapColorKeys(value) {
        return mapColorKeys(value);
    }

    formatPieChartValues = (value: number) : string => {
        const dur = moment.duration(value);
        const durHrMin = convertMillisToHrsMinutes(value);
        return dur.asMinutes() <= 60
            ? `${Math.round(dur.asMinutes() * 100.0) / 100.0}m`
            : `${durHrMin.hours}h ${durHrMin.minutes}m`;
    }

    //TODO: Map 'ProgramRMedia' to "Conversation media"
    formatPieChartNames = (name: string) : string => name;

    /**
     * getTooltipTemplateTime: used by the graph's tooltip to reformat the number of hours (as a float) into
     *                          a more legible `##h ##m` string (ex: 0.5000777777777777 -> `0h 30m`)
     * @param tooltipModel: a float number representing a number of hours
     */
    getTooltipTemplateTime(tooltipModel) {
        const dur = moment.duration(tooltipModel.value);
        const durHrMin = convertMillisToHrsMinutes(tooltipModel.value);

        return dur.asMinutes() <= 60
            ? `${Math.round(dur.asMinutes() * 100.0) / 100.0}min`
            : `${durHrMin.hours}hr ${durHrMin.minutes}min`;
    }

    exportData() {
        console.log('Export Data');
    }
    ngOnDestroy(): void {
        this.queryParamSubscription.unsubscribe();
    }

    onActivate(data): void {
        console.log('Activate', JSON.parse(JSON.stringify(data)));
    }

    onDeactivate(data): void {
        console.log('Deactivate', JSON.parse(JSON.stringify(data)));
    }
}
