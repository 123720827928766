import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AccountRoutingModule } from './account-routing.module';
import { DirectivesModule } from '../../components/directives.module';
import { LoginComponent } from './login/login.component';
import { VerifyComponent } from './verify/verify.component';
import { CreateAccountComponent } from './createAccount/createAccount.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { Background } from './background/background.component';
import { PoweredByDFT } from './powered-by-dft/powered-by-dft.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AuthService } from '../../components/auth/auth.service';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        AccountRoutingModule,
        DirectivesModule,
        BsDatepickerModule,
        AlertModule,
    ],
    declarations: [
        LoginComponent,
        CreateAccountComponent,
        ForgotPasswordComponent,
        PasswordResetComponent,
        VerifyComponent,
        Background,
        PoweredByDFT,
    ],
    exports: [
        Background,
        PoweredByDFT,
    ],
})
export class AccountModule { }
