<div class="page">
    <div class="mainContent">
        <h2>Program R</h2>
        <div class="rowArea">
            <div class="messageArea" #messageContainer>
                <div #messagesList *ngFor="let m of this.messages" [ngClass]="m.from">
                    <div class="textArea">
                        <p>{{m.text}}</p>
                        <span>
                            <fa-icon [icon]="icon.robot" *ngIf="m.from === 'ryan'" size="2x"></fa-icon>
                        </span>
                        <span>
                            <fa-icon [icon]="icon.user" *ngIf="m.from === 'me'" size="2x"></fa-icon>
                        </span>

                    </div>

                    <div class="mediaArea" *ngIf="m.option && m.option.robot">
                        <img [src]="m.option.robot.image.filename" *ngIf="m.option.robot.image">
                        <audio controls *ngIf="m.option.robot.audio">
                            <source [src]="m.option.robot.audio.filename" type="audio/mpeg">
                        </audio>
                        <video controls *ngIf="m.option.robot.video">
                            <source [src]="m.option.robot.video.filename" type="video/webm">
                        </video>
                    </div>

                </div>
            </div>
            <div>
                <form class="submitForm" #form="ngForm" (ngSubmit)="submitMessage(form)">
                    <input type="text" name="message" [(ngModel)]="inputMessage">
                    <button class="btn enterText" type="submit">Submit</button>
                </form>
            </div>
        </div>
    </div>
</div>
