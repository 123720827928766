<div class="mainContainer">
    <header class="mainContainer__header">
        <mainheader></mainheader>
    </header>
    <div class="mainContainer__leftNav">
        <left-nav></left-nav>
    </div>

    <div class="mainContainer__main">
        <caregiver-dashboard *ngIf="route.snapshot.params.pageView === 'dashboard'" [state]="state"></caregiver-dashboard>
        <caregiver-usersActivities *ngIf="route.snapshot.params.pageView === 'usersactivities'"></caregiver-usersActivities>
        <caregiver-gameMetrics *ngIf="route.snapshot.params.pageView === 'gamemetrics'"></caregiver-gameMetrics>
        <caregiver-calendar *ngIf="route.snapshot.params.pageView === 'calendar'"></caregiver-calendar>
        <caregiver-media *ngIf="route.snapshot.params.pageView === 'media'"></caregiver-media>
        <programrPage *ngIf="route.snapshot.params.pageView === 'programr'"></programrPage>
        <wikiPage *ngIf="state.pageView === 'wiki'"></wikiPage>
        <settingsPage *ngIf="route.snapshot.params.pageView === 'settings'" [viewAs]="this.state.roleView"></settingsPage>
    </div>
</div>

