<article class="graph-area conversation">
    <div class="conversation__header">
        <h5 class="graph-title">{{graphTitle}}</h5>
<!--    TODO: define unit of measurement and what "100%" is -->
    </div>

    <section class="conversation__mainGraph" id="mainGraph" *ngIf="conversationDataReady">
        <div *ngFor="let analysis of conversationSummary" class="topicData">
            <span class="topicName">{{formatSingleConversationTopicName('_', analysis.topic)}}</span>
<!--            TODO: include actual value / unit in tooltip -->
            <progressbar [max]="100" [value]="analysis.percentShare" tooltip="{{analysis.percentShare|number:'1.2-2'}}%"></progressbar>
        </div>
    </section>

    <section class="conversation__mainGraph" *ngIf="!conversationDataReady">
        No data available
    </section>

    <section class="conversation__table" *ngIf="conversationDataReady">
        <table mat-table [dataSource]="convoDetailsMatTable" class="convoDetails mat-elevation-z0" matSort>

            <!-- Date Column -->
            <ng-container matColumnDef="day">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Day </th>
                <td mat-cell *matCellDef="let element"> {{element.day | date:'M/d/yy'}} </td>
            </ng-container>

            <!-- Topic Column -->
            <ng-container matColumnDef="topic">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Topic </th>
                <td mat-cell *matCellDef="let element"> {{formatSingleConversationTopicName('_', element.topic)}} </td>
            </ng-container>

            <!-- UserWordCount Column -->
            <ng-container matColumnDef="userWordCount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> User Word Count </th>
                <td mat-cell *matCellDef="let element">{{element.userWordCount}}</td>
            </ng-container>

            <ng-container matColumnDef="ryanWordCount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Ryan Word Count </th>
                <td mat-cell *matCellDef="let element"> {{element.ryanWordCount}} </td>
            </ng-container>

            <!-- Response Column -->
            <ng-container matColumnDef="response">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Response </th>
                <td mat-cell *matCellDef="let element"> {{element.response}} </td>
            </ng-container>

            <!-- Sentiment Column -->
            <ng-container matColumnDef="sentiment">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Sentiment </th>
                <td mat-cell *matCellDef="let element"> {{element.sentiment | number: '1.0-2'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="displayRow"></tr>
        </table>

        <mat-paginator #convoDetailsPaginator pageSize="10" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
    </section>


    <section class="conversation__table" *ngIf="!conversationDataReady">

    </section>
</article>
