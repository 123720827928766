<article class="mainContent__tile graph-area pie-chart-graph">
    <div class="header">
        <h4 class="graphTitle">{{graphTitle}}</h4>
    </div>
    <div class="graph">
        <ngx-charts-advanced-pie-chart *ngIf="data" [scheme]="pieChartColorScheme" [customColors]="this.mapColorKeys" [valueFormatting]="this.formatPieChartValues" [nameFormatting]="this.formatPieChartNames" [animations]="false" [results]="data" [gradient]="gradient" [label]="'Total'" (select)="onSelect($event)">
            <ng-template #tooltipTemplate let-model="model">
                <span class="tooltip-label">{{model.label}}</span>
                <span class="tooltip-val">{{this.getTooltipTemplateTime(model)}}</span>
            </ng-template>
        </ngx-charts-advanced-pie-chart>
    </div>
</article>
