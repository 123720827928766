import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';

import { AuthService } from '../auth/auth.service';
import { FileService } from '../../services/file/file.service';
import { InventoryService } from '../../services/inventory/inventory.service';
import { OrganizationService } from '../../services/organization/organization.service';

import { AddEmailComponent} from './add-email/add-email.component';
import { EditOrgFacComponent} from './edit-org-fac/edit-org-fac.component';
import { ConfirmDeleteComponent} from './confirm-delete/confirm-delete.component';
import { DirectivesModule } from '../directives.module';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { CreateOrganizationComponent} from './create-organization/create-organization.component';
import { CreateFacilityComponent} from './create-facility/create-facility.component';
import { CreateUserComponent} from './create-user/create-user.component';
import { CreateStudyComponent } from './create-study/create-study.component';
import { EditStudyComponent } from './edit-study/edit-study.component';
import { AssignInventoryComponent} from './assign-inventory/assign-inventory.component';
import { ReassignInventoryComponent} from './reassign-inventory/reassign-inventory.component';
import { AssignAdminComponent} from './assign-admin/assign-admin.component';
import { AssignCaregiverOrGuardianComponent} from './assign-caregiver-or-guardian/assign-caregiver-or-guardian.component';
import { ViewEditCaregiverAssignmentsComponent} from './viewEditCaregiverAssignments/viewEditCaregiverAssignments.component';
import { AddFacilityToStudyComponent } from './addFacilityToStudy/addFacilityToStudy.component';
import { AddParticipantToStudyComponent } from './addParticipantToStudy/addParticipantToStudy.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {ResearchService} from '../../services/research/research.service';
import { QuillModule } from 'ngx-quill';
import {MarkAsteriskDirectiveModule} from '../directives/mark-asterisk.directive';
import {AddResearcherToFacilityOrStudyComponent} from "./AddResearcherToFacilityOrStudy/addResearcherToFacilityOrStudy.component";
import {CreateInventoryComponent2} from './create-inventory2/create-inventory.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        CollapseModule,
        RouterModule,
        AlertModule,
        DirectivesModule,
        ProgressbarModule,
        DragDropModule,
        ModalModule,
        QuillModule,
        MarkAsteriskDirectiveModule,
    ],
    declarations: [
        ConfirmDeleteComponent,
        EditOrgFacComponent,
        CreateOrganizationComponent,
        CreateFacilityComponent,
        CreateUserComponent,
        CreateStudyComponent,
        EditStudyComponent,
        AssignInventoryComponent,
        ReassignInventoryComponent,
        AssignAdminComponent,
        AddEmailComponent,
        AssignCaregiverOrGuardianComponent,
        ViewEditCaregiverAssignmentsComponent,
        AddFacilityToStudyComponent,
        AddParticipantToStudyComponent,
        AddResearcherToFacilityOrStudyComponent,
        CreateInventoryComponent2,
    ],
    exports: [
        CreateOrganizationComponent,
        CreateFacilityComponent,
        CreateUserComponent,
        CreateStudyComponent,
        EditStudyComponent,
        AssignInventoryComponent,
        AssignAdminComponent,
        AddFacilityToStudyComponent,
        AddParticipantToStudyComponent,
        AddResearcherToFacilityOrStudyComponent,
        CreateInventoryComponent2
    ],
    providers: [
        FileService,
        AuthService,
        InventoryService,
        OrganizationService,
        ResearchService,
    ],
    bootstrap: [
        CreateOrganizationComponent,
        ConfirmDeleteComponent,
        EditOrgFacComponent,
        CreateFacilityComponent,
        CreateUserComponent,
        CreateStudyComponent,
        EditStudyComponent,
        AssignInventoryComponent,
        ReassignInventoryComponent,
        AssignAdminComponent,
        AddEmailComponent,
        AssignCaregiverOrGuardianComponent,
        ViewEditCaregiverAssignmentsComponent,
        AddFacilityToStudyComponent,
        AddParticipantToStudyComponent,
        AddResearcherToFacilityOrStudyComponent,
        CreateInventoryComponent2
    ],
})
export class ModalSetModule { }
