import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from "../../auth/auth.service";

import { faUserCircle, faSearch, faSortAmountDown } from "@fortawesome/free-solid-svg-icons";

import { UserService, UserType } from "../../auth/user.service";
import { Organization } from "../../interfaces/Organization";

@Component({
    selector: 'assign-admin',
    templateUrl: './assign-admin.html',
    styleUrls: ['./assign-admin.scss']
})
export class AssignAdminComponent {

    @Input()
    org: Organization;

    @Input()
    formError: String;

    @Input()
    formInfo: String;

    @Output()
    adminAssignment: EventEmitter<UserType> = new EventEmitter<UserType>();

    @Input()
    exclude: string[];

    public assignedAdmin: UserType = null;

    search = '';
    allUsers: UserType[];

    icon = {
        userCircle: faUserCircle,
        search: faSearch
    };


    static parameters = [BsModalRef, AuthService, UserService];

    constructor(public bsModalRef: BsModalRef, public authService: AuthService, public userService: UserService) {
        this.authService = authService;
        this.userService = userService;

        this.getAllUsers();
    }

    getAllUsers() {
        this.userService.query()
            .then((userList) => {
                this.allUsers = userList.users.map((u) => u.value);
            });
    }

    selectUser(user) {
        this.assignedAdmin = user;
    }

    submitAdminAssignment() {
        this.adminAssignment.emit(this.assignedAdmin);
        this.bsModalRef.hide();
    }
}

