<div class="modal-content">
    <form id="addAdmin" name="addAdmin" role="form" class="form-horizontal" (ngSubmit)="submitFacilityAssignment()">
        <div class="modal-header">
            <h4 id="myModalLabel" class="modal-title">Please select the facilities to be added to this study</h4>
            <button type="button" (click)="bsModalRef.hide()" class="close"><span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>

        <div class="modal-body">
            <ul class="facilityOptionList" *ngIf="optionFacilities.length > 0">
                <li class="facilityListing" *ngFor="let facility of optionFacilities" [class.isHighlighted]="facilityIsMember(facility)" (click)="toggleFacilityMembership(facility)">
                    <div>
                        <h6>{{facility.name}}</h6>
                        <address class="facilityAddr">
                            {{facility.address || ''}} <span *ngIf="facility.city">, </span>
                            {{facility.city || ''}}<span *ngIf="facility.state">, </span>{{facility.state || ''}} {{facility.zip || ''}}
                        </address>
                    </div>
                </li>
            </ul>
            <ng-container *ngIf="optionFacilities.length === 0">
                <h5 class="center">Sorry, you do not have access to any facility not already in this study</h5>
            </ng-container>
        </div>

        <!--  ======  Alert  ======  -->
        <alert *ngIf="!!alert && alert?.show && alert?.message" [type]="alert.type" [hidden]="!alert || !alert?.show" [dismissible]="true" (onClosed)="alert.show = false">
            <strong *ngIf="(alert.type | lowercase) === 'success'">Success: </strong>
            <strong *ngIf="(alert.type | lowercase) === 'danger'">Error: </strong>
            {{ alert.message }}
        </alert>

        <div class="modal-footer">
            <button (click)="bsModalRef.hide()" type="reset" class="btn btn-default">Cancel</button>
            <button type="submit" class="btn btn-primary" [disabled]="alert?.show">Confirm</button>
        </div>
    </form>
</div>
