<background></background>
<div class="verifyPage">
    <form class="verifyForm" name="form" #form="ngForm" (ngSubmit)="handleConfirm()">

        <div class="logoBox">
            <img class="verify-overlay__logoDiv__img logo__img" src="/bcfcc9791c4b4bb0b3224c16fe6cd3ff.png" alt="DreamFace Technologies Logo">
        </div>

        <div class="title">
            <h4 class="verify-overlay__textDiv__text text--white">
                Please Verify your Account
            </h4>
        </div>

        <div class="inputContainer">
            <input [(ngModel)]="routeParams.userId" id="email" name="email" class="email-input" placeholder="Email" disabled="disabled" value="" #name="ngModel" required>
        </div>

        <div class="alert-container" *ngIf="alert.show">
            <alert type="{{alert.type}}">{{alert.message}}</alert>
        </div>

        <div class="buttonsArea">
            <button type="submit" class="verify-overlay__btnDiv btnDiv__btn">
                Confirm Verification
            </button>
        </div>

        <hr>

        <div class="bottomInfo">
            <p class="noAcct__text text--grey">
                Don't have an account?
                <a class="create-acct" routerLink="/account/create">Create an account</a>
            </p>
        </div>

    </form>
    <powered-by-dft class="logoBottom"></powered-by-dft>
</div>
<!--  <footer></footer>-->