<div class="modal-content orgClaimModal">
<!--    TODO: validate input-->
    <form id="createRyan" name="createRyan" role="form" class="form-horizontal" (ngSubmit)="submitRyanCreation()">
        <div class="modal-header">
            <button type="button" (click)="bsModalRef.hide()" class="close"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
            <h4 id="myModalLabel" class="modal-title">Create Inventory</h4>
        </div>
        <div class="modal-body">
            <div role="alert" [hidden]="!formError" class="alert alert-danger">{{ formError }}</div>
            <div role="alert" [hidden]="!formInfo" class="alert alert-info">{{ formInfo }}</div>

            <div class="form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <label for="macAddress" class="control-label">Greengrass Name</label>
                    <input id="macAddress" name="macAddress" required placeholder="{{'Greengrass Name'}}" class="form-control" [(ngModel)]="newRyan.greengrassName">
                </div>
            </div>

            <div class="form-group">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <label for="ryanState" class="control-label">Ryan State</label>
                    <select id="ryanState" name="ryanState" required class="form-control ryanState" [(ngModel)]="newRyan.ryanState">
                        <option *ngFor="let state of ryanStateOptions" [value]="state">
                            {{state}}
                        </option>
                    </select>
                </div>
            </div>

        </div>
        <div class="modal-footer" [hidden]="formInfo">
            <button (click)="bsModalRef.hide()" type="button" class="btn btndefault">
                Cancel</button>
            <button type="submit" class="btn btn-primary">Save</button>
        </div>
    </form>
</div>
