import {HttpParameterCodec} from '@angular/common/http';
import {emailValidation} from '../../components/util';

export class CustomEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }

    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}

export function encodeEmail(email: string): string {
    if (!email) return email;
    if (!emailValidation(email)) return email;
    const encoder = new CustomEncoder();
    return encoder.encodeValue(email);
}
