import {Component, Input} from '@angular/core';

@Component({
    selector: 'logout-icon',
    templateUrl: './logout-icon.svg',
    styleUrls: ['./logout-icon.scss']
})
export class LogoutIconComponent {
    fillColor = '#ABABAB';
}
