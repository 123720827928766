<div class="page">
    <!--  ======  Loading view  ======  -->
    <div *ngIf="(!this.loaded || !isLoaded()) && !this.errOccurred">
        Loading...
    </div>

    <!--  ======  Error view  ======  -->
    <alert type="danger" *ngIf="this.errOccurred">
        <strong>Whoops!</strong> We're sorry, an error occurred trying to load this page.
    </alert>

    <!--  ======  Org Admin view ====== -->
    <div class="mainContent dash" *ngIf="this.state.isOrgAdmin && (this.loaded || isLoaded())">

        <!--  =============  Table of Ryans =============  -->
        <article class="mainContent__tile ryanTable">
            <div class="header">
                <div class="text-area">
                    <h4 class="header__title graphTitle">Ryans</h4>
                    <small class="lastUpdated">Total {{numBots.all || 0}} Ryans</small>
                </div>

                <div class="buttons">
                    <button class="emptyBtn" (click)="navToRyanDataPage()">
                        Show All
                    </button>
                </div>
            </div>
            <div class="table-container">
                <table class="table">
                    <thead class="table__header">
                    <tr>
                        <th>ID</th>
                        <th>Status</th>
                        <th>Facility</th>
                        <th>Errors</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody class="table__body" *ngIf="bots && bots.all && bots.all.length > 0">
                    <tr class="table__data" *ngFor="let bot of bots.all.slice(0,4); let i = index;" (click)="navToBotFocus(bot._id)">
                        <td class="id">
                            <span class="bg-logo-blue">{{bot._id}}</span>
                        </td>
                        <td class="status">{{bot.ryanState}}</td>
                        <td class="community">{{bot.activeFacility || 'Unassigned'}}</td>
                        <td class="lastLogin">N/A</td>
                        <td class="more" (click)="navToBotFocus(bot._id)">more</td>
                    </tr>
                    </tbody>
                    <tbody *ngIf="this.numBots.all === 0">
                        <tr>
                            <td colspan="4">No Ryan Robots</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </article>

        <!-- ============= Org Table ============= -->
        <article class="mainContent__tile orgTable">
            <div class="header">
                <div class="text-area">
                    <h4 class="header__title graphTitle">Organizations</h4>
                    <small class="lastUpdated">Total Organizations: {{this.numOrgs || 0}}</small>
                </div>

                <div class="buttons">
                    <button class="emptyBtn" (click)="navToAllOrgPage()">
                        Show All
                    </button>
                </div>
            </div>
            <div class="table-container">
                <table class="table">
                    <thead class="table__header">
                    <tr>
                        <th class="orgName-header">Name</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Num. Ryans</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody class="table__body">
                    <tr class="table__data" *ngFor="let org of this.allOrgs; let i = index;" (click)="navToOrgFocus(org._id)">
                        <td class="orgName">
                            {{org.name}}
                        </td>
                        <td class="orgCity" *ngIf="i <= 4">{{org.city}}</td>
                        <td class="orgState" *ngIf="i <= 4">{{org.state}}</td>
                        <td class="orgRyans" *ngIf="i <= 4">{{org.ryans.length || '0'}}</td>
                        <td class="more" *ngIf="i <= 4" (click)="navToOrgFocus(org._id)">more</td>
                    </tr>
                    </tbody>
                    <tbody class="table__body" *ngIf="this.allOrgs.length == 0">
                    <tr class="table__data">
                        <td span="4">No Organizations</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </article>

        <article class="mainContent__tile facilityTable">
            <div class="header">
                <div class="text-area">
                    <h4 class="header__title graphTitle">Facilities</h4>
                    <small class="lastUpdated">Total Facilities: {{numFacilities || 0}}</small>
                </div>

                <div class="buttons">
                    <button class="emptyBtn" (click)="navToAllOrgPage()">
                        Show All
                    </button>
                </div>
            </div>
            <div class="table-container">
                <table class="table">
                    <thead class="table__header">
                    <tr>
                        <th class="orgName-header">Name</th>
                        <th>City</th>
                        <th>State</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody class="table__body">
                    <tr class="table__data" *ngFor="let facility of allFacilities; let i = index;" (click)="navToFacilityFocus(facility._id)">
                        <td class="orgName">
                            {{facility?.name}}
                        </td>
                        <td class="orgCity">{{facility?.city}}</td>
                        <td class="orgState">{{facility?.state}}</td>
                        <td class="more" (click)="navToFacilityFocus(facility?._id)">more</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </article>
    </div>

    <div class="rightPanel">
<!--        TODO: decide if we actually want this, if so: make it work, otherwise remove it-->
        <div class="addFacility-btn" *ngIf="state.isOrgAdmin && (isLoaded() || loaded)" (click)="navToAllOrgPage()">
            <facility-icon class="btn-icon" [ellipse]="true"></facility-icon>
            <span class="btn-title">New Facility</span>
        </div>
    </div>
</div>
