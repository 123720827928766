import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Facility } from "../../interfaces/Facility";

@Component({
    selector: 'create-user',
    templateUrl: './create-user.html',
    styleUrls: ['./create-user.scss']

})
export class CreateUserComponent {

    @Input()
    facility: Facility;
    @Output()
    newUserCreation: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    formError: String;

    @Input()
    formInfo: String;

    @Input()
    addTo: any;

    createMode: string = null;
    // newUser or existingUser

    email: string;

    newUser = {
        email: '',
        firstName: '',
        lastName: '',
        password: 'default',
        repeatPassword: 'default',
    }

    static parameters = [BsModalRef];

    constructor(public bsModalRef: BsModalRef) {
        this.bsModalRef = bsModalRef;
    }

    setCreateMode(mode: string) {
        if (mode === 'newUser') this.createMode = 'newUser';
        if (mode === 'existingUser') this.createMode = 'existingUser';
    }

    confirmSubmit() {
        if (this.createMode === 'existingUser') this.newUserCreation.emit({ email: this.email, createMode: this.createMode });
        if (this.createMode === 'newUser') this.newUserCreation.emit({ ...this.newUser, createMode: this.createMode });
        this.bsModalRef.hide();
    }

}
