import { Component, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'wikiPage',
    templateUrl: './wiki.html',
    styleUrls: ['./wiki.scss'],
})
export class WikiPageComponent implements OnInit {
    static parameters = [HttpClient];
    constructor(public http: HttpClient) {
        this.http = http;
    }
    ngOnInit() {
        console.log('here');
    }
}
