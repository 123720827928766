import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import { Router } from '@angular/router';
import { UserService, UserType } from '../../../components/auth/user.service';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { OrganizationService } from '../../../services/organization/organization.service';
import { Organization } from '../../../components/interfaces/Organization';
import { InventoryService } from '../../../services/inventory/inventory.service';
import { Inventory } from '../../../components/interfaces/Inventory';
import { Facility } from '../../../components/interfaces/Facility';
import { CalendarEvent } from '../../../components/interfaces/CalendarEvent';
import { CalendarEventService } from '../../../services/calendar-event/calendar-event.service';
import {AppState} from '../../../components/interfaces/AppState';

@Component({
    selector: 'orgadmin-dashboard',
    templateUrl: './orgadminDashboard.html',
    styleUrls: ['../../../assets/sharedStyles/pages/dashboard.scss'],
})


export class OrgAdminDashboardComponent implements OnInit, OnDestroy {
    @Input('state') appState: AppState;
    currentUser;

    state = {
        isOrgAdmin: false,
        org_id: undefined,
        ryan_id: undefined
    };

    pageLoaded: Promise<boolean>;
    usersLoadedPromise: Promise<boolean>;
    orgsLoadedPromise: Promise<boolean>;
    botsLoadedPromise: Promise<boolean>;
    eventsLoadedPromise: Promise<boolean>;
    facilitiesLoadedPromise: Promise<boolean>;

    loaded: boolean = true; //determines if all necessary content is loaded ("all" defined by permissions)
    usersLoaded: boolean = false; //determines if all user content is loaded
    orgsLoaded: boolean = false; //determines if all organizations are loaded
    botsLoaded: boolean = false; //determines if all Ryan information is loaded
    eventsLoaded: boolean = false; //determines if all Events have been loaded
    facilitiesLoaded: boolean = false; //determines if all Facilities have been loaded

    icon = {
        bell: faBell,
        userCircle: faUserCircle,
        search: faSearch
    };

    data: any;
    allUsers: UserType[] = [];
    numUsers: number;

    allOrgs: Organization[] = [];
    numOrgs: number;

    allFacilities: Facility[];
    numFacilities: number;

    bots = {
        all: [] as Inventory[],
        active: [] as Inventory[],
        suspended: [] as Inventory[],
        disabled: [] as Inventory[]
    };

    numBots = {
        all: 0,
        active: 0,
        suspended: 0,
        disabled: 0
    };

    myEvents: CalendarEvent[] = [];
    numEvents: number;
    errOccurred: boolean = false;

    static parameters = [Router, UserService, OrganizationService,
        InventoryService, CalendarEventService];

    constructor(public router: Router, public userService: UserService,
                public organizationService: OrganizationService, public inventoryService: InventoryService,
                public eventService: CalendarEventService) {
        this.router = router;
        this.userService = userService;
        this.organizationService = organizationService;
        this.inventoryService = inventoryService;
        this.eventService = eventService;

        this.loaded = false;

        //get the current user from the token
        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserType;
    }

    ngOnInit(): void {
        if (!this.appState) throw new Error('Input \'state\' is required!');
        this.isOrgAdmin()
            .then((res: Organization[]) => {
                this.allOrgs = res;
                if (res.length > 0) {
                    this.state.isOrgAdmin = true;
                }
                return this.allOrgs;
            })
            .then(() => {
                this.orgsLoaded = true;
                this.orgsLoadedPromise = Promise.resolve(true);
                return this.allOrgs.map((org) => org.ryans as Inventory[])
                    .reduce((accum, orgBots) => accum.concat(...orgBots), []);
            })
            .then((botList) => {
                this.bots.all = botList;
                this.bots.active = botList.filter((bot) => bot.ryanState === 'active');
                this.numBots.all = this.bots.all.length;
                this.numBots.active = this.bots.active.length;
                this.botsLoaded = true;
                this.botsLoadedPromise = Promise.resolve(true);
            })
            .then(() => this.getFacilities().then((res: Facility[]) => res))
            .then((facilities: Facility[]) => {
                this.allFacilities = facilities;
                this.numFacilities = this.allFacilities.length;
                this.facilitiesLoaded = true;
                this.facilitiesLoadedPromise = Promise.resolve(true);
            })
            .catch((err) => {
                this.errOccurred = true;
                console.error(err);
                Promise.reject();
            });

        this.loaded = this.isLoaded();
    }

    ngOnDestroy(): void { }

    /**
     * isLoaded
     *      this method will determine whether or not all of the necessary parts of the page have been loaded
     */
    isLoaded(): boolean {//Promise<boolean>{
        const loadReqs = [this.botsLoadedPromise, this.orgsLoadedPromise, this.facilitiesLoadedPromise];

        this.loaded = this.botsLoaded && this.orgsLoaded;
        if (this.botsLoaded && this.orgsLoaded) {
            this.pageLoaded = Promise.resolve(true);
        }
        return this.botsLoaded && this.orgsLoaded;
    }

    isOrgAdmin(): Promise<Organization[]> {
        return this.organizationService.getOrganizationsForAdmin()
            .toPromise()
            .then((res : any) => {
                //returns string[] of orgIds if OrgAdmin, false otherwise
                return res && res.organizations ? Promise.resolve(res.organizations) : Promise.resolve(false);
            })
            .catch((err) => {
                this.errOccurred = true;
                console.error(err);
                return Promise.reject(err);
            });
    }

    /**
     * loadUsers
     *      method to be used by OrgAdmins to retrieve ALL users affiliated with their orgs
     *          (all users from each facility)
     */
    loadUsers(): UserType[] {
        if (this.allFacilities) {
            let userEmails = this.allFacilities
                .filter((f: Facility) => f.users.length > 0)
                .map((f: Facility) => f.users)
                .reduce((acc, email) => {
                    acc.push(...email);
                    return acc;
                }) as UserType[];
            //filter duplicate emails
            userEmails = userEmails.filter((value, index, self) => self.indexOf(value) === index);

            const allUsersPromise = userEmails.map((user) => new Promise<UserType>((resolve, reject) => {
                this.userService.getUserByEmail(user.email)
                    .toPromise()
                    .then((u: UserType) => {
                        resolve(u);
                    })
                    .catch((err) => {
                        this.errOccurred = true;
                        console.error(err);
                        reject(err);
                    });
            }));

            Promise.all(allUsersPromise)
                .then((userList: UserType[]) => {
                    this.allUsers = userList;
                    this.numUsers = userList.length;
                    this.usersLoaded = true;
                    return userList;
                })
                .catch((err) => {
                    console.error(err);
                });
        } else {
            console.error('Facilities not loaded');
            return [] as UserType[];
        }
    }

    getFacilities() {
        if (this.allOrgs) {
            let facilityList = [] as Facility[];
            type expectedResType = { page, pageCount, numFacilities, facilities };
            const orgIds = this.allOrgs.map((org) => org._id);

            const promises = orgIds.map((orgId) => this.organizationService.getAllFacilitiesForOrg(orgId)
                .toPromise()
                .then((res: expectedResType) => res.facilities)
                .then((facilities: Facility[]) => {
                    facilityList.push(...facilities);
                    return facilities;
                })
                .catch((err) => console.error(err)));
            return Promise.all(promises)
                .then(() => {
                    this.allFacilities = facilityList;
                    return facilityList;
                });
        }
    }

    getMyEvents(): Promise<CalendarEvent[]> {
        return this.eventService.query(this.currentUser.email)
            .then((events: CalendarEvent[]) => Promise.resolve(events))
            .catch((err) => {
                console.error(err);
                this.errOccurred = true;
                return Promise.reject(err);
            });
    }

    /**
     * refreshOrgs
     *      helper method to update the list of organizations (and their data) when
     *      CRUD operations are performed on them (or upon initialization)
     */
    refreshOrgs() {
        this.organizationService.getAllOrganizations()
            .then(({ organizations, numOrganizations }) => {
                this.allOrgs = organizations.map(o => o.value);
                this.numOrgs = numOrganizations;
            })
            .then(() => {
                this.orgsLoaded = true;
            })
            .catch((err) => {
                this.errOccurred = true;
                this.orgsLoaded = false;
                console.error(err);
            });
    }

    navToBotFocus(botId: string) {
        this.router.navigate(['organizationadmin', 'ryandata'], {
            queryParams: {botId: botId}
        });
    }

    navToRyanDataPage() {
        this.router.navigate(['organizationadmin', 'ryandata']);
    }

    navToAllOrgPage() {
        this.router.navigate(['organizationadmin', 'manage']);
    }

    navToOrgFocus(orgId: string) {
        this.router.navigate(['organizationadmin', 'manage'], {queryParams: {orgId: orgId}});
    }

    navToFacilityFocus(facilityId: string) {
        const focusedOrg = this.allOrgs.find(o => o.facilities.includes(facilityId));
        this.router.navigate(['organizationadmin', 'manage'], {
            queryParams: {orgId: focusedOrg._id, facilityId: facilityId}
        });
    }
}
