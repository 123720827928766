// // @flow
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import config from '../../app/app.constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Organization } from '../interfaces/Organization';
import { Facility } from '../interfaces/Facility';
import {CustomEncoder} from '../../services/encoder/customEncoder';
import {ResearchStudy} from "../interfaces/ResearchStudy";

export type UserType = {
    _id?: string;
    id?: string;
    firstName?: string;
    lastName?: string;
    birthday?: string;
    email?: string;
    ryanName?: string;
    anonymizedName? : string;
    activeFacility?: string;
    guardians?: any[];
    caregivers?: any[];
    guardianOf?: any[];
    caregiverOf?: any[];
    role?: string;
    gender?: string;
    weight?: number;
    organizationAdminOf?: Organization[];
    facilityAdminOf?: Facility[];
    principleInvestigatorIn?: Facility[];
    researchCoordinatorOf?: ResearchStudy[];
    researcherOf?: ResearchStudy[];
    invitationAccepted?: boolean;
    lastLoginTime?: any;
}

@Injectable()
export class UserService {
    static parameters = [HttpClient];

    constructor(public http: HttpClient) {
        this.http = http;
    }

    query(numResults = '100', page = '1', state = 'all') {
        return this.http.get('/api/users/', { params: { page, numResults, state } })
            .toPromise()
            .then((data) => (data))
            .catch((err) => (err));
    }

    get(userID: string = 'me'): Observable<UserType> {
        var encoder = new CustomEncoder();
        const encodedId = encoder.encodeKey(userID);
        return this.http.get(`/api/users/${encodedId}`) as Observable<UserType>;
    }

    getUserByEmail(email: string): Observable<UserType> {
        var encoder = new CustomEncoder();
        const encodedId = encoder.encodeKey(email);
        return this.http.get(`/api/users/${email}`) as Observable<UserType>;
    }

    create(user: UserType) {
        return this.http.post('/api/users', user);
    }

    changePassword(user, oldPassword, newPassword, newPasswordRepeated) {
        return this.http.put(`/api/users/${user.id || user._id}/password`, { oldPassword, newPassword, newPasswordRepeated });
    }

    remove(user) {
        return this.http.delete(`/api/users/${user.id || user._id}`)
            .pipe(map(() => user));
    }

    upsert(user: UserType, userId?: string): Observable<UserType> {
        return this.http.put(`/api/users/${userId || 'me'}`, user) as Observable<UserType>;
    }

    addCaregiverOrGuardian(forUserId: string, email: string, addCaregiver = true) {
        return this.http.put(`api/users/${forUserId}/${addCaregiver ? 'addCaregiver' : 'addGuardian'}/${email}`, null) as Observable<UserType>;
    }

    getUsersForCaregiver() {
        return this.http.get(`api/facility/caregiver/users`);
    }
}
