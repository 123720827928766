import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, OnChanges} from '@angular/core';
import {
    AnalyticsService,
} from '../../../services/analytics/analytics.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

@Component({
    selector: 'hardware-graph',
    templateUrl: './hardware-graph.html',
    styleUrls: ['./hardware-graph.scss']
})

export class HardwareGraphComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @Input() graphTitle: string;
    @Input() hardwareDataReady: boolean = false;
    @Input() hardwareEvents: any [] = [
        {
            name: 'proximity',
            event: {
                details: 'detected',
                severity: 'low',
            },
            time: '2021-05-14T19:59:38.342Z',
            robot: 'ryan1'
        },
        {
            name: 'proximity',
            event: {
                details: 'cleared',
                severity: 'high',
            },
            time: '2021-05-14T19:57:35.940Z',
            robot: 'ryan1'
        },
        {
            name: 'arm_motion',
            event: {
                details: 'disabled',
                severity: 'low',
            },
            time: '2021-05-14T19:57:12.293Z',
            robot: 'ryan1'
        },
        {
            name: 'collision',
            event: {
                details: 'detected',
                severity: 'high'
            },
            time: '2021-05-14T19:56:32.800Z',
            robot: 'ryan1'
        }
    ];
    hardwareEventsMatTable: MatTableDataSource<any>;
    displayedColumns: string[] = ['name', 'date', 'severity', 'details', 'time'];
    private hardwareDetailsPaginator: MatPaginator;
    @ViewChild('hardwareDetailsPaginator') set matPaginator(mp: MatPaginator) {
        if (this.hardwareEvents && !this.hardwareEventsMatTable) {
            this.hardwareEventsMatTable = new MatTableDataSource<any>(this.hardwareEvents);
        }
        this.hardwareDetailsPaginator = mp;
        if (this.hardwareEventsMatTable) {
            this.hardwareEventsMatTable.paginator = this.hardwareDetailsPaginator;
        }
    }

    @ViewChild(MatSort, {static: false}) set sort(value: MatSort) {
        if (this.hardwareEventsMatTable) {
            this.hardwareEventsMatTable.sort = value;
        }
    }

    static parameters = [AnalyticsService];
    constructor(public analyticService: AnalyticsService) {
        this.analyticService = analyticService;
    }

    ngOnInit() {
        if (this.hardwareEvents) {
            this.hardwareEventsMatTable = new MatTableDataSource<any>(this.hardwareEvents);
        }
        console.log(this.hardwareEventsMatTable);
    }

    ngAfterViewInit(): void {
        if (this.hardwareEvents) {
            this.hardwareEventsMatTable = new MatTableDataSource<any>(this.hardwareEvents);
        }
    }

    ngOnChanges() {
        if (this.hardwareEvents) {
            this.hardwareEventsMatTable = new MatTableDataSource<any>(this.hardwareEvents);
        }
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.hardwareEventsMatTable.filter = filterValue.trim().toLowerCase();

        if (this.hardwareEventsMatTable.paginator) {
            this.hardwareEventsMatTable.paginator.firstPage();
        }
    }

    public ngOnDestroy(): void { }
}
