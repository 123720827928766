import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService} from '../../components/auth/auth.service';
import {MainComponent} from '../main/main.component';
import {Subscription} from 'rxjs';

@Component({
    selector: 'guardianPages',
    templateUrl: './guardianPages.html',
    styleUrls: ['./guardianPages.scss'],
})

export class GuardianPagesComponent extends MainComponent implements OnInit, OnDestroy, OnChanges {
    subscription: Subscription;

    static parameters = [Router, ActivatedRoute, AuthService];
    constructor(public router: Router, public route: ActivatedRoute, authService: AuthService) {
        super(router, route, authService);

        this.route.params.subscribe(p => {
            if (p['pageView']) {
                if (!this.state.navElements.map(n => n['link']).includes(p['pageView'])) {
                    this.router.navigate(['guardian', 'dashboard']);
                }
            }
        });
    }

    ngOnInit() {
        this.ensureValidPageView();
    }

    ensureValidPageView() {
        this.route.params.subscribe(p => {
            if (p['pageView']) {
                if (!this.state.navElements.map(n => n['link']).includes(p['pageView'])) {
                    this.router.navigate(['guardian', 'dashboard']);
                }
            }
        });
    }

    ngOnDestroy(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        // console.log(changes);
    }
}
