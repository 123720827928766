<div class="page">

    <div class="mainContent">
        <div class="breadcrumbs">
            <div class="breadcrumbItem" *ngIf="userId !== 'me'" (click)="changeUserFocusTo('me')">
                Settings / me
            </div>
            <div class="navInfoFocusButton">
                Settings / {{userId}}
            </div>

        </div>
        <div class="rowArea">
            <div class="titleEdit">
                <h3 class="title">Profile</h3>
                <button class="btn btn-outline-warning" (click)="this.state.editing = true" *ngIf="!state.editing">
                    <fa-icon class="icon" [icon]="icon.edit" size="2x"></fa-icon>
                </button>
            </div>

            <div class="content">
                <div class="content-row">
                    <span class="rowLabel"></span>
                    <fa-icon [icon]="icon.userIcon" size="8x"></fa-icon>
                </div>

                <ng-container *ngIf="!state.editing">
                    <div *ngFor="let property of editableProperties" class="content-row">
                        <span class="rowLabel">{{property.label}}</span>
                        <h3 *ngIf="!user">Loading User...</h3>
                        <h3 *ngIf="!!user">
                            {{property.type === "date" ? (user[property.name] | date: 'yyyy-MM-dd') :
                            user[property.name]}}
                        </h3>
                    </div>
                </ng-container>


                <form *ngIf="state.editing" (ngSubmit)="handleEdit($event, editProfile.value)" class="editProfileForm" #editProfile="ngForm">
                    <!-- <input type="{{property.type}}" placeholder="{{property.label}}"
                        name="{{property.name}}" #input value="{{
                                    property.type === 'date' ? formatDate(user[property.name]) : user[property.name]
                                }}" min="1" /> -->
                    <div class="formGroup">
                        <label for="firstName">First Name</label>
                        <input type="text" name="firstName" placeholder="First Name" [ngModel]="user.firstName">
                    </div>
                    <div class="formGroup">
                        <label for="lastName">Last Name</label>
                        <input type="text" name="lastName" placeholder="Last Name" [ngModel]="user.lastName">
                    </div>
                    <div class="formGroup">
                        <label for="birthday">Birthday</label>
                        <input type="date" name="birthday" placeholder="Birthday" [ngModel]="user.birthday | date: 'yyyy-MM-dd'">
                    </div>
                    <div class="formGroup">
                        <label for="weight">Weight</label>
                        <input type="number" name="weight" placeholder="Weight" [ngModel]="user.weight">
                    </div>
                    <div class="formGroup">
                        <label for="gender">Gender</label>
                        <select name="gender" [ngModel]="user.gender">
                            <option *ngFor="let gender of genderTypes" value="{{gender}}">{{gender}}</option>
                        </select>
                    </div>

                    <div class="formGroup">
                        <label for="job">Job</label>
                        <input type="text" name="job" placeholder="Job" [ngModel]="user.job">
                    </div>
                    <div class="formGroup">
                        <label for="birthplace">Birthplace</label>
                        <input type="text" name="birthplace" placeholder="Birthplace" [ngModel]="user.birthplace">
                    </div>
                    <div class="formGroup">
                        <label for="location">Location</label>
                        <input type="text" name="location" placeholder="Location" [ngModel]="user.location">
                    </div>
                    <div class="formGroup">
                        <label for="hobbies">Hobbies</label>
                        <input type="text" name="hobbies" placeholder="Hobbies" [ngModel]="user.hobbies">
                    </div>
                    <div class="formGroup">
                        <label for="mother">Mother</label>
                        <input type="text" name="mother" placeholder="Mother" [ngModel]="user.mother">
                    </div>

                    <div class="formGroup">
                        <label for="father">Father</label>
                        <input type="text" name="father" placeholder="Father" [ngModel]="user.father">
                    </div>
                    <div class="formGroup">
                        <label for="partner">Partner</label>
                        <input type="text" name="partner" placeholder="Partner" [ngModel]="user.partner">
                    </div>
                    <div class="formGroup">
                        <label for="pets">Pets</label>
                        <input type="text" name="pets" placeholder="Pets" [ngModel]="user.pets">
                    </div>
                    <div class="formGroup">
                        <label for="hobbies">Hobbies</label>
                        <input type="text" name="hobbies" placeholder="Hobbies" [ngModel]="user.hobbies">
                    </div>
                    <div class="formGroup">
                        <label for="children">Children</label>
                        <input type="text" name="children" placeholder="Children" [ngModel]="user.children">
                    </div>

                    <div class="formGroup">
                        <label for="have_children">Do You have children?</label>
                        <select name="have_children" [ngModel]="user.have_children">
                            <option *ngFor="let opt of ['Yes', 'No']" value="{{opt}}">{{opt}}</option>
                        </select>
                    </div>
                    <div class="formGroup">
                        <label for="num_children">Number of Children</label>
                        <input type="number" name="num_children" placeholder="Number of Children" [ngModel]="user.num_children">
                    </div>
                    <div class="formGroup">
                        <label for="went_to_college">Did you go to College?</label>
                        <select name="went_to_college" [ngModel]="user.went_to_college">
                            <option *ngFor="let opt of ['Yes', 'No']" value="{{opt}}">{{opt}}</option>
                        </select>
                    </div>
                    <div class="formGroup">
                        <label for="college">College</label>
                        <input type="text" name="college" placeholder="College" [ngModel]="user.college">
                    </div>

                    <div class="buttonsArea">
                        <button type="submit" class="btn btn-primary confirmButton">Confirm</button>
                        <button class="btn btn-warning cancelButton" (click)="state.editing = false">Cancel</button>
                    </div>

                </form>

                <alert *ngIf="alert.showFor === 'profile'" type="{{alert.type}}">{{alert.message}}</alert>


            </div>

        </div>
        <div class="rowArea">

            <h3>Change Password</h3>
            <div class="content">
                <form #pwForm="ngForm" (ngSubmit)="handlePasswordChange($event,pwForm)">
                    <div class="content-row centered">
                        <input type="password" placeholder="Old Password" id="oldPassword" name="oldPassword" #oldPassword="ngModel" [(ngModel)]="pwFields.oldPassword">
                    </div>
                    <div class="content-row centered">
                        <input type="password" placeholder="New Password" id="newPassword" name="newPassword" #newPassword="ngModel" [(ngModel)]="pwFields.newPassword">
                    </div>
                    <div class="content-row centered">
                        <input type="password" placeholder="Confirm Password" id="newPasswordRepeated" name="newPasswordRepeated" #newPasswordRepeated="ngModel" [(ngModel)]="pwFields.newPasswordRepeated">
                    </div>
                    <div class="content-row centered">
                        <button type="submit" class="btn btn-primary confirmButton">Change Password</button>
                    </div>
                </form>

                <alert *ngIf="alert.showFor === 'password'" type="{{alert.type}}">{{alert.message}}</alert>

            </div>

        </div>
    </div>
    <div class="rightPanel">
        <userlistpanel *ngIf="this.AuthService.getUsersInCare().length > 0 && viewAs !== 'user'" [users]="this.AuthService.getUsersInCare()" [clickCb]="this.changeUserFocusTo.bind(this)">
        </userlistpanel>

    </div>
</div>
