import { Pipe, PipeTransform } from '@angular/core';
import {UserType} from "../auth/user.service";

@Pipe({
    name: 'filterUsers'
})
export class FilterUsersPipe implements PipeTransform {
    transform(value: any, searchValue, exclude = []): UserType[] {
        if (!searchValue || value.length === 0) return value;
        return value.filter((v) => {
            return exclude.includes(v.email) ? false : (
                (v.firstName?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
                || (v.lastName?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
            );
        });
    }
}
