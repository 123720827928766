<form class="root" #form="ngForm" (submit)="handleSubmit($event)">
    <h6>Search Anonymized Names</h6>
    <input #email="ngModel" [(ngModel)]="searchTerm" type="email" name="email" placeholder="email">
    <div class="result" *ngIf="result && result.length > 0">
        <h6>{{result}}</h6>
    </div>
    <div class="invalidResult" *ngIf="invalidResult && invalidResult.length > 0">
        <h6>{{invalidResult}}</h6>
    </div>
    <div class="buttons">
        <button class="clear" type="reset" (click)="clear($event)">Clear</button>
        <button class="enter" type="submit">Enter</button>
    </div>
</form>