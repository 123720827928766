import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { RouterModule, Routes } from '@angular/router';
import { GraphsModule } from '../../components/graphs/graphs.module';
import { DirectivesModule } from '../../components/directives.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatTableModule } from '@angular/material/table';
import { AuthGuard } from '../../components/auth/auth-guard.service';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarSetModule } from '../../components/calendar/calendar.module';
import { UserGameMetricsComponent } from './userGameMetrics/userGameMetrics.component';
import { UserCalendarComponent } from './userCalendar/userCalendar.component';
import { UserDashboardComponent } from './userDashboard/userDashboard.component';
import { UserMediaComponent } from './userMedia/userMedia.component';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import { BsModalService } from 'ngx-bootstrap/modal';
import {ProgramRPageComponent} from '../programr/programr.component';
import {UserPagesComponent} from './userPages.component';
import {ProgramRSetModule} from '../programr/programr.module';
import {SettingsSetModule} from '../settings/settings.module';
import {WikiModule} from '../wiki/wiki.module';

const ROUTES: Routes = [
    {
        path: ':pageView',
        component: UserPagesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
    }
];
@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        FullCalendarModule,
        CalendarSetModule,
        CommonModule,
        CollapseModule,
        RouterModule,
        AccordionModule,
        MatExpansionModule,
        FontAwesomeModule,
        MatIconModule,
        CdkTreeModule,
        MatButtonModule,
        BsDropdownModule,
        BsDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
        NgxChartsModule,
        MatTableModule,
        AlertModule,
        GraphsModule,
        DirectivesModule,
        ProgressbarModule,
        ProgramRSetModule,
        SettingsSetModule,
        WikiModule,
    ],
    declarations: [
        UserPagesComponent,
        UserGameMetricsComponent,
        UserCalendarComponent,
        UserMediaComponent,
        UserDashboardComponent,
    ],
    exports: [
        UserPagesComponent,
        UserGameMetricsComponent,
        UserCalendarComponent,
        UserMediaComponent,
        UserDashboardComponent,
    ],
    providers: [BsModalService],
    schemas: [],
})
export class UserPagesModule { }
