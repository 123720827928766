import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class SiteAdminGuard implements CanActivate {
    static parameters = [UserService, Router];
    constructor(public userService: UserService, public router: Router) {
        this.userService = userService;
        this.router = router;
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.userService.get().toPromise().then((res) => {
            if (res.role === 'admin') {
                return true;
            } else {
                this.router.navigate(['/dashboard/user'], {
                    queryParams: {
                        error: 'unauthorized'
                    }
                });
                return false;
            }
        }).catch((err) => {
            this.router.navigate(['/account/login'], {
                queryParams: {
                    return: state.url,
                    alert: 'auth-required'
                }
            });
            return false;
        });
    }

}
