import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService} from '../../components/auth/auth.service';
import {MainComponent} from '../main/main.component';
import {Subscription} from 'rxjs';

@Component({
    selector: 'userPages',
    templateUrl: './userPages.html',
    styleUrls: ['./userPages.scss']
})

export class UserPagesComponent extends MainComponent implements OnInit, OnDestroy, OnChanges {
    subscription: Subscription;

    static parameters = [Router, ActivatedRoute, AuthService];
    constructor(public router: Router, public route: ActivatedRoute, authService: AuthService) {
        super(router, route, authService);
    }

    ngOnInit() { }

    ngOnDestroy(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(changes);
    }
}
