<div class="page">
    <div class="mainContent" *ngIf="allFacilities">
        <nav class="breadcrumb breadcrumbs" aria-label="breadcrumb">
            <ol class="breadcrumbs__list">
                <!--                Not focused on facility-->
                <li class="breadcrumb-item breadcrumbItem" [attr.aria-current]="(!focusedFacilityId) ? 'page' : null" (click)="focusOnFacility()">
                    Game Metrics
                </li>
                <!--                Focused on facility-->
                <li class="breadcrumb-item breadcrumbItem" *ngIf="focusedFacilityId" [attr.aria-current]="(!!focusedFacilityId) ? 'page' : null" (click)="focusOnFacility(focusedFacilityId)">
                    {{getCurrentFacility().name || 'n/a'}}
                </li>
                <!--                Focused on facility AND User-->
                <li class="breadcrumb-item breadcrumbItem" *ngIf="focusedFacilityId && focusedUserId" [attr.aria-current]="(!!focusedUserId) ? 'page' : null">
                    {{getCurrentUser().anonymizedName || 'n/a'}}
                </li>
            </ol>
        </nav>

        <ng-container *ngIf="!focusedFacilityId">
            <h4>Please select a Facility</h4>
            <div class="facilityList">
                <article class="facilityArea blueOnHover" *ngFor="let facility of allFacilities" (click)="focusOnFacility(facility._id)">
                    <div class="header">
                        <div class="text-area">
                            <h3 class="facilityName">{{facility?.name || "Loading Name"}}</h3>
                            <address class="facilityAddr">
                                {{facility.address || ''}} <span *ngIf="facility.city">, </span>
                                {{facility.city || ''}}<span *ngIf="facility.state">, </span>{{facility.state || ''}} {{facility.zip || ''}}
                            </address>
                        </div>
                    </div>
                    <div class="facilityInfoCount">
                        <h6>Administrators: <span>({{facility?.administrators?.length || 0}})</span></h6>
                        <h6>Users: <span>({{facility?.users?.length || 0}})</span></h6>
                    </div>
                </article>
            </div>
        </ng-container>

        <!--        Focused on Facility-->
        <ng-container *ngIf="!focusedUserId && focusedFacilityId">
            <article class="mainContent__tile">
                <queryDateRangeControl [title]="getCurrentFacility().name + '\'s Game Metrics'" [queryDateRange]="selectedRange" downloadBtnLabel="Export Data" [downloadModalHeader]="'Download User data for ' + getCurrentFacility().name" (queryDatesUpdate)="changeSelectedRange($event)">
                </queryDateRangeControl>
            </article>

            <!--Average Game Activity -->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Game Activity</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="graph">
                    <div *ngIf="!gameActivityGraphMultipleUsers || !gameActivityGraphMultipleUsers[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="gameActivityGraphMultipleUsers && !!gameActivityGraphMultipleUsers[0]" [scheme]="colorScheme" [results]="gameActivityGraphMultipleUsers" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>
                <div class="details">

                </div>
            </article>
            <!--Flight-->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Flight</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="breakdown-area">
                    <!--                    <span class="metric">Weekly average: </span>-->
                    <!--                    <span class="value"></span>-->
                </div>
                <div class="graph">
                    <div *ngIf="!flightGraphMultipleUsers || !flightGraphMultipleUsers[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="flightGraphMultipleUsers && !!flightGraphMultipleUsers[0]" [scheme]="colorScheme" [results]="flightGraphMultipleUsers" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>

                <div class="details">

                </div>
            </article>
            <!--Ryan runs -->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Ryan Runs</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="breakdown-area">
                    <!--                    <span class="metric">Weekly average: </span>-->
                    <!--                    <span class="value">3h 04min</span>-->
                </div>
                <div class="graph">
                    <div *ngIf="!ryanRunsGraphMultipleUsers || !ryanRunsGraphMultipleUsers[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="ryanRunsGraphMultipleUsers && !!ryanRunsGraphMultipleUsers[0]" [scheme]="colorScheme" [results]="ryanRunsGraphMultipleUsers" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>

                <div class="details">

                </div>
            </article>
            <!--Tic Tac Toe -->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Tic-Tac-Toe</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="breakdown-area">
                    <!--                    <span class="metric">Weekly average: </span>-->
                    <!--                    <span class="value">2h 04min</span>-->
                </div>
                <div class="graph">
                    <div *ngIf="!ticTacToeGraphMultipleUsers || !ticTacToeGraphMultipleUsers[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="ticTacToeGraphMultipleUsers && !!ticTacToeGraphMultipleUsers[0]" [scheme]="colorScheme" [results]="ticTacToeGraphMultipleUsers" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>

                <div class="details">

                </div>
            </article>
            <!--Flow Game -->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Flow Game</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="breakdown-area">
                    <!--                    <span class="metric">Weekly average: </span>-->
                    <!--                    <span class="value">1h 04min</span>-->
                </div>
                <div class="graph">
                    <div *ngIf="!flowGameGraphMultipleUsers || !flowGameGraphMultipleUsers[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="flowGameGraphMultipleUsers && !!flowGameGraphMultipleUsers[0]" [scheme]="colorScheme" [results]="flowGameGraphMultipleUsers" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>

                <div class="details">

                </div>
            </article>
            <!--Bird Counting-->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Bird Counting</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="breakdown-area">
                    <!--                    <span class="metric">Weekly average: </span>-->
                    <!--                    <span class="value">1h 04min</span>-->
                </div>
                <div class="graph">
                    <div *ngIf="!birdCountingGraphMultipleUsers || !birdCountingGraphMultipleUsers[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="birdCountingGraphMultipleUsers && !!birdCountingGraphMultipleUsers[0]" [scheme]="colorScheme" [results]="birdCountingGraphMultipleUsers" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>

                <div class="details">

                </div>
            </article>
            <!--Picture Puzzle-->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Picture Puzzle</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="breakdown-area">
                    <!--                    <span class="metric">Weekly average: </span>-->
                    <!--                    <span class="value">1h 04min</span>-->
                </div>
                <div class="graph">
                    <div *ngIf="!picturePuzzleGraphMultipleUsers || !picturePuzzleGraphMultipleUsers[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="picturePuzzleGraphMultipleUsers && !!picturePuzzleGraphMultipleUsers[0]" [scheme]="colorScheme" [results]="picturePuzzleGraphMultipleUsers" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>

                <div class="details">

                </div>
            </article>
            <!--Word Puzzle -->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Word Puzzle</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="breakdown-area">
                    <!--                    <span class="metric">Weekly average: </span>-->
                    <!--                    <span class="value">1h 04min</span>-->
                </div>
                <div class="graph">
                    <div *ngIf="!wordPuzzleGraphMultipleUsers || !wordPuzzleGraphMultipleUsers[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="wordPuzzleGraphMultipleUsers && !!wordPuzzleGraphMultipleUsers[0]" [scheme]="colorScheme" [results]="wordPuzzleGraphMultipleUsers" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>

                <div class="details">

                </div>
            </article>
            <!--Checkers-->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Checkers</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="breakdown-area">
                    <!--                    <span class="metric">Weekly average: </span>-->
                    <!--                    <span class="value">1h 04min</span>-->
                </div>
                <div class="graph">
                    <div *ngIf="!checkersGraphMultipleUsers || !checkersGraphMultipleUsers[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="checkersGraphMultipleUsers && !!checkersGraphMultipleUsers[0]" [scheme]="colorScheme" [results]="checkersGraphMultipleUsers" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>

                <div class="details">

                </div>
            </article>
            <!--Chess -->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Chess</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="breakdown-area">
                    <!--                    <span class="metric">Weekly average: </span>-->
                    <!--                    <span class="value">1h 04min</span>-->
                </div>
                <div class="graph">
                    <div *ngIf="!chessGraphMultipleUsers || !chessGraphMultipleUsers[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="chessGraphMultipleUsers && !!chessGraphMultipleUsers[0]" [scheme]="colorScheme" [results]="chessGraphMultipleUsers" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>

                <div class="details">

                </div>
            </article>
        </ng-container>

        <!--        Focused on User AND Facility-->
        <ng-container *ngIf="focusedUserId && focusedFacilityId">
            <article class="mainContent__tile focusBanner">
                <section class="focusBanner__top">
                    <div class="focusBanner__top--left">
                        <div class="focusBanner__userPfp userPfp">
                            <fa-icon [icon]="icons.user"></fa-icon>
                        </div>

                        <div class="userInfo__text">
                            <span class="header">
                                {{getCurrentUser().firstName}} {{getCurrentUser().lastName}}
                            </span>
                            <span class="lastConnection">
<!--                                TODO: track/get last login time   -->
                                <small>Last login: <strong>{{getCurrentUser()?.lastBotLogin || 'Unknown Bot'}}</strong> at <strong>{{(getCurrentUser()?.lastLoginTime | date:'short') || 'Unknown datetime'}}</strong></small>
                            </span>
                        </div>
                    </div>
                    <div class="focusBanner__top--right">
                        <div class="focusBanner__buttons">
<!--                        TODO: what should the 'Edit' button allow for?
                                            - age, weight, add guardians(?)-->
                            <button class="emptyBtn edit">Edit</button>
                        </div>

                        <div class="ageJoinDate">
                            <span class="userAge">
                                Age <span>{{getUserAge(getCurrentUser().birthday)}}</span>
                            </span>
                            <span class="userJoinDate">
                                Join Date <span>mm/dd/yyy</span>
                            </span>
                        </div>
                    </div>
                </section>

                <section class="focusBanner__bottom">
                    <hr>
                    <div class="bottom-content">
                        <div class="bottom-content__right">
                            <span class="location">

                            </span>
                        </div>
                        <div class="bottom-content__left">
                            <span class="assignment">Assigned to <b class="facName">{{getCurrentFacility()?.name || 'None'}}</b></span>
                        </div>
                    </div>
                </section>
            </article>

            <article class="mainContent__tile">
                <queryDateRangeControl title="Game Metrics" [queryDateRange]="selectedRange" downloadBtnLabel="Export Data" [downloadModalHeader]="'Download User data for ' + getCurrentFacility().name" (queryDatesUpdate)="changeSelectedRange($event)">
                </queryDateRangeControl>
            </article>

            <!--Average Game Activity -->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle title">Average Game Activity</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="graph">
                    <div *ngIf="!gameActivityGraphCurrentUser || !gameActivityGraphCurrentUser[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="gameActivityGraphCurrentUser && !!gameActivityGraphCurrentUser[0]" [scheme]="colorScheme" [results]="gameActivityGraphCurrentUser" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>

                <div class="details">

                </div>
            </article>
            <!--Flight-->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Flight</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="breakdown-area">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <span class="metric">Number of Games Played: </span>
                                <span class="value">{{flightDataCurrentUser.SummaryForEntireTimePeriod? (flightDataCurrentUser.SummaryForEntireTimePeriod.numberOfGamesPlayed | number:'1.0-2') : '0'}}</span>
                            </div>
                            <div class="col">
                                <span class="metric">Total Play Time: </span>
                                <span class="value">{{flightDataCurrentUser.SummaryForEntireTimePeriod? millisToMinutesAndSeconds(flightDataCurrentUser.SummaryForEntireTimePeriod.totalTimePlayed) : '0'}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="metric">Time Per Game: </span>
                                <span class="value">{{flightDataCurrentUser.SummaryForEntireTimePeriod? millisToMinutesAndSeconds(flightDataCurrentUser.SummaryForEntireTimePeriod.averageDurationPerGame) : 'N/A'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="graph graph-large">
                    <div *ngIf="!flightDataCurrentUser.BarGraph || !flightDataCurrentUser.BarGraph[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <div class="row" *ngIf="flightDataCurrentUser.BarGraph && !!flightDataCurrentUser.BarGraph[0]">
                        <ngx-charts-bar-vertical [view]="view" [scheme]="colorScheme" [results]="flightDataCurrentUser.BarGraph" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                        </ngx-charts-bar-vertical>
                    </div>
                    <br><br><br>
                    <div class="row" *ngIf="flightDataCurrentUser.AverageRangeOfMotion && !!flightDataCurrentUser.AverageRangeOfMotion[0]">
                        Average Range of Motion
                        <ngx-charts-line-chart #flightAverageRangeOfMotionChart [view]="view" [scheme]="flightAverageRangeOfMotionColorScheme" [results]="flightDataCurrentUser.AverageRangeOfMotion" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="true" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showRefLabels]="true" [showRefLines]="true" [xAxisLabel]="xAxisLabel" [showGridLines]="true" [yAxisLabel]="'Degrees &deg;'" [timeline]="true" [yScaleMin]="0" [yScaleMax]="180" [autoScale]="autoScale" (select)="onSelect($event)">
                        </ngx-charts-line-chart>
                    </div>
                    <br><br>
                    <div class="row" *ngIf="flightDataCurrentUser.AverageJerk && !!flightDataCurrentUser.AverageJerk[0]">
                        Average Jerk
                        <ngx-charts-line-chart #flightAverageJerkChart [view]="view" [scheme]="flightAverageJerkColorScheme" [results]="flightDataCurrentUser.AverageJerk" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="true" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showRefLabels]="true" [showRefLines]="true" [xAxisLabel]="xAxisLabel" [showGridLines]="true" [yAxisLabel]="''" [yScaleMin]="0" [yScaleMax]="500" [timeline]="true" [autoScale]="autoScale" (select)="onSelect($event)">
                        </ngx-charts-line-chart>
                    </div>
                </div>

                <div class="details" *ngIf="flightDataCurrentUser && flightDataCurrentUser.Breakdown &&
                        flightDataCurrentUser.Breakdown.data && flightDataCurrentUser.Breakdown.data.length > 0">
                    <table mat-table [dataSource]="flightDataCurrentUser.Breakdown" multiTemplateDataRows class="mat-elevation-z0">
                        <ng-container matColumnDef="{{column}}" *ngFor="let column of flightColumns">
                            <th mat-header-cell *matHeaderCellDef class="my-mat-header-cell"> {{column}} </th>
                            <td mat-cell *matCellDef="let element"> {{element[column | lowercase]}} </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="flightColumns.length">
                                <div class="example-element-detail" [@detailExpand]="element == expandedFlightDetailElement ? 'expanded' : 'collapsed'">
                                    <div class="example-element-description container">
                                        <div class="row">
                                            <div class="col container">
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Difficulty Level </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.difficulty}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Game Duration </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.duration}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Animals </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.animals}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Extra Animals </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.extraAnimals}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Animals to Spot </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.animalsToSpot}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col container">
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Animals spotted </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.animalsSpotted}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Average find time </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.averageFindTime? millisToMinutesAndSeconds(element.averageFindTime) : "null"}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Time toward target </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.timeTowardTarget? millisToMinutesAndSeconds(element.timeTowardTarget) : "null"}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Jerk</span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.jerk | number:'1.0-2' || ''}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Range of Motion</span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.rangeOfMotion | number:'1.0-2' || ''}}&deg;
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="flightColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: flightColumns;" class="example-element-row" [class.example-expanded-row]="expandedFlightDetailElement === element" (click)="expandedFlightDetailElement = expandedFlightDetailElement === element ? null : element">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    </table>
                    <mat-paginator #flightPaginator pageSize="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                </div>

            </article>
            <!--Ryan Runs -->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Ryan Runs</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="breakdown-area">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <span class="metric">Number of Games Played: </span>
                                <span class="value">{{ryanRunsDataCurrentUser.SummaryForEntireTimePeriod? (ryanRunsDataCurrentUser.SummaryForEntireTimePeriod.numberOfGamesPlayed | number:'1.0-2') : '0'}}</span>
                            </div>
                            <div class="col">
                                <span class="metric">Win Rate: </span>
                                <span class="value">{{ryanRunsDataCurrentUser.SummaryForEntireTimePeriod? (ryanRunsDataCurrentUser.SummaryForEntireTimePeriod.averageWinRate | number:'1.0-2') : 'N/A'}} %</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="metric">Total Play Time: </span>
                                <span class="value">{{ryanRunsDataCurrentUser.SummaryForEntireTimePeriod? millisToMinutesAndSeconds(ryanRunsDataCurrentUser.SummaryForEntireTimePeriod.totalTimePlayed) : '0'}}</span>
                            </div>
                            <div class="col">
                                <span class="metric">Time Per Game: </span>
                                <span class="value">{{ryanRunsDataCurrentUser.SummaryForEntireTimePeriod? millisToMinutesAndSeconds(ryanRunsDataCurrentUser.SummaryForEntireTimePeriod.averageDurationPerGame) : '0'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div *ngIf="!ryanRunsDataCurrentUser.BarGraph || !ryanRunsDataCurrentUser.BarGraph[0]" class="graph">
                        <div class="loadingData">
                            Sorry, no data in this timeframe
                        </div>
                    </div>
                    <div class="row" *ngIf="ryanRunsDataCurrentUser.BarGraph && !!ryanRunsDataCurrentUser.BarGraph[0]">
                        <ngx-charts-bar-vertical [view]="view" [scheme]="colorScheme" [results]="ryanRunsDataCurrentUser.BarGraph" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                        </ngx-charts-bar-vertical>
                    </div>
                    <div class="row" style="margin-top: 20px" *ngIf="ryanRunsDataCurrentUser.RatioLineGraphs &&
                         !!ryanRunsDataCurrentUser.RatioLineGraphs[0] &&
                         ryanRunsDataCurrentUser.RatioLineGraphs[0].series.length > 0 &&
                         ryanRunsDataCurrentUser.RatioLineGraphs[1].series.length > 0">
                        <ngx-charts-line-chart #ryanRunsLineCharts [view]="view" [scheme]="ryanRunsLineGraphColorScheme" [results]="ryanRunsDataCurrentUser.RatioLineGraphs" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="true" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showRefLabels]="true" [showRefLines]="true" [xAxisLabel]="xAxisLabel" [showGridLines]="true" [yAxisLabel]="'%'" [timeline]="true" [autoScale]="autoScale" [yScaleMin]="0" [yScaleMax]="100" (select)="onSelect($event)">
                        </ngx-charts-line-chart>
                    </div>
                    <!--                    <div class="row" style="margin-top: 20px">-->
                    <!--                        Win Rate-->
                    <!--                        <ngx-charts-line-chart-->
                    <!--                            #ryanRunsWinRate-->
                    <!--                            [view]="view"-->
                    <!--                            [scheme]="WinRateColorScheme"-->
                    <!--                            [results]="ryanRunsDataCurrentUser.WinRate"-->
                    <!--                            [gradient]="gradient"-->
                    <!--                            [xAxis]="showXAxis"-->
                    <!--                            [yAxis]="showYAxis"-->
                    <!--                            [legend]='true'-->
                    <!--                            [showXAxisLabel]="showXAxisLabel"-->
                    <!--                            [showYAxisLabel]='showYAxisLabel'-->
                    <!--                            [showRefLabels]='true'-->
                    <!--                            [showRefLines]='true'-->
                    <!--                            [xAxisLabel]="xAxisLabel"-->
                    <!--                            [showGridLines]='true'-->
                    <!--                            [yAxisLabel]="'%'"-->
                    <!--                            [autoScale]="autoScale"-->
                    <!--                            [yScaleMin]="0"-->
                    <!--                            [yScaleMax]="100"-->
                    <!--                            [timeline]='true'-->
                    <!--                            (select)="onSelect($event)">-->
                    <!--                        </ngx-charts-line-chart>-->
                    <!--                    </div>-->

                    <!--                    <div class="row" style="margin-top: 20px">-->
                    <!--                        Completion Rate-->
                    <!--                        <ngx-charts-line-chart-->
                    <!--                            #ryanRunsWinRate-->
                    <!--                            [view]="view"-->
                    <!--                            [scheme]="WinRateColorScheme"-->
                    <!--                            [results]="ryanRunsDataCurrentUser.CompletionRate"-->
                    <!--                            [gradient]="gradient"-->
                    <!--                            [xAxis]="showXAxis"-->
                    <!--                            [yAxis]="showYAxis"-->
                    <!--                            [legend]='true'-->
                    <!--                            [showXAxisLabel]="showXAxisLabel"-->
                    <!--                            [showYAxisLabel]='showYAxisLabel'-->
                    <!--                            [showRefLabels]='true'-->
                    <!--                            [showRefLines]='true'-->
                    <!--                            [xAxisLabel]="xAxisLabel"-->
                    <!--                            [showGridLines]='true'-->
                    <!--                            [yAxisLabel]="'%'"-->
                    <!--                            [autoScale]="autoScale"-->
                    <!--                            [yScaleMin]="0"-->
                    <!--                            [yScaleMax]="100"-->
                    <!--                            [timeline]='true'-->
                    <!--                            (select)="onSelect($event)">-->
                    <!--                        </ngx-charts-line-chart>-->
                    <!--                    </div>-->
                </div>

                <div class="details" *ngIf="ryanRunsDataCurrentUser && ryanRunsDataCurrentUser.Breakdown &&
                        ryanRunsDataCurrentUser.Breakdown.data && ryanRunsDataCurrentUser.Breakdown.data.length > 0">
                    <table mat-table [dataSource]="ryanRunsDataCurrentUser.Breakdown" multiTemplateDataRows class="mat-elevation-z0">
                        <ng-container matColumnDef="{{column}}" *ngFor="let column of ryanRunsColumns">
                            <th mat-header-cell *matHeaderCellDef class="my-mat-header-cell"> {{column}} </th>
                            <td mat-cell *matCellDef="let element"> {{element[column | lowercase]}} </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="ryanRunsColumns.length">
                                <div class="example-element-detail" [@detailExpand]="element == expandedRyanRunsDetailElement ? 'expanded' : 'collapsed'">
                                    <div class="example-element-description container">
                                        <div class="row">
                                            <div class="col container">
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Difficulty Level </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.difficulty}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Game Duration </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.duration}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Number of Coins Collected </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.coins}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Number of Deaths </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.deaths}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Number of Jumps </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.jumps}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col container">
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Win/Loss </span>
                                                    </div>
                                                    <div class="col">
                                                        <span *ngIf="element.win; then thenWin else elseLose"></span>
                                                        <ng-template #thenWin>Player Win</ng-template>
                                                        <ng-template #elseLose>Player Loss.</ng-template>
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Coins Per Second </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.coinsPerSecond | number:'1.0-2' || ''}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Coins to Jumps Ratio </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.coinsToJumpsRatio | number:'1.0-2' || ''}}%
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Jump Failure Rate</span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.jumpFailureRate | number:'1.0-2' || ''}}%
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Game Completed </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.finishedGame}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="ryanRunsColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: ryanRunsColumns;" class="example-element-row" [class.example-expanded-row]="expandedRyanRunsDetailElement === element" (click)="expandedRyanRunsDetailElement = expandedRyanRunsDetailElement === element ? null : element">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    </table>
                    <mat-paginator #ryanRunsPaginator pageSize="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                </div>

            </article>
            <!--Tic Tac Toe -->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Tic-Tac-Toe</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="breakdown-area">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <span class="metric">Number of Games Played: </span>
                                <span class="value">{{ticTacToeDataCurrentUser.SummaryForEntireTimePeriod? (ticTacToeDataCurrentUser.SummaryForEntireTimePeriod.numberOfGamesPlayed | number:'1.0-2') : '0'}}</span>
                            </div>
                            <div class="col">
                                <span class="metric">Win Rate: </span>
                                <span class="value">{{ticTacToeDataCurrentUser.SummaryForEntireTimePeriod? (ticTacToeDataCurrentUser.SummaryForEntireTimePeriod.averageWinRate | number:'1.0-2') : 'N/A'}} %</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="metric">Total Play Time: </span>
                                <span class="value">{{ticTacToeDataCurrentUser.SummaryForEntireTimePeriod? millisToMinutesAndSeconds(ticTacToeDataCurrentUser.SummaryForEntireTimePeriod.totalTimePlayed) : '0'}}</span>
                            </div>
                            <div class="col">
                                <span class="metric">Time Per Game: </span>
                                <span class="value">{{ticTacToeDataCurrentUser.SummaryForEntireTimePeriod? millisToMinutesAndSeconds(ticTacToeDataCurrentUser.SummaryForEntireTimePeriod.averageDurationPerGame) : '0'}}</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="graph">
                    <div *ngIf="!ticTacToeDataCurrentUser.BarGraph || !ticTacToeDataCurrentUser.BarGraph[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="ticTacToeDataCurrentUser.BarGraph && !!ticTacToeDataCurrentUser.BarGraph[0]" [scheme]="colorScheme" [results]="ticTacToeDataCurrentUser.BarGraph" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>

                <div class="details" *ngIf="ticTacToeDataCurrentUser && ticTacToeDataCurrentUser.Breakdown &&
                        ticTacToeDataCurrentUser.Breakdown.data && ticTacToeDataCurrentUser.Breakdown.data.length > 0">
                    <table mat-table [dataSource]="ticTacToeDataCurrentUser.Breakdown" multiTemplateDataRows class="mat-elevation-z0">
                        <ng-container matColumnDef="{{column}}" *ngFor="let column of ticTacToeColumns">
                            <th mat-header-cell *matHeaderCellDef class="my-mat-header-cell"> {{column}} </th>
                            <td mat-cell *matCellDef="let element"> {{element[column | lowercase]}} </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="ticTacToeColumns.length">
                                <div class="example-element-detail" [@detailExpand]="element == expandedTicTacToeDetailElement ? 'expanded' : 'collapsed'">
                                    <div class="example-element-description container">
                                        <div class="row">
                                            <div class="col container">
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Difficulty Level </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.difficulty}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Game Duration </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.duration}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Time per turn </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.timePerMove? millisToMinutesAndSeconds(element.timePerMove) : "null"}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Game Completed </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.finishedGame}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col container">
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Win/Loss </span>
                                                    </div>
                                                    <div class="col">
                                                        <span *ngIf="element.win; then thenWin else elseLose"></span>
                                                        <ng-template #thenWin>Player Win</ng-template>
                                                        <ng-template #elseLose>Robot Win.</ng-template>
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Player Starts first </span>
                                                    </div>
                                                    <div class="col">
                                                        <span *ngIf="element.playerStartFirst; then thenStarter else elseStarter"></span>
                                                        <ng-template #thenStarter>{{getCurrentUser()?.anonymizedName || ''}}</ng-template>
                                                        <ng-template #elseStarter>Robot</ng-template>
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> ML metrics </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.difficultyAIOffset}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="ticTacToeColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: ticTacToeColumns;" class="example-element-row" [class.example-expanded-row]="expandedTicTacToeDetailElement === element" (click)="expandedTicTacToeDetailElement = expandedTicTacToeDetailElement === element ? null : element">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    </table>
                    <mat-paginator #ticTacToePaginator pageSize="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                </div>

            </article>
            <!--Flow Game -->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">FlowGame</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="breakdown-area">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <span class="metric">Number of Games Played: </span>
                                <span class="value">{{flowGameDataCurrentUser.SummaryForEntireTimePeriod? (flowGameDataCurrentUser.SummaryForEntireTimePeriod.numberOfGamesPlayed | number:'1.0-2') : '0'}}</span>
                            </div>
                            <div class="col">
                                <span class="metric">Win Rate: </span>
                                <span class="value">{{flowGameDataCurrentUser.SummaryForEntireTimePeriod? (flowGameDataCurrentUser.SummaryForEntireTimePeriod.averageWinRate | number:'1.0-2') : 'N/A'}} %</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="metric">Total Play Time: </span>
                                <span class="value">{{flowGameDataCurrentUser.SummaryForEntireTimePeriod? millisToMinutesAndSeconds(flowGameDataCurrentUser.SummaryForEntireTimePeriod.totalTimePlayed) : '0'}}</span>
                            </div>
                            <div class="col">
                                <span class="metric">Time Per Game: </span>
                                <span class="value">{{flowGameDataCurrentUser.SummaryForEntireTimePeriod? millisToMinutesAndSeconds(flowGameDataCurrentUser.SummaryForEntireTimePeriod.averageDurationPerGame) : '0'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="graph">
                    <div *ngIf="!flowGameDataCurrentUser.BarGraph || !flowGameDataCurrentUser.BarGraph[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="flowGameDataCurrentUser.BarGraph && !!flowGameDataCurrentUser.BarGraph[0]" [scheme]="colorScheme" [results]="flowGameDataCurrentUser.BarGraph" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>

                <div class="details" *ngIf="flowGameDataCurrentUser && flowGameDataCurrentUser.Breakdown &&
                        flowGameDataCurrentUser.Breakdown.data && flowGameDataCurrentUser.Breakdown.data.length > 0">
                    <table mat-table [dataSource]="flowGameDataCurrentUser.Breakdown" multiTemplateDataRows class="mat-elevation-z0">
                        <ng-container matColumnDef="{{column}}" *ngFor="let column of flowGameColumns">
                            <th mat-header-cell *matHeaderCellDef class="my-mat-header-cell"> {{column}} </th>
                            <td mat-cell *matCellDef="let element"> {{element[column | lowercase]}} </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="flowGameColumns.length">
                                <div class="example-element-detail" [@detailExpand]="element == expandedFlowGameDetailElement ? 'expanded' : 'collapsed'">
                                    <div class="example-element-description container">
                                        <div class="row">
                                            <div class="col container">
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Difficulty Level </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.difficulty}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Game Duration </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.duration}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Time per turn </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.timePerMove? millisToMinutesAndSeconds(element.timePerMove) : "null"}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Number of Correct Lines </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.correctLines}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Number of Incorrect Lines </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.incorrectLines}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col container">
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Win/Loss </span>
                                                    </div>
                                                    <div class="col">
                                                        <span *ngIf="element.win; then thenWin else elseLose"></span>
                                                        <ng-template #thenWin>Player Win</ng-template>
                                                        <ng-template #elseLose>Player Loss.</ng-template>
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Color Blind Mode </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.colorBlindMode}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Number of Moves </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.numberOfPlayerMoves}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Number of Hints Used </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.numberOfRyanMoves}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Game Completed </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.finishedGame}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="flowGameColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: flowGameColumns;" class="example-element-row" [class.example-expanded-row]="expandedFlowGameDetailElement === element" (click)="expandedFlowGameDetailElement = expandedFlowGameDetailElement === element ? null : element">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    </table>
                    <mat-paginator #flowGamePaginator pageSize="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                </div>

            </article>
            <!--Bird Counting-->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Bird Counting</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="breakdown-area">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <span class="metric">Number of Games Played: </span>
                                <span class="value">{{birdCountingDataCurrentUser.SummaryForEntireTimePeriod? (birdCountingDataCurrentUser.SummaryForEntireTimePeriod.numberOfGamesPlayed | number:'1.0-2') : '0'}}</span>
                            </div>
                            <div class="col">
                                <span class="metric">Win Rate: </span>
                                <span class="value">{{birdCountingDataCurrentUser.SummaryForEntireTimePeriod? (birdCountingDataCurrentUser.SummaryForEntireTimePeriod.averageWinRate | number:'1.0-2') : 'N/A'}} %</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="metric">Total Play Time: </span>
                                <span class="value">{{birdCountingDataCurrentUser.SummaryForEntireTimePeriod? millisToMinutesAndSeconds(birdCountingDataCurrentUser.SummaryForEntireTimePeriod.totalTimePlayed) : '0'}}</span>
                            </div>
                            <div class="col">
                                <span class="metric">Time Per Game: </span>
                                <span class="value">{{birdCountingDataCurrentUser.SummaryForEntireTimePeriod? millisToMinutesAndSeconds(birdCountingDataCurrentUser.SummaryForEntireTimePeriod.averageDurationPerGame) : '0'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="graph">
                    <div *ngIf="!birdCountingDataCurrentUser.BarGraph || !birdCountingDataCurrentUser.BarGraph[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="birdCountingDataCurrentUser.BarGraph && !!birdCountingDataCurrentUser.BarGraph[0]" [scheme]="colorScheme" [results]="birdCountingDataCurrentUser.BarGraph" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>

                <div class="details" *ngIf="birdCountingDataCurrentUser && birdCountingDataCurrentUser.Breakdown &&
                        birdCountingDataCurrentUser.Breakdown.data && birdCountingDataCurrentUser.Breakdown.data.length > 0">
                    <table mat-table [dataSource]="birdCountingDataCurrentUser.Breakdown" multiTemplateDataRows class="mat-elevation-z0">
                        <ng-container matColumnDef="{{column}}" *ngFor="let column of birdCountingColumns">
                            <th mat-header-cell *matHeaderCellDef class="my-mat-header-cell"> {{column}} </th>
                            <td mat-cell *matCellDef="let element"> {{element[column | lowercase]}} </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="birdCountingColumns.length">
                                <div class="example-element-detail" [@detailExpand]="element == expandedBirdCountingDetailElement ? 'expanded' : 'collapsed'">
                                    <div class="example-element-description container">
                                        <div class="row">
                                            <div class="col container">
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Difficulty Level </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.difficulty}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Game Duration </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.duration}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Game Completed </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.finishedGame}}
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col container">
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Win/Loss </span>
                                                    </div>
                                                    <div class="col">
                                                        <span *ngIf="element.win; then thenWin else elseLose"></span>
                                                        <ng-template #thenWin>Player Win</ng-template>
                                                        <ng-template #elseLose>Player Loss.</ng-template>
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Time per turn </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.timePerMove? millisToMinutesAndSeconds(element.timePerMove) : "null"}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="birdCountingColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: birdCountingColumns;" class="example-element-row" [class.example-expanded-row]="expandedBirdCountingDetailElement === element" (click)="expandedBirdCountingDetailElement = expandedBirdCountingDetailElement === element ? null : element">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    </table>
                    <mat-paginator #birdCountingPaginator pageSize="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                </div>

            </article>
            <!--Picture Puzzle-->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Picture Puzzle</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="breakdown-area">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <span class="metric">Number of Games Played: </span>
                                <span class="value">{{picturePuzzleDataCurrentUser.SummaryForEntireTimePeriod? (picturePuzzleDataCurrentUser.SummaryForEntireTimePeriod.numberOfGamesPlayed | number:'1.0-2') : '0'}}</span>
                            </div>
                            <div class="col">
                                <span class="metric">Win Rate: </span>
                                <span class="value">{{picturePuzzleDataCurrentUser.SummaryForEntireTimePeriod? (picturePuzzleDataCurrentUser.SummaryForEntireTimePeriod.averageWinRate | number:'1.0-2') : 'N/A'}} %</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="metric">Total Play Time: </span>
                                <span class="value">{{picturePuzzleDataCurrentUser.SummaryForEntireTimePeriod? millisToMinutesAndSeconds(picturePuzzleDataCurrentUser.SummaryForEntireTimePeriod.totalTimePlayed) : '0'}}</span>
                            </div>
                            <div class="col">
                                <span class="metric">Time Per Game: </span>
                                <span class="value">{{picturePuzzleDataCurrentUser.SummaryForEntireTimePeriod? millisToMinutesAndSeconds(picturePuzzleDataCurrentUser.SummaryForEntireTimePeriod.averageDurationPerGame) : '0'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="graph">
                    <div *ngIf="!picturePuzzleDataCurrentUser.BarGraph || !picturePuzzleDataCurrentUser.BarGraph[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="picturePuzzleDataCurrentUser.BarGraph && !!picturePuzzleDataCurrentUser.BarGraph[0]" [scheme]="colorScheme" [results]="picturePuzzleDataCurrentUser.BarGraph" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>

                <div class="details" *ngIf="picturePuzzleDataCurrentUser && picturePuzzleDataCurrentUser.Breakdown &&
                        picturePuzzleDataCurrentUser.Breakdown.data && picturePuzzleDataCurrentUser.Breakdown.data.length > 0">
                    <table mat-table [dataSource]="picturePuzzleDataCurrentUser.Breakdown" multiTemplateDataRows class="mat-elevation-z0">
                        <ng-container matColumnDef="{{column}}" *ngFor="let column of picturePuzzleColumns">
                            <th mat-header-cell *matHeaderCellDef class="my-mat-header-cell"> {{column}} </th>
                            <td mat-cell *matCellDef="let element"> {{element[column | lowercase]}} </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="picturePuzzleColumns.length">
                                <div class="example-element-detail" [@detailExpand]="element == expandedPicturePuzzleDetailElement ? 'expanded' : 'collapsed'">
                                    <div class="example-element-description container">
                                        <div class="row">
                                            <div class="col container">
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Difficulty </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.difficulty}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Game Duration </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.duration}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Correct Placements </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.correctPlacements}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Incorrect Placements </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.incorrectPlacements}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Game Completed </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.finishedGame}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Genre</span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.genrePlayed}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col container">
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Win/Loss </span>
                                                    </div>
                                                    <div class="col">
                                                        <span *ngIf="element.win; then thenWin else elseLose"></span>
                                                        <ng-template #thenWin>Player Win</ng-template>
                                                        <ng-template #elseLose>Player Loss.</ng-template>
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Time per turn </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.timePerMove? millisToMinutesAndSeconds(element.timePerMove) : "null"}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Puzzle </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.puzzleId}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Hints Used </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.numberOfHintsUsed}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Player Rating</span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.ratingGiven}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="picturePuzzleColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: picturePuzzleColumns;" class="example-element-row" [class.example-expanded-row]="expandedPicturePuzzleDetailElement === element" (click)="expandedPicturePuzzleDetailElement = expandedPicturePuzzleDetailElement === element ? null : element">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    </table>
                    <mat-paginator #picturePuzzlePaginator pageSize="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                </div>

            </article>
            <!--Word Puzzle -->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Word Puzzle</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="breakdown-area">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <span class="metric">Number of Games Played: </span>
                                <span class="value">{{wordPuzzleDataCurrentUser.SummaryForEntireTimePeriod? (wordPuzzleDataCurrentUser.SummaryForEntireTimePeriod.numberOfGamesPlayed | number:'1.0-2') : '0'}}</span>
                            </div>
                            <div class="col">
                                <span class="metric">Win Rate: </span>
                                <span class="value">{{wordPuzzleDataCurrentUser.SummaryForEntireTimePeriod? (wordPuzzleDataCurrentUser.SummaryForEntireTimePeriod.averageWinRate | number:'1.0-2') : 'N/A'}} %</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="metric">Total Play Time: </span>
                                <span class="value">{{wordPuzzleDataCurrentUser.SummaryForEntireTimePeriod? millisToMinutesAndSeconds(wordPuzzleDataCurrentUser.SummaryForEntireTimePeriod.totalTimePlayed) : '0'}}</span>
                            </div>
                            <div class="col">
                                <span class="metric">Time Per Game: </span>
                                <span class="value">{{wordPuzzleDataCurrentUser.SummaryForEntireTimePeriod? millisToMinutesAndSeconds(wordPuzzleDataCurrentUser.SummaryForEntireTimePeriod.averageDurationPerGame) : '0'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="graph">
                    <div *ngIf="!wordPuzzleDataCurrentUser.BarGraph || !wordPuzzleDataCurrentUser.BarGraph[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="wordPuzzleDataCurrentUser.BarGraph && !!wordPuzzleDataCurrentUser.BarGraph[0]" [scheme]="colorScheme" [results]="wordPuzzleDataCurrentUser.BarGraph" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>

                <div class="details" *ngIf="wordPuzzleDataCurrentUser && wordPuzzleDataCurrentUser.Breakdown &&
                        wordPuzzleDataCurrentUser.Breakdown.data && wordPuzzleDataCurrentUser.Breakdown.data.length > 0">
                    <table mat-table [dataSource]="wordPuzzleDataCurrentUser.Breakdown" multiTemplateDataRows class="mat-elevation-z0">
                        <ng-container matColumnDef="{{column}}" *ngFor="let column of wordPuzzleColumns">
                            <th mat-header-cell *matHeaderCellDef class="my-mat-header-cell"> {{column}} </th>
                            <td mat-cell *matCellDef="let element"> {{element[column | lowercase]}} </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="wordPuzzleColumns.length">
                                <div class="example-element-detail" [@detailExpand]="element == expandedWordPuzzleDetailElement ? 'expanded' : 'collapsed'">
                                    <div class="example-element-description container">
                                        <div class="row">
                                            <div class="col container">
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Difficulty </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.difficulty}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Game Duration </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.duration}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Correct Guesses </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.correctGuesses}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Incorrect Guesses </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.incorrectGuesses}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Game Completed </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.finishedGame}}
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col container">
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Win/Loss </span>
                                                    </div>
                                                    <div class="col">
                                                        <span *ngIf="element.win; then thenWin else elseLose"></span>
                                                        <ng-template #thenWin>Player Win</ng-template>
                                                        <ng-template #elseLose>Player Loss.</ng-template>
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Time per turn </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.timePerMove? millisToMinutesAndSeconds(element.timePerMove) : "null"}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Correct Word </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.word}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Hints Used </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.numberOfHintsUsed}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Player moves</span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.numberOfTotalMoves}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="wordPuzzleColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: wordPuzzleColumns;" class="example-element-row" [class.example-expanded-row]="expandedWordPuzzleDetailElement === element" (click)="expandedWordPuzzleDetailElement = expandedWordPuzzleDetailElement === element ? null : element">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    </table>
                    <mat-paginator #wordPuzzlePaginator pageSize="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                </div>

            </article>
            <!--Checkers-->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Checkers</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="breakdown-area">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <span class="metric">Number of Games Played: </span>
                                <span class="value">{{checkersDataCurrentUser.SummaryForEntireTimePeriod? (checkersDataCurrentUser.SummaryForEntireTimePeriod.numberOfGamesPlayed | number:'1.0-2') : '0'}}</span>
                            </div>
                            <div class="col">
                                <span class="metric">Win Rate: </span>
                                <span class="value">{{checkersDataCurrentUser.SummaryForEntireTimePeriod? (checkersDataCurrentUser.SummaryForEntireTimePeriod.averageWinRate | number:'1.0-2') : 'N/A'}} %</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="metric">Total Play Time: </span>
                                <span class="value">{{checkersDataCurrentUser.SummaryForEntireTimePeriod? millisToMinutesAndSeconds(checkersDataCurrentUser.SummaryForEntireTimePeriod.totalTimePlayed) : '0'}}</span>
                            </div>
                            <div class="col">
                                <span class="metric">Time Per Game: </span>
                                <span class="value">{{checkersDataCurrentUser.SummaryForEntireTimePeriod? millisToMinutesAndSeconds(checkersDataCurrentUser.SummaryForEntireTimePeriod.averageDurationPerGame) : '0'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="graph">
                    <div *ngIf="!checkersDataCurrentUser.BarGraph || !checkersDataCurrentUser.BarGraph[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="checkersDataCurrentUser.BarGraph && !!checkersDataCurrentUser.BarGraph[0]" [scheme]="colorScheme" [results]="checkersDataCurrentUser.BarGraph" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>

                <div class="details" *ngIf="checkersDataCurrentUser && checkersDataCurrentUser.Breakdown &&
                        checkersDataCurrentUser.Breakdown.data && checkersDataCurrentUser.Breakdown.data.length > 0">
                    <table mat-table [dataSource]="checkersDataCurrentUser.Breakdown" multiTemplateDataRows class="mat-elevation-z0">
                        <ng-container matColumnDef="{{column}}" *ngFor="let column of checkersColumns">
                            <th mat-header-cell *matHeaderCellDef class="my-mat-header-cell"> {{column}} </th>
                            <td mat-cell *matCellDef="let element"> {{element[column | lowercase]}} </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="checkersColumns.length">
                                <div class="example-element-detail" [@detailExpand]="element == expandedCheckersDetailElement ? 'expanded' : 'collapsed'">
                                    <div class="example-element-description container">
                                        <div class="row">
                                            <div class="col container">
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Difficulty </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.difficulty}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Game Duration </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.duration}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Player Color </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.playerColor}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Game Completed </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.finishedGame}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col container">
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Win/Loss </span>
                                                    </div>
                                                    <div class="col">
                                                        <span *ngIf="element.win; then thenWin else elseLose"></span>
                                                        <ng-template #thenWin>Player Win</ng-template>
                                                        <ng-template #elseLose>Player Loss.</ng-template>
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Time per turn </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.timePerMove? millisToMinutesAndSeconds(element.timePerMove) : "null"}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Checkers Variety </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.checkersVariety}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="checkersColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: checkersColumns;" class="example-element-row" [class.example-expanded-row]="expandedCheckersDetailElement === element" (click)="expandedCheckersDetailElement = expandedCheckersDetailElement === element ? null : element">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    </table>
                    <mat-paginator #checkersPaginator pageSize="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                </div>

            </article>
            <!--Chess -->
            <article class="graph-area rowMain">
                <div class="header">
                    <h4 class="graphTitle">Chess</h4>
                    <granularity-select class="time-select" [selectedGranularity]="selectedGranularity" [granularityOptions]="granularityOptions" (granularityChange)="changeGranularity($event)">
                    </granularity-select>
                </div>
                <div class="breakdown-area">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <span class="metric">Number of Games Played: </span>
                                <span class="value">{{chessDataCurrentUser.SummaryForEntireTimePeriod? (chessDataCurrentUser.SummaryForEntireTimePeriod.numberOfGamesPlayed | number:'1.0-2') : '0'}}</span>
                            </div>
                            <div class="col">
                                <span class="metric">Win Rate: </span>
                                <span class="value">{{chessDataCurrentUser.SummaryForEntireTimePeriod? (chessDataCurrentUser.SummaryForEntireTimePeriod.averageWinRate | number:'1.0-2') : 'N/A'}} %</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="metric">Total Play Time: </span>
                                <span class="value">{{chessDataCurrentUser.SummaryForEntireTimePeriod? millisToMinutesAndSeconds(chessDataCurrentUser.SummaryForEntireTimePeriod.totalTimePlayed) : '0'}}</span>
                            </div>
                            <div class="col">
                                <span class="metric">Time Per Game: </span>
                                <span class="value">{{chessDataCurrentUser.SummaryForEntireTimePeriod? millisToMinutesAndSeconds(chessDataCurrentUser.SummaryForEntireTimePeriod.averageDurationPerGame) : '0'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="graph">
                    <div *ngIf="!chessDataCurrentUser.BarGraph || !chessDataCurrentUser.BarGraph[0]" class="loadingData">
                        Sorry, no data in this timeframe
                    </div>
                    <ngx-charts-bar-vertical *ngIf="chessDataCurrentUser.BarGraph && !!chessDataCurrentUser.BarGraph[0]" [scheme]="colorScheme" [results]="chessDataCurrentUser.BarGraph" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [roundDomains]="roundDomains" [schemeType]="schemeType" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>

                <div class="details" *ngIf="chessDataCurrentUser && chessDataCurrentUser.Breakdown &&
                        chessDataCurrentUser.Breakdown.data && chessDataCurrentUser.Breakdown.data.length > 0">
                    <table mat-table [dataSource]="chessDataCurrentUser.Breakdown" multiTemplateDataRows class="mat-elevation-z0">
                        <ng-container matColumnDef="{{column}}" *ngFor="let column of chessColumns">
                            <th mat-header-cell *matHeaderCellDef class="my-mat-header-cell"> {{column}} </th>
                            <td mat-cell *matCellDef="let element"> {{element[column | lowercase]}} </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="chessColumns.length">
                                <div class="example-element-detail" [@detailExpand]="element == expandedChessDetailElement ? 'expanded' : 'collapsed'">
                                    <div class="example-element-description container">
                                        <div class="row">
                                            <div class="col container">
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Difficulty </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.difficulty}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Game Duration </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.duration}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Player Color </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.playerColor}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Game Completed </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.finishedGame}}
                                                    </div>
                                                </div>


                                            </div>
                                            <div class="col container">
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Win/Loss </span>
                                                    </div>
                                                    <div class="col">
                                                        <span *ngIf="element.win; then thenWin else elseLose"></span>
                                                        <ng-template #thenWin>Player Win</ng-template>
                                                        <ng-template #elseLose>Player Loss.</ng-template>
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Time per turn </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.timePerMove? millisToMinutesAndSeconds(element.timePerMove) : "null"}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header"> Stalemate </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.staleMate}}
                                                    </div>
                                                </div>
                                                <div class="row element-detail-row">
                                                    <div class="col">
                                                        <span class="detail-header">Number of Pieces Captured </span>
                                                    </div>
                                                    <div class="col">
                                                        {{element.piecesCaptured}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="chessColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: chessColumns;" class="example-element-row" [class.example-expanded-row]="expandedChessDetailElement === element" (click)="expandedChessDetailElement = expandedChessDetailElement === element ? null : element">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    </table>
                    <mat-paginator #chessPaginator pageSize="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                </div>
            </article>
        </ng-container>
    </div>
    <div class="rightPanel" *ngIf="allFacilities && focusedFacilityId">
        <div class="usageData">
            <div class="infoHeader">
                <h6>Game Usage</h6>
            </div>
            <div class="graphSection">
                <ngx-charts-bar-horizontal *ngIf="gameUsageData" [scheme]="usageGraphColorScheme" [results]="gameUsageData" [gradient]="false" [xAxis]="false" [yAxis]="true" [legend]="false" [showXAxisLabel]="false" [showYAxisLabel]="false" [barPadding]="15" [showDataLabel]="false" [dataLabelFormatting]="formatUsageDataLabel" (select)="onSelect($event)">
                    <ng-template #tooltipTemplate let-model="model">
                        <h2>{{ model.value / 60000.0 | number:'1.0-0'}} min</h2>
                    </ng-template>
                </ngx-charts-bar-horizontal>
            </div>
        </div>
        <userlistpanel [users]="getUsersInCareForFacility()" [title]="'Residents'" [renderedBy]="'caregiver/gamemetrics'" [clickCb]="focusOnUser.bind(this)"></userlistpanel>

        <div class="queryDateRangeHelper">
            <panel-date-range-display [queryDateRange]="selectedRange"></panel-date-range-display>
        </div>
    </div>
</div>
