import { Component } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Router, ActivatedRoute } from '@angular/router';

export interface passwordResetBody {
    email: String,
    password: String,
};

@Component({
    selector: 'password-reset',
    templateUrl: './password-reset.html',
    styleUrls: ['./password-reset.scss']
})
export class PasswordResetComponent {
    email: String;
    password: String;
    repeatPassword: String;
    routeParams: {
        userId?: string,
        token?: string,
    };
    alert = {
        show: false,
        type: '',
        message: '',
    }
    static parameters = [HttpClient, Router, ActivatedRoute];
    constructor(public http: HttpClient, public router: Router, public activatedRoute: ActivatedRoute) {
        this.http = http;
        this.router = router;
        this.activatedRoute = activatedRoute;
    }
    ngOnInit() {
        const routeParams = this.activatedRoute.snapshot.paramMap;
        const token = String(routeParams.get('token'));
        const userId = String(routeParams.get('userId'));
        this.routeParams = {
            token,
            userId,
        }
        this.email = userId
    }

    handleSubmit(form) {
        if (this.password !== this.repeatPassword) {
            this.alert = {
                show: true,
                message: 'Passwords do not match.',
                type: 'danger',
            }
            return;
        }
        if (!form.valid) {
            this.alert = {
                show: true,
                message: 'Please fill all fields.',
                type: 'danger',
            }
            return;
        }
        this.alert = {
            show: false,
            type: '',
            message: '',
        }
        this.http.put(`/api/users/passwordreset/${this.email}/${this.routeParams.token}`, {
            email: this.email,
            password: this.password,
        }).subscribe({
            next: (data: { message: string }) => {
                console.log(data);
                let message = 'Password reset successfully. Please login.';
                if (data && data.message) message = data.message;
                this.alert = {
                    show: true,
                    message: String(message),
                    type: 'info',
                }
                this.router.navigateByUrl('/account/login?alert=passwordResetSuccess')
            },
            error: (error: HttpErrorResponse) => {
                let message = 'Error';
                if (error.statusText) message = error.statusText;
                if (error.error && error.error.message) message = error.error.message;
                if (error && error.status) {
                    this.alert = {
                        show: true,
                        message: String(message),
                        type: 'danger',
                    }
                }
            },
        })
    }
}
