import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import { Router } from '@angular/router';
import { UserService, UserType } from '../../../components/auth/user.service';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { OrganizationService } from '../../../services/organization/organization.service';
import { InventoryService } from '../../../services/inventory/inventory.service';
import { CalendarEventService } from '../../../services/calendar-event/calendar-event.service';

@Component({
    selector: 'principleInvestigator-dashboard',
    templateUrl: './PI-Dashboard.html',
    styleUrls: ['../../../assets/sharedStyles/pages/dashboard.scss'],
})


export class PrincipleInvestigatorDashboardComponent implements OnInit, OnDestroy {
    currentUser;

    icon = {
        bell: faBell,
        userCircle: faUserCircle,
        search: faSearch
    };

    static parameters = [Router, UserService, OrganizationService,
        InventoryService, CalendarEventService];

    constructor(public router: Router, public userService: UserService, public organizationService: OrganizationService,
                 public eventService: CalendarEventService) {
        //get the current user from the token
        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserType;
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void { }
}
