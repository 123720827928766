import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatTableModule } from '@angular/material/table';

import { MarkAsteriskDirectiveModule } from './directives/mark-asterisk.directive';

import { HeaderComponent } from './header/header.component';
import { LeftNavComponent } from './leftsidenav/leftNav.component';
import { LineGraphComponent } from './graphs/line-graph.component';
import { UserListPanelComponent } from './userlistpanel/userlistpanel.component';
import { OrgListPanelComponent } from './orglistpanel/orglistpanel.component';

import { RobotIconComponent } from '../assets/icons/robot-icon/robot-icon.component';
import { OrgIconComponent } from '../assets/icons/org-icon/org-icon.component';
import { RefreshIconComponent } from '../assets/icons/refresh-icon/refresh-icon.component';
import { RyanLogoComponent } from '../assets/icons/ryan-logo/ryan-logo.component';
import { LogoutIconComponent } from '../assets/icons/logout-icon/logout-icon.component';
import { FacilityIconComponent } from '../assets/icons/facility-icon/facility-icon.component';
import { QueryDateRangeControlComponent } from './queryDateRangeControl/queryDateRangeControl.component';
import { DownloadDataComponent } from './downloadData/downloadData.component';
import { DownloadDataModalComponent } from './downloadData/downloadDataModal/downloadDataModal.component';
import { GranularitySelectComponent } from './granularitySelect/granularitySelect.component';

import { FilterUsersPipe } from './pipes/filterUsers.pipe';
import { FilterOrgsPipe } from './pipes/filterOrgs.pipe';
import { FilterAnonUsersPipe } from './pipes/filterAnonUsers.pipe';
import { FormatRolePipe } from './pipes/formatRole.pipe';
import { FormatEntityTypeNamePipe } from './pipes/formatEntityTypeName.pipe';
import {SearchAnonymous} from './search-anonymous/search-anonymous.component';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {PanelDateRangeDisplayComponent} from './queryDateRangeControl/panelDateRangeDisplay/panelDateRangeDisplay.component';

@NgModule({
    imports: [
        CommonModule,
        CollapseModule,
        RouterModule,
        AccordionModule,
        MatExpansionModule,
        FontAwesomeModule,
        MatIconModule,
        CdkTreeModule,
        MatButtonModule,
        BsDropdownModule,
        BsDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
        NgxChartsModule,
        MatTableModule,
        AlertModule,
        ProgressbarModule,
        MarkAsteriskDirectiveModule,
        ButtonsModule,
    ],
    declarations: [
        HeaderComponent,
        LeftNavComponent,
        UserListPanelComponent,
        LineGraphComponent,
        FilterUsersPipe,
        FilterAnonUsersPipe,
        FilterOrgsPipe,
        FormatRolePipe,
        FormatEntityTypeNamePipe,
        OrgListPanelComponent,
        RobotIconComponent,
        OrgIconComponent,
        RefreshIconComponent,
        RyanLogoComponent,
        LogoutIconComponent,
        FacilityIconComponent,
        QueryDateRangeControlComponent,
        PanelDateRangeDisplayComponent,
        DownloadDataComponent,
        DownloadDataModalComponent,
        SearchAnonymous,
        GranularitySelectComponent,
    ],
    exports: [
        HeaderComponent,
        LeftNavComponent,
        UserListPanelComponent,
        LineGraphComponent,
        FilterUsersPipe,
        FilterAnonUsersPipe,
        FilterOrgsPipe,
        RobotIconComponent,
        OrgIconComponent,
        RefreshIconComponent,
        RyanLogoComponent,
        LogoutIconComponent,
        FacilityIconComponent,
        QueryDateRangeControlComponent,
        PanelDateRangeDisplayComponent,
        DownloadDataComponent,
        DownloadDataModalComponent,
        GranularitySelectComponent,
        SearchAnonymous,
        FormatRolePipe,
        FormatEntityTypeNamePipe,
    ],
    bootstrap: [
        DownloadDataModalComponent
    ]
})
export class DirectivesModule { }
