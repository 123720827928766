<div class="page">

    <!--  ======  Loading view  ======  -->
    <div *ngIf="(!loaded || !isLoaded()) && !errOccurred">
        Loading...
    </div>

    <!--  ======  Error view  ======  -->
    <alert type="danger" *ngIf="errOccurred">
        <strong>Whoops!</strong> We're sorry, an error occurred trying to load this page.
    </alert>


    <div class="mainContent" *ngIf="(isLoaded() || loaded)">
        <nav class="breadcrumb breadcrumbs" aria-label="breadcrumb">
            <ol class="breadcrumbs__list">
    <!--                Not focused on facility-->
                <li class="breadcrumb-item breadcrumbItem" [attr.aria-current]="(!focusedFacilityId) ? 'page' : null" (click)="focusOnFacility()">
                    Users Activities
                </li>
    <!--                Focused on facility-->
                <li class="breadcrumb-item breadcrumbItem" *ngIf="focusedFacilityId && !!currentFacility" [attr.aria-current]="(!!focusedFacilityId && !!currentFacility) ? 'page' : null" (click)="this.focusOnUser()">
                    {{currentFacility.name || 'n/a'}}
                </li>
    <!--                Focused on facility AND user -->
                <li class="breadcrumb-item breadcrumbItem" *ngIf="focusedFacilityId && !!currentFacility && focusedUserId && !!focusedUser" [attr.aria-current]="(focusedFacilityId && !!currentFacility && focusedUserId && !!focusedUser) ? 'page' : null">
                    {{focusedUser.firstName + ' ' + focusedUser.lastName || 'n/a'}}
                </li>
            </ol>
        </nav>

        <!--  ======  Select Facility  ======  -->
        <ng-container *ngIf="!focusedFacilityId">
            <h4>Please select a Facility</h4>
            <div class="facilityList">
                <article class="facilityArea blueOnHover" *ngFor="let facility of allFacilities" (click)="focusOnFacility(facility._id)">
                    <div class="header">
                        <div class="text-area">
                            <h3 class="facilityName">{{facility?.name || "Loading Name"}}</h3>
                            <address class="facilityAddr">
                                {{facility.address || ''}} <span *ngIf="facility.city">, </span>
                                {{facility.city || ''}}<span *ngIf="facility.state">, </span>{{facility.state || ''}} {{facility.zip || ''}}
                            </address>
                        </div>
                    </div>
                    <div class="facilityInfoCount">
                        <h6>Administrators: <span>({{facility?.administrators?.length || 0}})</span></h6>
                        <h6>Users: <span>({{facility?.users?.length || 0}})</span></h6>
                    </div>
                </article>
            </div>
        </ng-container>

        <!--  ======  Focused on Facility, but NOT focused on User  ======  -->
        <ng-container *ngIf="!!focusedFacilityId && !focusedUserId && (loaded || isLoaded())">
            <div class="mainContent">
                <article class="mainContent__tile">
                    <queryDateRangeControl [title]="'Control'" [queryDateRange]="queryDateRange" downloadBtnLabel="Download" [downloadModalHeader]="'Download User data for '+currentFacility.name" (queryDatesUpdate)="queryDateUpdate($event)">
                    </queryDateRangeControl>
                </article>

                <stacked-bar-chart [graphTitle]="'User Activities'" dataName="ActivityMetricsMany" [queryDateRange]="queryDateRange">
                </stacked-bar-chart>
            </div>
        </ng-container>


        <!--  ======  Focused on Facility AND User  ======  -->
        <ng-container *ngIf="!!focusedFacilityId && !!focusedUserId && (loaded || isLoaded())">
            <div class="mainContent" *ngIf="currentUser.hasOwnProperty('caregiverOf')
                && (currentUser.caregiverOf.length > 0)">
                <article class="mainContent__tile focusBanner">
                    <section class="focusBanner__top">
                        <div class="focusBanner__top--left">
                            <div class="focusBanner__userPfp userPfp">
                                <fa-icon [icon]="icon.userIcon"></fa-icon>
                            </div>

                            <div class="userInfo__text">
                            <span class="header">
                                {{focusedUser.firstName}} {{focusedUser.lastName}}
                            </span>
                                <span class="lastConnection">
<!--                                TODO: track/get last login time   -->
<!--                                <small>Last login: R## at hh:mm mm/dd/yyy</small>-->
                            </span>
                            </div>
                        </div>
                        <div class="focusBanner__top--right">
                            <div class="focusBanner__buttons">
                                <!--                        TODO: what should the 'Edit' button allow for?
                                                                    - age, weight, add guardians(?)-->
                                <button class="emptyBtn edit">Edit</button>
                            </div>

                            <div class="ageJoinDate">
                            <span class="userAge">
                                Age <span>{{getUserAge(focusedUser.birthday)}}</span>
                            </span>
                                <span class="userJoinDate">
                                Join Date <span>mm/dd/yyy</span>
                            </span>
                            </div>
                        </div>
                    </section>

                    <section class="focusBanner__bottom">
                        <hr>
                        <div class="bottom-content">
                            <div class="bottom-content__right">
                            <span class="location">

                            </span>
                            </div>
                            <div class="bottom-content__left">
                                <span class="assignment">Assigned to <b class="facName">{{(currentFacility?.name || 'None')}}</b></span>
                            </div>
                        </div>
                    </section>
                </article>

                <article class="mainContent__tile">
                    <queryDateRangeControl [title]="'Control'" [queryDateRange]="queryDateRange" [downloadBtnLabel]="'Download'" [downloadModalHeader]="'Download '+focusedUser.firstName +' ' + focusedUser.lastName+'\'s Data'" (queryDatesUpdate)="queryDateUpdate($event)">
                    </queryDateRangeControl>
                </article>

                <stacked-bar-chart [graphTitle]="focusedUser.firstName+' '+ focusedUser.lastName +'\'s Activities'" dataName="ActivityMetricsOne" [queryDateRange]="queryDateRange" [targetUser]="focusedUser">
                </stacked-bar-chart>

<!--                <conversation-graph-->
<!--                    [graphTitle]="'Conversation'"-->
<!--                    [targetUser]="this.focusedUser"-->
<!--                    [role]="'caregiver'"-->
<!--                    [queryDateRange]="queryDateRange">-->
<!--                </conversation-graph>-->
            </div>
        </ng-container>
    </div>


    <div class="rightPanel">
        <div *ngIf="currentUser.hasOwnProperty('caregiverOf') && (!isLoaded() || !loaded)">Loading Users...</div>

        <!--        Caregiver view, focused on facility, NOT specific user:
                                show list of users in facility for whom I'm a caregiver     -->
        <userlistpanel *ngIf="currentUser.hasOwnProperty('caregiverOf') && focusedFacilityId && !focusedUserId && (isLoaded() || loaded)" [users]="getFacility().users" [title]="'Residents'" [clickCb]="this.focusOnUser.bind(this)"></userlistpanel>

        <div class="queryDateRangeHelper" *ngIf="focusedFacilityId">
            <panel-date-range-display [queryDateRange]="selectedRange"></panel-date-range-display>
        </div>

        <!--        Caregiver view, focused -->
<!--        TODO: update user list to contain all guardians/caregivers of the focused user-->
<!--        <userlistpanel *ngIf="currentUser.hasOwnProperty('caregiverOf') && !state.focusView &&  (isLoaded() || loaded)"-->
<!--                       [users]="allUsersForCaregiver" [title]="'Residents'" [renderedBy]="'usersactivities/caregiver'" [clickCb]="navToFocusedUserActivitiesPage"></userlistpanel>-->

    </div>
</div>
