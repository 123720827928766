import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { faUserCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from '../../components/auth/auth.service';
import { UserType } from '../../components/auth/user.service';
import { AppState, RoleViewType} from '../../components/interfaces/AppState';
import {getPagesForRole} from '../../components/leftsidenav/rolePages';
import {UIRoleViews} from '../../../server/config/environment/shared';
import {race, Subscription} from 'rxjs';
import {mapTo} from 'rxjs/operators';

@Component({
    selector: 'home-page',
    templateUrl: './main.html',
    styleUrls: ['./main.scss'],
})

export class MainComponent implements OnInit, OnDestroy {
    state: AppState = {
        pageView: 'dashboard',
        navElements: [],
        roleView: 'user',
        queryParams: { facilityId: null, userId: null },
    };

    icon = {
        search: faSearch,
        bell: faBell,
        userCircle: faUserCircle
    };

    currentUser: UserType;

    queryParamSubscription: Subscription;
    routerUpdateListener: Subscription;

    static parameters = [Router, ActivatedRoute, AuthService];

    constructor(public router: Router, public activatedRoute: ActivatedRoute, authService: AuthService) {
        this.router = router;
        this.activatedRoute = activatedRoute;

        // get the current user from the token
        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserType;

        // get/set the correct state
        const currUrl = this.router.url.substr(1).toLowerCase().split('/');
        const roleView: RoleViewType = UIRoleViews.includes(currUrl[0])
            ? currUrl[0] as RoleViewType : 'user' as RoleViewType;

        const pageView: string = this.activatedRoute.snapshot.paramMap.get('pageView')
            ? this.activatedRoute.snapshot.url[0].path : undefined;

        const queryParams = this.activatedRoute.snapshot.queryParams || {} as AppState;
        if (pageView) this.state = { ...this.state, pageView: pageView };
        if (roleView) this.state = { ...this.state, roleView: roleView };
        if (queryParams) this.state = { ...this.state, queryParams };
        this.state.navElements = getPagesForRole(this.state.roleView);

        this.currentUser = authService.currentUser as UserType;

        //FIXME: debug why the queryParam changes are not recognized in the race()
        this.routerUpdateListener = race(
            this.activatedRoute.url.pipe(mapTo('URL Updated!')),
            this.activatedRoute.queryParams.pipe(mapTo('QueryParams Updated!')),
            this.activatedRoute.params.pipe(mapTo('PageView Updated!'))
        ).subscribe(newVal => {
            // console.log('RACE: ', newVal);
            this.initializeState();
        });

        this.queryParamSubscription = this.activatedRoute.queryParams.subscribe(qParams => {
            this.state.queryParams = qParams;
            this.initializeState();
        });
    }


    public ngOnInit(): void { }

    initializeState() {
        const snapshot = this.activatedRoute.snapshot;
        //url.split('/') -> ['', ':roleView', ':pageView']
        const routerRoleView = this.router.url.split('/')[1].toLowerCase(); //roleView
        const newPageView = snapshot.paramMap.get('pageView').toLowerCase();
        if (!this.isValidPageView(newPageView, routerRoleView)) throw new Error(`Invalid page view: ${newPageView}`);

        this.state = {
            pageView: newPageView,
            navElements: getPagesForRole(routerRoleView),
            roleView: routerRoleView as RoleViewType,
            queryParams: snapshot.queryParams,
        };
    }

    /**
     * isValidPageView: verifies that the requesting page (e.g. 'usersactivities') is allowed by this user's role permissions
     * @param { string } newPage
     * @param { string } roleView
     */
    isValidPageView(newPage: string, roleView?: string): boolean {
        newPage = newPage.toLowerCase();
        if (roleView) {
            roleView = roleView.toLowerCase();
            return getPagesForRole(roleView).map(n => n['link']).includes(newPage);
        }
        return this.state.navElements.map(n => n['link']).includes(newPage);
    }

    ngOnDestroy(): void {
        this.queryParamSubscription.unsubscribe();
        this.routerUpdateListener.unsubscribe();
    }
}
