import { Pipe, PipeTransform } from '@angular/core';
import {UserType} from '../auth/user.service';

@Pipe({
    name: 'filterAnonUsers'
})
export class FilterAnonUsersPipe implements PipeTransform {
    transform(value: any, searchValue, exclude = []): UserType[] {
        if (!searchValue || value.length === 0) return value;
        return value.filter((v) => {
            return exclude.includes(v.email) ? false : (
                (v.anonymizedName?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
            );
        });
    }
}
