import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService} from '../../components/auth/auth.service';
import {MainComponent} from '../main/main.component';

@Component({
    selector: 'facilityAdminPages',
    templateUrl: './facilityAdminPages.html',
    styleUrls: ['./facilityAdminPages.scss'],
})

export class FacilityAdminPagesComponent extends MainComponent implements OnInit, OnDestroy, OnChanges {

    static parameters = [Router, ActivatedRoute, AuthService];
    constructor(public router: Router, public route: ActivatedRoute, authService: AuthService) {
        super(router, route, authService);
    }

    ngOnInit() { }

    //prevent mem leaks
    ngOnDestroy(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        // console.log(changes);
    }
}
