<div class="modal-content orgClaimModal">
    <form id="assignAdmin" name="assignAdmin" role="form" class="form-horizontal" (ngSubmit)="submitAdminAssignment()" #submitadminAssignment>
        <div class="modal-header">
            <h4 id="myModalLabel" class="modal-title">Assign an Admin to {{org.name}}</h4>
            <button type="button" (click)="bsModalRef.hide()" class="close"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </div>
        <div class="modal-body">
            <div role="alert" [hidden]="!formError" class="alert alert-danger">{{ formError }}</div>
            <div role="alert" [hidden]="!formInfo" class="alert alert-info">{{ formInfo }}</div>
            <div class="topSearch">
                <div class="searchBox">
                    <fa-icon [icon]="icon.search" class="icon"></fa-icon>
                    <input class="search__input" placeholder="Search Users" [(ngModel)]="search" name="search">
                </div>
            </div>
            <div class="list">
                <div class="user" *ngFor="let user of (allUsers | filterUsers:search:exclude)" (click)="selectUser(user)">
                    <div class="icon" *ngIf="!user.pfp">
                        <fa-icon size="2x" [icon]="icon.userCircle"></fa-icon>
                        <span class="role" #userRole>{{user.role}}</span>
                    </div>
                    <div class="details">
                        <span class="name" #userFirstName>{{user.firstName}} {{user.lastName}}</span>
                        <span class="email" #userEmail>{{user.email}}</span>
                    </div>
                </div>
                <div class="list__user user notFound" *ngIf="!allUsers">
                    Not Found
                </div>
            </div>
            <hr>

        </div>
        <div class="selectedAdmin" *ngIf="assignedAdmin">
            <h4>Selected Admin</h4>
            <div class="user">
                <div class="icon">
                    <fa-icon size="2x" [icon]="icon.userCircle"></fa-icon>
                    <span class="role" #userRole>{{assignedAdmin.role || ''}}</span>
                </div>
                <div class="details">
                    <span class="name" #userFirstName>{{assignedAdmin.firstName || ''}} {{assignedAdmin.lastName ||
                        ''}}</span>
                    <span class="email" #userEmail>{{assignedAdmin.email || ''}}</span>
                </div>
            </div>
        </div>

        <div class="modal-footer" [hidden]="formInfo">
            <button (click)="bsModalRef.hide()" type="button" class="btn btndefault">
                Cancel</button>
            <button type="submit" class="btn btn-primary" *ngIf="assignedAdmin">Confirm Admin</button>
        </div>
    </form>
</div>