import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { BsModalRef } from 'ngx-bootstrap/modal';
import { states, facilityTypes } from "../../../../server/config/environment/shared";

@Component({
    selector: 'edit-org-fac',
    templateUrl: './edit-org-fac.html',
    styleUrls: ['./edit-org-fac.scss']
})
export class EditOrgFacComponent implements OnInit {
    @Input()
    editType: String;
    @Input()
    toEdit: any;
    @Input()
    formError: String;
    @Input()
    formInfo: String;
    @Output()
    confirmEdit: EventEmitter<any> = new EventEmitter();

    allStates = states;
    facilityTypes = facilityTypes;

    static parameters = [BsModalRef];

    constructor(public bsModalRef: BsModalRef) {
    }

    ngOnInit() {
    }

    handleEdit(form) {
        console.log(form.value)
        this.confirmEdit.emit({ editedEntity: form.value })
    }

}
