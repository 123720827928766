// @flow
import { Component } from '@angular/core';
// import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { AuthService } from '../../../components/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { emailValidation } from '../../../components/util';
import { genderTypes } from '../../../../server/config/environment/shared';
import {FacilityService} from '../../../services/facility/facility.service';

interface User {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    repeatPassword: string;
    birthday?: string;
    gender?: string;
    weight?: number;
}

@Component({
    selector: 'createAccount',
    templateUrl: './createAccount.html',
    styleUrls: ['./createAccount.scss']
})
export class CreateAccountComponent {
    routeParams: {
        facilityId?: string,
        userId?: string,
        role?: string,
    };
    facility: {
        name?: string,
    }

    user: User = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        repeatPassword: '',
        birthday: '',
        gender: '',
        weight: undefined,
    };


    privacyAgreement: false;
    cookiesPolicy: false

    genderTypes = genderTypes;
    return = '/dashboard';
    // signupQueryParams = '';

    alert = {
        show: false,
        type: '',
        message: '',
    }

    currentDate = new Date();
    // bsConfig: Partial<BsDatepickerConfig>;


    static parameters = [Router, ActivatedRoute, FacilityService, AuthService];

    constructor(public router: Router, public activatedRoute: ActivatedRoute,
                public facilityService: FacilityService, public authService: AuthService,
    ) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.facilityService = facilityService;
        this.authService = authService;
    }

    ngOnInit() {
        const routeParams = this.activatedRoute.snapshot.paramMap;
        const role = String(routeParams.get('role'));
        const facilityId = String(routeParams.get('facilityId'));
        const userId = String(routeParams.get('userId'));
        this.user.email = userId;
        this.routeParams = {
            facilityId,
            role,
            userId,
        };
        this.facilityService.getPublicInfoFacility(facilityId).toPromise()
            .then((data) => {
                console.log(data);
                this.facility = data;
                this.alert = {
                    show: false,
                    type: '',
                    message: '',
                };
            })
            .catch((err) => {
                console.error(err);
                this.alert = {
                    show: true,
                    type: 'warning',
                    message: 'Facility does not exist.',
                };
            });
    }

    register(form) {
        this.clearAlert();
        const { firstName, lastName, password, repeatPassword, gender, weight } = this.user;
        const { cookiesPolicy, privacyAgreement } = this;
        if (form.invalid) {
            this.alert = {
                show: true,
                type: 'danger',
                message: 'Please complete all fields',
            };
            return;
        }
        let message = '';
        if (!cookiesPolicy || !privacyAgreement) message = 'Please agree to our policies.';
        if (password !== repeatPassword) message = 'Passwords do not match';
        if (!emailValidation(this.user.email)) message = 'Please submit a valid email';
        if (message) {
            this.alert = {
                show: true,
                type: 'danger',
                message: message,
            };
            return;
        }

        return this.authService.createUser({
            firstName,
            lastName,
            email: this.user.email.toLowerCase(),
            password,
            repeatPassword,
            gender,
            weight,
        })
            .then((data: User) => {
                //Account created, log in
                return this.authService.login({ email: this.user.email.toLowerCase(), password: this.user.password });
            })
            .then((data: User) => {
                // console.log('DATA', data);
                return this.facilityService.verifyUserOrCaregiver(this.routeParams.facilityId, this.routeParams.role, this.routeParams.userId).toPromise();
            })
            .then((data) => {
                this.router.navigateByUrl(this.return);
            })
            // .then(() => {
            //     //logged in, redirect to query param "return" or /home
            //     this.router.navigateByUrl(`facility/${facilityId}/${role}/${userId}/verify`);
            // })
            .catch(({ error }) => {
                this.alert = {
                    show: true,
                    type: 'danger',
                    message: error.message && typeof error.message === 'string' ? error.message : 'An error occurred.',
                };
            });
    }

    clearAlert() {
        this.alert = {
            show: false,
            type: '',
            message: '',
        };
    }
}
