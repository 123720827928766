import {Component, Input} from '@angular/core';
import {error} from "selenium-webdriver";
import {throwError} from "rxjs";


var hexDigits = ['0','1','2','3','4','5','6','7','8','9','a','b','c','d','e','f'];
@Component({
    selector: 'ryan-logo',
    templateUrl: './ryan-logo.svg',
    styleUrls: ['./ryan-logo.scss']
})
export class RyanLogoComponent {

    @Input() mainFillColor: string = '#89A3B3';

    @Input() circleFillColor: string = '#F9B560';


    static parameters = [];
    constructor() {
        let valid = /^#[0-9A-F]{6}$/i.test(this.mainFillColor);
        // console.log(this.mainFillColor);
        // console.log(this.validateHexColors());
        this.validateHexColors();
    }

    validateHexColors() {
        let mainColorValid: boolean;
        let circleColorValid: boolean;
        switch (this.mainFillColor.length) {
            case 4:
                mainColorValid = /^#([0-9A-F]{3}){1,2}$/i.test(this.mainFillColor);
                break;
            case 7:
                mainColorValid = /^#[0-9A-F]{6}$/i.test(this.mainFillColor);
                break;
            default:
                mainColorValid = false;
                throwError('Invalid value for mainFillColor -> please provide a valid Hex Color');
        }

        switch (this.circleFillColor.length) {
            case 4:
                circleColorValid = /^#([0-9A-F]{3}){1,2}$/i.test(this.circleFillColor);
                break;
            case 7:
                circleColorValid = /^#[0-9A-F]{6}$/i.test(this.circleFillColor);
                break;
            default:
                circleColorValid = false;
                throwError('Invalid value for circleFillColor -> please provide a valid Hex Color');
        }
        // console.log('mainColorValid: ' + mainColorValid);
        // console.log('circleColorValid: ' + circleColorValid);
        return circleColorValid && mainColorValid;
    }
}
