<div class="modal-content">
    <form id="reassignInventory" name="reassignInventory" role="form" class="form-horizontal" #form="ngForm" (ngSubmit)="handleSubmit()">
        <div class="modal-header">
            <h4 id="myModalLabel" class="modal-title">Reassign Inventory to {{assignToType}}: {{assignTo.name}}</h4>
        </div>
        <div class="modal-body">

            <div class="ryanInfoRow">
                <h6>Inventory to be assigned to {{assignToType}}: {{assignTo.name}}</h6>
                <div class="ryanBoxContainer">
                    <div *ngFor="let ryan of selectedInventory" class="ryanBox selected" (click)="selectInventory(ryan)">
                        R{{ryan._id.slice(ryan._id.length - 3)}}
                    </div>
                </div>
            </div>
            <hr>

            <div class="ryanInfoRow" *ngIf="orgObject.ryans && assignToType !== 'organization'">
                <h6>Inventory to be taken from Organization: {{orgObject.name}}</h6>
                <div class="ryanBoxContainer">
                    <div *ngFor="let ryan of orgObject.ryans" class="ryanBox" (click)="selectInventory(ryan)" [ngClass]="{'deselected': this.selectedInventory.has(ryan)}">
                        R{{ryan._id.slice(ryan._id.length - 3)}}
                    </div>
                </div>
            </div>


            <div class="ryanInfoRow" *ngFor="let facility of orgObject.facilities">
                <ng-container *ngIf="facility._id !== assignTo._id">
                    <h6>Inventory to be taken from Facility: {{facility.name}}</h6>
                    <div class="ryanBoxContainer" *ngIf="facility.ryans && facility.ryans.length > 0">
                        <div *ngFor="let ryan of facility.ryans" class="ryanBox" (click)="selectInventory(ryan)" [ngClass]="{'deselected': this.selectedInventory.has(ryan)}">
                            R{{ryan._id.slice(ryan._id.length - 3)}}
                        </div>
                    </div>
                    <div class="ryanBoxContainer" *ngIf="!facility.ryans || facility.ryans.length === 0">
                        <h5 class="noInventory">(no available inventory)</h5>
                    </div>
                </ng-container>
            </div>

        </div>
        <div class="modal-footer">
            <button (click)="bsModalRef.hide()" type="button" class="btn btndefault">
                Cancel</button>
            <button type="submit" class="btn btn-primary" disabled="{{selectedInventory?.size > 0 ? null : true}}">Confirm Selection</button>
        </div>
    </form>
</div>