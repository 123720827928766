import {AfterViewInit, Component} from '@angular/core';

@Component({
    selector: 'app',
    templateUrl: './app.html',
})
export class AppComponent implements AfterViewInit {
    isLoggedIn: boolean = false;
    static parameters = [];
    constructor() {
        this.isLoggedIn = !!localStorage.getItem('user');
    }

    ngAfterViewInit(): void { }
}
