import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../components/auth/auth.service';
import { FileService } from '../../../services/file/file.service';
import { HttpEventType } from '@angular/common/http';
import { faImage, faVideo, faMusic, faFileAlt, faTimesCircle, faEdit, faPen } from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer } from '@angular/platform-browser';
import { EditFileModalComponent } from '../../../components/media/modals/editFile.component';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'user-media',
    templateUrl: './userMedia.html',
    styleUrls: ['./userMedia.scss'],
})


export class UserMediaComponent implements OnInit, OnDestroy {
    userId = 'me';

    // could be cleaned up by taking out functionality to retrieve for other users
    userIdSubscription = new BehaviorSubject(this.userId);
    filesObject: any = undefined;
    filesObjectSubscription = new BehaviorSubject(this.filesObject);
    progress = undefined;

    alert = {
        showFor: '',
        type: '',
        message: '',
    }

    icon = {
        image: faImage,
        video: faVideo,
        audio: faMusic,
        others: faFileAlt,
        delete: faTimesCircle,
        edit: faEdit,
    }

    static parameters = [FileService, AuthService, BsModalService, DomSanitizer];

    constructor(public fileService: FileService, authService: AuthService, public modalService: BsModalService, public domSanitizer: DomSanitizer) {
        this.fileService = fileService;
        this.modalService = modalService;
        this.domSanitizer = domSanitizer;
        this.userIdSubscription.subscribe({
            next: (newValue) => this.handleNewUserId(newValue),
            error: (e) => console.error(e),
        });
        this.filesObjectSubscription.subscribe({
            next: (newFilesObject) => this.handleNewFilesObject(newFilesObject),
            error: (err) => console.log(err),
        });
    }

    getFilesByType(fileTypes: string = 'others') {
        const audioRegex = /.+\.(m4a$|mp3$|aac$|flac$|wma$|wav$)/i;
        const imageRegex = /.+\.(tif$|tiff$|gif$|jpg$|jpeg$|png$)/i;
        const videoRegex = /.+\.(mov$|avi$|wmv$|flv$|3gp$|mp4$|mpg$)/i;
        const allRegex = /.+\.(m4a$|mp3$|aac$|flac$|wma$|wav$|tif$|tiff$|bmp$|jpeg$|jpg$|gif$|png$|mov$|avi$|wmv$|flv$|3gp$|mp4$|mpg$)/i;
        if (!this.filesObject || !this.filesObject.files) return [];
        switch (fileTypes.toLowerCase()) {
        case 'audio':
            return this.filesObject.files.filter((f) => audioRegex.test(f.value.awsLocation));
        case 'image':
            return this.filesObject.files.filter((f) => imageRegex.test(f.value.awsLocation));
        case 'video':
            return this.filesObject.files.filter((f) => videoRegex.test(f.value.awsLocation));
        case 'others':
            return this.filesObject.files.filter((f) => !allRegex.test(f.value.awsLocation));
        case 'all':
            return this.filesObject.files;
        default:
            return this.filesObject.files;
        }
    }

    ngOnInit(): void { }

    ngOnDestroy(): void {
        this.userIdSubscription.unsubscribe();
    }

    showAlertTemporary(showFor: string, type: string, message: string, milliseconds: number = 3000) {
        this.alert = {
            showFor,
            type,
            message,
        };
        window.setTimeout(() => {
            this.clearAlert();
        }, milliseconds);
    }

    clearAlert() {
        this.alert = {
            showFor: '',
            type: '',
            message: '',
        };
    }

    handleNewUserId(newUserId) {
        this.fileService.get(newUserId).pipe(take(1)).subscribe({
            next: (data) => {
                this.filesObjectSubscription.next(data);
                this.userId = newUserId;
                this.showAlertTemporary('media', 'warning', `Retrieved ${data.files.length} files for ${newUserId}`, 3000);
            },
            error: ({ error }) => {
                console.log(error);
                this.showAlertTemporary('media', 'warning', `Unable to get files for ${newUserId}`, 3000);
            },
        });
    }

    handleNewFilesObject(newFilesObject) {
        if (!newFilesObject) return;
        this.filesObject = newFilesObject;
        this.filesObject.files.forEach((f) => {
            this.getImageUrl(f.key);
            console.log('getting image url');
        });
    }

    getImageUrl(fileKey) {
        console.log(fileKey);
        this.fileService.download(fileKey, this.userId).subscribe({
            next: (blob) => {
                const unsafeImgUrl = URL.createObjectURL(blob);
                const sanitizedImgUrl = this.domSanitizer.bypassSecurityTrustUrl(unsafeImgUrl);
                const thisFile = this.filesObject.files.find(f => f.key === fileKey);
                thisFile.imgUrl = sanitizedImgUrl;
                console.log(thisFile);
            },
            error: (err) => {
                console.log(err);
            }
        });
    }

    openEditModal(file) {
        const initialState = {file};
        const modalRef = this.modalService.show(EditFileModalComponent, { initialState });
        modalRef.content.deleteFile.pipe(take(1)).subscribe((file) => {
            this.deleteFile(file.key);
        });
        modalRef.content.editFile.pipe(take(1)).subscribe((file) => {
            console.log(file);
            console.log('To Edit above file');
        });
    }

    getCleanFileName(longFileName: string) {
        return decodeURIComponent(longFileName.slice(longFileName.lastIndexOf('/') + 1));
    }

    changeUserFocusTo(newUserId?) {
        this.userIdSubscription.next(newUserId ? newUserId : 'me');
        return;
    }

    uploadFile(event) {
        if (!event.target.files[0]) return;
        const fileSizeLimit = 15; // Mb
        const fileSize = event.target.files[0].size / 1024 ** 2; // Mb
        if (fileSize > fileSizeLimit) {
            this.showAlertTemporary('uploader', 'warning', `File too large. Please limit your file size to ${fileSizeLimit}mb.`, 3000);
            return;
        }
        this.fileService.upload(event.target.files[0], this.userId).subscribe((evt) => {
            switch (evt.type) {
            case HttpEventType.Sent:
                console.log('Request has been made!');
                break;
            case HttpEventType.ResponseHeader:
                console.log('Response header has been received!');
                break;
            case HttpEventType.UploadProgress:
                console.log(evt);
                this.progress = Math.round(evt.loaded / evt.total * 100);
                console.log(`Uploaded! ${this.progress}%`);
                break;
            case HttpEventType.Response:
                console.log(evt);
                console.log('Successfully created!', evt.body);
                // refresh filesObj
                this.changeUserFocusTo(this.userId);
                setTimeout(() => {
                    this.progress = undefined;
                }, 1000);
            }
        }, (errorMessage) => {
            this.progress = undefined;
            this.showAlertTemporary('uploader', 'warning', errorMessage || 'error', 3000);
        });
    }

    deleteFile(fileKey) {
        this.fileService.delete(this.userId, fileKey).subscribe((resp) => {
            console.log(resp);
            //update files showing
            this.filesObject.files = this.filesObject.files.filter(f =>
                f.key !== fileKey
            );
            this.showAlertTemporary('media', 'info', 'File Deleted', 3000);
        }, (err) => {
            console.error(err);
            this.showAlertTemporary('media', 'warning', 'Deletion error', 3000);
        });
    }
}
