import {
    ChangeDetectionStrategy,
    Component, Input, OnChanges,
    OnDestroy, OnInit, SimpleChanges, EventEmitter, Output
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {UserType, UserService} from '../../auth/user.service';
import {AnalyticsService} from '../../../services/analytics/analytics.service';
import {OrganizationService} from '../../../services/organization/organization.service';
import {
    mapColorKeys, convertMillisToHrsMinutes, capitalizeFirstLetter, convertMillisToMinutes
} from '../../util';
import moment from 'moment';
import {FacilityService} from '../../../services/facility/facility.service';

@Component({
    selector: 'advanced-pie-chart',
    templateUrl: './advanced-pie-chart.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./advanced-pie-chart.scss']
})

/**
 * TODO: this component is not currently used anywhere on the site
 */
export class AdvancedPieChartComponent implements OnInit, OnDestroy, OnChanges {
    @Input('graphTitle') graphTitle: string = 'Usage Analysis';
    @Input('data') data?: any = undefined;
    @Input('dataSummary') dataSummary?: any = undefined;
    //TODO: we can determine the necessary query based on ActivatedRoute context
    @Input('queryDateRange') queryDateRange: [Date, Date];
    @Input('targetUser') targetUser?: UserType = undefined;

    pieChartColorScheme = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
    };

    gradient = false;

    static parameters = [Router, ActivatedRoute, AnalyticsService, OrganizationService, FacilityService, UserService];
    constructor(public router: Router, public route: ActivatedRoute, public analyticService: AnalyticsService,
                public organizationService: OrganizationService, public facilityService: FacilityService, public userService: UserService) {
        this.router = router;
        this.route = route;
        this.analyticService = analyticService;
        this.userService = userService;
    }

    public ngOnInit(): void { }

    triggerQuery() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('targetUser')) {
            this.triggerQuery();
        }
    }

    formatPieChartValues = (value: number) : string => {
        const dur = moment.duration(value);
        const durHrMin = convertMillisToHrsMinutes(value);
        return dur.asMinutes() <= 60
            ? `${Math.round(dur.asMinutes() * 100.0) / 100.0}m`
            : `${durHrMin.hours}h ${durHrMin.minutes}m`;
    }

    //TODO: Map 'ProgramRMedia' to "Conversation media"
    formatPieChartNames = (name: string) : string => name;

    onSelect(event) {
        console.log(event);
    }

    /**
     * mapColorKeys: used by the graph to ensure each category is properly color-coded in both the graph itself
     *                  and in the legend
     * @param value
     */
    mapColorKeys(value) : string {
        return mapColorKeys(value);
    }

    capitalizeFirstLetter(str: string): string {
        return capitalizeFirstLetter(str);
    }

    /**
     * getTooltipTemplateTime: used by the graph's tooltip to reformat the number of hours (as a float) into
     *                          a more legible `##h ##m` string (ex: 0.5000777777777777 -> `0h 30m`)
     * @param tooltipModel: a float number representing a number of hours
     */
    getTooltipTemplateTime(tooltipModel) {
        const dur = moment.duration(tooltipModel.value);
        const durHrMin = convertMillisToHrsMinutes(tooltipModel.value);

        return dur.asMinutes() <= 60
            ? `${Math.round(dur.asMinutes() * 100.0) / 100.0}min`
            : `${durHrMin.hours}hr ${durHrMin.minutes}min`;
    }

    public ngOnDestroy(): void { }
}
