<div class="control" id="queryDateRangeControl">
<!--    TODO: handle long facility names    -->
    <h5 class="title">{{title}}</h5>

    <div class="dateSelect">
        <form [formGroup]="dateControl">
            <div class="form-group cal-div">
                <button class="cal" (click)="daterangepicker.toggle()" [attr.aria-expanded]="daterangepicker.isOpen" type="button">
                    <fa-icon [icon]="icon.calendar"></fa-icon>
                </button>
            </div>

            <div class="form-group datePicker">
                <input type="text" class="form-control" formControlName="dateRange" #daterangepicker="bsDaterangepicker" bsDaterangepicker [(ngModel)]="selectedRange" [minDate]="dateParams.minAllowed" [maxDate]="dateParams.maxAllowed" [bsConfig]="{rangeInputFormat : 'MMM. Do, YYYY', dateInputFormat: 'MMM. Do, YYYY',
                                   selectWeekDateRange: true,
                                   selectFromOtherMonth: true,
                                   ranges: this.dateParams.ranges,
                                   showPreviousMonth: true,
                                   containerClass: 'theme-blue' }" (bsValueChange)="datePickerUpdate($event)">
            </div>
        </form>
    </div>

    <div class="download">
        <downloadData [queryDateRange]="this.queryDateRange" [btnLabel]="downloadBtnLabel" [modalHeader]="downloadModalHeader"></downloadData>
    </div>
</div>

