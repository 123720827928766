<div class="orgList">
    <div class="orgList__top top">
        <div class="top__header">
            Organizations
        </div>
        <div class="top__search">
            <fa-icon [icon]="faSearch" class="search__icon"></fa-icon>
            <input class="search__input" placeholder="Search Organizations" [(ngModel)]="search">
        </div>

        <div class="top__count-sort">
            <div class="count" *ngIf="(orgs | filterOrgs:search).length === 1">
                {{(orgs | filterOrgs:search).length}} Organization
            </div>
            <div class="count" *ngIf="(orgs | filterOrgs:search).length !== 1">
                {{(orgs | filterOrgs:search).length}} Organizations
            </div>
        </div>
    </div>

    <div class="orgList__list list">
        <!--        TODO: use real data for Org info -->
        <div class="list__org org" *ngFor="let org of (orgs | filterOrgs:search)">
            <span class="org__name">{{org.name}}</span>
            <span class="org__addr">{{org.city}}, {{org.state}}</span>
        </div>

        <div class="list__org org notFound" *ngIf="(orgs | filterOrgs:search).length <1 || orgs === undefined">
            Not Found
        </div>
    </div>
</div>
