import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Facility } from '../../interfaces/Facility';
import { Organization } from '../../interfaces/Organization';
import { Inventory } from '../../interfaces/Inventory';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
    selector: 'reassign-inventory',
    templateUrl: './reassign-inventory.html',
    styleUrls: ['./reassign-inventory.scss']
})

export class ReassignInventoryComponent {
    @Input()
    assignToType: String;
    @Input()
    assignTo: Organization | Facility;
    @Input()
    orgObject: Organization;
    @Output()
    confirmSubmit: EventEmitter<any> = new EventEmitter();

    selectedInventory;

    static parameters = [BsModalRef];

    constructor(public bsModalRef: BsModalRef) {
        this.bsModalRef = bsModalRef;
        this.selectedInventory = new Set();
    }

    selectInventory(inventory: Inventory) {
        if (this.selectedInventory.has(inventory)) {
            this.selectedInventory.delete(inventory);
        } else {
            this.selectedInventory.add(inventory);
        }
    }

    handleSubmit() {
        this.confirmSubmit.emit(Array.from(this.selectedInventory))
    }


}
