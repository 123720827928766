import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { UserType} from '../../../components/auth/user.service';
import {
    faCloudDownloadAlt,
    faFilter, faPlus,
    faSearch,
    faSortAmountDown,
    faUserCircle,
    faMinusCircle, faEye,
} from '@fortawesome/free-solid-svg-icons';
import { faBell, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { BsModalService } from 'ngx-bootstrap/modal';
import {FacilityService} from '../../../services/facility/facility.service';
import {Subscription} from 'rxjs';
import {ResearchService} from '../../../services/research/research.service';
import {
    mapResearchRoleNameToProperty,
    ResearchRoleName,
    ResearchStudy
} from '../../../components/interfaces/ResearchStudy';
import {Facility} from '../../../components/interfaces/Facility';
import {checkIfExistsInRole, formatRoleName, onlyUniqueFilter} from '../../../components/util';
import {OrganizationService} from '../../../services/organization/organization.service';
import {AuthService} from '../../../components/auth/auth.service';
import {AddParticipantToStudyComponent} from '../../../components/modals/addParticipantToStudy/addParticipantToStudy.component';
import _ from 'lodash';

@Component({
    selector: 'researchCoordinator-manage',
    templateUrl: './RC-Manage.html',
    styleUrls: ['../../../assets/sharedStyles/pages/managePages.scss', '../researchCoordinatorPages.scss', './RC-Manage.scss'],
})


export class ResearchCoordinatorManageComponent implements OnInit, OnDestroy {
    queryParamSubscription: Subscription;
    currentUserSubscription: Subscription;
    currentUser: UserType;
    focusedStudyId: string;

    icon = {
        userCircle: faUserCircle,
        bell: faBell,
        search: faSearch,
        sort: faSortAmountDown,
        calendar: faCalendarAlt,
        filter: faFilter,
        download: faCloudDownloadAlt,
        add: faPlus,
        delete: faMinusCircle,
        view: faEye,
    };

    allStudies: ResearchStudy[] = [];
    allFacilities: Facility[] = [];
    currentStudy: ResearchStudy;

    static parameters = [AuthService, Router, ActivatedRoute, OrganizationService, FacilityService, ResearchService, BsModalService];

    constructor(public authService: AuthService, public router: Router, public route: ActivatedRoute, public organizationService: OrganizationService,
                public facilityService: FacilityService, public researchService: ResearchService,
                public modalService: BsModalService) {
        this.router = router;
        this.route = route;
        this.facilityService = facilityService;
        this.researchService = researchService;
        this.modalService = modalService;

        this.queryParamSubscription = this.route.queryParamMap.subscribe(queryParams => {
            this.focusedStudyId = queryParams.has('studyId') ? queryParams.get('studyId') : undefined;
        });
        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserType;
        this.currentUserSubscription = authService.currentUserChanged.subscribe(updatedUser => {
            this.currentUser = updatedUser as UserType;
        });
    }

    ngOnInit(): void {
        this.refreshFullyPopulatedStudies();
    }

    ngOnDestroy(): void {
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
        this.currentUserSubscription.unsubscribe();
    }

    refreshFullyPopulatedStudies() {
        this.getAllResearchStudies()
            .then((studies: ResearchStudy[]) => {
                const studyFacilities = _.flatten(studies.map(s => s.facilities)).filter(onlyUniqueFilter) as Facility[];

                const sortedStudies = studies
                    .sort((a, b) => a.title.localeCompare(b.title)) as ResearchStudy[];
                return {studies: sortedStudies, facilities: studyFacilities};
            })
            .then(({studies, facilities}) => {
                this.allStudies = studies;
                this.allFacilities = facilities;
                this.currentStudy = this.getStudy(this.focusedStudyId);
                return this.allStudies;
            })
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
    }

    /**
     * getAllResearchStudies
     */
    getAllResearchStudies(): Promise<ResearchStudy[]> {
        return this.researchService.getAllMyStudies('coordinator')
            .then((res: {studies: ResearchStudy[]}) => {
                const sortedStudies: ResearchStudy[] = res.studies
                    .sort((a, b) => a.title.localeCompare(b.title));
                return Promise.resolve(sortedStudies as ResearchStudy[]);
            })
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
    }

    /**
     * getAllFacilities
     */
    getAllFacilities(): Promise<Facility[]> {
        return this.facilityService.getFacilitiesForPrincipleInvestigator()
            .then((facs: {facilities: Facility[]}) => facs.facilities.sort((a, b) => a.name.localeCompare(b.name)) as Facility[])
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
    }

    focusOnStudy(studyId?: string) {
        this.router.navigate([], {queryParams: {studyId: studyId}});
        this.currentStudy = this.getStudy(studyId);
    }

    getStudy(studyId?: string): ResearchStudy {
        return this.allStudies.find(s => s._id === studyId);
    }

    getNumPendingInvitations(role: ResearchRoleName = 'participant', study?: ResearchStudy): number {
        if (!study) study = this.getStudy();
        const propName = mapResearchRoleNameToProperty(role);
        if (!study[propName] || study[propName].length === 0) return 0;
        return study[mapResearchRoleNameToProperty(role)].filter(u => !u.invitationAccepted).length;
    }

    /**
     * addParticipantToStudy: invite a principleinvestigator, researchcoordinator, researcher, or participant this ResearchStudy
     * @param {String}        addToType
     * @param {ResearchStudy} study
     */
    addParticipantsToStudy(addToType: 'participant', study: ResearchStudy) {
        if (!study) study = this.getStudy(this.focusedStudyId);
        this.researchService.getEligibleParticipantsForStudy(study)
            .then((eligible: { eligibleParticipants }) => eligible.eligibleParticipants)
            .then((eligible: UserType[]) => {
                const initialState = {
                    study: study,
                    optionParticipants: eligible.filter(u => !checkIfExistsInRole(addToType, u.email || u.anonymizedName, study)),
                    optionFacilities: this.allFacilities
                };
                const modalRef = this.modalService.show(AddParticipantToStudyComponent, { initialState, class: 'modal-lg' });
                modalRef.content.addedParticipants.subscribe((toBeAdded: string[]) => {
                    if (!toBeAdded || toBeAdded.length < 1) return;
                    this.researchService.addParticipantsToStudy(study, toBeAdded).toPromise()
                        .then((updatedStudy: ResearchStudy) => {
                            const msgMid = toBeAdded.length > 1 ? 'users have' : 'user has';
                            const msgEnd = toBeAdded.length > 1 ? 'Participants' : 'a Participant';
                            modalRef.content.alert = {
                                type: 'success',
                                show: true,
                                message: `${toBeAdded.length} ${msgMid} been invited to join this study as ${msgEnd}.` };
                            setTimeout(() => {
                                modalRef.content.alert.show = false;
                            }, 10000);
                        })
                        .then(() => {
                            if (toBeAdded.includes(this.currentUser.email || this.currentUser.anonymizedName)) {
                                this.authService.refreshCurrentUser();
                            }
                            this.refreshFullyPopulatedStudies();
                        })
                        .catch((e) => {
                            console.error(e);
                            modalRef.content.alert = {
                                type: 'danger',
                                show: true,
                                message: `Whoops, an Error occurred trying to invite one or more of the selected Users to join this study as a ${formatRoleName(addToType)}.
                            (${e.status} ${e.statusText})`
                            };
                        });
                });
            })
            .catch(err => console.error(err));
    }
}
