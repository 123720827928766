import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Facility } from 'client/components/interfaces/Facility';
import {UserService, UserType} from '../../../components/auth/user.service';
import { getAge } from '../../../components/util';
import {
    faCalendar, faChartBar,
    faCog,
    faDownload,
    faMapMarker,
    faPhone,
    faSearch,
    faUserCircle
} from '@fortawesome/free-solid-svg-icons';
import {FacilityService} from '../../../services/facility/facility.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'caregiver-usersActivities',
    templateUrl: './caregiverUsersActivities.html',
    styleUrls: ['../../../assets/sharedStyles/pages/usersActivities.scss', './caregiverUsersActivities.scss'],
})

export class CaregiverUsersActivitiesComponent implements OnInit, OnChanges, OnDestroy {
    @Output() queryDateRangeChange = new EventEmitter<[Date, Date]>();

    icon = {
        search: faSearch,
        phone: faPhone,
        userIcon: faUserCircle,
        download: faDownload,
        settings: faCog,
        location: faMapMarker,
        calendar: faCalendar,
        barChart: faChartBar,
    }

    queryParamSubscription: Subscription;
    focusedFacilityId: string;
    focusedUserId: string;


    currentUser;
    focusedUser: UserType = undefined

    loaded: boolean = false;
    focusedUserLoaded: boolean = false;
    allUsersLoaded: boolean = false;
    analyticsLoaded: boolean = false;
    facilitiesLoaded: boolean = false;
    errOccurred: boolean = false; //tracks if an error occurred somewhere in the loading process

    allFacilities: Facility[] = [];
    currentFacility: Facility = undefined;
    allUsersForCaregiver: UserType[] = [];
    // currFacilityUsers: UserType[] = [];

    currentDate = new Date();

    selectedRange: Date[];
    //Date picker
    queryMinDate: Date = undefined;
    queryMaxDate: Date = undefined;
    queryDateRange:[Date, Date] = [this.queryMinDate, this.queryMaxDate];

    static parameters = [Router, ActivatedRoute, FacilityService, UserService];

    constructor(public router: Router, public route: ActivatedRoute,
                public facilityService: FacilityService, public userService: UserService) {
        this.router = router;
        this.route = route;
        this.facilityService = facilityService;
        this.userService = userService;

        this.selectedRange = [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()];
        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserType;

        // console.log(this.route.snapshot);
        this.loaded = false;
        this.errOccurred = false;
        this.focusedUserLoaded = false;
        this.allUsersLoaded = false;
        this.analyticsLoaded = false;
        this.facilitiesLoaded = false;

        this.queryParamSubscription = this.route.queryParamMap.subscribe(queryParams => {
            this.focusedFacilityId = queryParams.has('facilityId') ? queryParams.get('facilityId') : undefined;
            this.focusedUserId = queryParams.has('userId') ? queryParams.get('userId') : undefined;
        });
    }

    ngOnInit() {
        this.loaded = false;
        this.errOccurred = false;
        this.currentFacility = undefined;
        this.focusedUser = undefined;

        //first, load all facilities and users
        Promise.all([this.refreshFacilities(), this.refreshUsers()])
            .then((res: [Facility[], UserType[]]) => {
                this.allFacilities = this.completeUserInfoForFacilities([...res[0]], [...res[1]])
                    .sort((a:any, b:any) => a.name.localeCompare(b.name));
                this.allUsersForCaregiver = [...res[1]] as UserType[];
                return {facilities: res[0], users: res[1]};
            })
            .then(() => {
                this.facilitiesLoaded = true;
                //if we are ARE focused on a facility
                if (this.focusedFacilityId) {
                    this.currentFacility = this.getFacility(this.focusedFacilityId);
                    // check if we are also focused on a specific User
                    if (this.focusedUserId) {
                        this.loadSingleUser(this.focusedUserId);
                    }
                }
            })
            .catch(e => {
                console.error(e);
                this.errOccurred = true;
            });
    }

    isLoaded(): boolean {
        this.loaded = false;
        let loadReqs: boolean[];
        //if NOT focused on a facility, need to load list of facilities
        if (!this.focusedFacilityId) {
            loadReqs = [this.facilitiesLoaded];
        } else {
            //we ARE focused on a facility, we need to load this facility, filter users in this facility
            loadReqs = [this.facilitiesLoaded, this.allUsersLoaded];
            if (this.focusedUserId) {
                loadReqs = [this.focusedUserLoaded];
            }
        }
        this.loaded = loadReqs.every(v => v === true);
        return this.loaded;
    }

    refreshFacilities(): Promise<Facility[]> {
        this.facilitiesLoaded = false;
        return this.facilityService.getFacilitiesForCaregiver()
            .toPromise()
            .then((facs: {facilities: Facility[]}) => facs.facilities as Facility[])
            .catch(e => {
                console.error(e);
                this.errOccurred = true;
                return [] as Facility[];
            });
    }

    refreshUsers(): Promise<UserType[]> {
        this.allUsersLoaded = false;
        return this.userService.getUsersForCaregiver()
            .toPromise()
            .then((userList: {users: UserType[]}) => {
                this.allUsersForCaregiver = userList.users;
                this.allUsersLoaded = true;
                return userList.users;
            })
            .catch(e => {
                console.error(e);
                this.errOccurred = true;
                return [] as UserType[];
            });
    }

    completeUserInfoForFacilities(facilitySet: Facility[], userSet: UserType[]): Facility[] {
        return facilitySet.map(f => ({
            ...f,
            users: f.users.map(u => {
                const {firstName, lastName, email, anonymizedName, birthday } = userSet.find(user => u.email === user.email);
                return {
                    id: anonymizedName,
                    _id: anonymizedName,
                    firstName,
                    lastName,
                    email,
                    anonymizedName,
                    birthday,
                    caregivers: u.caregivers || [],
                    guardians: u.guardians || [],
                    invitationAccepted: u.invitationAccepted
                };
            })
                //Sort users alphabetically by full name (mostly for the userList Panel)
                .sort((a, b) =>
                    `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
                )
        }));
    }

    loadSingleUser(anonUserId?: string): UserType {
        this.focusedUser = this.allUsersForCaregiver.find(u => [this.focusedUserId, anonUserId].includes(u.anonymizedName));
        this.focusedUserLoaded = !!this.focusedUser;
        return this.focusedUser;
    }

    focusOnUser(userId: string = undefined) {
        if (userId) {
            this.router.navigate([],
                {queryParams: {facilityId: this.focusedFacilityId, userId: userId}});
            this.loadSingleUser(userId);
        } else { //if userId undefined, go back to facility page
            this.focusedUser = undefined;
            this.focusedUserLoaded = false;
            this.focusOnFacility(this.focusedFacilityId);
        }
    }

    focusOnFacility(facilityId: string = undefined): Facility {
        //if empty facilityId, clear queryParams, local vars, and return
        if (!facilityId) {
            this.router.navigate([], {queryParams: {}});
            this.focusedUser = undefined;
            this.currentFacility = undefined;
            return undefined;
        }
        this.router.navigate([], {queryParams: {facilityId: facilityId}});
        this.focusedUser = undefined;

        //update current facility
        this.currentFacility = this.getFacility(facilityId);
        return this.currentFacility;
    }

    getUsersInCareForFacility() {
        return this.getFacility().users || [];
    }

    getFacility(facilityId: string = this.focusedFacilityId): Facility {
        return this.allFacilities.find((f) => f._id === facilityId);
    }

    getFocusedUser() {
        const currentFacility = this.getFacility();
        return currentFacility ? currentFacility.users.find(u => [u.email, u.anonymizedName].includes(this.focusedUserId)) : undefined;
    }

    getUserAge(birthday: string): number {
        return getAge(birthday);
    }

    queryDateUpdate(ev:[Date, Date]) {
        this.queryDateRange = [...ev];
        this.queryDateRangeChange.emit(this.queryDateRange);
    }

    ngOnChanges(changes: SimpleChanges): void {
        // console.log(changes);
        // console.log(this.state);
    }

    ngOnDestroy(): void {
        this.queryParamSubscription.unsubscribe();
    }
}
