<div class="mainContent__tile graph-area total-usage">
    <div class="total-usage__header">
        <h5 class="graphTitle graph-title">{{graphTitle}}</h5>
    </div>

    <granularity-select class="time-select total-usage__granularity-select" (granularityChange)="handleGranularityChange($event)"></granularity-select>

    <div class="total-usage__mainGraph" id="mainGraph">
        <div *ngIf="dataReady === false && !errOccurred" class="loadingData">
            <mat-spinner class="loadingSpinner"></mat-spinner>
            Loading Data...
        </div>
        <ngx-charts-bar-vertical-stacked *ngIf="dataReady && data.length > 0" [view]="chartOptions.view" [results]="chartOptions.results" [gradient]="chartOptions.gradient" [customColors]="this.mapColorKeys" [xAxis]="chartOptions.showXAxis" [yAxis]="chartOptions.showYAxis" [legend]="chartOptions.showLegend" [showXAxisLabel]="chartOptions.showXAxisLabel" [showYAxisLabel]="chartOptions.showYAxisLabel" [xAxisLabel]="chartOptions.xAxisLabel" [maxXAxisTickLength]="22" [yAxisLabel]="chartOptions.yAxisLabel" [animations]="chartOptions.animations" [roundDomains]="chartOptions.roundDomains" (select)="chartOptions.onSelect($event)">
            <ng-template #tooltipTemplate let-model="model">
                <span class="tooltip-label">{{model.series}} &bull; {{model.label}}</span>
                <span class="tooltip-val">{{this.getTooltipTemplateTime(model)}}</span>
            </ng-template>
        </ngx-charts-bar-vertical-stacked>
        <div *ngIf="dataReady && data.length<=0" class="loadingData">
            Sorry, no data in this timeframe
        </div>
        <div *ngIf="errOccurred" class="loadingData">
            We're sorry, an error occurred processing your request
        </div>
    </div>

    <div class="legend">
        <div class="key" *ngFor="let category of activityTypes; let i = index">
            <span class="activityName"><span class="activityColor" [style.background-color]="mapColorKeys(category)"></span>{{category}}</span>
            <span class="activityTime">{{overallActivityUsageTime[category | lowercase].hours}}h {{overallActivityUsageTime[category | lowercase].minutes}}m</span>
        </div>
    </div>

    <div class="total-usage__dateToggle">
        <!--               TODO: replace ##  -->
        <span>
            Week ##
        </span>
    </div>

    <div class="total-usage__breakdown">
        <span>Total Usage</span>
        <span class="breakdown__time">{{overallTotalTime.hours}}h {{overallTotalTime.minutes}}m</span>
    </div>
</div>
