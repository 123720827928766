<div class="background">
    <div class="top-left">
        <svg width="135" height="137" viewBox="0 0 135 137" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M135 136L40.4847 136L0.999999 102.698L0.999993 -32" stroke="#58C2EE"/>
        </svg>
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 0.500001C8.26142 0.500001 10.5 2.73858 10.5 5.5C10.5 8.26142 8.26142 10.5 5.5 10.5C2.73858 10.5 0.5 8.26142 0.5 5.5C0.5 2.73858 2.73858 0.500001 5.5 0.500001Z" fill="white" stroke="#58C2EE"/>
        </svg>

        <svg width="73" height="2" viewBox="0 0 73 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M73 0.999994L0 1" stroke="#58C2EE"/>
        </svg>
        <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 8.5C7.53614 8.5 9.5 6.66089 9.5 4.5C9.5 2.33911 7.53614 0.5 5 0.5C2.46386 0.5 0.5 2.33911 0.5 4.5C0.5 6.66089 2.46386 8.5 5 8.5Z" fill="white" stroke="#58C2EE"/>
        </svg>
        <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 8.5C7.53614 8.5 9.5 6.66089 9.5 4.5C9.5 2.33911 7.53614 0.5 5 0.5C2.46386 0.5 0.5 2.33911 0.5 4.5C0.5 6.66089 2.46386 8.5 5 8.5Z" fill="white" stroke="#58C2EE"/>
        </svg>
    </div>
</div>