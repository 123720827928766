import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService} from '../../auth/auth.service';
import { Inventory } from '../../interfaces/Inventory';

import { ryanStates, ryanUsageScenarios } from '../../../../server/config/environment/shared';

import { UserService} from '../../auth/user.service';

@Component({
    selector: 'create-ryan2',
    templateUrl: './create-inventory.html',
    styleUrls: ['./create-inventory.scss']
})
export class CreateInventoryComponent2 {
    @Input()
    formError: String;

    @Input()
    formInfo: String;

    @Output()
    newRyanCreation: EventEmitter<Inventory> = new EventEmitter<Inventory>();
    public newRyan: Inventory = {
        _id: undefined,
        greengrassName: undefined,
        ryanState: 'active'
    };

    ryanStateOptions: any[];

    static parameters = [BsModalRef];

    constructor(public bsModalRef: BsModalRef, public authService: AuthService) {
        this.authService = authService;

        this.ryanStateOptions = ryanStates;
    }

    submitRyanCreation() {
        console.log(this.newRyan);
        this.newRyanCreation.emit(this.newRyan);
    }
}

