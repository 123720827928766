import {AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { UserType } from '../auth/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import {Subscription} from 'rxjs';
import {faWikipediaW} from '@fortawesome/free-brands-svg-icons';

@Component({
    selector: 'mainheader',
    templateUrl: './header.html',
    styleUrls: ['./header.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
    icon = {
        bell: faBell,
        wiki: faWikipediaW
    };

    currentUser: UserType = undefined;
    currentUserSubscription: Subscription;
    isCaregiver: boolean = false;
    isSiteAdmin: boolean = false;
    isOrgAdmin: boolean = false;
    isFacAdmin: boolean = false;
    isGuardian: boolean = false;
    isPrincipleInvestigator: boolean = false;
    isResearchCoordinator: boolean = false;
    isResearcher: boolean = false;

    currentPageView: string;
    currentRoleView: string;
    static parameters = [Router, ActivatedRoute, AuthService];

    constructor(public router: Router, public route: ActivatedRoute, authService: AuthService) {
        this.router = router;
        this.route = route;

        // get the currentUser from the JSON token, but if the current user is changed in AuthService,
        //      source info from there
        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserType;
        this.currentUserSubscription = authService.currentUserChanged.subscribe(user => {
            // console.log('HEADER user changed: ', user);
            this.currentUser = authService.currentUser;
            if (this.currentUser) {
                this.getRolePermissions();
            }
        });

        if (this.route.parent) {
            this.currentRoleView = this.route.parent.snapshot.url[0].path;
        } else {
            throw new Error('route.parent does not exist!');
        }
    }

    changeRoleViewTo(newRoleView: string) {
        if (newRoleView !== this.currentRoleView) {
            this.router.navigate([newRoleView, 'dashboard']);
        }
    }

    getRolePermissions() {
        this.isSiteAdmin = this.currentUser.role === 'admin';
        this.isOrgAdmin = this.currentUser.organizationAdminOf && this.currentUser.organizationAdminOf.length > 0;
        this.isFacAdmin = this.currentUser.facilityAdminOf && this.currentUser.facilityAdminOf.length > 0;
        this.isCaregiver = this.currentUser.caregiverOf && this.currentUser.caregiverOf.length > 0;
        this.isGuardian = this.currentUser.guardianOf && this.currentUser.guardianOf.length > 0;
        this.isPrincipleInvestigator = this.currentUser?.principleInvestigatorIn?.length > 0;
        this.isResearchCoordinator = this.currentUser?.researchCoordinatorOf?.length > 0;
        this.isResearcher = this.currentUser?.researcherOf?.length > 0;
        if (this.route.parent) {
            this.currentRoleView = this.route.parent.snapshot.url[0].path;
        }
    }

    ngOnInit(): void {
        this.getRolePermissions();
    }

    ngAfterViewInit(): void {
        // console.log('ngAfterViewInit');
        // this.getRolePermissions();
    }

    ngAfterViewChecked(): void {
        // console.log('ngAfterViewChecked');
        // this.getRolePermissions();
    }

    ngOnDestroy(): void {
        this.currentUserSubscription.unsubscribe();
    }
}

