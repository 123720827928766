import { Pipe, PipeTransform } from '@angular/core';
import {formatRoleName} from '../util';

@Pipe({
    name: 'formatRole'
})
export class FormatRolePipe implements PipeTransform {
    transform(value: string): string {
        return formatRoleName(value);
    }
}
