import {Component, Input} from '@angular/core';

@Component({
    selector: 'robot-icon',
    templateUrl: './robot-icon.svg',
    styleUrls: ['./robot-icon.scss']
})
export class RobotIconComponent {
    @Input() ellipse: boolean = true;
    fillColor = '#58C2EE';
}
