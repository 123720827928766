import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {faUserCircle, faSearch, faSortAmountDown, faImage} from "@fortawesome/free-solid-svg-icons";
import {FileService} from "../../../services/file/file.service";


@Component({
    selector: 'edit-file-modal',
    templateUrl: './editFile.html',
    styleUrls: ['./modalStyles.scss'],
})
export class EditFileModalComponent implements OnInit {
    file = undefined;
    state = 'editing';

    icon = {
        image: faImage,
    }

    editFile: EventEmitter<any> = new EventEmitter<any>();
    deleteFile: EventEmitter<any> = new EventEmitter<any>();

    static parameters = [BsModalRef, FileService];

    constructor(public bsModalRef: BsModalRef) {
        this.bsModalRef = bsModalRef;
    }

    ngOnInit(): void {
        if (!this.file) throw new Error('No file provided!');
        console.log(this.file);
        console.log(typeof this.file);
    }

    // getImageUrl(fileKey) {
    //     console.log(fileKey);
    //     this.fileService.download(fileKey, this.userId).subscribe({
    //         next: (blob) => {
    //             const unsafeImgUrl = URL.createObjectURL(blob);
    //             const sanitizedImgUrl = this.domSanitizer.bypassSecurityTrustUrl(unsafeImgUrl);
    //             const thisFile = this.filesObject.files.find(f => f.key === fileKey);
    //             thisFile.imgUrl = sanitizedImgUrl;
    //             console.log(thisFile);
    //         },
    //         error: (err) => {
    //             console.error(err);
    //         }
    //     });
    // }

    changeStateTo(newState = 'editing') {
        this.state = newState;
    }

    confirmEdit() {
        this.editFile.emit(this.file);
        this.close();
    }

    confirmDelete() {
        this.deleteFile.emit(this.file);
        this.close();
    }

    close() {
        this.bsModalRef.hide();
    }

    getCleanFileName(longFileName: string) {
        return decodeURIComponent(longFileName.slice(longFileName.lastIndexOf('/') + 1));
    }

    //TODO:
    getEditableFileName(longFileName:string){

    }
}

