import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { Router } from '@angular/router';
import { faUserCircle, faSearch, faSortAmountDown, faGamepad, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt, faBell } from '@fortawesome/free-regular-svg-icons';
import { UserType } from '../auth/user.service';


@Component({
    selector: 'userlistpanel',
    templateUrl: './userlistpanel.html',
    providers: [],
    styleUrls: ['./userlistpanel.scss'],
})


export class UserListPanelComponent implements OnInit, OnDestroy, OnChanges {
    showAlert = false;
    alertType = '';
    alertMessage = '';
    faUserCircle = faUserCircle;
    faBell = faBell;
    faSearch = faSearch;
    faSortAmountDown = faSortAmountDown;
    faCalendarAlt = faCalendarAlt;
    faGamepad = faGamepad;
    faUserFriends = faUserFriends;

    @Input() users: UserType[];
    @Input() renderedBy = '';
    @Input() clickCb = undefined;
    @Input() title: string = 'Users';
    //TODO: make anonymized version

    // @Input() anonymized: boolean = true;

    closeAlert() {
        this.showAlert = false;
        this.alertType = '';
        this.alertMessage = '';
    }

    search = '';

    static parameters = [Router];

    constructor(public router: Router) {
        this.router = router;
    }

    public ngOnInit(): void {
        //TODO: make anonymized version

        // if(this.anonymized){
        //     this.users = this.users.map(u => {
        //         console.log(delete u.email);
        //         return {...u,
        //             email: undefined,
        //             id: u.anonymizedName, _id: u.anonymizedName,
        //             birthday: undefined, weight: undefined,
        //             firstName: undefined, lastName: undefined, ryanName: undefined,
        //             guardians: undefined, caregivers: undefined, gender: undefined};
        //     })
        //     console.log(this.users);
        // }
        this.users = this.users.map((u) => ({...u, id: u.anonymizedName, _id: u.anonymizedName}));
    }

    public ngOnDestroy(): void { }

    searchUsers(evt) { }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    handleUserClick(userId) {
        if (this.clickCb) {
            this.clickCb(userId);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['users']) {
            this.users = changes['users'].currentValue.map((u) => ({...u, id: u.anonymizedName, _id: u.anonymizedName}));
        }
    }
}

