import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {Facility} from '../../interfaces/Facility';
import {ResearchStudy} from '../../interfaces/ResearchStudy';
import {UserType} from '../../auth/user.service';
import {AuthService} from '../../auth/auth.service';
import {getAge} from "../../util";

@Component({
    selector: 'addParticipantToStudy',
    templateUrl: './addParticipantToStudy.html',
    styleUrls: ['./addParticipantToStudy.scss']
})
/**
 * TODO: allow for the search / filtering of users
 */
export class AddParticipantToStudyComponent {
    @Input('study') study: ResearchStudy;
    @Input('optionFacilities') optionFacilities: Facility[] = [];
    @Input('optionParticipants') optionParticipants: UserType[] = [];
    @Input('alert') alert: {
        type: string,
        show: boolean,
        message: string,
    };
    @Output('addedParticipants') addedParticipants: EventEmitter<string[]> = new EventEmitter<string[]>();

    currentUser: UserType;
    participantsToBeAdded: string[] = [];
    static parameters = [BsModalRef, AuthService];

    constructor(public bsModalRef: BsModalRef) {}

    toggleParticipantMembership(email: string) {
        const participantIsMember = this.participantsToBeAdded.includes(email);
        if (participantIsMember) {
            this.participantsToBeAdded = this.participantsToBeAdded.filter(p => p !== email);
        } else {
            this.participantsToBeAdded.push(email);
        }
    }

    participantIsMember(email: string): boolean {
        return this.participantsToBeAdded.includes(email);
    }

    getFacilityForParticipant(user: UserType): Facility {
        return this.optionFacilities.find(f => f._id === user.activeFacility);
    }

    getParticipantAge(user: UserType): number {
        return getAge(user.birthday);
    }

    submitFacilityAssignment() {
        this.addedParticipants.emit(this.participantsToBeAdded);
        this.optionParticipants = this.optionParticipants.filter(p => !this.participantsToBeAdded.includes(p.email));
        this.participantsToBeAdded = [];
    }
}

