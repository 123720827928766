<div class="mainContainer">
    <header class="mainContainer__header">
        <mainheader></mainheader>
    </header>
    <div class="mainContainer__leftNav">
        <left-nav></left-nav>
    </div>

    <div class="mainContainer__main">
        <researchCoordinator-dashboard *ngIf="state.pageView === 'dashboard'"></researchCoordinator-dashboard>
        <researchCoordinator-gameMetrics *ngIf="state.pageView === 'gamemetrics'"></researchCoordinator-gameMetrics>
        <researchCoordinator-manage *ngIf="state.pageView === 'manage'"></researchCoordinator-manage>
        <researchCoordinator-usersActivities *ngIf="state.pageView === 'usersactivities'"></researchCoordinator-usersActivities>
<!--        <programrPage *ngIf="state.pageView === 'programr'"></programrPage>-->
        <wikiPage *ngIf="state.pageView === 'wiki'"></wikiPage>
        <settingsPage *ngIf="state.pageView === 'settings'" [viewAs]="this.state.roleView"></settingsPage>
    </div>
</div>
