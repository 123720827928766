<div class="page">
    <div class="mainContent">
        <nav class="breadcrumb breadcrumbs" aria-label="breadcrumb">
            <ol class="breadcrumbs__list">
                <li class="breadcrumb-item breadcrumbItem" [attr.aria-current]="(userId === 'me') ? 'page' : null" (click)="this.changeUserFocusTo('me')">
                    My Calendar
                </li>
                <li class="breadcrumb-item breadcrumbItem" *ngIf="!!userId && userId !== 'me'" [attr.aria-current]="(!!userId && userId !== 'me') ? 'page' : null">
                    {{this.userId}}'s calendar
                </li>
            </ol>
        </nav>

        <alert type="{{alert.type}}" *ngIf="alert.showFor === 'calendar'">{{alert.message}}</alert>

        <div class="calendar-wrapper contentBox">
            <calendar-view [eventsList]="eventsList" [(activeEventId)]="state.activeEventId"></calendar-view>
        </div>

    </div>
    <div class="rightPanel">
        <button *ngIf="!state.createEvent" (click)="toggleCreateEvent()" class="createEvent">
            Create Event
        </button>
        <div *ngIf="!!state.createEvent" class="contentBox">
            <create-event [calendarFunctions]="{
                handleCreateEvent : handleCreateEvent.bind(this),
                closeCreateEvent: toggleCreateEvent.bind(this)
            }"></create-event>
        </div>
        <userlistpanel *ngIf="usersInCare.length > 0" [users]="usersInCare" [clickCb]="this.changeUserFocusTo.bind(this)">
        </userlistpanel>
        <div class="contentBox" *ngIf="!!state.activeEventId">
            <view-event [userId]="userId" [(activeEventId)]="state.activeEventId" [calendarFunctions]="{
                handleDeleteEvent: handleDeleteEvent.bind(this),
                handleEditEvent: handleEditEvent.bind(this)
            }"></view-event>
        </div>
    </div>
</div>
