import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarViewComponent } from './calendarView/calendarView.component';
import { CalendarPageComponent } from '../../app/calendar/calendarPage.component';

import { CommonModule } from '@angular/common';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CreateEventComponent } from './createEvent/createEvent.component';
import { EditEventComponent } from './editEvent/editEvent.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import { CalendarEventService } from '../../services/calendar-event/calendar-event.service';
import { AuthService } from '../auth/auth.service';
import { ViewEventComponent } from './viewEvent/viewEvent.component';
import { ViewEventModalComponent } from './viewEvent-modal/viewEventModal.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DirectivesModule } from '../directives.module';

@NgModule({
    imports: [
        FullCalendarModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        FontAwesomeModule,
        AlertModule,
        DirectivesModule,
    ],
    declarations: [
        CalendarViewComponent,
        CalendarPageComponent,
        CreateEventComponent,
        EditEventComponent,
        ViewEventComponent,
        ViewEventModalComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ],
    exports: [
        CalendarViewComponent,
        CalendarPageComponent,
        CreateEventComponent,
        EditEventComponent,
        ViewEventComponent,
        ViewEventModalComponent,
    ],
    providers: [
        CalendarEventService,
        AuthService
    ]
})
export class CalendarSetModule { }
