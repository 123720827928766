import _ from 'lodash';

import sharedConfig from '../../server/config/environment/shared';

let localConfig = {
    gcp: {
        clientAPIKey: 'CLIENT API KEY HERE',
    },
};

export default _.merge(
    sharedConfig,
    localConfig);
