import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { UserService } from '../../../components/auth/user.service';
import {
    faCloudDownloadAlt,
    faFilter,
    faMinusCircle,
    faPlus,
    faSearch,
    faSortAmountDown,
    faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faBell, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { InventoryService } from '../../../services/inventory/inventory.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmDeleteComponent } from '../../../components/modals/confirm-delete/confirm-delete.component';
import { EditOrgFacComponent } from '../../../components/modals/edit-org-fac/edit-org-fac.component';
import { CreateFacilityComponent } from '../../../components/modals/create-facility/create-facility.component';
import { ReassignInventoryComponent } from '../../../components/modals/reassign-inventory/reassign-inventory.component';
import { OrganizationService } from '../../../services/organization/organization.service';
import {
    instanceOfOrganization,
    OrgAdminData,
    Organization,
    OrganizationRoleName
} from '../../../components/interfaces/Organization';
import {
    Facility,
    FacilityRoleName,
    FacilityUserType,
    instanceOfFacility, mapFacilityRoleNameToProperty
} from '../../../components/interfaces/Facility';
import { AddEmailComponent } from '../../../components/modals/add-email/add-email.component';
import _ from 'lodash';
import {FacilityService} from '../../../services/facility/facility.service';
import {Subscription} from 'rxjs';
import {checkIfExistsInRole, formatRoleName, getEntityTypeName} from '../../../components/util';
import {AddResearcherToFacilityOrStudyComponent} from '../../../components/modals/AddResearcherToFacilityOrStudy/addResearcherToFacilityOrStudy.component';


@Component({
    selector: 'orgAdmin-manage',
    templateUrl: './orgAdminManage.html',
    styleUrls: ['../../../assets/sharedStyles/pages/managePages.scss', './orgAdminManage.scss'],
})


export class OrgAdminManageComponent implements OnInit, OnDestroy, OnChanges {
    queryParamSubscription: Subscription;
    focusedOrgId: string;
    focusedFacilityId: string;
    focusedUserId: string;

    icon = {
        userCircle: faUserCircle,
        bell: faBell,
        search: faSearch,
        sort: faSortAmountDown,
        calendar: faCalendarAlt,
        filter: faFilter,
        download: faCloudDownloadAlt,
        add: faPlus,
        delete: faMinusCircle,
    };

    distinctBots: any;

    currentOrganization: Organization = null;
    currentFacility: Facility = null;

    allOrgs: Organization[] = [];

    static parameters = [Router, ActivatedRoute, UserService, InventoryService, OrganizationService, FacilityService, BsModalService];

    constructor(public router: Router, public route: ActivatedRoute, public userService: UserService, public inventoryService: InventoryService,
        public organizationService: OrganizationService, public facilityService: FacilityService, public modalService: BsModalService) {
        this.router = router;
        this.route = route;
        this.userService = userService;
        this.inventoryService = inventoryService;
        this.organizationService = organizationService;
        this.facilityService = facilityService;
        this.modalService = modalService;

        this.queryParamSubscription = this.route.queryParamMap.subscribe(queryParams => {
            this.focusedOrgId = queryParams.has('orgId') ? queryParams.get('orgId') : undefined;
            this.focusedFacilityId = queryParams.has('facilityId') ? queryParams.get('facilityId') : undefined;
            this.focusedUserId = queryParams.has('userId') ? queryParams.get('userId') : undefined;
        });
    }

    ngOnInit(): void {
        this.fullyRefreshOrgs();
    }

    fullyRefreshOrgs() {
        this.refreshOrgs()
            .then(() => {
                if (this.focusedOrgId) {
                    this.currentOrganization = this.getCurrentOrganization();
                    if (this.focusedFacilityId) {
                        this.currentFacility = this.getCurrentFacility();
                    }
                }
            })
            .catch(err => console.error(err));
    }

    focusOnOrganization(orgId: string = undefined) {
        this.router.navigate([], {queryParams: { orgId: orgId}});
        this.currentOrganization = this.getOrganization(orgId);
        this.currentFacility = null;
    }

    focusOnFacility(facilityId: string = undefined) {
        if (!this.focusedOrgId) return;
        this.router.navigate([], {queryParams: {orgId: this.focusedOrgId, facilityId: facilityId}});
        this.currentFacility = this.getFacility(facilityId);
    }

    getCurrentOrganization() {
        return this.allOrgs.find(o => o._id === this.focusedOrgId);
    }

    getOrganization(orgId?: string) {
        return this.allOrgs.find(o => o._id === orgId) || null;
    }

    getCurrentFacility() {
        const focusedOrg: Organization = this.currentOrganization ? this.currentOrganization : this.getCurrentOrganization();
        return focusedOrg.facilities.find(f => f._id === this.focusedFacilityId || f === this.focusedFacilityId);
    }

    getFacility(facilityId?: string) {
        return this.currentOrganization.facilities.find((f) => f._id === facilityId);
    }

    botSelect(evt) {
        console.log(evt);
        //    TODO: when user select is updated, change the query and re-filter/parse/format data
    }

    addAdminOrUserEmailTo(addToType: OrganizationRoleName | FacilityRoleName, addTo: Organization | Facility) {
        const initialState = { addToType, addTo };
        const entityTypeName = getEntityTypeName(addTo);
        const anOrA = formatRoleName(addToType).startsWith('Org') ? 'an' : 'a'; //use proper "a" / "an" grammar in alerts
        const modalRef = this.modalService.show(AddEmailComponent, { initialState });
        modalRef.content.confirmEmail.subscribe(toBeAdded => {
            if (!toBeAdded) return;
            //Ensure the user does not already exist in the org / facility in target role
            if (checkIfExistsInRole(addToType, toBeAdded, addTo)) {
                modalRef.content.alert = {
                    type: 'info',
                    show: true,
                    message: `${toBeAdded} has already been invited to join this ${entityTypeName} as ${anOrA} ${formatRoleName(addToType)}.`
                };
                return;
            }
            var promise: Promise<Object>;
            if (instanceOfOrganization(addTo)) {
                if (addToType === 'organizationadmin') {
                    promise = this.organizationService.addOrganizationAdmin(addTo, toBeAdded);
                } else if (addToType === 'organizationresearcher' && instanceOfOrganization(addTo)) {
                    promise = this.organizationService.addResearcherToOrganization(addTo, toBeAdded);
                }
            } else { // instanceOfFacility(addTo) === true
                // eslint-disable-next-line no-lonely-if
                if (addToType === 'facilityadmin') {
                    promise = this.facilityService.addFacilityAdmin(addTo, toBeAdded);
                } else if (addToType === 'user') {
                    promise = this.facilityService.addUserToFacility(toBeAdded, addTo._id).toPromise();
                } else if (addToType === 'principleinvestigator') {
                    promise = this.facilityService.addPrincipleInvestigatorToFacility(toBeAdded, addTo);
                }
            }
            promise
                .then(() => {
                    this.fullyRefreshOrgs();
                    modalRef.content.alert = {
                        type: 'success',
                        show: true,
                        message: `'${toBeAdded}' has successfully been invited to join this ${entityTypeName} as ${anOrA} ${formatRoleName(addToType)}.`
                    };
                })
                .catch((err) => {
                    console.error(err);
                    modalRef.content.alert = {
                        type: 'danger',
                        show: true,
                        message: `We're sorry, an error occurred trying to invite ${toBeAdded} to join this ${entityTypeName} as ${anOrA} ${formatRoleName(addToType)}.`
                    };
                })
                .finally(() => {
                    setTimeout(() => {
                        modalRef.content.alert = {...modalRef.content.alert, show: false};
                    }, 3000);
                });
        });
    }

    addResearcherToFacility(facility: Facility) {
        if (!this.currentOrganization) throw new Error('no currentOrganization');
        const currentOrg: Organization = this.currentOrganization;
        const role = 'principleinvestigator';
        const validResearchers = currentOrg.researchers;
        const propName = mapFacilityRoleNameToProperty(role);
        const initialState = {
            entity: facility,
            role: role,
            organization: currentOrg,
            optionOrganizations: [currentOrg],
            optionResearchers: validResearchers.filter(r => !facility[propName].some(u => u.email === r))
        };
        const modalRef = this.modalService.show(AddResearcherToFacilityOrStudyComponent, { initialState, class: 'modal-lg' });
        modalRef.content.addedResearcher.subscribe(toBeAdded => {
            if (!toBeAdded) return;

            //Ensure the user does not already exist in the study in target role
            if (checkIfExistsInRole(role, toBeAdded, facility)) {
                modalRef.content.alert = {
                    type: 'info',
                    show: true,
                    message: `${toBeAdded} has already been invited to join this study as a ${formatRoleName(role)}.`
                };
                return;
            }

            this.facilityService.addPrincipleInvestigatorToFacility(toBeAdded, facility)
                .then(() => {
                    modalRef.content.alert = {
                        type: 'success',
                        show: true,
                        message: `${toBeAdded} has been invited to join this study as a ${formatRoleName(role)}.` };
                    setTimeout(() => {
                        modalRef.hide();
                    }, 5000);
                })
                .then(() => {
                    this.fullyRefreshOrgs();
                })
                .catch((e) => {
                    console.error(e);
                    modalRef.content.alert = {
                        type: 'danger',
                        show: true,
                        message: `${e.message}`
                    };
                });
        });
    }

    checkForFocusedOrganization() {
        if (this.focusedOrgId) this.focusOnOrganization(this.focusedOrgId);
    }

    checkForFocusedFacility() {
        if (this.focusedOrgId && this.focusedFacilityId) this.focusOnFacility(this.focusedFacilityId);
    }

    checkIfOnlyOneOrgThenFocus(orgArr: any[]) {
        if (orgArr.length === 1) {
            this.focusOnOrganization(orgArr[0]._id);
            this.currentOrganization = orgArr[0];
        }
    }

    refreshOrgs() {
        return this.organizationService.getOrganizationsForAdmin().toPromise()
            .then((orgs: OrgAdminData) => {
                const { organizations } = orgs;
                this.allOrgs = [...organizations];
                // remove this when api is changed to show whole facilityObject
                return organizations as Organization[];
            })
            .then((orgs: Organization[]) => this.refreshFacilities(orgs)
                .then((populatedOrgs: Organization[]) => {
                    this.allOrgs = populatedOrgs.sort((a, b) => a.name.localeCompare(b.name));
                    return Promise.resolve();
                }))
            .catch(e => console.error(e));
    }

    refreshFacilities(orgSet?: Organization[]): Promise<Organization[]> {
        if (!orgSet) orgSet = [...this.allOrgs];
        //get the facilityIDs from the list of orgs
        const facIdList = _.flatten(orgSet.map(o => o.facilities));
        //create a retrieval promise for each facilityID that responds with the Facility object
        const promises = facIdList.map(id => new Promise<Facility>((resolve, reject) => {
            this.facilityService.getFacilityById(id).toPromise()
                .then((res: Facility) => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        }));
        //Once all Facilities have been loaded, add them to the appropriate org
        return Promise.all(promises)
            .then((res: Facility[]) => orgSet.map(o => {
                const populatedFacilities = res
                    .filter(fac => o.facilities.includes(fac._id))
                    .sort((a, b) => a.name.localeCompare(b.name));
                return {...o, facilities: [...populatedFacilities]};
            }))
            .catch(e => {
                console.error(e);
                return Promise.reject(e);
            });
    }

    public openAddFacilityForOrganization(organization: Organization) {
        const modalRef = this.modalService.show(CreateFacilityComponent);
        modalRef.content.newFacilityCreation.subscribe(facilityToCreate => {
            this.organizationService.createFacility(organization, facilityToCreate).subscribe({
                next: (newFacility) => {
                    modalRef.hide();
                    this.fullyRefreshOrgs();
                },
                error: (e) => {
                    modalRef.hide();
                    console.error(e);
                }
            });
        });
    }

    /**
     * get available information on an Org's Admin
     * @param orgAdmin
     */
    getUser(orgAdmin: string) {
        let test;
        this.userService.getUserByEmail(orgAdmin).subscribe((user) => {
            test = user;
        });
    }

    openReassignInventoryModal(assignToType: string, assignTo, orgObject: Organization) {
        if (assignToType === 'organization') {
            const initialState = { assignToType, assignTo, orgObject };
            const modalRef = this.modalService.show(ReassignInventoryComponent, { initialState });
            modalRef.content.confirmSubmit.subscribe({
                next: (selectedInventory) => {
                    this.organizationService.reassignInventoryToOrganization(assignTo, selectedInventory);
                    modalRef.hide();
                },
                error: () => {
                    modalRef.hide();
                }
            });
        }
        if (assignToType === 'facility') {
            const initialState = { assignToType, assignTo, orgObject };
            const modalRef = this.modalService.show(ReassignInventoryComponent, { initialState });
            modalRef.content.confirmSubmit.subscribe({
                next: (selectedInventory) => {
                    this.organizationService.reassignInventoryToFacility(assignTo, orgObject, selectedInventory)
                        .subscribe((data) => {
                            this.fullyRefreshOrgs();
                        }, (err) => {
                            console.error(err);
                        });
                    modalRef.hide();
                },
                error: () => {
                    modalRef.hide();
                }
            });
        }
    }

    assignBotToFacility(facility) {
        console.log(facility);
    }

    openEditModal(editType: string, toEdit: Organization | Facility) {
        // edit type: 'organization' or 'facility'
        editType = editType.toLowerCase();
        if (editType === 'organization') {
            const initialState = { editType, toEdit };
            const modalRef = this.modalService.show(EditOrgFacComponent, { initialState });
            modalRef.content.confirmEdit.subscribe(({ editedEntity }) => {
                this.organizationService.update(editedEntity)
                    .subscribe({
                        next: (d) => {
                            this.fullyRefreshOrgs();
                            modalRef.hide();
                        },
                        error: (e) => console.error(e)
                    });
            });
        }
        if (editType === 'facility') {
            const initialState = { editType, toEdit };
            const modalRef = this.modalService.show(EditOrgFacComponent, { initialState });
            modalRef.content.confirmEdit.subscribe(({ editedEntity }) => {
                this.organizationService.updateFacility(editedEntity)
                    .subscribe({
                        next: (d) => {
                            this.fullyRefreshOrgs();
                            modalRef.hide();
                        },
                        error: (e) => console.error(e)
                    });
            });
        }
    }

    openDeleteModal(deleteType: OrganizationRoleName | FacilityRoleName | 'organization' | 'facility' | 'principleinvestigator',
        toDelete: Organization | Facility | FacilityUserType | string,
        deleteFrom: Organization | Facility = undefined) {
        //toDelete: 'organization' || 'facility'|| 'organizationadmin' || 'organizationresearcher' || 'facilityadmin'
        //                  || 'principleinvestigator' || 'user'

        // disallow caregiver removal
        if (deleteType === 'caregiver') throw new Error('Error: cannot remove \'caregiver\'');
        const initialState = { deleteType, toDelete, deleteFrom };
        const modalRef = this.modalService.show(ConfirmDeleteComponent, { initialState });
        modalRef.content.confirmDeletion.subscribe(({ removeType, removeValue, removeFrom }) => {
            var promise: Promise<Object>;
            switch (deleteType) {
            case 'organization':
                promise = this.organizationService.remove(removeValue);
                break;
            case 'facility':
                promise = this.organizationService.removeFacilityFromOrganization(removeValue, removeFrom).toPromise();
                break;
            case 'organizationadmin':
                promise = this.organizationService.removeOrganizationAdmin(removeFrom._id, removeValue);
                break;
            case 'facilityadmin':
                promise = this.facilityService.removeFacilityAdmin(removeFrom._id, removeValue);
                break;
            case 'organizationresearcher':
                promise = this.organizationService.removeResearcherFromOrganization(removeFrom._id, removeValue);
                break;
            case 'principleinvestigator':
                if (instanceOfFacility(deleteFrom) && !instanceOfOrganization(toDelete) && !instanceOfFacility(toDelete)) {
                    promise = this.facilityService.removePrincipleInvestigatorFromFacility(deleteFrom, toDelete);
                }
                break;
            default: // 'user'
                promise = this.facilityService.removeUserFromFacility(removeValue.email, removeFrom);
            }
            promise
                .then((d) => {
                    this.fullyRefreshOrgs();
                })
                .catch((err) => {
                    console.error(err);
                });
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { previousValue, currentValue } = changes['state'];
    }

    ngOnDestroy(): void { }
}
