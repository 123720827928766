import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UserService, UserType } from '../../../components/auth/user.service';
import { AuthService } from '../../../components/auth/auth.service';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CalendarEvent } from '../../../components/interfaces/CalendarEvent';
import { CalendarEventService } from '../../../services/calendar-event/calendar-event.service';
import {featureFlags} from '../../../../server/config/environment/shared';

@Component({
    selector: 'userGameMetrics',
    templateUrl: './userGameMetrics.html',
    styleUrls: ['../../../assets/sharedStyles/pages/gameMetrics.scss'],
})


export class UserGameMetricsComponent implements OnInit, OnDestroy {
    @Output() queryDateRangeChange = new EventEmitter<[Date, Date]>();

    Router;
    currentUser;
    currentDate = new Date();

    featureFlags = featureFlags;

    state = {
        org_id: undefined,
        ryan_id: undefined
    };

    loaded: boolean = true; //determines if all necessary content is loaded ("all" defined by permissions)
    usersLoaded: boolean = false; //determines if all user content is loaded
    eventsLoaded: boolean = false; //determines if all Events have been loaded

    icon = {
        bell: faBell,
        userCircle: faUserCircle,
        search: faSearch
    };

    dateParams = {
        minAllowed: new Date('November 1, 2020 01:00:00'),
        maxAllowed: this.currentDate,
        minParam: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7),
        maxParam: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        ranges: [{
            value: [
                new Date(new Date().setDate(new Date().getDate() - 7)),
                new Date()],
            label: 'Last 7 days'
        },
        {
            value: [new Date(new Date().setDate(new Date().getDate() - 30)),
                new Date()],
            label: 'Last 30 days'
        },
        ]
    };
    // data: any = UserGameData; //TODO: once reliant on real data, remove assignment
    allUsers: UserType[] = [];
    numUsers: number;

    numBots = {
        all: 0,
        active: 0,
        suspended: 0,
        disabled: 0
    };

    queryMinDate: Date = undefined;
    queryMaxDate: Date = undefined;
    queryDateRange:[Date, Date] = [this.queryMinDate, this.queryMaxDate];

    myEvents: CalendarEvent[] = [];
    numEvents: number;

    static parameters = [Router, AuthService, UserService, CalendarEventService,
        BsModalService, HttpClient];

    constructor(public router: Router, authService: AuthService,
        public userService: UserService, public eventService: CalendarEventService,
        public modalService: BsModalService, public http: HttpClient) {
        this.router = router;
        this.modalService = modalService;
        this.userService = userService;
        this.eventService = eventService;

        this.loaded = false;

        //get the current user from the token
        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserType;
    }

    ngOnInit(): void {
        this.getUserGroup();
        this.getMyEvents();
        this.loaded = this.isLoaded();
    }

    ngOnDestroy(): void {}

    /**
     * isLoaded
     *      this method will determine whether or not all of the necessary parts of the page have been loaded
     */
    isLoaded(): boolean {
        //TODO: get info for each user in current user's 'caregiverOf' prop
        this.loaded = this.usersLoaded && this.eventsLoaded; //&& this.gameDataLoaded
        // console.log(this.loaded);
        return this.usersLoaded && this.eventsLoaded; //TODO: analyticsLoaded
    }

    /**
     * getUserGroup
     *      method to be used by users to retrieve everyone in their "group"
     *          (guardians, caregivers)
     */
    getUserGroup() {
        console.log(this.currentUser);
        console.log(`has caregivers: ${this.currentUser.hasOwnProperty('caregivers')}`);
        if (this.currentUser.hasOwnProperty('caregivers')) {
            console.log('has caregivers');
            //    TODO: query for caregiver info
        }

        console.log(`has guardians: ${this.currentUser.hasOwnProperty('guardians')}`);
        if (this.currentUser.hasOwnProperty('guardians')) {
            console.log('has guardians');
            //    TODO: query for guardian info
        }

        this.usersLoaded = true;
    }


    getMyEvents() {
        this.eventService.query(this.currentUser.email)
            .then((res) => {
                // console.log('events:');
                console.log(res);
                this.myEvents = res as CalendarEvent[];
                // this.numEvents = res.length;
            })
            .then(() => {
                console.log(this.myEvents);
                this.eventsLoaded = true;
                this.isLoaded();
            })
            .catch((err) => console.log(err));
    }


    navToCalendar() {
        // this.router.navigate(['/calendar']);
    }

    queryDateUpdate(ev) {
        this.queryMinDate = ev[0];
        this.queryMaxDate = ev[1];
        this.queryDateRange = [this.queryMinDate, this.queryMaxDate];
        this.queryDateRangeChange.emit(this.queryDateRange);
    }

    downloadData() {
        console.log('downloadData');
    }

    /**
     * initDatePicker: initialize the values used to control the datePicker
     */
    public initDatePicker() {
        this.currentDate = new Date();
        //set date params without timestamp
        this.queryMinDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() - 7);
        this.queryMaxDate = new Date(this.currentDate);
        this.dateParams = {...this.dateParams,
            minAllowed: new Date('November 1, 2020 01:00:00'),
            maxAllowed: this.currentDate,
            minParam: this.queryMinDate,
            maxParam: this.queryMaxDate
        };
        this.queryDateRange = [this.queryMinDate, this.queryMaxDate];
        console.log(this.queryDateRange);
    }
}
