import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserType} from '../../../components/auth/user.service';
import {
    faCloudDownloadAlt,
    faFilter, faPlus,
    faSearch,
    faSortAmountDown,
    faUserCircle,
    faMinusCircle, faEye,
} from '@fortawesome/free-solid-svg-icons';
import { faBell, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { BsModalService } from 'ngx-bootstrap/modal';
import {FacilityService} from '../../../services/facility/facility.service';
import {Subscription} from 'rxjs';
import {CreateStudyComponent} from '../../../components/modals/create-study/create-study.component';
import {ResearchService} from '../../../services/research/research.service';
import {
    ResearchStudy,
    ResearchRoleName,
    mapResearchRoleNameToProperty
} from '../../../components/interfaces/ResearchStudy';
import {Facility, instanceOfFacility} from '../../../components/interfaces/Facility';
import {ConfirmDeleteComponent} from '../../../components/modals/confirm-delete/confirm-delete.component';
import {checkIfExistsInRole, formatRoleName} from '../../../components/util';
import {Organization} from '../../../components/interfaces/Organization';
import {OrganizationService} from '../../../services/organization/organization.service';
import {AddFacilityToStudyComponent} from '../../../components/modals/addFacilityToStudy/addFacilityToStudy.component';
import {AuthService} from '../../../components/auth/auth.service';
import _ from 'lodash';
import {AddResearcherToFacilityOrStudyComponent} from '../../../components/modals/AddResearcherToFacilityOrStudy/addResearcherToFacilityOrStudy.component';
import {EditOrgFacComponent} from '../../../components/modals/edit-org-fac/edit-org-fac.component';
import {EditStudyComponent} from '../../../components/modals/edit-study/edit-study.component';

@Component({
    selector: 'principleInvestigator-manage',
    templateUrl: './PI-Manage.html',
    styleUrls: ['../../../assets/sharedStyles/pages/managePages.scss', './PI-Manage.scss'],
})


export class PrincipleInvestigatorManageComponent implements OnInit, OnDestroy {
    queryParamSubscription: Subscription;
    currentUserSubscription: Subscription;
    currentUser: UserType;
    focusedStudyId: string;

    icon = {
        userCircle: faUserCircle,
        bell: faBell,
        search: faSearch,
        sort: faSortAmountDown,
        calendar: faCalendarAlt,
        filter: faFilter,
        download: faCloudDownloadAlt,
        add: faPlus,
        delete: faMinusCircle,
        view: faEye,
    };

    allStudies: ResearchStudy[] = [];
    allOrganizations: Organization[] = []; //organizations for which I'm a researcher in
    allFacilities: Facility[] = [];
    currentStudy: ResearchStudy;

    static parameters = [AuthService, Router, ActivatedRoute, OrganizationService, FacilityService, ResearchService, BsModalService];

    constructor(public authService: AuthService, public router: Router, public route: ActivatedRoute, public organizationService: OrganizationService,
                public facilityService: FacilityService, public researchService: ResearchService,
                public modalService: BsModalService) {
        this.router = router;
        this.route = route;
        this.facilityService = facilityService;
        this.researchService = researchService;
        this.modalService = modalService;

        this.queryParamSubscription = this.route.queryParamMap.subscribe(queryParams => {
            this.focusedStudyId = queryParams.has('studyId') ? queryParams.get('studyId') : undefined;
        });
        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserType;
        this.currentUserSubscription = authService.currentUserChanged.subscribe(updatedUser => {
            this.currentUser = updatedUser as UserType;
        });
    }

    ngOnInit(): void {
        this.refreshFullyPopulatedStudies();
    }

    ngOnDestroy(): void {
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }

    refreshFullyPopulatedStudies() {
        Promise.all([this.getAllResearchStudies(), this.getAllOrganizations(), this.getAllFacilities()])
            .then(([studies, orgs, facilities]: [ResearchStudy[], Organization[], Facility[]]) => {
                this.allFacilities = [...facilities] as Facility[];
                const organizations = [...orgs] as Organization[];
                //populate the Org facilityId[] with actual Facility[]
                this.allOrganizations = organizations.map((o) => ({
                    ...o,
                    facilities: o.facilities
                        //filter out any facilities that have not granted PI permission to conduct research
                        .filter(f => facilities.some((fac: Facility) => fac._id === f))
                        //replace id with actual Facility w/ corresponding id
                        .map(f => facilities.find(fac => fac._id === f))
                })) as Organization[];
                //do the same for ResearchStudy.facilities
                this.allStudies = studies.map((study: ResearchStudy) => ({
                    ...study,
                    //replace id with actual Facility w/ corresponding id
                    facilities: study.facilities?.length > 0
                        ? study.facilities.map(f => facilities.find(fac => fac._id === f))
                            .sort((a, b) => a.name.localeCompare(b.name)) as Facility[]
                        : [] as Facility[]
                })) as ResearchStudy[];
            })
            .then(() => {
                this.currentStudy = this.getCurrentStudy();
                return this.allStudies;
            })
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
    }

    /**
     * getAllResearchStudies
     */
    getAllResearchStudies(): Promise<ResearchStudy[]> {
        return this.researchService.getAllMyStudies('principleinvestigator')
            .then((res: {studies: ResearchStudy[]}) => {
                const sortedStudies: ResearchStudy[] = res.studies
                    .sort((a, b) => a.title.localeCompare(b.title));
                return Promise.resolve(sortedStudies as ResearchStudy[]);
            })
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
    }

    /**
     * getAllOrganizations
     */
    getAllOrganizations(): Promise<Organization[]> {
        return this.organizationService.getOrganizationsForResearcher()
            .then((orgs: {organizations: Organization[]}) => orgs.organizations as Organization[])
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
    }

    /**
     * getAllFacilities
     */
    getAllFacilities(): Promise<Facility[]> {
        return this.facilityService.getFacilitiesForPrincipleInvestigator()
            .then((facs: {facilities: Facility[]}) => facs.facilities.sort((a, b) => a.name.localeCompare(b.name)) as Facility[])
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
    }

    focusOnStudy(studyId?: string) {
        this.router.navigate([], {queryParams: {studyId: studyId}});
        this.currentStudy = this.getStudy(studyId);
    }

    getCurrentStudy(): ResearchStudy {
        this.currentStudy = this.getStudy(this.focusedStudyId);
        return this.currentStudy;
    }

    getStudy(studyId: string): ResearchStudy {
        return this.allStudies.find(s => s._id === studyId);
    }

    /**
     * openDeleteModal -- opens the modal to delete a facilityAdmin, caregiver, or a User from a faciltiy
     * @param role: the role of the user to delete ('researchcoordinator', 'coordinator', 'researcher', or 'participant')
     * @param toDelete: the id (email or anonymizedId) of the user to delete
     * @param study: The Study to delete the entity from
     */
    openDeleteModal(role: ResearchRoleName, toDelete: string, study: ResearchStudy) {
        const initialState = {
            deleteType: role,
            toDelete: toDelete,
            deleteFrom: study
        };
        const modalRef = this.modalService.show(ConfirmDeleteComponent, { initialState });
        modalRef.content.confirmDeletion.subscribe(({ removeType, removeValue, removeFrom }) => {
            if (role !== 'principleinvestigator') {
                this.researchService.removeMemberFromStudy(role, study, toDelete)
                    .then(() => {
                        modalRef.content.alert = {
                            type: 'success',
                            show: true,
                            message: `${toDelete} has been removed from this study as a ${formatRoleName(role)}.` };
                        setTimeout(() => {
                            modalRef.hide();
                        }, 10000);
                    })
                    .then(() => {
                        this.refreshFullyPopulatedStudies();
                    })
                    .catch(err => {
                        console.error(err);
                    });
            } else {
                throw new Error(`Invalid value for role: ${role}`);
            }
        });
    }

    addResearcherToStudy(role: 'researcher' | 'coordinator', addTo: ResearchStudy) {
        //get the parent org(s) of all facilities in this study;
        const affiliatedOrgs = this.getOrgsAffiliatedWithStudy(addTo);
        const allResearchers = affiliatedOrgs.map(o => o.researchers);
        //  the set of valid researchers are those with permission in ALL orgs affiliated with a study
        //      (affiliation defined by an org having a Facility that's in the study)
        //      ( _.intersection is faster -and more readable- than js Set intersection:
        //              https://www.measurethat.net/Benchmarks/Show/3690/0/lodash-vs-set-intersection)
        const validResearchers = _.intersection(...allResearchers);
        const propName = mapResearchRoleNameToProperty(role);
        const initialState = {
            role: role,
            entity: addTo,
            optionOrganizations: affiliatedOrgs,
            optionResearchers: validResearchers.filter(r => !addTo[propName].some(u => u.email === r))
        };
        const modalRef = this.modalService.show(AddResearcherToFacilityOrStudyComponent, { initialState, class: 'modal-lg' });
        modalRef.content.addedResearcher.subscribe(toBeAdded => {
            if (!toBeAdded) return;

            //Ensure the user does not already exist in the study in target role
            if (checkIfExistsInRole(role, toBeAdded, addTo)) {
                modalRef.content.alert = {
                    type: 'info',
                    show: true,
                    message: `${toBeAdded} has already been invited to join this study as a ${formatRoleName(role)}.`
                };
                return;
            }

            this.researchService.addMemberToStudy(role, addTo, toBeAdded)
                .then(() => {
                    modalRef.content.alert = {
                        type: 'success',
                        show: true,
                        message: `${toBeAdded} has been invited to join this study as a ${formatRoleName(role)}.` };
                    setTimeout(() => {
                        modalRef.hide();
                    }, 5000);
                })
                .then(() => {
                    this.refreshFullyPopulatedStudies();
                })
                .catch((e) => {
                    console.error(e);
                    modalRef.content.alert = {
                        type: 'danger',
                        show: true,
                        message: `${e.message}`
                    };
                });
        });
    }

    getOrgsAffiliatedWithStudy(study: ResearchStudy): Organization[] {
        // eslint-disable-next-line no-confusing-arrow
        const studyFacilityIds = study.facilities.map((f) => instanceOfFacility(f) ? f._id : f);
        //get the parent org(s) of all facilities in this study;
        return this.allOrganizations.filter(o => o.facilities.some(f => {
            const searchId = instanceOfFacility(f) ? f._id : f;
            return studyFacilityIds.includes(searchId);
        }));
    }

    addFacilityToStudy(addTo: ResearchStudy) {
        const initialState = {
            study: addTo,
            optionFacilities: [...this.allFacilities.filter(f => !addTo.facilities.includes(f || f._id))]
        };
        const modalRef = this.modalService.show(AddFacilityToStudyComponent, { initialState });
        modalRef.content.addedFacilities.subscribe((toBeAdded: Facility[]) => {
            if (!toBeAdded || toBeAdded.length === 0) return;
            this.researchService.addFacilitiesToStudy(addTo, toBeAdded)
                .then((res) => {
                    const pluralized = toBeAdded.length !== 1 ? 'Facilities have' : 'Facility has';
                    modalRef.content.alert = {
                        type: 'success',
                        show: true,
                        message: `${toBeAdded.length} ${pluralized} been added to this study`
                    };
                    setTimeout(() => {
                        modalRef.hide();
                    }, 2000);
                })
                .then(() => {
                    this.refreshFullyPopulatedStudies();
                })
                .catch(err => {
                    console.error(err);
                    modalRef.content.alert = {
                        type: 'danger',
                        show: true,
                        message: 'An error occurred trying to add the selected facilities to this study'
                    };
                    setTimeout(() => {
                        modalRef.hide();
                    }, 3000);
                });
        });
    }

    createNewStudy() {
        const modalRef = this.modalService.show(CreateStudyComponent, {class: 'modal-lg'});
        modalRef.content.newStudyCreation.subscribe((toCreate: ResearchStudy) => {
            // error-checking is handled by EditStudyComponent, so we know we're okay to just go ahead and create it
            this.researchService.create(toCreate)
                .toPromise()
                .then(() => {
                    modalRef.content.formInfo = 'Research Study created successfully!';
                    setTimeout(() => {
                        modalRef.hide();
                    }, 3000);
                })
                .then(() => {
                    this.getAllResearchStudies()
                        .then((studies: ResearchStudy[]) => {
                            this.allStudies = [...studies];
                            setTimeout(() => {
                                location.reload();
                            }, 1000);
                        });
                })
                .catch(err => {
                    console.error(err);
                    modalRef.content.formError = err.error.message;
                });
        });
    }

    editStudy(toEdit) {
        const initialState = { toEdit };
        const modalRef = this.modalService.show(EditStudyComponent, {class: 'modal-lg', initialState});
        modalRef.content.confirmEdit.subscribe((editedStudy: ResearchStudy) => {
            // error-checking is handled by EditStudyComponent, so we know we're okay to just go ahead and create it
            this.researchService.updateFacility(editedStudy)
                .toPromise()
                .then(() => {
                    modalRef.content.formInfo = 'Research Study updated successfully!';
                    setTimeout(() => {
                        modalRef.hide();
                    }, 1000);
                })
                .then(() => {
                    this.getAllResearchStudies()
                        .then((studies: ResearchStudy[]) => {
                            this.allStudies = [...studies];
                            this.getCurrentStudy();
                            setTimeout(() => {
                                location.reload();
                            }, 1000);
                        });
                })
                .catch(err => {
                    console.error(err);
                    modalRef.content.formError = err.error.message;
                });
        });
    }
}


