<router-outlet></router-outlet>
<!--<ng-container *ngIf="!isLoggedIn">-->
<!--    <router-outlet></router-outlet>-->
<!--</ng-container>-->

<!--<ng-container *ngIf="isLoggedIn">-->
<!--    <div class="mainContainer">-->
<!--        <div class="mainContainer__main">-->
<!--            <router-outlet></router-outlet>-->
<!--        </div>-->
<!--        <mainheader class="mainContainer__header"></mainheader>-->
<!--        <left-nav class="mainContainer__leftNav"></left-nav>-->
<!--    </div>-->
<!--</ng-container>-->
