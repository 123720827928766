<div class="mainContainer">
    <header class="mainContainer__header">
        <mainheader></mainheader>
    </header>
    <div class="mainContainer__leftNav">
        <left-nav></left-nav>
    </div>

    <div class="mainContainer__main">
        <guardian-dashboard *ngIf="route.snapshot.params.pageView === 'dashboard'"></guardian-dashboard>
        <guardian-gameMetrics *ngIf="route.snapshot.params.pageView === 'gamemetrics'"></guardian-gameMetrics>
        <guardian-calendar *ngIf="route.snapshot.params.pageView === 'calendar'"></guardian-calendar>
<!--        <guardian-media *ngIf="route.snapshot.params.pageView === 'media'"></guardian-media>-->
        <programrPage *ngIf="route.snapshot.params.pageView === 'programr'"></programrPage>
        <wikiPage *ngIf="state.pageView === 'wiki'"></wikiPage>
        <settingsPage *ngIf="route.snapshot.params.pageView === 'settings'" [viewAs]="this.state.roleView"></settingsPage>
    </div>
</div>

