<div class="graph-area">
    <div class="total-usage__header">
        <div class="title">Age</div>
    </div>


    <div class="total-usage__mainGraph" id="mainGraph">
<!--        TODO: Implement this-->
<!--        <div *ngIf="dataReady === false" class="loadingData">-->
<!--            <mat-spinner class="loadingSpinner"></mat-spinner>-->
<!--            Loading Data...-->
<!--        </div>-->
        <ngx-charts-bar-vertical *ngIf="data.length > 0" [view]="chartOptions.view" [scheme]="chartOptions.colorScheme" [results]="chartOptions.results" [gradient]="chartOptions.gradient" [xAxis]="chartOptions.showXAxis" [yAxis]="chartOptions.showYAxis" [legend]="chartOptions.showLegend" [showXAxisLabel]="chartOptions.showXAxisLabel" [showYAxisLabel]="chartOptions.showYAxisLabel" [xAxisLabel]="chartOptions.xAxisLabel" [maxXAxisTickLength]="22" [yAxisLabel]="chartOptions.yAxisLabel" [yAxisTicks]="chartOptions.yAxisTicks" [yScaleMax]="chartOptions.yScaleMax" [animations]="chartOptions.animations" (select)="chartOptions.onSelect($event)">
<!--            <ng-template #tooltipTemplate let-model="model">-->
<!--                <span class="tooltip-label">{{model.series}} &bull; {{model.label}}</span>-->
<!--                <span class="tooltip-val">{{this.getTooltipTemplateTime(model)}}</span>-->
<!--            </ng-template>-->
        </ngx-charts-bar-vertical>
<!--        <ngx-charts-bar-vertical *ngIf="data.length > 0"></ngx-charts-bar-vertical>-->
    </div>
</div>
