import {Facility} from "./Facility";

export interface ResearchStudy {
    _id?: string,
    title: string,
    irbNumber?: string,
    notes?: StudyNote[],
    description?: string, // a description of the study itself
    sponsors?: any,
    facilities?: any[],   // facilities who've granted permission to the researchers
    keywords?: string[],
    startDate: Date,      // the earliest date for which researchers can query data
    endDate: Date,        // the latest date for which researchers can query data
    principleInvestigators: Researcher[],
    participants?: any[], //anonymized users participating in the study
    researchCoordinators?: Researcher[],
    researchers?: Researcher[],
    state: string,
    summary?: any,         // a summary of the results of the study
}

export interface StudyNote {
    title: string, //plain-text
    topic?: string, //plain-text
    content: string, //rich-text
    dateCreated: Date,
    dateLastEdited?: Date,
    createdBy: string, //email or anonymizedName of researcher
    lastEditedBy?: string, //email or anonymizedName of researcher
}

export interface Researcher {
    email: string,
    anonymizedName: string,
    invitationAccepted: boolean,
    dateAddedToStudy: Date
}

export type ResearchRoleName = 'principleinvestigator' | 'researchcoordinator' | 'coordinator' | 'researcher' | 'participant';

export function instanceOfResearchStudy(obj): obj is ResearchStudy {
    // StudyNote and Facility each have one of these properties, but only ResearchStudy has both
    return obj.hasOwnProperty('title') && obj.hasOwnProperty('principleInvestigators');
}

export function isResearchRoleName(str: string): str is ResearchRoleName {
    return ['principleinvestigator', 'researchcoordinator', 'coordinator', 'researcher'].includes(str);
}

export function mapResearchRoleNameToProperty(role: ResearchRoleName) {
    return {
        principleinvestigator: 'principleInvestigators',
        researchcoordinator: 'researchCoordinators',
        coordinator: 'researchCoordinators',
        researcher: 'researchers',
        participant: 'participants'
    }[role];
}
