import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {forkJoin} from 'rxjs';
import {tap} from 'rxjs/operators';
import {UserService} from '../../components/auth/user.service';
import {FacAdminData, Facility, FacilityCaregiverType} from '../../components/interfaces/Facility';
import {Organization, OrganizationData} from '../../components/interfaces/Organization';
import {Inventory} from '../../components/interfaces/Inventory';
import {CustomEncoder, encodeEmail} from '../encoder/customEncoder';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {
    public baseUrl = '/api/organization';
    static parameters = [HttpClient, UserService];
    constructor(public http: HttpClient, public userService: UserService) { }

    getAllOrganizations() {
        return this.http.get(this.baseUrl)
            .toPromise()
            .then((data: OrganizationData) => Promise.resolve(data))
            .catch((err) => Promise.reject(err));
    }

    getAllFacilitiesForOrg(orgId: string) {
        return this.http.get(`${this.baseUrl}/${orgId}/facility`);
    }

    getAllRyansForOrg(orgId: string) {
        return this.http.get(`${this.baseUrl}/${orgId}/ryans`);
    }

    getAllFacilitiesForManyOrgs(orgIds: string[]) {
        return orgIds.map((id) => this.getAllFacilitiesForOrg(id).subscribe(org => org));
        // return this.http.get(`${this.baseUrl}/${orgId}/facility`);
    }

    getOrganizationById(id: string) {
        return this.http.get(`${this.baseUrl}/${id}`);
    }

    getOrganizationsForAdmin() {
        return this.http.get(`${this.baseUrl}/me/admin`);
    }

    getOrganizationsForResearcher() {
        return this.http.get(`${this.baseUrl}/me/researcher`).toPromise();
    }

    assignBotToOrg(organization, bot) {
        var updateRyans = organization.ryans;

        if (updateRyans) {
            updateRyans.push(bot._id);
        }
        if (!updateRyans) {
            updateRyans = [bot._id];
        }

        const toSend = updateRyans;
        // console.log('toSend');
        // console.log(toSend);
        return this.http.put(`${this.baseUrl}/${organization._id}`, {
            _id: organization._id,
            ryans: toSend
        });
    }

    addOrganizationAdmin(organization, adminEmail: string) {
        const encodedEmail = encodeEmail(adminEmail);
        return this.http.put(`${this.baseUrl}/${organization.id || organization._id || organization}/addOrganizationAdmin/${encodedEmail}`, {})
            .toPromise();
    }

    addResearcherToOrganization(organization, researcherEmail: string) {
        const encodedEmail = encodeEmail(researcherEmail);
        return this.http.put(`${this.baseUrl}/${organization.id || organization._id || organization}/addResearcher/${encodedEmail}`, {})
            .toPromise();
    }

    removeOrganizationAdmin(organizationId: string, adminEmail: string) {
        const encodedEmail = encodeEmail(adminEmail);
        return this.http.put(`${this.baseUrl}/${organizationId}/removeOrganizationAdmin/${encodedEmail}`, {})
            .toPromise();
    }

    removeResearcherFromOrganization(organizationId, researcherEmail) {
        return this.getOrganizationById(organizationId).toPromise()
            .then((orgData: Organization) => {
                const { researchers } = orgData;
                if (!researchers) {
                    return Promise.reject('Unable to delete researcher from organization.');
                }
                const newResearchers = researchers.filter((e) => e !== researcherEmail);
                return Promise.resolve(this.update({ _id: organizationId, researchers: newResearchers }).toPromise());
            })
            .catch((e) => Promise.reject(String(e)));
    }

    remove(organization) {
        return this.http.delete(`${this.baseUrl}/${organization._id}`)
            .toPromise()
            .then((data) => data)
            .catch((err) => err);
    }

    removeFacilityFromOrganization(facility: Facility, organization: Organization) {
        return this.http.delete(`${this.baseUrl}/${organization._id || organization.id}/facility/${facility._id || facility.id}`);
    }

    create(organization: Organization) {
        return this.http.post(this.baseUrl, organization)
            .toPromise()
            .then((data) => data)
            .catch((err) => err);
    }

    createFacility(organization: Organization, facility: Facility) {
        return this.http.post(`${this.baseUrl}/${(organization._id || organization.id)}/facility`, facility);
    }

    update(organization) {
        return this.http.put(`${this.baseUrl}/${organization._id || organization.id}`, organization, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })

        }).pipe(
            tap(_ => {
                console.log('updated organization');
                console.log(organization);
            })
        );
    }

    updateFacility(facility) {
        return this.http.put(`/api/facility/${facility._id || facility.id}`, facility, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }).pipe(
            tap(_ => {
                console.log('updated facility');
                console.log(facility);
            })
        );
    }

    reassignInventoryToOrganization(organization: Organization, selectedInventory: Inventory[]) {
        const selectedRyanIds = new Set(selectedInventory.map((ryan: Inventory) => ryan._id));
        organization.ryans = Array.from(new Set([...organization.ryans, ...selectedInventory]));
        const facilityObjects = [];
        organization.facilities.forEach((fac: Facility) => {
            if (!fac.ryans || fac.ryans.length === 0) return;
            fac.ryans = fac.ryans.filter((ryan: Inventory) => !selectedRyanIds.has(ryan._id));
            facilityObjects.push(fac);
        });
        const combinedObservable = forkJoin([
            // test only change this
            this.getOrganizationsForAdmin(),
            this.getOrganizationById(organization._id),
        ]);
        return combinedObservable;
    }

    reassignInventoryToFacility(facilityToUpdate: Facility, organization: Organization, selectedInventory: Inventory[]) {
        const selectedRyanIds = new Set(selectedInventory.map((ryan: Inventory) => ryan._id));
        // set newly added inventory to facility
        facilityToUpdate.ryans = facilityToUpdate.ryans || [];
        facilityToUpdate.ryans = Array.from(new Set([...facilityToUpdate.ryans, ...selectedInventory]));
        const facilityObjects = [facilityToUpdate];
        // remove selected inventory from organization
        organization.ryans = organization.ryans.filter((ryan: Inventory) => !selectedRyanIds.has(ryan._id));
        // remove selected inventory from facilities
        organization.facilities.forEach((fac: Facility) => {
            if (!fac.ryans || fac.ryans.length === 0 || fac._id === facilityToUpdate._id) return;
            fac.ryans = fac.ryans.filter((ryan: Inventory) => !selectedRyanIds.has(ryan._id));
            facilityObjects.push(fac);
        });
        const combinedObservable = forkJoin([
            this.update({ _id: organization._id, ryans: organization.ryans }),
            ...facilityObjects.map((fac) => this.updateFacility({ _id: fac._id, ryans: fac.ryans || [] })),
        ]);
        return combinedObservable;
    }
}
