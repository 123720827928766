import {Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { UserService, UserType } from '../../../components/auth/user.service';
import {AnalyticQuery, AnalyticsService} from '../../../services/analytics/analytics.service';
import {faPhone, faUserCircle} from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { CalendarEventService } from '../../../services/calendar-event/calendar-event.service';
import {Facility} from '../../../components/interfaces/Facility';
import {FacilityService} from '../../../services/facility/facility.service';
import {
    convertMillisToHoursMinutesSecondsAndMillis,
    convertMillisToMinutes, getAge,
    isValidDate
} from '../../../components/util';
import {Subscription} from 'rxjs';
import {granularityOptions} from '../../../../server/config/environment/shared';
import moment from 'moment';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {CustomLinerChartService} from '../../../services/custom-dots-ngx-line-chart/CustomLinerChartService';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {GranularityOptions} from '../../../components/graphs/granularityOptions';

type AnalyticType = {
    name: string,
    duration: number,
    startTime: string
};

@Component({
    selector: 'caregiver-gameMetrics',
    templateUrl: './caregiverGameMetrics.html',
    styleUrls: ['../../../assets/sharedStyles/pages/gameMetrics.scss', '../caregiverPages.scss', './caregiverGameMetrics.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})


export class CaregiverGameMetricsComponent implements OnInit, OnDestroy {
    @Output() queryDateRangeChange = new EventEmitter<[Date, Date]>();

    currentUser;
    selectedGranularity: 'hourly' | 'daily' | 'weekly' | 'monthly' = 'daily';
    granularityOptions = granularityOptions;

    queryParamSubscription: Subscription;
    focusedFacilityId: string;
    focusedUserId: string;

    icons = {
        search: faSearch,
        user: faUserCircle,
        phone: faPhone,
    }

    allFacilities: Facility[];
    search: string;

    currentDate = new Date();

    selectedRange: Date[];

    gameUsageData : any[] ;
    gameActivityGraphMultipleUsers = [];
    ticTacToeGraphMultipleUsers = [];
    flowGameGraphMultipleUsers = [];
    picturePuzzleGraphMultipleUsers = [];
    wordPuzzleGraphMultipleUsers = [];
    flightGraphMultipleUsers = [];
    ryanRunsGraphMultipleUsers = [];
    birdCountingGraphMultipleUsers = [];
    checkersGraphMultipleUsers = [];
    chessGraphMultipleUsers = [];

    gameActivityGraphCurrentUser = [];

    ticTacToeDataCurrentUser: any = {};
    ticTacToeColumns = ['Robot', 'Win', 'Date', 'Duration'];
    expandedTicTacToeDetailElement: any;

    flowGameDataCurrentUser: any = {};
    flowGameColumns = ['Robot', 'Win', 'Date', 'Duration'];
    expandedFlowGameDetailElement: any;

    ryanRunsDataCurrentUser: any = {};
    ryanRunsColumns = ['Robot', 'Win', 'Date', 'Duration'];
    expandedRyanRunsDetailElement: any;

    birdCountingDataCurrentUser: any = {};
    birdCountingColumns = ['Robot', 'Win', 'Date', 'Duration'];
    expandedBirdCountingDetailElement: any;

    picturePuzzleDataCurrentUser: any = {};
    picturePuzzleColumns = ['Robot', 'Win', 'Date', 'Duration'];
    expandedPicturePuzzleDetailElement: any;

    wordPuzzleDataCurrentUser: any = {};
    wordPuzzleColumns = ['Robot', 'Win', 'Date', 'Duration'];
    expandedWordPuzzleDetailElement: any;

    flightDataCurrentUser: any = {};
    flightColumns = ['Robot', 'Win', 'Date', 'Duration'];
    expandedFlightDetailElement: any;


    checkersDataCurrentUser: any = {};
    checkersColumns = ['Robot', 'Win', 'Date', 'Duration'];
    expandedCheckersDetailElement: any;


    chessDataCurrentUser: any = {};
    chessColumns = ['Robot', 'Win', 'Date', 'Duration'];
    expandedChessDetailElement: any;

    //Graph options
    view: any[] = [700, 400];
    // options for general graphs
    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = false;
    showXAxisLabel = true;
    xAxisLabel = 'Day';
    showYAxisLabel = true;
    showGridLines = true;
    roundDomains = false;
    yAxisLabel = 'Time (Minutes)';
    noBarWhenZero = false;
    schemeType= 'ordinal';

    colorScheme = {
        domain: ['#FDD660']
    };
    autoScale = true;
    ryanRunsLineGraphColorScheme = {
        domain: ['#0066FF', '#56CCF2']
    };

    WinRateColorScheme = {
        domain: ['#F8906E', '#A71414', '#F5BD14']
    }
    flightAverageRangeOfMotionColorScheme = {
        domain: ['#0066FF', '#56CCF2']
    }
    flightAverageJerkColorScheme = {
        domain: ['#F8906E', '#A71414']
    };

    usageGraphView: any[] = [300, 200];
    usageGraphColorScheme = {
        domain: ['#F5BD14']
    };

    private ticTacToePaginator: MatPaginator;
    @ViewChild('ticTacToePaginator') set matPaginator(mp: MatPaginator) {
        this.ticTacToePaginator = mp;
        if (this.ticTacToeDataCurrentUser && this.ticTacToeDataCurrentUser.Breakdown) {
            this.ticTacToeDataCurrentUser.Breakdown.paginator = this.ticTacToePaginator;
        }
    }

    private flowGamePaginator: MatPaginator;
    @ViewChild('flowGamePaginator') set matPaginator2(mp: MatPaginator) {
        this.flowGamePaginator = mp;
        if (this.flowGameDataCurrentUser && this.flowGameDataCurrentUser.Breakdown) {
            this.flowGameDataCurrentUser.Breakdown.paginator = this.flowGamePaginator;
        }
    }

    private ryanRunsPaginator: MatPaginator;
    @ViewChild('ryanRunsPaginator') set matPaginator3(mp: MatPaginator) {
        this.ryanRunsPaginator = mp;
        if (this.ryanRunsDataCurrentUser && this.ryanRunsDataCurrentUser.Breakdown) {
            this.ryanRunsDataCurrentUser.Breakdown.paginator = this.ryanRunsPaginator;
        }
    }

    private birdCountingPaginator: MatPaginator;
    @ViewChild('birdCountingPaginator') set matPaginator4(mp: MatPaginator) {
        this.birdCountingPaginator = mp;
        if (this.birdCountingDataCurrentUser && this.birdCountingDataCurrentUser.Breakdown) {
            this.birdCountingDataCurrentUser.Breakdown.paginator = this.birdCountingPaginator;
        }
    }

    private picturePuzzlePaginator: MatPaginator;
    @ViewChild('picturePuzzlePaginator') set matPaginator5(mp: MatPaginator) {
        this.picturePuzzlePaginator = mp;
        if (this.picturePuzzleDataCurrentUser && this.picturePuzzleDataCurrentUser.Breakdown) {
            this.picturePuzzleDataCurrentUser.Breakdown.paginator = this.picturePuzzlePaginator;
        }
    }

    private wordPuzzlePaginator: MatPaginator;
    @ViewChild('wordPuzzlePaginator') set matPaginator6(mp: MatPaginator) {
        this.wordPuzzlePaginator = mp;
        if (this.wordPuzzleDataCurrentUser && this.wordPuzzleDataCurrentUser.Breakdown) {
            this.wordPuzzleDataCurrentUser.Breakdown.paginator = this.wordPuzzlePaginator;
        }
    }

    private flightPaginator: MatPaginator;
    @ViewChild('flightPaginator') set matPaginator7(mp: MatPaginator) {
        this.flightPaginator = mp;
        if (this.flightDataCurrentUser && this.flightDataCurrentUser.Breakdown) {
            this.flightDataCurrentUser.Breakdown.paginator = this.flightPaginator;
        }
    }

    private checkersPaginator: MatPaginator;
    @ViewChild('checkersPaginator') set matPaginator8(mp: MatPaginator) {
        this.checkersPaginator = mp;
        if (this.checkersDataCurrentUser && this.checkersDataCurrentUser.Breakdown) {
            this.checkersDataCurrentUser.Breakdown.paginator = this.checkersPaginator;
        }
    }
    private chessPaginator: MatPaginator;
    @ViewChild('chessPaginator') set matPaginator9(mp: MatPaginator) {
        this.chessPaginator = mp;
        if (this.chessDataCurrentUser && this.chessDataCurrentUser.Breakdown) {
            this.chessDataCurrentUser.Breakdown.paginator = this.chessPaginator;
        }
    }

    private flightAverageRangeOfMotionChart : any;
    @ViewChild('flightAverageRangeOfMotionChart') set dots3(chart: any) {
        this.flightAverageRangeOfMotionChart = chart;
        if (this.flightDataCurrentUser && this.flightDataCurrentUser.AverageRangeOfMotion) {
            this.dotsLineChart.showDotsSingle(this.flightAverageRangeOfMotionChart);
        }
    }

    private flightAverageJerkChart : any;
    @ViewChild('flightAverageJerkChart') set dots2(chart: any) {
        this.flightAverageJerkChart = chart;
        if (this.flightDataCurrentUser && this.flightDataCurrentUser.AverageJerk) {
            this.dotsLineChart.showDotsSingle(this.flightAverageJerkChart);
        }
    }

    private ryanRunsLineChart : any;
    @ViewChild('ryanRunsLineCharts') set dots(chart: any) {
        this.ryanRunsLineChart = chart;
        if (this.ryanRunsDataCurrentUser && this.ryanRunsDataCurrentUser.RatioLineGraphs) {
            this.dotsLineChart.showDots(this.ryanRunsLineChart);
        }
    }

    static parameters = [Router, ActivatedRoute, UserService, FacilityService, CalendarEventService, AnalyticsService, CustomLinerChartService];

    constructor(public router: Router, public route: ActivatedRoute, public userService: UserService, public facilityService: FacilityService,
                public eventService: CalendarEventService, public analyticService: AnalyticsService, public dotsLineChart: CustomLinerChartService) {
        this.router = router;
        this.route = route;
        this.userService = userService;
        this.facilityService = facilityService;
        this.eventService = eventService;
        this.dotsLineChart = dotsLineChart;

        this.selectedRange = [
            moment(new Date(new Date().setDate(new Date().getDate() - 14))).startOf('day').toDate(),
            moment().endOf('day').toDate()];

        //get the current user from the token
        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserType;
        this.queryParamSubscription = this.route.queryParamMap.subscribe(queryParams => {
            this.focusedFacilityId = queryParams.has('facilityId') ? queryParams.get('facilityId') : undefined;
            this.focusedUserId = queryParams.has('userId') ? queryParams.get('userId') : undefined;
        });
    }

    ngOnInit(): void {
        this.facilityService.getFacilitiesForCaregiver().subscribe({
            next: (d: { facilities }) => {
                const { facilities } = d;
                if (facilities) {
                    this.completeUserInfoForFacilities([...facilities])
                        .then((completedFacilities: Facility[]) => {
                            this.allFacilities = [...completedFacilities].sort((a:any, b:any) => a.name.localeCompare(b.name));
                            this.triggerQuery();
                        }).catch(err => console.error(err));
                }
                if (!facilities.find((f) => this.focusedFacilityId === f._id)) this.router.navigate([], {queryParams: {}});
            },
            error: (err) => {
                console.error(err);
            },
        });
    }

    triggerQuery() {
        if (!this.focusedFacilityId) return;
        if (this.focusedUserId) this.getSingleUserData(this.focusedUserId);
        else this.getMultipleUsersData();
    }

    formatUsageDataLabel = value => `${Math.round(value / 60000.0)}min`;

    millisToMinutesAndSeconds(ms) {
        const duration = convertMillisToHoursMinutesSecondsAndMillis(ms);
        let milliseconds = duration.millis;
        let seconds: string | number = duration.seconds;
        let minutes: string | number = duration.minutes;
        let hours: string | number = duration.hours;

        hours = hours < 10 ? `0${hours}` : hours;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        return `${hours}:${minutes}:${seconds}.${milliseconds}`;
    }
    millisToMinutes(ms) {
        return Math.round(convertMillisToMinutes(ms));
    }
    getUserAge(birthday: string): number {
        return getAge(birthday);
    }

    focusOnUser(userId: string = undefined) {
        const focusedFacility = this.getCurrentFacility();
        this.router.navigate([], {queryParams: {facilityId: focusedFacility._id, userId: userId}})
            .then(() => this.triggerQuery());
    }

    focusOnFacility(facilityId: string = undefined) {
        this.focusedFacilityId = facilityId;
        this.focusedUserId = undefined;
        this.router.navigate([], {queryParams: {facilityId: facilityId}})
            .then(() => this.triggerQuery());
    }

    getUsersInCareForFacility() {
        return this.getCurrentFacility().users || [];
    }

    completeUserInfoForFacilities(facilityList: Facility[]): Promise<Facility[]> {
        return this.facilityService.getUsersForCaregiver().toPromise()
            .then((caregiverUsers: {users: UserType[]}) => {
                const userList = caregiverUsers.users;
                return facilityList.map(f => {
                    const updatedUsers = f.users
                        .filter(u => u.invitationAccepted)
                        .map(facUser => {
                            let foundUser = userList.find(user => user.email === facUser.email);
                            return {...foundUser, caregivers: facUser.caregivers, guardians: facUser.guardians};
                        })
                        //Sort users alphabetically by full name (mostly for the userList Panel)
                        .sort((a, b) =>
                            `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
                        );
                    return {...f, users: [...updatedUsers]};
                });
            })
            .catch(err => {
                console.error(err);
                return Promise.reject('Something went wrong populating Facility User information');
            });
    }

    getCurrentFacility() {
        return this.allFacilities.find(f => f._id === this.focusedFacilityId);
    }
    getCurrentUser() {
        const currentFacility = this.getCurrentFacility();
        if (!currentFacility) return;
        return currentFacility.users.find(u => [u.email, u.anonymizedName].includes(this.focusedUserId));
    }

    changeSelectedRange(newDates: Date[]) {
        //check the year of the first date to handle the bsDateRangePicker init value of Dec 31, 1969
        if (newDates[0].getFullYear() < 1971 || !isValidDate(newDates[0]) || !isValidDate(newDates[1])) return;
        //ensure not redundant update
        if (newDates[0].getTime() === this.selectedRange[0].getTime() && newDates[1].getTime() === this.selectedRange[1].getTime()) return;
        this.selectedRange = [...newDates];
        this.triggerQuery();
    }

    changeGranularity(newValue: string) {
        newValue = newValue.toLowerCase();
        //ensure valid, new granularity
        if (granularityOptions.includes(newValue) && newValue !== this.selectedGranularity) {
            this.selectedGranularity = newValue as GranularityOptions;
            this.triggerQuery();
        }
    }

    getMultipleUsersData() {
        this.getGameUsageDataForMultipleUsers();
        this.getAverageGameActivityDataForMultipleUsers();
        this.getTicTacToeDataForMultipleUsers();
        this.getFlowGameDataForMultipleUsers();
        this.getPicturePuzzleDataForMultipleUsers();
        this.getWordPuzzleDataForMultipleUsers();
        this.getFlightDataForMultipleUsers();
        this.getRyanRunsDataForMultipleUsers();
        this.getBirdCountingDataForMultipleUsers();
        this.getCheckersDataForMultipleUsers();
        this.getChessDataForMultipleUsers();
        this.formatLabelsForGraphs();
    }

    getSingleUserData(user?: string) {
        this.getGameUsageDataForSingleUser(user);
        this.getAverageGameActivityDataForSingleUser(user);
        this.getTicTacToeDataForSingleUser(user);
        this.getFlowGameDataForSingleUser(user);
        this.getPicturePuzzleDataForSingleUser(user);
        this.getWordPuzzleDataForSingleUser(user);
        this.getFlightDataForSingleUser(user);
        this.getRyanRunsDataForSingleUser(user);
        this.getBirdCountingDataForSingleUser(user);
        this.getCheckersDataForSingleUser(user);
        this.getChessDataForSingleUser(user);
        this.formatLabelsForGraphs();
    }

    private setupQueryForMultipleUsers(): AnalyticQuery {
        const currentFacility = this.getCurrentFacility();
        let start = new Date(this.selectedRange[0]);
        let end = new Date(this.selectedRange[1]);
        return {
            startTime: start,
            endTime: end,
            role: 'caregiver',
            granularity: this.selectedGranularity,
            facility: currentFacility._id
        };
    }

    private formatLabelsForGraphs(): void {
        if (this.selectedGranularity === 'hourly') {
            this.xAxisLabel = 'Hour';
        }
        if (this.selectedGranularity === 'daily') {
            this.xAxisLabel = 'Day';
        }
        if (this.selectedGranularity === 'weekly') {
            this.xAxisLabel = 'Week Of';
        }
        if (this.selectedGranularity === 'monthly') {
            this.xAxisLabel = 'Month';
        }
        this.yAxisLabel = 'Time (Minutes)';
    }

    private formatDataForBarGraph(analytic: any) : void {
        if (this.selectedGranularity === 'hourly') {
            analytic.name = moment(analytic.name).format('MMM D, LT');
        }
        if (this.selectedGranularity === 'daily') {
            analytic.name = moment(analytic.name).add(1, 'days').format('ll');
        }

        if (this.selectedGranularity === 'weekly') {
            analytic.name = moment(analytic.name).add(1, 'days').format('ll');
        }

        if (this.selectedGranularity === 'monthly') {
            analytic.name = moment(analytic.name).format('MMM');
        }
        analytic.value = Math.round(moment.duration(analytic.value).asMinutes() * 100.0) / 100.0;
    }

    getGameUsageDataForMultipleUsers(): any {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getGameUsageAnalyticsForMultipleUsers(query)
            .then((analytics:any) => {
                this.gameUsageData = analytics.UsageData;
            })
            .catch(err => console.error(err));
    }

    getAverageGameActivityDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getGameActivityAnalyticsForMultipleUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.Breakdown;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.gameActivityGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }

    getTicTacToeDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getTicTacToeDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.ticTacToeGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }

    getCheckersDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getCheckersDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.checkersGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }
    getChessDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getChessDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.chessGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }

    getFlowGameDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getFlowGameDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.flowGameGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }

    getPicturePuzzleDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getPicturePuzzleDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.picturePuzzleGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }

    getWordPuzzleDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getWordPuzzleDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.wordPuzzleGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }

    getFlightDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getFlightDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.flightGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }

    getRyanRunsDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getRyanRunsDataForUsers(query)
            .then((analytics: any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.ryanRunsGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }

    getBirdCountingDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getBirdCountingDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.birdCountingGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }

    private setupQueryForSingleUser(user? : any): AnalyticQuery {
        const currentUser = this.getCurrentUser();
        let start = new Date(this.selectedRange[0]);
        let end = new Date(this.selectedRange[1]);
        return {
            startTime: start,
            endTime: end,
            role: 'caregiver',
            granularity: this.selectedGranularity,
            email: user || currentUser.anonymizedName
        };
    }

    getGameUsageDataForSingleUser(user?): any {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getGameUsageAnalyticsForSingleUser(query)
            .then((analytics:any) => {
                this.gameUsageData = analytics.UsageData;
            })
            .catch(err => console.error(err));
    }
    getAverageGameActivityDataForSingleUser(user?) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getGameActivityAnalyticsForSingleUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics.Breakdown;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });

                this.gameActivityGraphCurrentUser = analytics_;
            })
            .catch(err => console.error(err));
    }

    private formatBreakdownData(analytic: any) : void {
        analytic.date = moment(analytic.startTime).format('ll');
        analytic.duration = this.millisToMinutesAndSeconds(analytic.gameDuration);
    }

    getTicTacToeDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getTicTacToeDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                analytics_.BarGraph[0].series.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                analytics_.Breakdown.forEach((analytic: any) => {
                    this.formatBreakdownData(analytic);
                });
                this.ticTacToeDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                this.ticTacToeDataCurrentUser.Summary = analytics_.Summary;
                this.ticTacToeDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
                this.ticTacToeDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
            })
            .catch(err => console.error(err));
    }

    getFlowGameDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getFlowGameDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                analytics_.BarGraph[0].series.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                analytics_.Breakdown.forEach((analytic: any) => {
                    this.formatBreakdownData(analytic);
                });
                this.flowGameDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                this.flowGameDataCurrentUser.Summary = analytics_.Summary;
                this.flowGameDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
                this.flowGameDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
            })
            .catch(err => console.error(err));
    }

    getRyanRunsDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getRyanRunsDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                //FIXME: handle empty analytic response (forEach of undefined)
                analytics_.BarGraph[0].series.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                analytics_.Breakdown.forEach((analytic: any) => {
                    this.formatBreakdownData(analytic);
                });
                analytics_.RatioLineGraphs.forEach(graph => {
                    graph.series.forEach(analytic => {
                        analytic.value = Math.round(analytic.value * 100.0) / 100.0;
                        analytic.name = moment(analytic.name).toDate();
                    });
                });
                analytics_.WinRate.forEach(graph => {
                    graph.series.forEach(analytic => {
                        analytic.value = Math.round(analytic.value * 100.0) / 100.0;
                        analytic.name = moment(analytic.name).toDate();
                    });
                });

                analytics_.CompletionRate.forEach(graph => {
                    graph.series.forEach(analytic => {
                        analytic.value = Math.round(analytic.value * 100.0) / 100.0;
                        analytic.name = moment(analytic.name).toDate();
                    });
                });
                this.ryanRunsDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                this.ryanRunsDataCurrentUser.Summary = analytics_.Summary;
                this.ryanRunsDataCurrentUser.RatioLineGraphs = analytics_.RatioLineGraphs;
                this.ryanRunsDataCurrentUser.WinRate = analytics_.WinRate;
                this.ryanRunsDataCurrentUser.CompletionRate = analytics_.CompletionRate;
                this.ryanRunsDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
                this.ryanRunsDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
            })
            .catch(err => console.error(err));
    }

    getBirdCountingDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getBirdCountingDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                analytics_.BarGraph[0].series.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                analytics_.Breakdown.forEach((analytic: any) => {
                    this.formatBreakdownData(analytic);
                });
                this.birdCountingDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                this.birdCountingDataCurrentUser.Summary = analytics_.Summary;
                this.birdCountingDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
                this.birdCountingDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
            })
            .catch(err => console.error(err));
    }

    getCheckersDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getCheckersDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                analytics_.BarGraph[0].series.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                analytics_.Breakdown.forEach((analytic: any) => {
                    this.formatBreakdownData(analytic);
                });
                this.checkersDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                this.checkersDataCurrentUser.Summary = analytics_.Summary;
                this.checkersDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
                this.checkersDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
            })
            .catch(err => console.error(err));
    }

    getChessDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getChessDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                analytics_.BarGraph[0].series.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                analytics_.Breakdown.forEach((analytic: any) => {
                    this.formatBreakdownData(analytic);
                });
                this.chessDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                this.chessDataCurrentUser.Summary = analytics_.Summary;
                this.chessDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
                this.chessDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
            })
            .catch(err => console.error(err));
    }

    getPicturePuzzleDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getPicturePuzzleDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                analytics_.BarGraph[0].series.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                analytics_.Breakdown.forEach((analytic: any) => {
                    this.formatBreakdownData(analytic);
                });
                this.picturePuzzleDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                this.picturePuzzleDataCurrentUser.Summary = analytics_.Summary;
                this.picturePuzzleDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
                this.picturePuzzleDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
            })
            .catch(err => console.error(err));
    }

    getWordPuzzleDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getWordPuzzleDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                analytics_.BarGraph[0].series.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                analytics_.Breakdown.forEach((analytic: any) => {
                    this.formatBreakdownData(analytic);
                });
                this.wordPuzzleDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                this.wordPuzzleDataCurrentUser.Summary = analytics_.Summary;
                this.wordPuzzleDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
                this.wordPuzzleDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
            })
            .catch(err => console.error(err));
    }

    getFlightDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getFlightDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                if (analytics_.BarGraph[0]?.series?.length > 0) {
                    analytics_.BarGraph[0].series.forEach(analytic => {
                        this.formatDataForBarGraph(analytic);
                    });
                    this.flightDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                } else {
                    this.flightDataCurrentUser.BarGraph = [];
                }

                if (analytics_.Breakdown.length > 0) {
                    analytics_.Breakdown.forEach((analytic: any) => {
                        this.formatBreakdownData(analytic);
                    });
                    this.flightDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
                } else {
                    this.flightDataCurrentUser.Breakdown = undefined;
                }

                if (analytics_.AverageRangeOfMotion[0].series.length > 0) {
                    analytics_.AverageRangeOfMotion[0].series.forEach(analytic => {
                        analytic.value = Math.round(analytic.value * 100.0) / 100.0;
                        analytic.name = moment(analytic.name).toDate();
                    });
                    this.flightDataCurrentUser.AverageRangeOfMotion = analytics_.AverageRangeOfMotion;
                } else {
                    this.flightDataCurrentUser.AverageRangeOfMotion = [];
                }

                if (analytics_.AverageJerk[0].series.length > 0) {
                    analytics_.AverageJerk[0].series.forEach(analytic => {
                        analytic.value = Math.round(analytic.value * 100.0) / 100.0;
                        analytic.name = moment(analytic.name).toDate();
                    });
                    this.flightDataCurrentUser.AverageJerk = analytics_.AverageJerk;
                } else {
                    this.flightDataCurrentUser.AverageJerk = [];
                }

                this.flightDataCurrentUser.Summary = analytics_.Summary;
                this.flightDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
            })
            .catch(err => console.error(err));
    }

    getAgeData() {
        if (this.currentUser.hasOwnProperty('caregiverOf') && this.currentUser.caregiverOf.length > 0) {
            this.facilityService.getFacilitiesForCaregiver()
                .toPromise()
                .then((facs: {facilities: Facility[]}) =>
                    facs.facilities.map(f => f._id)
                )
                .then((facilityIDs: string[]) => {
                    const queries = facilityIDs.map(facID => ({
                        facility: facID,
                        role: 'caregiver'
                    }));
                    this.analyticService.getAgeDataForFacility(queries[0])
                        .toPromise()
                        .then((res: { ageData: any[] }) => res.ageData)
                        .catch((err) => {
                            console.error(err);
                            return [];
                        });
                })
                .catch((err) => {
                    console.error(err);
                    return [];
                });
        }
        return [];
    }

    navToCalendar() {
        this.router.navigate(['caregiver', 'calendar']);
    }

    onSelect(event) {
        console.log(event);
    }

    ngOnDestroy(): void {
        this.queryParamSubscription.unsubscribe();
    }
}
