import {ModuleWithProviders, NgModule} from '@angular/core';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { AuthGuard } from './auth-guard.service';
import { SiteAdminGuard } from './site-admin-guard.service';
import { OrgAdminGuard } from './org-admin-guard.service';
import {CaregiverGuard} from './caregiver-guard.service';
import {FacilityAdminGuard} from './facility-admin-guard.service';

@NgModule()
export class AuthModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: AuthModule,
            providers: [
                AuthService,
                UserService,
                AuthGuard,
                SiteAdminGuard,
                OrgAdminGuard,
                CaregiverGuard,
                FacilityAdminGuard]
        };
    }
}
