import { Pipe, PipeTransform } from '@angular/core';
import {getEntityTypeName, normalizeEntityTypeName} from '../util';

type AcceptedFormatTypes = 'normalized';

@Pipe({
    name: 'formatEntityTypeName'
})
export class FormatEntityTypeNamePipe implements PipeTransform {
    transform(value: any, format?: AcceptedFormatTypes): string {
        const entityTypeName = getEntityTypeName(value);
        // switch statement used to easily maintain if/when more formats are added
        switch (format) {
        case 'normalized':
            return normalizeEntityTypeName(entityTypeName);
        default:
            return entityTypeName;
        }
    }
}
