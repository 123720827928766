import { Component, OnInit, AfterViewInit, ViewChildren, QueryList, ViewChild, ElementRef } from '@angular/core';
import { faRobot, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { HttpClient } from '@angular/common/http';
import { ProgramRService } from '../../services/programr/programr.service';
// let tempImage = { "robot": { "image": { "filename": "https://www.planetware.com/wpimages/2020/02/france-in-pictures-beautiful-places-to-photograph-eiffel-tower.jpg" } } };
// let tempVideo = { "robot": { "video": { "filename": "https://dl8.webmfiles.org/big-buck-bunny_trailer.webm" } } };
// let tempAudio = { "robot": { "audio": { "filename": "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3" } } };
// let randMedia = [tempImage, tempAudio, tempVideo];
const stubMessages = [
    { text: 'Hi, how may I help you today?', from: 'ryan', timestamp: new Date().toLocaleTimeString()},
];
@Component({
    selector: 'programrPage',
    templateUrl: './programr.html',
    styleUrls: ['./programr.scss'],
})
export class ProgramRPageComponent implements OnInit, AfterViewInit {
    @ViewChildren('messagesList') messagesList: QueryList<any>;
    @ViewChild('messageContainer') messageContainer: ElementRef;
    messages = [];
    inputMessage: string = '';
    icon = {
        robot: faRobot,
        user: faUserAlt,
    };
    static parameters = [HttpClient, ProgramRService];
    constructor(public http: HttpClient, public programRService: ProgramRService) {
        this.programRService = programRService;
        this.http = http;
    }
    ngOnInit() {
        this.messages = stubMessages;
    }
    ngAfterViewInit() {
        this.scrollToBottom();
        this.messagesList.changes.subscribe(this.scrollToBottom);
    }
    //scroll to bottom functionality courtesy of https://stackoverflow.com/a/45655337/7012554
    scrollToBottom = () => {
        try {
            this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
        } catch (err) { }
    };
    submitMessage(form) {
        const { message } = form.value;
        if (!message) return;
        form.reset();
        this.messages = [...this.messages, { text: `${message}`, from: 'me', timestamp: new Date().toLocaleTimeString()}];
        this.http.post('/api/rest/v3.0/ask', {
            question: message
        }).subscribe({
            next: (data: any) => {
                let response = {
                    text: `${data[0].response.answer}` || `${'I\'m sorry, I\'m not able to answer that right now.'}`,
                    from: 'ryan',
                    timestamp: new Date().toLocaleTimeString(),
                    option: data[0].response.option[0] || undefined
                };
                this.messages = [...this.messages, response];
                console.log(this.messages);
            },
            error: (error: any) => {
                console.error(error);
                let response = {
                    text: error.answer || `${'I\'m sorry, I\'m not able to answer that right now. '}`, //sometimes even when something goes wrong, server still gives answer
                    from: 'ryan',
                    timestamp: new Date().toLocaleTimeString()
                };
                this.messages = [...this.messages, response];
                console.log(this.messages);
            },
        });
    }
}
