import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output, SimpleChanges,
} from '@angular/core';
import {capitalizeFirstLetter} from '../util';
import {GranularityOptions} from '../graphs/granularityOptions';
import {granularityOptions} from '../../../server/config/environment/shared';

export type GranularityChangeType = {};
@Component({
    selector: 'granularity-select',
    templateUrl: './granularitySelect.html',
    styleUrls: ['./granularitySelect.scss'],
})
export class GranularitySelectComponent implements OnInit, OnDestroy, OnChanges {
    @Input('selectedGranularity') _granularitySelected: GranularityOptions | string = 'daily';
    @Input('granularityOptions') granularityOptions: string[] = granularityOptions;
    @Output('granularityChange') granularityChange: EventEmitter<string> = new EventEmitter<string>();

    _prevGranularitySelected: GranularityOptions | string = undefined;

    firstChange: boolean = true;
    static parameters = [];

    constructor() { }

    public ngOnInit(): void { }

    public ngOnDestroy(): void { }

    capitalizeFirstLetter(str: string) {
        return capitalizeFirstLetter(str);
    }

    /**
     * changeGranularity: handle changes to the granularity (Hourly, Daily, Weekly, etc.) for the graph
     * @param granularity
     */
    changeGranularity(granularity: string) {
        granularity = granularity.toLowerCase();
        if (granularity !== this._granularitySelected) {
            // console.log('new granularity: ', granularity);
            // if previous granularity is undefined, then we know this is the first change
            this.firstChange = !this._prevGranularitySelected;
            this._prevGranularitySelected = this._granularitySelected;
            this._granularitySelected = granularity;
            this.granularityChange.emit(this._granularitySelected);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const granularitySelected = changes._granularitySelected;
        const granularities = changes.granularityOptions;
        this._granularitySelected = granularitySelected.currentValue ? granularitySelected.currentValue : this._granularitySelected;
        this._prevGranularitySelected = granularitySelected.previousValue ? granularitySelected.previousValue : this._prevGranularitySelected;
        this.granularityOptions = granularities ? granularities.currentValue : this.granularityOptions;
        this.firstChange = granularitySelected.firstChange;
    }

    isFirstChange(): boolean {
        return this.firstChange;
    }
}

