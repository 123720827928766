import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {Facility} from '../../interfaces/Facility';
import {ResearchStudy} from '../../interfaces/ResearchStudy';

@Component({
    selector: 'addFacilityToStudy',
    templateUrl: './addFacilityToStudy.html',
    styleUrls: ['./addFacilityToStudy.scss']
})

export class AddFacilityToStudyComponent {
    @Input() study: ResearchStudy;
    @Input() optionFacilities: Facility[] = [];
    @Input() alert: {
        type: string,
        show: boolean,
        message: string,
    };
    // @Output() addedFacility: EventEmitter<Facility> = new EventEmitter<Facility>();
    @Output() addedFacilities: EventEmitter<Facility[]> = new EventEmitter<Facility[]>();

    facilitiesToBeAdded: Facility[] = [];
    static parameters = [BsModalRef];

    constructor(public bsModalRef: BsModalRef) { }

    toggleFacilityMembership(f: Facility) {
        const facilityIsMember = this.facilitiesToBeAdded.includes(f);
        if (facilityIsMember) {
            this.facilitiesToBeAdded = this.facilitiesToBeAdded.filter(fac => fac._id !== f._id);
        } else {
            this.facilitiesToBeAdded.push(f);
        }
        // this.facilityToBeAdded = this.facilityToBeAdded === f ? undefined : f;
    }

    facilityIsMember(f: Facility): boolean {
        // return this.facilityToBeAdded === f;
        return this.facilitiesToBeAdded.includes(f);
    }

    submitFacilityAssignment() {
        // this.addedFacility.emit(this.facilityToBeAdded);
        this.addedFacilities.emit(this.facilitiesToBeAdded);
    }
}

