import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    faCloudDownloadAlt,
    faFilter, faPlus,
    faSearch,
    faSortAmountDown,
    faUserCircle,
    faMinusCircle, faEye,
} from '@fortawesome/free-solid-svg-icons';
import { faBell, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import {Subscription} from 'rxjs';
import {ResearchService} from '../../../services/research/research.service';
import {ResearchStudy} from '../../../components/interfaces/ResearchStudy';
import {Facility} from "../../../components/interfaces/Facility";
import {FacilityService} from "../../../services/facility/facility.service";
import _ from "lodash";

@Component({
    selector: 'researcher-viewStudies',
    templateUrl: './researcherViewStudies.html',
    styleUrls: ['../../../assets/sharedStyles/pages/managePages.scss', '../researcherPages.scss', './researcherViewStudies.scss'],
})

export class ResearcherViewStudiesComponent implements OnInit, OnDestroy {
    queryParamSubscription: Subscription;
    focusedStudyId: string;

    icon = {
        userCircle: faUserCircle,
        bell: faBell,
        search: faSearch,
        sort: faSortAmountDown,
        calendar: faCalendarAlt,
        filter: faFilter,
        download: faCloudDownloadAlt,
        add: faPlus,
        delete: faMinusCircle,
        view: faEye,
    };

    allStudies: ResearchStudy[] = [];
    currentStudy: ResearchStudy;

    static parameters = [Router, ActivatedRoute, FacilityService, ResearchService];

    constructor(public router: Router, public route: ActivatedRoute, public facilityService: FacilityService, public researchService: ResearchService) {
        this.router = router;
        this.route = route;
        this.facilityService = facilityService;
        this.researchService = researchService;

        this.queryParamSubscription = this.route.queryParamMap.subscribe(queryParams => {
            this.focusedStudyId = queryParams.has('studyId') ? queryParams.get('studyId') : undefined;
        });
    }

    ngOnInit(): void {
        this.refreshFullyPopulatedStudies();
    }

    ngOnDestroy(): void {
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }

    refreshFullyPopulatedStudies() {
        this.getAllResearchStudies()
            .then((studies: ResearchStudy[]) => {
                const facilityIds = _.flatten(studies.map(s => s.facilities));
                return this.getAllFacilities(facilityIds)
                    .then((facilities: {name: string, _id: string}[]) => {
                        return studies
                            .map(s => ({
                                ...s,
                                facilities: facilities.filter(f => s.facilities.includes(f._id))
                                    .sort((a, b) => a.name.localeCompare(b.name))
                            })) as ResearchStudy[];
                    });
            })
            .then((res) => {
                this.allStudies = res;
                this.currentStudy = this.getStudy(this.focusedStudyId);
                return this.allStudies;
            })
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
    }

    /**
     * getAllResearchStudies
     */
    getAllResearchStudies(): Promise<ResearchStudy[]> {
        return this.researchService.getAllMyStudies('researcher')
            .then((res: {studies: ResearchStudy[]}) => {
                const sortedStudies: ResearchStudy[] = res.studies
                    .sort((a, b) => a.title.localeCompare(b.title));
                return Promise.resolve(sortedStudies as ResearchStudy[]);
            })
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
    }

    /**
     * getAllFacilities
     */
    getAllFacilities(facilityIds: string[]): Promise<{name: string, _id: string}[]> {
        facilityIds = [...new Set(facilityIds)];
        const promises = facilityIds.map(id => this.facilityService.getPublicInfoFacility(id).toPromise().then((res: {name: string}) => ({...res, _id: id})));
        return Promise.all(promises)
            .then((facilityNamesAndIds: {name: string, _id: string}[]) => Promise.resolve(facilityNamesAndIds))
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
    }

    focusOnStudy(studyId?: string) {
        this.router.navigate([], {queryParams: {studyId: studyId}});
        this.currentStudy = this.getStudy(studyId);
    }

    getStudy(studyId?: string): ResearchStudy {
        return this.allStudies.find(s => s._id === studyId);
    }
}
