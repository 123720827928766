import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {Organization} from '../../interfaces/Organization';
import {Facility, FacilityUserType} from '../../interfaces/Facility';
import {isResearchRoleName, ResearchStudy} from '../../interfaces/ResearchStudy';

@Component({
    selector: 'confirm-delete',
    templateUrl: './confirm-delete.html',
    styleUrls: ['./confirm-delete.scss']
})
export class ConfirmDeleteComponent {
    @Input() deleteType: string; //OrganizationRoleName, FacilityRoleName, ResearchRoleName,
    @Input() toDelete: Organization | Facility | FacilityUserType | string;
    @Input() deleteFrom: Organization | Facility | ResearchStudy;
    @Output() confirmDeletion: EventEmitter<any> = new EventEmitter();

    static parameters = [BsModalRef];

    constructor(public bsModalRef: BsModalRef) { }

    isResearchRoleName(str: string): boolean {
        return isResearchRoleName(str);
    }

    handleDelete() {
        this.confirmDeletion.emit({ removeType: this.deleteFrom, removeValue: this.toDelete, removeFrom: this.deleteFrom });
        this.bsModalRef.hide();
    }
}
