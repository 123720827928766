<div class="modal-lg">
    <form (ngSubmit)="handleSubmit($event)">
        <div class="modal-header">
            <h4 id="dialog-sizes-name1" class="modal-title pull-left">Users assigned to this caregiver</h4>
            <button type="button" class="close pull-right" (click)="bsModalRef.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="userList assignedUsers">
                <h4 class="numMembers">Assigned Users ({{assignedUsers.length}}):</h4>
                <div cdkDropList #assignedUserList="cdkDropList" [cdkDropListData]="assignedUsers" [cdkDropListConnectedTo]="[notAssignedUserList]" class="user-list" (cdkDropListDropped)="drop($event)">
                    <div class="user-box" *ngFor="let user of assignedUsers" cdkDrag cdkDragBoundary=".modal-body">
                        <div class="custom-placeholder" *cdkDragPlaceholder></div>
                        {{user.email}}
                        <!--                    <div class="userInfo__name" *ngIf="user.firstName && user.lastName">{{user.firstName}}-->
                        <!--                        {{user.lastName}}</div>-->
                        <!--                    <small class="userInfo__email">{{user.email}}</small>-->
                    </div>
                </div>
            </div>
            <div class="userList notAssignedUsers">
                <h4 class="numMembers">NOT Assigned Users ({{notAssignedUsers.length}}):</h4>
                <div cdkDropList #notAssignedUserList="cdkDropList" [cdkDropListData]="notAssignedUsers" [cdkDropListConnectedTo]="[assignedUserList]" class="user-list" (cdkDropListDropped)="drop($event)">
                    <div class="user-box" *ngFor="let user of notAssignedUsers" cdkDrag cdkDragBoundary=".modal-body">
                        <div class="custom-placeholder" *cdkDragPlaceholder></div>
                        {{user.email}}
                        <!--                    <div class="userInfo__name" *ngIf="user.firstName && user.lastName">{{user.firstName}}-->
                        <!--                        {{user.lastName}}</div>-->
                        <!--                    <small class="userInfo__email">{{user.email}}</small>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="buttonsArea">
                <button (click)="bsModalRef.hide()" class="btn btn-outline-warning">Cancel</button>
                <button type="submit" [disabled]="!changesMade()" class="btn btn-outline-success">Submit Changes</button>
            </div>
        </div>
        <div class="fullWidth" *ngIf="alert.show">
            <alert class="fullWidth" type="{{alert.type}}">
                {{alert.message}}
            </alert>
        </div>
    </form>
</div>
