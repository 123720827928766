import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ArrayDataSource } from "@angular/cdk/collections";
import { NestedTreeControl } from "@angular/cdk/tree";
import { FormControl, FormGroup } from "@angular/forms";

import { faUserCircle, faSearch, faSortAmountDown, faGamepad, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt, faBell } from '@fortawesome/free-regular-svg-icons';
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";


//TODO: This is temporary for the sake of frameworking the prototype
//   This will be replaced once we can query the database for this info
const sampleOrgs = [
    {
        name: 'Myinnovage',
        address: '8950 E. Lowry Blvd.',
        city: 'Denver',
        state: 'CO'
    },
    {
        name: 'nursing home 3',
        address: '21 palm drive',
        city: 'Denver',
        state: 'CO'
    },
    {
        name: 'Heather Gardens',
        address: '1901 Thornridge Cir.',
        city: 'Shiloh',
        state: 'HI'
    },
];

@Component({
    selector: 'orglistpanel',
    templateUrl: './orglistpanel.html',
    providers: [],
    styleUrls: ['./orglistpanel.scss'],
})


export class OrgListPanelComponent implements OnInit, OnDestroy {
    Router;
    showAlert = false;
    alertType = '';
    alertMessage = '';
    faUserCircle = faUserCircle;
    faBell = faBell;
    faSearch = faSearch;
    faSortAmountDown = faSortAmountDown;
    faCalendarAlt = faCalendarAlt;
    faGamepad = faGamepad;
    faUserFriends = faUserFriends;

    //TODO: eventually this will be a prepared set of data incl. each org's name and addr
    @Input() orgs: any = [];

    closeAlert() {
        this.showAlert = false;
        this.alertType = '';
        this.alertMessage = '';
    }

    filterOrgList = this.orgs;
    search = '';

    static parameters = [Router];
    orgStruct: any;

    constructor(public router: Router) {
        this.Router = router;
    }

    public ngOnInit(): void {
        console.log('OrgList Panel');
        console.log(this.orgs);
        this.sampleOrgStruct();
    }

    public ngOnDestroy(): void { }

    searchUsers(evt) {
        console.log('searchOrgs:');
        console.log(evt);
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    //TODO: this is for the sake of demonstration, ideally we will be able to get ths info from database
    sampleOrgStruct() {
        if (this.orgs === undefined) {
            this.orgs = sampleOrgs;

            this.orgStruct = this.orgs.map((u) => {
                return { name: u.name, city: (u.city || "Denver"), state: (u.state || 'CO') };
            });
            console.log('orgStruct:');
            console.log(this.orgStruct);

            this.orgs = this.orgStruct;
        }
    }
}

