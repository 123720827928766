import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterOrgs'
})
export class FilterOrgsPipe implements PipeTransform {
    transform(value: any, searchValue): any {
        if (!searchValue) return value;
        return value.filter((v) => v.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
            || v.city.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
            || v.state.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
    }
}
