import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService, UserType} from '../../../components/auth/user.service';
import { AuthService } from '../../../components/auth/auth.service';
import {
    faCloudDownloadAlt,
    faFilter, faPlus,
    faSearch,
    faSortAmountDown,
    faUserCircle,
    faMinusCircle, faEye,
} from '@fortawesome/free-solid-svg-icons';
import { faBell, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { InventoryService } from '../../../services/inventory/inventory.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmDeleteComponent } from '../../../components/modals/confirm-delete/confirm-delete.component';
import { EditOrgFacComponent } from '../../../components/modals/edit-org-fac/edit-org-fac.component';
import { Organization } from '../../../components/interfaces/Organization';
import {Facility, FacilityRoleName} from '../../../components/interfaces/Facility';
import { AddEmailComponent } from '../../../components/modals/add-email/add-email.component';
import { AssignCaregiverOrGuardianComponent } from '../../../components/modals/assign-caregiver-or-guardian/assign-caregiver-or-guardian.component';
import { ViewEditCaregiverAssignmentsComponent } from '../../../components/modals/viewEditCaregiverAssignments/viewEditCaregiverAssignments.component';
import {FacilityService} from '../../../services/facility/facility.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'facilityAdmin-manage',
    templateUrl: './facilityAdminManage.html',
    styleUrls: ['../../../assets/sharedStyles/pages/managePages.scss', './facilityAdminManage.scss'],
})


export class FacilityAdminManageComponent implements OnInit, OnDestroy {
    queryParamSubscription: Subscription;
    focusedFacilityId: string;
    focusedUserId: string;

    icon = {
        userCircle: faUserCircle,
        bell: faBell,
        search: faSearch,
        sort: faSortAmountDown,
        calendar: faCalendarAlt,
        filter: faFilter,
        download: faCloudDownloadAlt,
        add: faPlus,
        delete: faMinusCircle,
        view: faEye,
    };

    distinctBots: any;

    allFacilities: Facility[] = [];
    currentFacility: Facility = null;
    currFacilityCaregivers: UserType[] = [];
    currFacilityUsers: UserType[] = [];
    currFacilityPrincipleInvestigators: any[] = [];


    static parameters = [Router, ActivatedRoute, UserService, InventoryService, FacilityService, BsModalService, ActivatedRoute];

    constructor(public router: Router, public route: ActivatedRoute,
        public userService: UserService, public inventoryService: InventoryService,
        public facilityService: FacilityService, public modalService: BsModalService, public activatedRoute: ActivatedRoute) {
        this.router = router;
        this.route = route;
        this.userService = userService;
        this.inventoryService = inventoryService;
        this.facilityService = facilityService;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;

        this.queryParamSubscription = this.route.queryParamMap.subscribe(queryParams => {
            this.focusedFacilityId = queryParams.has('facilityId') ? queryParams.get('facilityId') : undefined;
            this.focusedUserId = queryParams.has('userId') ? queryParams.get('userId') : undefined;
        });
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(p => {
            // console.log(p);
        });

        if (this.focusedFacilityId) {
            //focus on specific facility
        }

        this.refreshFacilities();

    /*  TODO: handle if focused on facility and page refreshes
            --> get facilityId from URL and route accordingly
     */
    }

    ngOnDestroy(): void { }

    focusOnFacility(facilityId: string = undefined) {
        this.router.navigate([], {queryParams: {facilityId: facilityId}});
        if (facilityId) {
            this.currentFacility = this.getFacility(facilityId);
            this.facilityService.getAllUserDetailsInFacility(facilityId)
                .toPromise()
                .then((facUsers: {users: UserType[]}) => {
                    // combine currFacilityUsers with currentFacility.users to get 'caregivers', 'guardians', and
                    //          'invitationAccepted' props
                    this.currFacilityUsers = facUsers.users.map(user => {
                        const matchingUser = this.currentFacility.users.find(u => u.email === user.email);
                        return {...user, ...matchingUser};
                    });
                })
                .catch(e => console.error(e));
        } else {
            this.currentFacility = undefined;
        }
    }

    getFacility(facilityId?: string) {
        return this.allFacilities.find((f) => f._id === facilityId);
    }

    botSelect(evt) {
        // console.log(evt);
        //    TODO: when user select is updated, change the query and re-filter/parse/format data
    }

    /**
     * addAdminOrUserEmailTo: invite a facilityAdmin, caregiver, or user this facility
     * @param addToType:
     * @param addTo
     */
    addAdminOrUserEmailTo(addToType: FacilityRoleName, addTo: Facility) {
        if (!addTo) addTo = this.getFacility(this.focusedFacilityId);
        const initialState = { addToType, addTo };
        if (addToType === 'facilityadmin') {
            const modalRef = this.modalService.show(AddEmailComponent, { initialState });
            modalRef.content.confirmEmail.subscribe(adminEmail => {
                if (!adminEmail) return;
                //Ensure the user does not already exist in the facility
                if (addTo.users.some(c => c.email === adminEmail)) {
                    modalRef.content.alert = {
                        type: 'info',
                        show: true,
                        message: `${adminEmail} has already been invited to join this facility as an admin.` };
                    setTimeout(() => {
                        modalRef.hide();
                        return;
                    }, 10000);
                }
                this.facilityService.addFacilityAdmin(addTo, adminEmail)
                    .then((updatedFacility) => {
                        this.refreshFacilities();
                        modalRef.hide();
                    })
                    .catch((e) => {
                        console.error(e);
                        modalRef.hide();
                    });
            });
            return;
        }

        if (addToType === 'caregiver') {
            const modalRef = this.modalService.show(AddEmailComponent, { initialState });
            modalRef.content.confirmEmail.subscribe(caregiverEmail => {
                if (!caregiverEmail) return;
                //Ensure the caregiver does not already exist in the facility
                if (addTo.caregivers.some(c => c.email === caregiverEmail)) {
                    modalRef.content.alert = {
                        type: 'info',
                        show: true,
                        message: `${caregiverEmail} has already been invited to join this facility as a caregiver.` };
                    setTimeout(() => {
                        modalRef.hide();
                    }, 10000);
                    return;
                }
                this.facilityService.addCaregiverToFacility(caregiverEmail, addTo._id)
                    .toPromise()
                    .then((updatedFacility) => {
                        this.refreshFacilities();
                        modalRef.content.alert = {
                            type: 'success',
                            show: true,
                            message: `${caregiverEmail} has been invited to join this facility as a caregiver. They will have to accept the invitation sent via e-mail before they can be assigned to any users.` };
                        setTimeout(() => {
                            modalRef.hide();
                        }, 10000);
                    })
                    .catch((e) => {
                        console.error(e);
                        modalRef.hide();
                    });
            });
        }

        if (addToType === 'principleinvestigator') {
            const modalRef = this.modalService.show(AddEmailComponent, { initialState });
            modalRef.content.confirmEmail.subscribe(principleInvestigatorEmail => {
                if (!principleInvestigatorEmail) return;
                //Ensure the PI does not already exist in the facility
                if (addTo?.principleInvestigators?.some(pi => pi.email === principleInvestigatorEmail)) {
                    modalRef.content.alert = {
                        type: 'info',
                        show: true,
                        message: `${principleInvestigatorEmail} has already been invited to join this facility as a principle investigator.` };
                    setTimeout(() => {
                        modalRef.hide();
                    }, 10000);
                    return;
                }
                this.facilityService.addPrincipleInvestigatorToFacility(principleInvestigatorEmail, addTo)
                    .then((updatedFacility) => {
                        // console.log(updatedFacility);
                        this.refreshFacilities();
                        modalRef.content.alert = {
                            type: 'success',
                            show: true,
                            message: `${principleInvestigatorEmail} has been invited to join this facility as a principle investigator (researcher). They will have to accept the invitation sent via e-mail before they can create new studies.`
                        };
                        setTimeout(() => {
                            modalRef.hide();
                        }, 10000);
                    })
                    .catch((e) => {
                        console.error(e);
                        modalRef.hide();
                    });
            });
        }

        if (addToType === 'user') {
            const modalRef = this.modalService.show(AddEmailComponent, { initialState });
            modalRef.content.confirmEmail.subscribe(userEmail => {
                if (!userEmail) return;
                //Ensure the user does not already exist in the facility
                if (addTo.users.some(c => c.email === userEmail)) {
                    modalRef.content.alert = {
                        type: 'info',
                        show: true,
                        message: `${userEmail} has already been invited to join this facility as a user.` };
                    setTimeout(() => {
                        modalRef.hide();
                    }, 10000);
                    return;
                }
                this.facilityService.addUserToFacility(userEmail, addTo._id)
                    .toPromise()
                    .then((updatedFacility) => {
                        this.refreshFacilities();
                        modalRef.hide();
                    })
                    .catch((e) => {
                        console.error(e);
                        modalRef.hide();
                    });
            });
        }
    }

    checkForFocusedFacility() {
        if (this.focusedFacilityId) this.focusOnFacility(this.focusedFacilityId);
    }

    checkIfOnlyOneFacilityThenFocus(facilityArr: Facility[]) {
        if (facilityArr.length === 1) {
            this.focusOnFacility(facilityArr[0]._id);
            this.currentFacility = facilityArr[0];
        }
    }

    refreshFacilities() {
        this.facilityService.getFacilitiesForAdmin()
            .toPromise()
            .then((facs: {facilities: Facility[]}) => {
                this.allFacilities = facs.facilities.sort((a:any, b:any) => a.name.localeCompare(b.name));
                this.checkForFocusedFacility();
                this.checkIfOnlyOneFacilityThenFocus(facs.facilities);
            })
            .catch(e => console.error(e));
    }

    openAssignCaregiverOrGuardianModal(user: UserType, facility: Facility) {
        const initialState = { user: user, facility: facility };
        const modalRef = this.modalService.show(AssignCaregiverOrGuardianComponent, { initialState });
        modalRef.content.confirmAdd.subscribe(({ addType, assignee }) => {
            modalRef.content.alert = { type: 'secondary', show: true, message: `Adding ${addType} ${assignee} to user ${user.email}` };
            modalRef.content.submitting = true;
            this.facilityService
                .assignCaregiverOrGuardianToUserInFacility(
                    facility._id,
                    user.email,
                    addType,
                    assignee
                )
                .subscribe({
                    next: (fac) => {
                        this.refreshFacilities();
                        modalRef.content.alert = { type: 'info', show: true, message: `Successfully added ${addType} ${assignee} to user ${user.email}` };
                        setTimeout(() => {
                            modalRef.hide();
                        }, 10000);
                    },
                    error: (err) => {
                        let message = 'Error';
                        if (err.message) message = String(message);
                        if (err.error.message) message = String(err.error.message);
                        modalRef.content.alert = { type: 'danger', show: true, message };
                    },
                });
        });
    }

    /**
     * openViewEditCaregiverAssignmentsModal: open the modal to allow a facilityAdmin to
     *       view which user's a caregiver has access to, and give the option to add/remove users to/from that list
     * @param caregiverEmail: the email of the caregiver in question
     * @param facility: the facility in which this is all taking place
     */
    openViewEditCaregiverAssignmentsModal(caregiverEmail: string, facility: Facility) {
        //first, get the users in the facility that are already assigned to the caregiver
        const origAssignedUsers = facility.users.filter(u => u.invitationAccepted && u.caregivers.includes(caregiverEmail));
        //then, get the users in the facility that are NOT assigned to the caregiver
        const origNotAssignedUsers = facility.users.filter(u => u.invitationAccepted && !u.caregivers.includes(caregiverEmail));
        // console.log('notAssignedUsers: ', origNotAssignedUsers);
        const initialState = {
            caregiverEmail: caregiverEmail,
            facility: facility,
            assignedUsers: [...origAssignedUsers],
            notAssignedUsers: [...origNotAssignedUsers]
        };

        const modalRef = this.modalService.show(ViewEditCaregiverAssignmentsComponent, {initialState});
        modalRef.content.confirmChanges.subscribe(({assignedUsers, notAssignedUsers}) => {
            modalRef.content.submitting = true;

            //since assigned/notAssigned is binary, the below operations CAN be done using only one of the arrays,
            //      but, for the sake of clarity, it is done using both

            //now, we want to find the difference between the list of users the caregiver
            //  was previously assigned to, and the list that they should be assigned to now, and reflect those changes
            const newlyAssigned = assignedUsers.filter(u => !origAssignedUsers.some(user => user.email === u.email));

            //next, we do essentially the same thing for the list of notAssigned and reflect those changes
            const newlyUnassigned = notAssignedUsers.filter(u => !origNotAssignedUsers.some(user => user.email === u.email));

            const updatedFacilityUsers = facility.users.map(u => {
                //if they have been newly assigned to the caregiver
                if (newlyAssigned.includes(u) && !u.caregivers.includes(caregiverEmail)) {
                    u.caregivers.push(caregiverEmail);
                }
                //if they have been newly unassigned from the caregiver
                if (newlyUnassigned.includes(u)) {
                    u.caregivers = u.caregivers.filter(c => c !== caregiverEmail);
                }
                return u;
            });
            const newFacility: Facility = {...facility, users: updatedFacilityUsers};

            return this.facilityService.updateFacility(newFacility).toPromise()
                .then((res) => {
                    // console.log(res);
                    this.refreshFacilities();
                    modalRef.content.alert = { type: 'success', show: true, message: `Successfully updated ${caregiverEmail}'s User assignments` };
                    setTimeout(() => {
                        modalRef.hide();
                    }, 10000);
                })
                .catch(e => {
                    console.error(e);
                    modalRef.content.alert = { type: 'danger', show: true, message: `Opps! Something went wrong trying to update ${caregiverEmail}'s User assignments` };
                    setTimeout(() => {
                        modalRef.hide();
                    }, 10000);
                });
        });
    }

    openEditModal(editType: string, toEdit: Organization | Facility) {
        if (editType === 'facility') {
            const initialState = { editType, toEdit };
            const modalRef = this.modalService.show(EditOrgFacComponent, { initialState });
            modalRef.content.confirmEdit.subscribe(({ editedEntity }) => {
                this.facilityService.updateFacility(editedEntity)
                    .subscribe({
                        next: (d) => {
                            this.refreshFacilities();
                            modalRef.hide();
                        },
                        error: (e) => console.error(e)
                    });
            });
        }
    }

    /**
     * openDeleteModal -- opens the modal to delete a facilityAdmin, caregiver, or a User from a faciltiy
     * @param deleteType: the type of user to delete ('facilityAdmin', 'caregiver', or 'user')
     * @param toDelete: the id (email or anonymizedId) of the user to delete
     * @param deleteFrom: The Facility to delete the entity from
     */
    openDeleteModal(deleteType: FacilityRoleName, toDelete: string, deleteFrom: Facility) {
        const initialState = {deleteType, toDelete, deleteFrom};
        if (deleteType === 'facilityadmin') {
            const modalRef = this.modalService.show(ConfirmDeleteComponent, { initialState });
            modalRef.content.confirmDeletion.subscribe(({ removeType, removeValue, removeFrom }) => {
                this.facilityService.removeFacilityAdmin(removeFrom._id, removeValue)
                    .then((d) => {
                        this.refreshFacilities();
                    })
                    .catch((message) => console.error(message));
            });
        }

        if (deleteType === 'caregiver') {
            const modalRef = this.modalService.show(ConfirmDeleteComponent, {initialState});
            modalRef.content.confirmDeletion.subscribe(({ removeType, removeValue, removeFrom }) => {
                this.facilityService.removeCaregiverFromFacility(removeValue, removeFrom)
                    .then((d) => {
                        this.refreshFacilities();
                    })
                    .catch((message) => console.error(message));
            });
        }

        if (deleteType === 'user') {
            const modalRef = this.modalService.show(ConfirmDeleteComponent, { initialState });
            modalRef.content.confirmDeletion.subscribe(({ removeType, removeValue, removeFrom }) => {
                this.facilityService.removeUserFromFacility(removeValue, removeFrom)
                    .then((d) => {
                        this.refreshFacilities();
                    })
                    .catch((message) => console.error(message));
            });
        }

        if (deleteType === 'principleinvestigator') {
            const modalRef = this.modalService.show(ConfirmDeleteComponent, { initialState });
            modalRef.content.confirmDeletion.subscribe(({ removeType, removeValue, removeFrom }) => {
                this.facilityService.removePrincipleInvestigatorFromFacility(removeFrom.id || removeFrom._id, removeValue)
                    .then((d) => {
                        this.refreshFacilities();
                    })
                    .catch((message) => console.error(message));
            });
        }
    }
}
