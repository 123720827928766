import { NgModule } from '@angular/core';
import { CalendarEventService } from './calendar-event/calendar-event.service';
import { InventoryService } from './inventory/inventory.service';
import { OrganizationService } from './organization/organization.service';
import { AnalyticsService } from './analytics/analytics.service';
import { FileService } from './file/file.service';
import { ProgramRService} from './programr/programr.service';
import {CustomLinerChartService} from './custom-dots-ngx-line-chart/CustomLinerChartService';
import {FacilityService} from './facility/facility.service';
import { ResearchService } from './research/research.service';
import {ProfileService} from './profile/profile.service';

@NgModule({
    providers: [
        CalendarEventService,
        InventoryService,
        OrganizationService,
        FacilityService,
        FileService,
        AnalyticsService,
        ProgramRService,
        CustomLinerChartService,
        ResearchService,
        ProfileService
    ]
})

export class ServicesModule { }
