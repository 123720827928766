<div class="page">
    <div class="mainContent organizations" *ngIf="allFacilities">
        <nav class="breadcrumb breadcrumbs" aria-label="breadcrumb">
            <ol class="breadcrumbs__list">
<!--                Not focused on facility-->
                <li class="breadcrumb-item breadcrumbItem" [attr.aria-current]="(!this.focusedFacilityId) ? 'page' : null" (click)="focusOnFacility()">
                    Manage
                </li>
<!--                Focused on facility-->
                <li class="breadcrumb-item breadcrumbItem" *ngIf="this.focusedFacilityId && !!currentFacility" [attr.aria-current]="(!!this.focusedFacilityId && !!currentFacility) ? 'page' : null">
                    {{currentFacility.name || 'n/a'}}
                </li>
            </ol>
        </nav>

        <!--  List of Facilities-->
        <!--  ======  Select Facility  ======  -->
        <ng-container *ngIf="!focusedFacilityId">
            <h4>Please select a Facility</h4>
            <div class="facilityList">
                <article class="facilityArea blueOnHover" *ngFor="let facility of allFacilities" (click)="focusOnFacility(facility._id)">
                    <div class="header">
                        <div class="text-area">
                            <h3 class="facilityName">{{facility?.name || "Loading Name"}}</h3>
                            <address class="facilityAddr">
                                {{facility.address || ''}} <span *ngIf="facility.city">, </span>
                                {{facility.city || ''}}<span *ngIf="facility.state">, </span>{{facility.state || ''}} {{facility.zip || ''}}
                            </address>
                        </div>
                    </div>
                    <div class="facilityInfoCount">
                        <h6>Administrators: <span>({{facility?.administrators?.length || 0}})</span></h6>
                        <h6>Users: <span>({{facility?.users?.length || 0}})</span></h6>
                    </div>
                </article>
            </div>
        </ng-container>

        <!-- Facility focus -->
        <ng-container *ngIf="this.focusedFacilityId && currentFacility">
            <article class="facilityDetails">
                <div class="header">
                    <div class="text-area">
                        <h4 class="facilityName">{{currentFacility?.name || "Loading Name"}}</h4>
                        <address class="facilityAddr">{{currentFacility?.address || ""}},
                            {{currentFacility?.city || ""}}, {{currentFacility?.state || ""}}
                            {{currentFacility?.zip || ""}}</address>
                    </div>
                    <div class="header__buttons">
                        <button class="emptyBtn edit" (click)="openEditModal('facility', currentFacility)">Edit</button>
                    </div>
                </div>
                <div class="facilityMembers">
                    <div class="numMembers" *ngIf="currentFacility.administrators">Admins
                        ({{currentFacility?.administrators?.length || 0}})</div>
                    <div class="facilityMembers__list">
                        <div class="memberInfo" *ngFor="let facilityAdmin of currentFacility.administrators">
                            <div class="memberInfo__name">{{facilityAdmin}}</div>
                            <div class="memberInfo__role">Facility Admin</div>
                            <fa-icon class="deleteIcon" [icon]="icon.delete" (click)="openDeleteModal('facilityadmin', facilityAdmin, currentFacility)"></fa-icon>
                        </div>
                        <div class="memberInfo addNew" (click)="addAdminOrUserEmailTo('facilityadmin', currentFacility)">
                            <div class="memberInfo__name addIcon">
                                <fa-icon [icon]="icon.add"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="facilityMembers">
                    <div class="numMembers" *ngIf="currentFacility.caregivers">Caregivers
                        ({{currentFacility?.caregivers?.length || 0}})</div>
                    <div class="facilityMembers__list">
                        <div class="memberInfo" [ngClass]="{'memberInfo': true, 'notVerified': !caregiver?.invitationAccepted}" *ngFor="let caregiver of currentFacility.caregivers">
                            <div class="memberInfo__name">{{caregiver.email}}</div>
                            <div class="memberInfo__role">Caregiver</div>
                            <p *ngIf="!caregiver?.invitationAccepted">(needs verification)</p>
                            <fa-icon class="deleteIcon" [icon]="icon.delete" (click)="openDeleteModal('caregiver', caregiver.email, currentFacility)">
                            </fa-icon>
<!--                            TODO: make model to show who this caregiver is responsible for / ability to edit that list-->
                            <fa-icon *ngIf="caregiver?.invitationAccepted" class="viewIcon" [icon]="icon.view" (click)="openViewEditCaregiverAssignmentsModal(caregiver.email, currentFacility)"></fa-icon>
                        </div>
                        <div class="memberInfo addNew" (click)="addAdminOrUserEmailTo('caregiver', currentFacility)">
                            <div class="memberInfo__name addIcon">
                                <fa-icon [icon]="icon.add"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="facilityMembers">
                    <div class="numMembers">Users
                        ({{currentFacility?.users?.length || 0}})
                    </div>
                    <div class="facilityMembers__list">
                        <div [ngClass]="{'memberInfo': true, 'notVerified': !user?.invitationAccepted}" *ngFor="let user of currFacilityUsers">
                            <div class="memberInfo__name" *ngIf="user.firstName && user.lastName">{{user.firstName}}
                                {{user.lastName}}</div>
                            <small class="memberInfo__email">{{user.email}}</small>
                            <p *ngIf="!user?.invitationAccepted">(needs verification)</p>
                            <div class="iconsArea">
                                <fa-icon *ngIf="user?.invitationAccepted" class="viewIcon" [icon]="icon.view" (click)="openAssignCaregiverOrGuardianModal(user, currentFacility)"></fa-icon>
                                <fa-icon class="deleteIcon" [icon]="icon.delete" (click)="openDeleteModal('user', user.email, currentFacility)"></fa-icon>
                            </div>
                        </div>
                        <div class="memberInfo addNew" (click)="addAdminOrUserEmailTo('user', currentFacility)">
                            <div class="memberInfo__name addIcon">
                                <fa-icon [icon]="icon.add"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="facilityMembers">
                    <div class="numMembers" tooltip="Special Researchers who are allowed to create studies">Principle Investigators
                        ({{currentFacility?.principleInvestigators?.length || 0}})</div>
                    <div class="facilityMembers__list">
                        <div class="memberInfo" [ngClass]="{'memberInfo': true, 'notVerified': !principleInvestigator?.invitationAccepted}" *ngFor="let principleInvestigator of currentFacility.principleInvestigators">
                            <div class="memberInfo__name">{{principleInvestigator.email}}</div>
                            <div class="memberInfo__role">Principle Investigator</div>
                            <p *ngIf="!principleInvestigator?.invitationAccepted">(needs verification)</p>
                            <fa-icon class="deleteIcon" [icon]="icon.delete" (click)="openDeleteModal('principleinvestigator', principleInvestigator.email, currentFacility)">
                            </fa-icon>
                        </div>
                        <div class="memberInfo addNew" (click)="addAdminOrUserEmailTo('principleinvestigator', currentFacility)">
                            <div class="memberInfo__name addIcon">
                                <fa-icon [icon]="icon.add"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </ng-container>
        <!-- facility focus -->


    </div>

    <!-- <div class="rightPanel">

    </div> -->
</div>
