import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class FacilityAdminGuard implements CanActivate {
    static parameters = [UserService, Router];
    constructor(public userService: UserService, public router: Router) {
        this.userService = userService;
        this.router = router;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.userService.get()
            .toPromise()
            .then((res) => {
                const adminOfFacility = JSON.parse(localStorage.getItem('user')).facilityAdminOf;
                const validFacilityAdmin = !!adminOfFacility && adminOfFacility.length > 0;

                //TODO: check queryParams to ensure user is valid facilityAdmin of focused facility
                // let currentOrg = state.url.split('/')[2];

                if (res.role === 'admin' || validFacilityAdmin) {
                    return true;
                } else {
                    this.router.navigate(['/user/dashboard'], {
                        queryParams: {
                            error: 'unauthorized'
                        }
                    });
                    return false;
                }
            })
            .catch((err) => {
                this.router.navigate(['/account/login'], {
                    queryParams: {
                        return: state.url,
                        alert: 'auth-required'
                    }
                });
                return false;
            });
    }
}
