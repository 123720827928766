import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { RouterModule, Routes } from '@angular/router';
import { GraphsModule } from '../../components/graphs/graphs.module';
import { DirectivesModule } from '../../components/directives.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from "@angular/material/paginator";
import { AuthGuard } from '../../components/auth/auth-guard.service';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarSetModule } from '../../components/calendar/calendar.module';

import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import { BsModalService } from 'ngx-bootstrap/modal';
import {ProgramRSetModule} from '../programr/programr.module';
import {SettingsSetModule} from '../settings/settings.module';
import {WikiModule} from '../wiki/wiki.module';
import {CaregiverPagesComponent} from './caregiverPages.component';
import {CaregiverDashboardComponent} from './caregiverDashboard/caregiverDashboard.component';
import {CaregiverUsersActivitiesComponent} from './caregiverUserActivities/caregiverUsersActivities.component';
import {CaregiverCalendarComponent} from './caregiverCalendar/caregiverCalendar.component';
import {CaregiverMediaComponent} from './caregiverMedia/caregiverMedia.component';
import {CaregiverGameMetricsComponent} from './caregiverGameMetrics/caregiverGameMetrics.component';
import {MediaModule} from '../../components/media/media.module';

const ROUTES: Routes = [
    {
        path: ':pageView',
        component: CaregiverPagesComponent, //CaregiverDashboardComponent,
        canActivate: [AuthGuard]
    },
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
];
@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        FullCalendarModule,
        CalendarSetModule,
        CommonModule,
        CollapseModule,
        RouterModule,
        AccordionModule,
        MatExpansionModule,
        FontAwesomeModule,
        MatIconModule,
        CdkTreeModule,
        MatButtonModule,
        BsDropdownModule,
        BsDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
        NgxChartsModule,
        MatTableModule,
        MatPaginatorModule,
        AlertModule,
        GraphsModule,
        DirectivesModule,
        ProgressbarModule,
        ProgramRSetModule,
        SettingsSetModule,
        MediaModule,
        WikiModule,
    ],
    declarations: [
        CaregiverPagesComponent,
        CaregiverDashboardComponent,
        CaregiverUsersActivitiesComponent,
        CaregiverCalendarComponent,
        CaregiverMediaComponent,
        CaregiverGameMetricsComponent
    ],
    exports: [
        CaregiverPagesComponent,
        CaregiverDashboardComponent,
        CaregiverUsersActivitiesComponent,
        CaregiverCalendarComponent,
        CaregiverMediaComponent,
        CaregiverGameMetricsComponent
    ],
    providers: [BsModalService],
    schemas: [],
})
export class CaregiverPagesModule { }
