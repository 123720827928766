<div class="page">
    <div class="mainContent organizations" *ngIf="allOrgs">
        <nav class="breadcrumb breadcrumbs" aria-label="breadcrumb">
            <ol class="breadcrumbs__list">
<!--                Not focused on Org NOR facility-->
                <li class="breadcrumb-item breadcrumbItem" [attr.aria-current]="(!focusedOrgId) ? 'page' : null" (click)="focusOnOrganization()">
                    Manage
                </li>
<!--                Focused on Org-->
                <li class="breadcrumb-item breadcrumbItem" *ngIf="focusedOrgId && !!currentOrganization" [attr.aria-current]="(focusedOrgId && !!currentOrganization && !focusedFacilityId) ? 'page' : null" (click)="focusOnOrganization(currentOrganization._id)">
                    {{currentOrganization.name || "n/a"}}
                </li>
<!--                Focused on Org AND facility-->
                <li class="breadcrumb-item breadcrumbItem" *ngIf="focusedOrgId && focusedFacilityId && !!currentOrganization && !!currentFacility" [attr.aria-current]="(!!focusedOrgId && !!focusedFacilityId && !!currentOrganization && !!currentFacility) ? 'page' : null">
                    {{currentFacility.name || 'n/a'}}
                </li>
            </ol>
        </nav>

        <!--    Select Organization     -->
        <ng-container *ngIf="!focusedOrgId">
            <h4>Please select an Organization</h4>
            <div class="orgList">
                <article class="orgArea blueOnHover" *ngFor="let org of allOrgs" (click)="focusOnOrganization(org._id)">
                    <div class="header">
                        <div class="text-area">
                            <h3 class="orgName">{{org?.name || "Loading Name"}}</h3>
                            <address class="orgAddr">
                                {{org.address || ''}} <span *ngIf="org.city">, </span>
                                {{org.city || ''}}<span *ngIf="org.state">, </span>{{org.state || ''}} {{org.zip || ''}}
                            </address>
                        </div>
                    </div>
                    <div class="orgInfoCount">
                        <h6>Administrators: <span>({{org?.administrators?.length || 0}})</span></h6>
                        <h6>Robots: <span>({{org.ryans.length || 0}})</span></h6>
                        <h6>Facilities: <span>({{org?.facilities?.length || 0}})</span></h6>
                    </div>
                </article>
            </div>
        </ng-container>
        <!--        end select Organization     -->

        <!-- organization focus -->
        <ng-container *ngIf="focusedOrgId && currentOrganization && !focusedFacilityId">
            <article class="orgDetails">
                <div class="header">
                    <div class="text-area">
                        <h4 class="orgName">{{currentOrganization?.name || "Loading Name"}}</h4>
                        <address class="orgAddr">{{currentOrganization?.address || ""}},
                            {{currentOrganization?.city || ""}}, {{currentOrganization?.state || ""}}
                            {{currentOrganization?.zip || ""}}</address>
                    </div>
                    <div class="header__buttons">
                        <button class="emptyBtn edit" (click)="openEditModal('organization', currentOrganization)">Edit</button>
                    </div>
                </div>
                <div class="orgMembers">
                    <div class="numMembers" *ngIf="currentOrganization.administrators">Admins
                        ({{currentOrganization?.administrators?.length || 0}})</div>
                    <div class="orgMembers__list">
                        <div class="memberInfo" *ngFor="let orgAdmin of currentOrganization.administrators">
                            <div class="memberInfo__name">{{orgAdmin}}</div>
                            <div class="memberInfo__role">Organization Admin</div>
                            <fa-icon class="deleteIcon" [icon]="icon.delete" (click)="openDeleteModal('organizationadmin', orgAdmin, currentOrganization)"></fa-icon>
                        </div>
                        <div class="memberInfo addNew" (click)="addAdminOrUserEmailTo('organizationadmin', currentOrganization)">
                            <div class="memberInfo__name addIcon">
                                <fa-icon [icon]="icon.add"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="orgMembers">
                    <div class="numMembers" *ngIf="currentOrganization.researchers">Researchers
                        ({{currentOrganization?.researchers?.length || 0}})</div>
                    <div class="orgMembers__list">
                        <div class="memberInfo" *ngFor="let researcher of currentOrganization.researchers">
                            <div class="memberInfo__name">{{researcher}}</div>
                            <div class="memberInfo__role">Researcher</div>
                            <fa-icon class="deleteIcon" [icon]="icon.delete" (click)="openDeleteModal('organizationresearcher', researcher, currentOrganization)"></fa-icon>
                        </div>
                        <div class="memberInfo addNew" (click)="addAdminOrUserEmailTo('organizationresearcher', currentOrganization)">
                            <div class="memberInfo__name addIcon">
                                <fa-icon [icon]="icon.add"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="orgBots">
                    <div class="numBots" *ngIf="currentOrganization.ryans">Robots
                        ({{currentOrganization.ryans.length}})</div>
                    <div class="numBots" *ngIf="!currentOrganization.ryans">Robots (0)</div>
                    <div class="orgBots__list">
                        <div class="botInfo" *ngFor="let ryan of currentOrganization.ryans">
                            <div class="botInfo__name" (click)="navToBotFocus(ryan)">
                                {{ryan._id}}
                            </div>
                        </div>
                        <!-- <div class="botInfo addNew"
                            (click)="openReassignInventoryModal('organization', currentOrganization, currentOrganization)">
                            <div class="botInfo__name addIcon">
                                <fa-icon [icon]="icon.add"></fa-icon>
                            </div>
                        </div> -->
                    </div>
                </div>
            </article>
            <div class="facility-count">
                <h3>Facilities: {{currentOrganization?.facilities?.length || 0}}</h3>
                <div class="addFacility" (click)="openAddFacilityForOrganization(currentOrganization)">
                    <fa-icon [icon]="icon.add"></fa-icon>
                </div>
            </div>
            <article class="facilityDetails" *ngFor="let facility of currentOrganization.facilities">
                <div class="header">
                    <div class="text-area">
                        <h4 class="facilityName">{{facility?.name || "Loading Name"}}</h4>
                        <address class="facilityAddr">{{facility?.address || ""}},
                            {{facility?.city || ""}}, {{facility?.state || ""}}
                            {{facility?.zip || ""}}</address>
                    </div>
                    <div class="header__buttons">
                        <button class="emptyBtn edit" (click)="focusOnFacility(facility._id)">View</button>
                    </div>
                </div>
                <div class="facilityMembers">
                    <div class="numMembers" *ngIf="facility.administrators">Admins
                        ({{facility?.administrators?.length || 0}})</div>
                    <div class="facilityMembers__list">
                        <div class="memberInfo" *ngFor="let facilityAdmin of facility.administrators">
                            <div class="memberInfo__name">{{facilityAdmin}}</div>
                            <div class="memberInfo__role">Facility Admin</div>
                            <fa-icon class="deleteIcon" [icon]="icon.delete" (click)="openDeleteModal('facilityadmin', facilityAdmin, facility)"></fa-icon>
                        </div>
                        <div class="memberInfo addNew" (click)="addAdminOrUserEmailTo('facilityadmin', facility)">
                            <div class="memberInfo__name addIcon">
                                <fa-icon [icon]="icon.add"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="orgBots">
                    <div class="numBots" *ngIf="facility.ryans">Robots
                        ({{facility.ryans.length}})</div>
                    <div class="numBots" *ngIf="!facility.ryans">Robots (0)</div>
                    <div class="orgBots__list">
                        <div class="botInfo" *ngFor="let ryan of facility.ryans">
                            <div class="botInfo__name" (click)="navToBotFocus(ryan)">R{{ryan._id.slice(ryan._id.length -
                                3)}}</div>
                        </div>
                        <!-- <div class="botInfo addNew"
                            (click)="openReassignInventoryModal('facility', facility, currentOrganization)">
                            <div class="botInfo__name addIcon">
                                <fa-icon [icon]="icon.add"></fa-icon>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="facilityMembers">
                    <div class="numMembers">Users
                        ({{facility?.users?.length || 0}})</div>
                    <div class="facilityMembers__list">
                        <div [ngClass]="{'memberInfo': true, 'notVerified': !user.invitationAccepted}" *ngFor="let user of facility.users">
                            <div class="memberInfo__name">{{user.email}}</div>
                            <p *ngIf="!user.invitationAccepted">(needs verification)</p>
                            <fa-icon class="deleteIcon" [icon]="icon.delete" (click)="openDeleteModal('user', user, facility)"></fa-icon>
                        </div>
                        <div class="memberInfo addNew" (click)="addAdminOrUserEmailTo('user', facility)">
                            <div class="memberInfo__name addIcon">
                                <fa-icon [icon]="icon.add"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="facilityMembers">
                    <div tooltip="Special Researchers who are allowed to create studies" class="numMembers">Principle Investigators
                        ({{facility?.principleInvestigators?.length || 0}})</div>
                    <div class="facilityMembers__list">
                        <div [ngClass]="{'memberInfo': true, 'notVerified': !principleInvestigator.invitationAccepted}" *ngFor="let principleInvestigator of facility.principleInvestigators">
                            <div class="memberInfo__name">{{principleInvestigator.email}}</div>
                            <p *ngIf="!principleInvestigator.invitationAccepted">(needs verification)</p>
                            <fa-icon class="deleteIcon" [icon]="icon.delete" (click)="openDeleteModal('principleinvestigator', principleInvestigator, facility)"></fa-icon>
                        </div>
                        <div class="memberInfo addNew" (click)="addResearcherToFacility(facility)">
                            <div class="memberInfo__name addIcon">
                                <fa-icon [icon]="icon.add"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </ng-container>
        <!-- organization focus -->

        <!-- Facility Focus -->
        <ng-container *ngIf="focusedOrgId && focusedFacilityId && currentOrganization && currentFacility">
            <article class="facilityDetails">
                <div class="header">
                    <div class="text-area">
                        <h4 class="facilityName">{{currentFacility.name || "Loading Name"}}</h4>
                        <small class="facilityAddr">{{currentFacility.address || ""}},
                            {{currentFacility.city || ""}}, {{currentFacility.state || ""}}
                            {{currentFacility.zip || ""}}</small>
                    </div>
                    <div class="header__buttons">
                        <button class="emptyBtn edit" (click)="openEditModal('facility', currentFacility)">Edit</button>
                    </div>
                </div>
                <div class="facilityMembers">
                    <div class="numMembers" *ngIf="currentFacility.administrators">Admins
                        ({{currentFacility.administrators?.length || 0}})</div>
                    <div class="facilityMembers__list">
                        <div class="memberInfo" *ngFor="let facilityAdmin of currentFacility.administrators">
                            <div class="memberInfo__name">{{facilityAdmin}}</div>
                            <div class="memberInfo__role">Facility Admin</div>
                            <fa-icon class="deleteIcon" [icon]="icon.delete" (click)="openDeleteModal('facilityadmin', facilityAdmin, currentFacility)"></fa-icon>
                        </div>
                        <div class="memberInfo addNew" (click)="addAdminOrUserEmailTo('facilityadmin', currentFacility)">
                            <div class="memberInfo__name addIcon">
                                <fa-icon [icon]="icon.add"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="facilityBots">
                    <div class="numBots" *ngIf="currentFacility.ryans">Robots
                        ({{currentFacility.ryans.length}})</div>
                    <div class="numBots" *ngIf="!currentFacility.ryans">Robots (0)</div>
                    <div class="facilityBots__list">
                        <div class="botInfo" *ngFor="let ryan of currentFacility.ryans">
                            <div class="botInfo__name" (click)="navToBotFocus(ryan)">R{{ryan._id.slice(ryan._id.length -
                                3)}}</div>
                        </div>
                        <!-- <div class="botInfo addNew"
                            (click)="openReassignInventoryModal('facility', currentFacility, currentOrganization)">
                            <div class="botInfo__name addIcon">
                                <fa-icon [icon]="icon.add"></fa-icon>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="facilityMembers">
                    <div class="numMembers" *ngIf="currentFacility.users">Users
                        ({{currentFacility.users?.length || 0}})</div>
                    <div class="facilityMembers__list">
                        <div class="memberInfo" *ngFor="let user of currentFacility.users">
                            <div class="memberInfo__name">{{user.email}}</div>
                            <div class="memberInfo__role">User</div>
                            <fa-icon class="deleteIcon" [icon]="icon.delete" (click)="openDeleteModal('user', user, currentFacility)"></fa-icon>
                        </div>
                        <div class="memberInfo addNew" (click)="addAdminOrUserEmailTo('user', currentFacility)">
                            <div class="memberInfo__name addIcon">
                                <fa-icon [icon]="icon.add"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="facilityMembers">
                    <div tooltip="Special Researchers who are allowed to create studies" class="numMembers">Principle Investigators
                        ({{currentFacility?.principleInvestigators?.length || 0}})</div>
                    <div class="facilityMembers__list">
                        <div [ngClass]="{'memberInfo': true, 'notVerified': !principleInvestigator.invitationAccepted}" *ngFor="let principleInvestigator of currentFacility.principleInvestigators">
                            <div class="memberInfo__name">{{principleInvestigator.email}}</div>
                            <p *ngIf="!principleInvestigator.invitationAccepted">(needs verification)</p>
                            <fa-icon class="deleteIcon" [icon]="icon.delete" (click)="openDeleteModal('principleinvestigator', principleInvestigator, currentFacility)"></fa-icon>
                        </div>
                        <div class="memberInfo addNew" (click)="addResearcherToFacility(currentFacility)">
                            <div class="memberInfo__name addIcon">
                                <fa-icon [icon]="icon.add"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </ng-container>
        <!-- Facility Focus -->


    </div>

    <!-- <div class="rightPanel">
        <div class="addOrg-btn" (click)="addFacility()">
            <org-icon class="btn-icon" [ellipse]="true"></org-icon>
            <span class="btn-title">Add a Facility</span>
        </div>
        <orglistpanel [orgs]="allOrgs"></orglistpanel>
    </div> -->
</div>
