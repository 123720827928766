import {
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import moment from 'moment';

@Component({
    selector: 'panel-date-range-display',
    templateUrl: './panelDateRangeDisplay.html',
    styleUrls: ['./panelDateRangeDisplay.scss']
})


export class PanelDateRangeDisplayComponent implements OnInit, OnDestroy {
    @Input('queryDateRange') queryDateRange: [Date, Date] = [new Date(), new Date()];

    upArrow = faArrowUp;
    static parameters = [];
    constructor() {}

    public ngOnInit(): void { }

    formatDate(d: Date) {
        return moment(d).format('MMM Do YYYY');
    }

    scrollToTop() {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    public ngOnDestroy(): void { }
}


