import {Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {isValidDate} from '../../util';
import moment from "moment";
import {FormControl, FormGroup, NgForm} from "@angular/forms";

@Component({
    selector: 'downloadDataModal',
    templateUrl: './downloadDataModal.html',
    styleUrls: ['./downloadDataModal.scss']
})
export class DownloadDataModalComponent implements OnChanges {
    @Input()
    alert: { show: boolean, type: string, message: string } = {
        show: false,
        type: '',
        message: '',
    }
    @Input('modalHeader') modalHeader: string = 'Download data';

    @Output() submitQuery: EventEmitter<any> = new EventEmitter();

    targetId: string = undefined; //the _id of the target user or facility we want to download the data for
    queryDateRange: [Date, Date] = undefined;
    queryActivity: string = 'Activity';

    activityTypeOptions = [
        'Activity', 'BirdCounting', 'CalendarCreateEvent', 'CalendarDeleteEvent',
        'Checkers', 'Chess', 'Connectivity', 'Dementia', 'FER', 'Flight', 'FlowGame', 'Mood', 'OneLine', 'PicturePuzzle', 'ProgramR', 'Poker',
        'RyanRuns', 'Solitaire', 'Session', 'TicTacToe', 'WordPuzzle', 'Yoga', 'YogaROMTest', 'LegBalanceTest', 'WreckingBall'
    ]

    queryForm: FormGroup;
    submitting: boolean = false;

    minAllowedDate: Date = undefined;
    maxAllowedDate: Date = undefined;

    static parameters = [BsModalRef];

    constructor(public bsModalRef: BsModalRef) {
        this.bsModalRef = bsModalRef;

        this.queryForm = new FormGroup({
            activityType: new FormControl(),
            startTime: new FormControl(),
            endTime: new FormControl(),
            id: new FormControl(),
        });
        this.queryForm.controls['activityType'].setValue('Activity', {onlySelf: true});
        this.minAllowedDate = new Date('November 1, 2020 01:00:00'),
        this.maxAllowedDate = new Date();
    }

    ngOnChanges(changes: SimpleChanges): void { }

    ngOnInit(): void {
        if (!this.validateInput()) throw new Error('Missing or Invalid input values');

        this.queryForm.controls['id'].setValue(this.targetId);
        this.queryForm.setValue({
            startTime: this.queryDateRange[0],
            endTime: this.queryDateRange[1],
            id: this.targetId,
            activityType: 'Activity',
        });
    }

    validateInput(): boolean {
        if (this.targetId && this.queryDateRange) {
            if (this.queryDateRange.length === 2 && isValidDate(this.queryDateRange[0]) && isValidDate(this.queryDateRange[1])) {
                if (this.queryDateRange[0].getTime() > this.queryDateRange[1].getTime()) {
                    const temp = this.queryDateRange[0];
                    this.queryDateRange[0] = this.queryDateRange[1];
                    this.queryDateRange[1] = temp as Date;
                }
                return true;
            }
        }
        return false;
    }

    /**
     * datePickerUpdate: handles updates to the datePicker date range, and EMITs an event to the parent
     *                      (which, in turn, should send it back down to each of the child graphs on the page)
     * @param newDates: an array of the new [startTime, endTime] for the query
     */
    datePickerUpdate(newDates: [Date, Date]) {
        if (!isValidDate(newDates[0]) || !isValidDate(newDates[1])) throw new Error('Invalid Dates');

        //format the dates to be the UTC absolute beginning and absolute end of a day
        //  ex: 2021-02-15T00:00:00.000Z  -  2021-03-20T23:59:59.999Z
        var newStart = moment(newDates[0]).startOf('day').toDate();
        const newEnd = moment(newDates[1]).endOf('day').toDate();

        if (newStart.getTime() !== this.queryDateRange[0].getTime() || newEnd.getTime() !== this.queryDateRange[1].getTime()) {
            this.queryDateRange[0] = newStart;
            this.queryDateRange[1] = newEnd;
            this.queryForm.controls['startTime'].setValue(newStart);
            this.queryForm.controls['endTime'].setValue(newEnd);
            // this.queryDatesUpdate.emit(this.queryDateRange);
        }
    }

    handleSubmit() {
        if (!this.queryForm.valid) return;
        this.submitting = true;
        const start = moment(this.queryForm.controls.startTime.value);
        const end = this.queryForm.controls.endTime.value;
        const activity = this.queryForm.controls.activityType.value;
        this.submitQuery.emit({
            startTime: this.queryForm.controls.startTime.value,
            endTime: this.queryForm.controls.endTime.value,
            activityType: this.queryForm.controls.activityType.value
        });
    }

    exit() {
        this.submitting = false;
    }
}
