<article class="graph-area hardware">
    <div class="hardware__header">
        <h5 class="graph-title col-8">{{graphTitle}}</h5>

    </div>

    <section class="hardware__mainGraph" *ngIf="!hardwareDataReady">
        No data available
    </section>

    <section class="hardware__table" *ngIf="hardwareDataReady">
        <mat-form-field class="float-right" appearance="standard">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Internet Off" #input>
        </mat-form-field>
        <table mat-table [dataSource]="hardwareEventsMatTable" class="hardwareDetails mat-elevation-z0" matSort>


            <!-- Date -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let element">{{element.time | date:'M/d/yy'}}</td>
            </ng-container>
            <!-- Event Name-->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            <!-- Severity -->
            <ng-container matColumnDef="severity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Severity </th>
                <td mat-cell *matCellDef="let element" [ngClass]="element?.event?.severity"> {{element.event.severity}} </td>
            </ng-container>
            <!-- Details -->
            <ng-container matColumnDef="details">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Details </th>
                <td mat-cell *matCellDef="let element"> {{element.event.details}} </td>
            </ng-container>

            <!-- Time -->
            <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Time </th>
                <td mat-cell *matCellDef="let element"> {{element.time | date:'HH:mm'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="displayRow"></tr>
        </table>

        <mat-paginator #hardwareDetailsPaginator pageSize="20" [pageSizeOptions]="[10, 20, 30, 50, 100, 500]" showFirstLastButtons></mat-paginator>
    </section>
</article>
