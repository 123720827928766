import {Component, OnDestroy, OnInit, EventEmitter, Output, Input} from '@angular/core';

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UserService, UserType } from '../../../components/auth/user.service';
import { AuthService } from '../../../components/auth/auth.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { faBell, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { InventoryService } from '../../../services/inventory/inventory.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CalendarEvent } from '../../../components/interfaces/CalendarEvent';
import { CalendarEventService } from '../../../services/calendar-event/calendar-event.service';
import {FormControl, FormGroup} from '@angular/forms';
import {Facility} from '../../../components/interfaces/Facility';
import {FacilityService} from '../../../services/facility/facility.service';
import _ from 'lodash';
import {AppState} from '../../../components/interfaces/AppState';
import moment from 'moment';


@Component({
    selector: 'caregiver-dashboard',
    templateUrl: './caregiverDashboard.html',
    styleUrls: ['../../../assets/sharedStyles/pages/dashboard.scss'],
})


export class CaregiverDashboardComponent implements OnInit, OnDestroy {
    @Input('state') appState: AppState;
    @Output() queryDateRangeChange = new EventEmitter<[Date, Date]>();

    currentUser: UserType;
    currentDate = new Date();

    state = {
        org_id: undefined,
        ryan_id: undefined
    };

    usersLoaded: boolean = false; //determines if all user content is loaded
    facilitiesLoaded: boolean = false; //determines if all facilities are loaded
    botsLoaded: boolean = false; //determines if all Ryan information is loaded
    eventsLoaded: boolean = false; //determines if all Events have been loaded
    analyticsLoaded: boolean = false; //determines if all Analytics have been loaded
    ageDataLoaded: boolean = false;
    loaded: boolean[] = [false]; //determines if all necessary content is loaded


    icon = {
        bell: faBell,
        userCircle: faUserCircle,
        search: faSearch,
        calendar: faCalendarAlt
    };
    dateControl = new FormGroup({
        dateYMD: new FormControl(new Date()),
        dateFull: new FormControl(new Date()),
        dateMDY: new FormControl(new Date()),
        dateRange: new FormControl([
            new Date(new Date().getDate() - 7),
            new Date()
        ])
    });
    dateParams = {
        minAllowed: new Date('November 1, 2020 01:00:00'),
        maxAllowed: this.currentDate,
        minParam: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7),
        maxParam: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        ranges: [{
            value: [
                new Date(new Date().setDate(new Date().getDate() - 7)),
                new Date()],
            label: 'Last 7 days'
        },
        {
            value: [new Date(new Date().setDate(new Date().getDate() - 30)),
                new Date()],
            label: 'Last 30 days'
        },
        ]
    };
    selectedRange: Date[] = this.dateParams.ranges[0].value;
    //Date picker
    queryMinDate: Date = undefined;
    queryMaxDate: Date = undefined;
    queryDateRange:[Date, Date] = [this.queryMinDate, this.queryMaxDate];

    allUsers: UserType[] = [];
    userAgeData: any[] = [];

    allFacilities: Facility[] = [];

    myEvents: CalendarEvent[] = [];
    numEvents: number;

    errOccurred: boolean = false;

    static parameters = [Router, AuthService, UserService, FacilityService, InventoryService, CalendarEventService, AnalyticsService, BsModalService, HttpClient];

    constructor(public router: Router, public authService: AuthService,
                public userService: UserService, public facilityService: FacilityService,
                public inventoryService: InventoryService, public eventService: CalendarEventService, public analyticService: AnalyticsService,
                public modalService: BsModalService, public http: HttpClient) {
        this.router = router;
        this.modalService = modalService;
        this.authService = authService;
        this.userService = userService;
        this.facilityService = facilityService;
        this.inventoryService = inventoryService;
        this.eventService = eventService;

        this.loaded = [false];

        //get the current user from the token
        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserType;

        this.initDatePicker();
    }

    ngOnInit(): void {
        Promise.all([this.getFacilities(), this.getCaregiversUsers(), this.getMyEvents()])
            .then(res => {
                this.allFacilities = this.completeUserInfoForFacilities([...res[0]], [...res[1]]);
                this.allUsers = res[1] as UserType[];
                this.myEvents = res[2] as CalendarEvent[];
                this.getAgeData([...res[0]]);
            })
            .catch(e => {
                console.error(e);
                this.errOccurred = true;
            });
        this.isLoaded();
    }

    /**
     * isLoaded
     *      this method will determine whether or not all of the necessary parts of the page have been loaded
     */
    isLoaded(): boolean {
        this.loaded = [
            !!this.allUsers,
            !!this.allFacilities,
            !!this.myEvents,
            !!this.userAgeData
        ];
        return this.loaded.every(v => v === true);
    }

    getFacilities(): Promise<Facility[]> {
        return this.facilityService.getFacilitiesForCaregiver().toPromise()
            .then((f: {facilities: Facility[]}) => f.facilities)
            .catch(e => {
                console.error(e);
                this.errOccurred = true;
                return Promise.reject('Failed to load Facilities');
            });
    }

    /**
     * getCaregiversUsers
     *      method to be used to retrieve the user data for those in a caregiver's caregiverOf[]
     */
    getCaregiversUsers(): Promise<UserType[]> {
        return this.userService.getUsersForCaregiver()
            .toPromise()
            .then((userList: { users: UserType[]}) => userList.users as UserType[])
            .catch(e => {
                console.error(e);
                this.errOccurred = true;
                return Promise.reject('Failed to load Users');
            });
    }

    completeUserInfoForFacilities(facilitySet: Facility[], userSet: UserType[]): Facility[] {
        return facilitySet.map(f => ({
            ...f,
            users: f.users.map(u => {
                const {firstName, lastName, email, anonymizedName, birthday } = userSet.find(user => u.email === user.email);
                return {
                    id: anonymizedName,
                    _id: anonymizedName,
                    firstName,
                    lastName,
                    email,
                    anonymizedName,
                    birthday,
                    caregivers: u.caregivers || [],
                    guardians: u.guardians || []
                };
            })
                //Sort users alphabetically by full name (mostly for the userList Panel)
                .sort((a, b) =>
                    `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
                )
        }));
    }

    getMyEvents(): Promise<CalendarEvent[]> {
        return this.eventService.query(this.currentUser.email)
            .then((res: CalendarEvent[]) => {
                //Filter out days that are from more than a day ago
                //TODO: handle recurring events
                const now = moment();
                return res.filter(e => e.begin > now.toISOString()) as CalendarEvent[];
            })
            .catch((err) => {
                this.errOccurred = true;
                console.error(err);
                return Promise.reject('Failed to load Events');
            });
    }

    getAgeData(facilities: Facility[]) {
        const facilityIds: string[] = facilities.map(f => f._id);
        const promises = facilityIds.map(facID => new Promise<any>((resolve, reject) => {
            const query = { facility: facID, role: 'caregiver' };
            this.analyticService.getAgeDataForFacility(query).toPromise()
                .then((res: {ageData: {name, value}[]}) => resolve(res.ageData))
                .catch(e => {
                    console.error(e);
                    this.errOccurred = true;
                    return reject(`An error occured retrieving age data for: ${query.facility}`);
                });
        }));

        //FIXME: if caregiver for same User in N facilities, the age data will count them N times
        Promise.all(promises)
            .then((res: any[]) => _.flatten(res)
                .reduce((arr, i) => {
                    const pos = arr.findIndex(e => e.name === i.name);
                    if (pos > -1) {
                        arr[pos].value += i.value;
                        return arr;
                    }
                    return arr.concat(i);
                }, [])
            )
            .then((r: {name: string, value: number}[]) => {
                this.userAgeData = r;
            })
            .catch(e => {
                console.error(e);
                this.errOccurred = true;
            });
    }


    queryDateUpdate(ev) {
        this.queryMinDate = ev[0];
        this.queryMaxDate = ev[1];
        this.queryDateRange = [this.queryMinDate, this.queryMaxDate];
        this.queryDateRangeChange.emit(this.queryDateRange);
    }

    navToFocusedUserActivitiesPage(userId: string) {
        const focusedUser = this.allUsers.find(u => u.anonymizedName === userId || u.email === userId);
        const focusedFacility = this.allFacilities.find(f => f.users.some(u => u.email === focusedUser.email && u.caregivers.includes(this.currentUser.email)));
        if (focusedUser && focusedFacility) {
            this.router.navigate(['caregiver', 'usersactivities'], {
                queryParams: {facilityId: focusedFacility._id, userId: focusedUser.anonymizedName}})
                .then(r => console.log(r));
        }
    }
    navToRyanDataPage() {
        // this.router.navigate(['/ryandata']);
    }

    navToAllOrgPage() {
        // this.router.navigate(['/organizations']);
    }

    navToOrgFocus(orgId: string) {
        console.log(`nav to org-focus page for org${orgId}`);
    }

    navToCalendar() {
        this.router.navigate(['caregiver', 'calendar']);
    }

    downloadData() {
        console.log('downloadData');
    }

    /**
     * initDatePicker: initialize the values used to control the datePicker
     */
    public initDatePicker() {
        this.currentDate = new Date();
        //set date params without timestamp
        this.queryMinDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() - 7);
        this.queryMaxDate = new Date(this.currentDate);
        this.dateParams = {...this.dateParams,
            minAllowed: new Date('November 1, 2020 01:00:00'),
            maxAllowed: this.currentDate,
            minParam: this.queryMinDate,
            maxParam: this.queryMaxDate
        };
        this.queryDateRange = [this.queryMinDate, this.queryMaxDate];
    }

    ngOnDestroy(): void { }
}
