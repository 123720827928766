import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AlertModule } from 'ngx-bootstrap/alert';
import { UserSettingsComponent } from "./userSettings/userSettings.component";
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from "../../components/auth/auth.service";
import { UserService } from "../../components/auth/user.service";
import { SettingsPageComponent } from "./settings.component";
import { DirectivesModule } from "../../components/directives.module";



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        CollapseModule,
        RouterModule,
        AlertModule,
        DirectivesModule,
    ],
    declarations: [
        SettingsPageComponent,
        UserSettingsComponent,
    ],
    exports: [
        SettingsPageComponent
    ],
    providers: [
        AuthService,
        UserService,
    ],

})
export class SettingsSetModule { }
