// // @flow
import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class ProgramRService {
    public baseUrl = '/programr';
    static parameters = [HttpClient];
    constructor(public http: HttpClient) {
        this.http = http;
    }

    sendMessage({message}){
        this.http.post(this.baseUrl, {
            question: message
        }).subscribe({
            next: (data: { message: string }) => {
                console.log(data);
                return data;
            },
            error: (error: HttpErrorResponse) => {
                console.error(error);
                // let message = 'Error';
                // if (error.statusText) message = error.statusText;
                // if (error.error && error.error.message) message = error.error.message;
                // if (error && error.status) {
                    // this.alert = {
                    //     show: true,
                    //     message: String(message),
                    //     type: 'danger',
                    // }
                }
            }
        )
    }

}
