<div class="page">
    <!--  ======  Loading view  ======  -->
    <div *ngIf="(!loaded || !isLoaded())">
        Loading...
    </div>

    <div class="mainContent dash" *ngIf="(isLoaded() || loaded)">

        <article class="mainContent__tile">
            <queryDateRangeControl [title]="'Control'" [queryDateRange]="queryDateRange" (queryDatesUpdate)="queryDateUpdate($event)">
            </queryDateRangeControl>
        </article>

        <stacked-bar-chart *ngIf="featureFlags.gameAnalytics" [graphTitle]="'My Activities'" [queryDateRange]="queryDateRange" [targetUser]="currentUser" [dataName]="'ActivityMetricsMe'">
        </stacked-bar-chart>

        <div class="events">
            <div class="mainContent__tile events__table">
                <div class="header">
                    <div class="text-area">
                        <h4 class="header__title graphTitle">Events</h4>
                    </div>
                </div>
                <div class="table-container">
                    <table class="table">
                        <thead class="table__header">
                            <tr>
                                <th>Event Name</th>
                                <th>Date</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody class="table__body" *ngIf="myEvents.length > 0">
                            <tr class="table__data" *ngFor="let ev of myEvents; let i = index;">
                                <td class="id eventName" *ngIf="i <=4"><span class="colorCode"></span>{{ev.title}}</td>
                                <td class="status eventDate" *ngIf="i <=4">{{ev.end | date:'shortDate'}}</td>
                                <td class="community eventTime" *ngIf="i <=4">{{ev.end | date:'shortTime'}}</td>
                            </tr>
                        </tbody>

                        <tbody class="table__body" *ngIf="myEvents.length === 0">
                            <tr class="table__data">
                                <td class="id eventName noEvents" colspan="3">No Upcoming Events</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="moreBtn">
                    <span (click)="navToCalendar()">More</span>
                </div>
            </div>

            <div class="mainContent__tile events__calendar">
                <bs-datepicker-inline [bsValue]="currentDate" [isDisabled]="true" [bsConfig]="{showWeekNumbers: false}">
                </bs-datepicker-inline>
                <button type="button" class="btn filledBtn" (click)="navToCalendar()">
                    Create New Event
                </button>
            </div>
        </div>
    </div>

    <div class="rightPanel">
        <!--        TODO: check user acct permissions to decide what content to display -->
        <div *ngIf="(!isLoaded() || !loaded)">Loading Users...</div>

        <!--        Caregiver view -->
        <userlistpanel [users]="allUsers" [title]="'My Contacts'" *ngIf="(isLoaded() || loaded)"></userlistpanel>
    </div>
</div>
