import {Component, Input} from '@angular/core';

@Component({
    selector: 'org-icon',
    templateUrl: './org-icon.svg',
    styleUrls: ['./org-icon.scss']
})
export class OrgIconComponent {
    @Input() ellipse:boolean;
    fillColor = '#66B734';
}
