import { Component, OnDestroy, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventClickArg, EventInput, EventApi, FullCalendarComponent } from '@fullcalendar/angular';

// ["Activity", "Medicine", "Appointment", "Exercise"];
const getColorForEventType = (eventType: string) => {
    switch (eventType.toLowerCase()) {
    case 'activity':
        return 'orange';
    case 'medicine':
        return 'purple';
    case 'appointment':
        return 'pink';
    case 'exercise':
        return 'green';
    default:
        return 'blue';
    }
};
// for now, 1 timezone only (UTC)
const convertDateTimeToDateString = (dateTime) => `${dateTime.getFullYear()}-${`0${dateTime.getMonth() + 1}`.slice(-2)}-${`0${dateTime.getDate()}`.slice(-2)}`;
const convertDateTimeToTimeString = (dateTime) => `${`0${dateTime.getHours()}`.slice(-2)}:${`0${dateTime.getMinutes()}`.slice(-2)}`;

const convertDayNamesToNumber = (daysArray: string[]) => daysArray.map((day) => {
    switch (day.toLowerCase()) {
    case 'su':
        return 0;
    case 'mo':
        return 1;
    case 'tu':
        return 2;
    case 'we':
        return 3;
    case 'th':
        return 4;
    case 'fr':
        return 5;
    case 'sa':
        return 6;
    default:
        return 0;
    }
});

@Component({
    selector: 'calendar-view',
    templateUrl: './calendarView.html',
    providers: [],
    styleUrls: ['./calendarView.scss'],
})

export class CalendarViewComponent implements OnChanges {
    @Input() eventsList = [];
    @Input() activeEventId = undefined;
    @Output() activeEventIdChange = new EventEmitter<string>();

    ngOnChanges(changes: SimpleChanges) {
        this.mapEvents();
    }

    @ViewChild('calendar') calendarComponent: FullCalendarComponent;

    calendarOptions: CalendarOptions = {
        timeZone: 'local',
        initialView: 'dayGridMonth',
        weekends: true,
        initialEvents: [],
        headerToolbar: {
            left: 'prev,today,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },
        selectable: true,
        select: this.handleDateSelect.bind(this),
        eventClick: this.handleEventClick.bind(this),
        eventsSet: this.handleEvents.bind(this),
    };

    //list of EventApi's
    // instance of EventApi.remove() to remove an event
    currentEvents: EventApi[] = [];

    handleDateSelect(selectInfo: DateSelectArg) {
        const targetDay = selectInfo.startStr;
        const calendarApi = this.calendarComponent.getApi();
        calendarApi.changeView('timeGridDay', targetDay);
    }

    handleEventClick(clickInfo: EventClickArg) {
        // sample: use publicId for deleting event
        this.activeEventId = clickInfo.event._def.publicId;
        this.activeEventIdChange.emit(this.activeEventId);
    }


    removeEvent(publicId: string) {
        // EventApi._def.publidId ~ event.id
        const targetEvent = this.currentEvents.find(e => e._def.publicId === publicId);
        targetEvent.remove();
    }

    handleEvents(events: EventApi[]) {
        this.currentEvents = events;
    }

    public mapEvents(): void {
        // sets events coming from parent
        this.calendarOptions.events = this.eventsList.map(e => {
            const beginDateTime = new Date(e.begin);
            const endDateTime = new Date(e.end);
            const startDate = convertDateTimeToDateString(beginDateTime);
            const endDate = convertDateTimeToDateString(endDateTime);
            const startTime = convertDateTimeToTimeString(beginDateTime);
            const endTime = convertDateTimeToTimeString(endDateTime);
            const eventObj = e.recurring
                ? {
                    id: e._id,
                    title: e.title,
                    // recur should be date yyyy-mm-dd
                    startRecur: startDate,
                    endRecur: endDate,
                    // start time should be hh:mm:ss
                    startTime: startTime,
                    endTime: endTime,
                    eventType: e.eventType,
                    description: e.caption,
                    daysOfWeek: convertDayNamesToNumber(e.recurring.dow),
                    backgroundColor: getColorForEventType(e.eventType),
                    borderColor: getColorForEventType(e.eventType),

                }
                : {
                    id: e._id,
                    title: e.title,
                    start: e.begin,
                    end: e.end,
                    eventType: e.eventType,
                    description: e.caption,
                    backgroundColor: getColorForEventType(e.eventType),
                    borderColor: getColorForEventType(e.eventType),
                };
            return eventObj;
        });
    }

    click() { }
}

