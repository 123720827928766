import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { RouterModule, Routes } from '@angular/router';
import { GraphsModule } from '../../components/graphs/graphs.module';
import { DirectivesModule } from '../../components/directives.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatTableModule } from '@angular/material/table';
import { AuthGuard } from '../../components/auth/auth-guard.service';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarSetModule } from '../../components/calendar/calendar.module';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProgramRSetModule } from '../programr/programr.module';
import { SettingsSetModule } from '../settings/settings.module';
import {WikiModule} from '../wiki/wiki.module';
import { OrganizationAdminPagesComponent } from './orgadminPages.component';
import { OrgAdminGameMetricsComponent } from './orgadminGameMetrics/orgadminGameMetrics.component';
import { OrgAdminDashboardComponent } from './orgadminDashboard/orgadminDashboard.component';
import { OrgAdminRyanDataComponent} from './orgadminRyanData/orgadminRyanData.component';
import { OrgAdminManageComponent } from './orgAdminManage/orgAdminManage.component';
import {TooltipModule} from "ngx-bootstrap/tooltip";

const ROUTES: Routes = [
    {
        path: ':pageView',
        component: OrganizationAdminPagesComponent,
        canActivate: [AuthGuard]
    },
];
@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        FullCalendarModule,
        CalendarSetModule,
        CommonModule,
        CollapseModule,
        RouterModule,
        AccordionModule,
        MatExpansionModule,
        FontAwesomeModule,
        MatIconModule,
        CdkTreeModule,
        MatButtonModule,
        BsDropdownModule,
        BsDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
        NgxChartsModule,
        MatTableModule,
        AlertModule,
        WikiModule,
        GraphsModule,
        DirectivesModule,
        ProgressbarModule,
        ProgramRSetModule,
        SettingsSetModule,
        TooltipModule,
    ],
    declarations: [
        OrganizationAdminPagesComponent,
        OrgAdminGameMetricsComponent,
        OrgAdminDashboardComponent,
        OrgAdminRyanDataComponent,
        OrgAdminManageComponent,
    ],
    exports: [
        OrganizationAdminPagesComponent,
        OrgAdminGameMetricsComponent,
        OrgAdminDashboardComponent,
    ],
    providers: [BsModalService],
    schemas: [],
})
export class OrganizationAdminPagesModule { }
