import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '../../auth/auth.service';
import {emailValidation, formatRoleName} from '../../util';
import {instanceOfFacility} from '../../interfaces/Facility';

@Component({
    selector: 'add-email',
    templateUrl: './add-email.html'
})
export class AddEmailComponent {
    @Input()
    alert: {
        type: string,
        show: boolean,
        message: string,
    }

    @Input() addToType: String;
    @Input() addTo: any;
    @Output() confirmEmail: EventEmitter<String> = new EventEmitter<String>();

    email: string = '';
    validEmail = () => emailValidation(this.email);
    formattedRoleName: string;
    static parameters = [BsModalRef];

    constructor(public bsModalRef: BsModalRef, public authService: AuthService) {
        this.authService = authService;
    }

    formatRoleName(): string {
        if (!this.formattedRoleName) this.formattedRoleName = formatRoleName(this.addToType.toString());
        return this.formattedRoleName;
    }

    isFacility(obj) {
        if (!obj) obj = this.addTo;
        return instanceOfFacility(obj);
    }

    validateEmail(ev?) {
        if (this.alert?.show) this.alert.show = false;
    }

    submitEmail() {
        // console.log(this.email, emailValidation(this.email));
        if (!this.email || !emailValidation(this.email)) {
            this.alert = {
                type: 'info',
                show: true,
                message: 'Please provide a valid email address',
            };
            return;
        }
        this.alert = { type: undefined, show: false, message: undefined };
        this.confirmEmail.emit(this.email);
    }
}

