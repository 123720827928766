<div class="left-nav">
    <div class="left-nav__profile">
        <div class="userIcon">
            <fa-icon size="7x" [icon]="icon.userIcon"></fa-icon>
        </div>
        <div class="userDetails" *ngIf="currentUser">
            <h4 class="userName" *ngIf="currentUser != undefined">{{currentUser?.firstName}} {{currentUser?.lastName}}
            </h4>
            <h4 class="userName text-danger" *ngIf="currentUser === undefined">undefined undefined</h4>

            <h5 class="userRole" *ngIf="currentUser.role !== undefined">{{state.roleView | formatRole}}</h5>
            <h5 class="userRole userRole--undef" *ngIf="currentUser.role === undefined">undefined</h5>
        </div>
    </div>
    <nav role="navigation" aria-roledescription="Page Selection" class="left-nav__nav">
        <div class="navLinksArea">
            <div *ngFor="let el of state.navElements" class="navLink navLink__auto" [ngClass]="{'selected': el.iconName === state.pageView.split('/')[0]}" (click)="navigateTo(el.link)">
                <div class="linkIcon">
                    <fa-icon [icon]="icon[el.iconName]"></fa-icon>
                </div>
                <h4 class="linkName">{{el.name}}</h4>
            </div>
            <div class="navLink navLink__logout" (click)="handleLogout($event)">
                <div class="linkIcon">
                    <fa-icon [icon]="icon.logout"></fa-icon>
                    <!--                    <logout-icon></logout-icon>-->
                </div>
                <h4 class="linkName">Log out</h4>
            </div>
        </div>
    </nav>
    <div class="left-nav__logo">
        <img src="/59c7405690f23855518ca58076df9c06.png" alt="DreamFace Technologies Logo" class="pull-left">
    </div>
</div>
