import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class CaregiverGuard implements CanActivate {
    static parameters = [UserService, Router];
    constructor(public userService: UserService, public router: Router) {
        this.userService = userService;
        this.router = router;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.userService.get()
            .toPromise()
            .then((res) => {
                const caregiver = JSON.parse(localStorage.getItem('user')).caregiverOf;
                const isCaregiver = !!caregiver && caregiver.length > 0;

                //TODO: check queryParams to ensure user is valid caregiver of focused facility / User
                // const isCaregiverForFacility = ....
                // const isCaregiverForUser = ....

                // console.log(state);
                // console.log(route);
                // let currentUser = state.url.split('/')[2];

                if (res.role === 'admin' || isCaregiver) {
                    return true;
                } else {
                    this.router.navigate(['user', 'dashboard'], {
                        queryParams: {
                            error: 'unauthorized'
                        }
                    });
                    return false;
                }
            })
            .catch((err) => {
                console.error(err);
                this.router.navigate(['/account/login'], {
                    queryParams: {
                        return: state.url,
                        alert: 'auth-required'
                    }
                });
                return false;
            });
    }
}
