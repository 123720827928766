<div class="page">
    <div class="mainContent">
        <div class="breadcrumbs">
            <div class="navInfoFocusButton">
                My Calendar
            </div>

        </div>

        <alert type="{{alert.type}}" *ngIf="alert.showFor === 'calendar'">{{alert.message}}</alert>

        <div class="calendar-wrapper contentBox">
            <calendar-view [eventsList]="eventsList" [(activeEventId)]="state.activeEventId"></calendar-view>
        </div>

    </div>
    <div class="rightPanel">
        <button *ngIf="!state.createEvent" (click)="toggleCreateEvent()" class="createEvent">
            Create Event
        </button>
        <div *ngIf="!!state.createEvent" class="contentBox">
            <create-event [calendarFunctions]="{
                handleCreateEvent : handleCreateEvent.bind(this),
                closeCreateEvent: toggleCreateEvent.bind(this)
            }"></create-event>
        </div>

        <div class="contentBox" *ngIf="!!state.activeEventId">
            <view-event [userId]="userId" [(activeEventId)]="state.activeEventId" [calendarFunctions]="{
                handleDeleteEvent: handleDeleteEvent.bind(this),
                handleEditEvent: handleEditEvent.bind(this)
            }"></view-event>
        </div>
    </div>
</div>
