<div class="modal-content orgClaimModal">
    <!--    TODO: validate input-->
    <form id="createOrg" name="createOrg" role="form" class="form-horizontal" (ngSubmit)="submitOrgCreation()">
        <div class="modal-header">
            <h4 id="myModalLabel" class="modal-title">Create an Organization</h4>
            <button type="button" (click)="bsModalRef.hide()" class="close"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </div>
        <div class="modal-body">
            <div role="alert" [hidden]="!formError" class="alert alert-danger">{{ formError }}</div>
            <div role="alert" [hidden]="!formInfo" class="alert alert-info">{{ formInfo }}</div>

            <div class="form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <label for="orgName" class="control-label">Organization Name</label>
                    <input id="orgName" name="orgName" required placeholder="Organization Name" class="form-control" [(ngModel)]="newOrg.name" #name="ngModel" minlength="3">
                </div>
            </div>

            <div class="form-group">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <label for="address" class="control-label">Address</label>
                    <input id="address" name="address" required placeholder="Address" class="form-control" [(ngModel)]="newOrg.address">
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <label for="city" class="control-label">City</label>
                    <input id="city" name="city" required placeholder="City" class="form-control" [(ngModel)]="newOrg.city">
                </div>
            </div>

            <div class="form-group">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <label for="state" class="control-label">State</label>
                    <select id="state" name="state" required class="form-control" [(ngModel)]="newOrg.state">
                        <option *ngFor="let state of allStates" [value]="state.abbreviation">{{state.name}}</option>
                    </select>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <label for="zip" class="control-label">Zip Code</label>
                    <input id="zip" name="zip" required placeholder="Zip Code" class="form-control" [(ngModel)]="newOrg.zip">
                </div>
            </div>

            <div class="form-group">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <label for="site" class="control-label">Website</label>
                    <input id="site" name="site" placeholder="Website URL" class="form-control" [(ngModel)]="newOrg.website">
                </div>
            </div>
        </div>
        <div class="modal-footer" [hidden]="formInfo">
            <button (click)="bsModalRef.hide()" type="button" class="btn btndefault">
                Cancel</button>
            <button type="submit" class="btn btn-primary">Save</button>
        </div>
    </form>
</div>