import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, EventEmitter, Output, OnChanges, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {faCalendarAlt} from '@fortawesome/free-regular-svg-icons';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {AuthService} from '../auth/auth.service';
import {AnalyticQuery, AnalyticsService} from '../../services/analytics/analytics.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {DownloadDataModalComponent} from './downloadDataModal/downloadDataModal.component';
import moment from 'moment';
import {UserService, UserType} from '../auth/user.service';
import {isValidDate} from '../util';
import {isResearchRoleName} from '../interfaces/ResearchStudy';
import {Subscription} from 'rxjs';

@Component({
    selector: 'downloadData',
    templateUrl: './downloadData.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./downloadData.scss']
})


export class DownloadDataComponent implements OnInit, OnDestroy, OnChanges {
    @Input('queryDateRange') queryDateRange: [Date, Date];
    //the _id of the target user or facility we want to download the data for
    @Input('id') targetId: string = undefined;
    @Input('btnLabel') btnLabel: string = 'Download';
    @Input('modalHeader') modalHeader: string = 'Download Data';

    queryMinDate: Date = undefined;
    queryMaxDate: Date = undefined;

    routeParamSubscription: Subscription;

    currentUser: UserType = undefined;
    Router;
    showAlert = false;
    alertType = '';
    alertMessage = '';
    dataReady: boolean = false;

    //Date picker
    currentDate = new Date();
    bsConfig: Partial<BsDatepickerConfig>;

    icon = {
        calendar: faCalendarAlt
    };

    dateParams = {
        minAllowed: new Date('November 1, 2020 01:00:00'),
        maxAllowed: new Date(),
        minParam: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7),
        maxParam: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        ranges: [{
            value: [
                new Date(new Date().setDate(new Date().getDate() - 7)),
                new Date()],
            label: 'Last 7 days'
        },
        {
            value: [new Date(new Date().setDate(new Date().getDate() - 30)),
                new Date()],
            label: 'Last 30 days'
        },
        ]
    };
    selectedRange: Date[] = this.dateParams.ranges[0].value;

    queryRole: string = undefined;
    queryFacilityId: string = undefined;
    queryStudyId: string = undefined;
    queryUserId: string = undefined;
    forFacility: boolean = false;
    forStudy: boolean = false;

    static parameters = [Router, AuthService, AnalyticsService, ActivatedRoute, BsModalService, UserService];

    constructor(public router: Router, public authService: AuthService,
                public analyticService: AnalyticsService, public route: ActivatedRoute,
                public modalService: BsModalService, public userService: UserService) {
        this.Router = router;
        this.authService = authService;
        this.analyticService = analyticService;
        this.route = route;
        this.modalService = modalService;
        this.userService = userService;

        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserType;
        this.initDatePicker();

        this.routeParamSubscription = this.route.queryParamMap.subscribe(params => {
            this.queryStudyId = params.has('studyId') ? params.get('studyId') : undefined;
            this.queryFacilityId = params.has('facilityId') ? params.get('facilityId') : undefined;
            this.queryUserId = params.has('userId') ? params.get('userId') : undefined;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('queryDateRange')) {
            this.queryDateRange = changes.queryDateRange.currentValue;
        }
    }

    public ngOnInit(): void {
        //the split url will be ['', :roleView, :pageView]
        this.queryRole = this.router.url.split('/')[1];
        if (isResearchRoleName(this.queryRole)) {
            this.queryRole = 'researcher';
        }

        //if focused on a facility, make that the targetId
        if (this.route && this.route.snapshot.queryParams['facilityId']) {
            this.targetId = this.queryFacilityId;
            this.forFacility = true;
            //if also focused on a user, make the user the targetId
            if (this.route.snapshot.queryParams['userId']) {
                this.targetId = this.queryUserId;
                this.forFacility = false;
            }
        } else if (this.route && this.queryStudyId) {
            this.targetId = this.queryStudyId;
            this.forFacility = false;
            this.forStudy = true;
            //if also focused on a user, make the user the targetId
            if (this.queryUserId) {
                this.targetId = this.queryUserId;
                this.forStudy = false;
            }
        } else {
            //catch (for dashboard, when neither a user nor facility are focused)
            this.targetId = this.currentUser.anonymizedName;
            this.forFacility = false;
        }
    }

    /**
     * initDatePicker: initialize the values used to control the datePicker
     */
    public initDatePicker() {
        this.currentDate = new Date();
        //set date params without timestamp
        this.queryMinDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() - 7);
        this.queryMaxDate = new Date(this.currentDate);
        this.dateParams = {...this.dateParams,
            minAllowed: new Date('November 1, 2020 01:00:00'),
            maxAllowed: this.currentDate,
            minParam: this.queryMinDate,
            maxParam: this.queryMaxDate
        };
    }


    /**
     * datePickerUpdate: handles updates to the datePicker date range, and EMITs an event to the parent
     *                      (which, in turn, should send it back down to each of the child graphs on the page)
     * @param newDates: an array of the new [startTime, endTime] for the query
     */
    datePickerUpdate(newDates: [Date, Date]) {
        if (!isValidDate(newDates[0]) || !isValidDate(newDates[1])) throw new Error('Invalid Dates');

        //format the dates to be the absolute beginning and absolute end of a day
        //  ex: 2021-02-15T00:00:00.000Z  -  2021-03-20T23:59:59.999Z
        const newStart = moment(newDates[0]).startOf('day').toDate();
        const newEnd = moment(newDates[1]).endOf('day').toDate();

        if (newStart.getTime() !== this.queryMinDate.getTime() || newEnd.getTime() !== this.queryMaxDate.getTime()) {
            this.queryMinDate = newStart;
            this.queryMaxDate = newEnd;
            this.queryDateRange = [newStart, newEnd];
        }
    }

    showDownloadDataModal() {
        if (isResearchRoleName(this.queryRole)) {
            this.queryRole = 'researcher';
        }
        const initialState = {
            modalHeader: this.modalHeader,
            queryDateRange: this.queryDateRange,
            targetId: this.targetId,
            queryRole: this.queryRole
        };
        const modalRef = this.modalService.show(DownloadDataModalComponent, {initialState, class: 'modal-lg'});
        modalRef.content.submitQuery.subscribe(({startTime, endTime, activityType}) => {
            const query: AnalyticQuery = {
                startTime: startTime,
                endTime: endTime,
                analyticType: activityType,
                email: this.targetId,
                role: this.queryRole,
            };
            if (this.queryStudyId || isResearchRoleName(this.queryRole)) {
                query.study = this.queryStudyId;
            } else if (this.queryFacilityId) {
                query.facility = this.queryFacilityId;
            }

            //check if facility or User download
            if (this.queryStudyId || this.queryFacilityId) {
                this.analyticService.getAnalyticDownloadForFacilityOrStudy(query)
                    .toPromise()
                    .then((data) => {
                        const blob = new Blob([data], {type: 'text/csv'});
                        let downloadUrl = URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = downloadUrl;
                        a.download = `${query.analyticType}-analytics-${moment().format().toString()}.csv`;

                        // start download
                        a.click();
                        // after certain amount of time remove this object!
                        setTimeout(() => {
                            URL.revokeObjectURL(downloadUrl);
                        }, 500);
                        // saveAs(blob,fileName)
                    })
                    .catch(e => console.error(e));
            } else {
                this.analyticService.getAnalyticDownloadForUser(query).toPromise()
                    .then((data) => {
                        const blob = new Blob([data], {type: 'text/csv'});
                        let downloadUrl = URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = downloadUrl;
                        a.download = `User-${query.analyticType}-analytics-${moment().format().toString()}.csv`;

                        // start download
                        a.click();
                        // after certain amount of time remove this object!
                        setTimeout(() => {
                            URL.revokeObjectURL(downloadUrl);
                        }, 500);
                        // saveAs(blob,fileName)
                    })
                    .catch(e => console.error(e));
            }
        });
        // modalRef.content.log()
    }

    public ngOnDestroy(): void {
        if (this.routeParamSubscription) {
            this.routeParamSubscription.unsubscribe();
        }
    }
}


