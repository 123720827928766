import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserType } from "../../auth/user.service";
import { Facility } from "../../interfaces/Facility";
import { emailValidation } from "../../util";

@Component({
    selector: 'assign-caregiver-or-guardian',
    templateUrl: './assign-caregiver-or-guardian.html',
    styleUrls: ['./assign-caregiver-or-guardian.scss']
})
export class AssignCaregiverOrGuardianComponent implements OnInit {

    @Input() submitting: boolean = false;

    //The type of relationship being assigned
    @Input() addType: 'caregiver' | 'guardian' | '' = '';

    @Output() confirmAdd: EventEmitter<any> = new EventEmitter();

    @Input()
    alert: { show: boolean, type: string, message: string } = {
        show: false,
        type: '',
        message: '',
    }

    facility: Facility; //the facility in which this is all taking place
    user: UserType; //the user to whom a caregiver/guardian will be assigned
    assignee: string; //the email of the caregiver/guardian being assigned to the user

    static parameters = [BsModalRef];

    constructor(public bsModalRef: BsModalRef) {
        this.bsModalRef = bsModalRef;
    }

    ngOnInit(): void {}

    getEmailVerifiedCaregivers(caregivers: any[]) {
        return caregivers.filter(c => {
            return (c.invitationAccepted && !this.user.caregivers.includes(c.email))
        });
    }

    reset() {
        this.addType = '';
        this.assignee = '';
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.addType || !this.assignee) {
            this.alert = { show: true, type: 'warning', message: 'Caregiver/Guardian cannot be empty.' };
            return;
        }
        if (!emailValidation(this.assignee)) {
            this.alert = { show: true, type: 'warning', message: 'Please put a valid email.' };
            return;
        }
        else this.alert = { show: false, type: '', message: '' };

        this.confirmAdd.emit({ addType: this.addType, assignee: this.assignee })
    }
}
