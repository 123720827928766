<div class="userList">
    <div class="userList__top top">
        <div class="top__header">
            {{title}}
        </div>
        <div class="top__search">
            <fa-icon [icon]="faSearch" class="search__icon"></fa-icon>
            <input class="search__input" placeholder="Search Users" [(ngModel)]="search">
        </div>

        <div class="top__count-sort">
            <div class="count">
                {{(this.users | filterUsers:search).length}} Users
            </div>
            <div class="sort">
                <fa-icon [icon]="faSortAmountDown"></fa-icon>
                <span>Sort</span>
            </div>
        </div>
    </div>

    <div class="userList__list list">
        <div class="list__user user" *ngFor="let user of (this.users | filterUsers:search)" (click)="handleUserClick(user.anonymizedName)">
            <div class="user__pfp" *ngIf="!user.pfp">
                <fa-icon [icon]="faUserCircle"></fa-icon>
            </div>
            <span class="user__name">{{user.firstName}} {{user.lastName}}</span>
<!--            <span class="user__name" *ngIf="!this.anonymized">{{user.firstName}} {{user.lastName}}</span>-->
<!--            <span class="user__name" *ngIf="this.anonymized">{{user.anonymizedName || 'Unknown'}}</span>-->
            <span class="user__role">User</span>
            <span class="user__last-seen">{{user?.lastLoginTime}}</span>
        </div>

        <div class="list__user user notFound" *ngIf="(this.users | filterUsers:search).length < 1">
            Not Found
        </div>
    </div>
</div>
