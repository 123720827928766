<div class="dashboard">

    <mainheader class="dashboard__header"></mainheader>

    <div class="dashboard__leftNav">
        <left-nav></left-nav>
    </div>

    <div class="dashboard__mainContent">
        <programrPage *ngIf="state.pageView === 'programr'"></programrPage>
        <wikiPage *ngIf="state.pageView === 'wiki'"></wikiPage>
        <settingsPage *ngIf="state.pageView === 'settings'" [viewAs]="this.state.roleView"></settingsPage>
    </div>
</div>
