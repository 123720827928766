export interface Organization {
    _id: string,
    id?: string,
    name: string,
    address: string,
    contactPerson?: string,
    telephoneNumber?: string,
    city: string,
    state: string,
    country: string,
    zip: string,
    type?: string,
    website?: string,
    administrators: any[],
    facilities?: any[],
    ryans: any[],
    users?: any[],
    email: string,
    researchers?: any[]
}

export type OrgAdminData = {
    organizations: Organization[],
}

export interface OrganizationData {
    numOrganizations?: number;
    page?: number;
    pageCount?: number;
    organizations: {
        id: string,
        key: string,
        value: Organization,
    }[];
}

export type OrganizationRoleName = 'organizationadmin' | 'organizationresearcher';

//function to easily determine if an 'organization' is just an orgId or an actual Organization
export function instanceOfOrganization(object): object is Organization {
    return object.hasOwnProperty('facilities') && object.hasOwnProperty('address');
}
