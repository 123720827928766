import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { UserService, UserType } from '../../../components/auth/user.service';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { Organization } from '../../../components/interfaces/Organization';
import { Facility } from '../../../components/interfaces/Facility';
import { CalendarEvent } from '../../../components/interfaces/CalendarEvent';
import { CalendarEventService } from '../../../services/calendar-event/calendar-event.service';
import {FacilityService} from '../../../services/facility/facility.service';
import _ from 'lodash';

@Component({
    selector: 'facilityAdmin-dashboard',
    templateUrl: './facilityadminDashboard.html',
    styleUrls: ['../../../assets/sharedStyles/pages/dashboard.scss'],
})


export class FacilityAdminDashboardComponent implements OnInit, OnDestroy {
    @Output() queryDateRangeChange = new EventEmitter<[Date, Date]>();

    Router;
    currentUser;
    currentDate = new Date();

    state = {
        isFacAdmin: false,
        fac_id: undefined,
        ryan_id: undefined
    };

    pageLoaded: Promise<boolean>;
    usersLoadedPromise: Promise<boolean>;
    eventsLoadedPromise: Promise<boolean>;
    facilitiesLoadedPromise: Promise<boolean>;

    loaded: boolean = true; //determines if all necessary content is loaded ("all" defined by permissions)
    usersLoaded: boolean = false; //determines if all user content is loaded
    eventsLoaded: boolean = false; //determines if all Events have been loaded
    facilitiesLoaded: boolean = false; //determines if all Facilities have been loaded

    icon = {
        bell: faBell,
        userCircle: faUserCircle,
        search: faSearch
    };

    allUsers: UserType[] = [];
    numUsers: number;

    allOrgs: Organization[] = [];
    numOrgs: number;

    allFacilities: Facility[];
    numFacilities: number;

    dateParams = {
        minAllowed: new Date('November 1, 2020 01:00:00'),
        maxAllowed: this.currentDate,
        minParam: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7),
        maxParam: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        ranges: [{
            value: [
                new Date(new Date().setDate(new Date().getDate() - 7)),
                new Date()],
            label: 'Last 7 days'
        },
        {
            value: [new Date(new Date().setDate(new Date().getDate() - 30)),
                new Date()],
            label: 'Last 30 days'
        },
        ]
    };
    //Date picker
    queryMinDate: Date = undefined;
    queryMaxDate: Date = undefined;
    queryDateRange:[Date, Date] = [this.queryMinDate, this.queryMaxDate];

    myEvents: CalendarEvent[] = [];
    numEvents: number;
    errOccurred: boolean = false;

    static parameters = [Router, FacilityService, CalendarEventService];

    constructor(public router: Router, public facilityService: FacilityService, public eventService: CalendarEventService) {
        this.router = router;
        this.facilityService = facilityService;
        this.eventService = eventService;

        this.loaded = false;

        //get the current user from the token
        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserType;
    }

    ngOnInit(): void {
        this.isFacilityAdmin()
            .then((res: Facility[]) => {
                this.allFacilities = res;
                this.numFacilities = res.length;
                if (res.length > 0) this.state.isFacAdmin = true;
                return this.allFacilities;
            })
            .then(() => {
                this.facilitiesLoaded = true;
                this.facilitiesLoadedPromise = Promise.resolve(true);
            })
            .then(() => {
                this.loadUsers();
            })
            .catch((err) => {
                this.errOccurred = true;
                console.error(err);
                Promise.reject();
            });

        this.loaded = this.isLoaded();
    }

    ngOnDestroy(): void { }

    /**
     * isLoaded
     *      this method will determine whether or not all of the necessary parts of the page have been loaded
     */
    isLoaded(): boolean {//Promise<boolean>{
        const loadReqs = [this.facilitiesLoadedPromise, this.usersLoadedPromise];
        this.loaded = this.facilitiesLoaded && this.usersLoaded;
        if (this.facilitiesLoaded && this.usersLoaded) {
            this.pageLoaded = Promise.resolve(true);
        }
        return this.facilitiesLoaded && this.usersLoaded;
    }

    /**
     * ensures user is actually a facilityAdmin
     *      if yes -> returns Facility[]
     *      if no -> returns false
     */
    isFacilityAdmin(): Promise<Facility[] | boolean> {
        return new Promise((resolve, reject) => {
            this.facilityService.getFacilitiesForAdmin()
                .toPromise()
                .then((res: any) => {
                    if (res && res.facilities) {
                        resolve(res.facilities as Facility[]);//returns Facility[] if FacilityAdmin
                    } else {
                        return Promise.resolve(false);
                    }
                })
                .catch((err) => {
                    this.errOccurred = true;
                    console.error(err);
                    reject(false);
                });
        });
    }


    /**
     * loadUsers
     *      method to be used by FacilityAdmins to retrieve ALL users affiliated with their facility/facilities
     *          (all users from each facility)
     */
    loadUsers(): UserType[] {
        if (this.allFacilities) {
            let users = [] as UserType[];

            const allUsersPromise = this.allFacilities.map((fac) =>
                new Promise<UserType[]>((resolve, reject) => {
                    this.facilityService.getAllUserDetailsInFacility(fac._id)
                        .toPromise()
                        .then((facUsers: {users: UserType[]}) => {
                            resolve(facUsers.users);
                        })
                        .catch((err) => {
                            this.errOccurred = true;
                            console.error(err);
                            reject(err);
                        });
                }));

            Promise.all(allUsersPromise)
                .then((allUsers) => {
                    const flatUsers = _.flatten(allUsers);
                    this.allUsers = flatUsers;
                    this.numUsers = flatUsers.length;
                    this.usersLoaded = true;
                    return allUsers;
                })
                .catch((err) => {
                    console.error(err);
                });
        } else {
            console.error('Facilities not loaded');
            return [] as UserType[];
        }
    }

    getMyEvents() {
        this.eventService.query(this.currentUser.email)
            .then((res) => {
                this.myEvents = res as CalendarEvent[];
            })
            .then(() => {
                this.eventsLoaded = true;
                this.isLoaded();
            })
            .catch((err) => console.error(err));
    }

    queryDateUpdate(ev) {
        this.queryMinDate = ev[0];
        this.queryMaxDate = ev[1];
        this.queryDateRange = [this.queryMinDate, this.queryMaxDate];
        this.queryDateRangeChange.emit(this.queryDateRange);
    }

    navToAllFacilitiesPage() {
        this.router.navigate(['facilityadmin', 'manage']);
    }

    navToFacilityFocus(facilityId: string) {
        this.router.navigate(['facilityadmin', 'manage'],
            {queryParams: {facilityId: facilityId}});
    }
}
