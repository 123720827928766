<background></background>
<div class="loginPage">
    <div class="container-fluid">
        <div class="row formRow">
            <div class="formContainer">
            <div class="logoBox">
                <img class="login-overlay__logoDiv__img logo__img" src="/bcfcc9791c4b4bb0b3224c16fe6cd3ff.png" alt="DreamFace Technologies Logo">
            </div>

            <div class="title">
                <h4 class="login-overlay__textDiv__text text--white">
                    Log in to your Ryan Account
                </h4>
            </div>
            <form class="loginForm" name="form" (ngSubmit)="login(loginForm)" novalidate #loginForm="ngForm">

                <div class="form-group row inputContainer">
                    <div class="col-sm-12 col-md-10 offset-md-1">
                        <input id="email" name="email" autocomplete="email" class="form-control email-input" [(ngModel)]="user.email" placeholder="Email" #email="ngModel" required>
                    </div>
                </div>

                <div class="form-group row inputContainer">
                    <div class="col-sm-12 col-md-10 offset-md-1">
                        <input id="password" name="password" autocomplete="current-password" class="form-control pwd-input" placeholder="Password" type="password" [(ngModel)]="user.password" #password="ngModel" required>
                    </div>
                </div>

                <div class="alert-container" *ngIf="alert.show">
                    <alert type="{{alert.type}}">{{alert.message}}</alert>
                </div>

                <div class="buttonsArea">
                    <button type="submit" class="btn filledBtn" [disabled]="!loginForm.form.valid">
                        Login
                    </button>
                </div>
                <hr>
            </form>

            <div class="bottomInfo">
                <a class="forgot-pwd text--grey" routerLink="/account/forgotpassword">Forgot Password?</a>
            </div>

            <div class="bottomInfo">
                <p class="noAcct__text text--grey">
                    Don't have an account?
                    <a class="create-acct" routerLink="/account/create">Create an account</a>
                </p>
            </div>
        </div>
        </div>
    </div>
    <powered-by-dft class="logoBottom"></powered-by-dft>
</div>
