import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../components/auth/auth.service';
import {UserService, UserType} from '../../../components/auth/user.service';
import { CalendarEventService } from '../../../services/calendar-event/calendar-event.service';
import { CalendarOptions, DateSelectArg, EventClickArg, EventInput, EventApi, FullCalendarComponent } from '@fullcalendar/angular'; // useful for typechecking
import {ActivatedRoute, Router} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import _ from 'lodash';
import {CalendarEvent} from '../../../components/interfaces/CalendarEvent';
@Component({
    selector: 'caregiver-calendar',
    templateUrl: './caregiverCalendar.html',
    styleUrls: ['./caregiverCalendar.scss'],
})

export class CaregiverCalendarComponent implements OnInit {
    userId: string = 'me';
    userIdSubscription = new BehaviorSubject(this.userId);

    state = {
        createEvent: false,
        activeEventId: undefined,
    }

    alert = {
        showFor: '',
        type: '',
        message: '',
    };

    eventsList: CalendarEvent[] = [];
    usersInCare: UserType[] = [];

    currentUser: UserType;
    static parameters = [Router, ActivatedRoute, AuthService, CalendarEventService, UserService];
    constructor(public router: Router, public activatedRoute: ActivatedRoute,
                public authService: AuthService, public eventService: CalendarEventService,
                public userService: UserService) {
        this.eventService = eventService;
        this.authService = authService;
        this.router = router;
        this.activatedRoute = activatedRoute;

        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserType;

        this.activatedRoute.queryParamMap.subscribe(queryParams => {
            const newUserId = queryParams.get('userId');
            this.handleNewUserId(newUserId);
        });
    }

    handleNewUserId(newUserId: string) {
        /* FIXME: when the page is refreshed / linked to before ngOnInit can finish populating this.usersInCare,
            then the user's anonymizedId cannot be properly mapped to the correct User
         */
        this.userId = !!newUserId && !newUserId.includes('@') && newUserId !== 'me' && this.usersInCare.length > 0
            ? this.usersInCare.find(u => u.anonymizedName === newUserId).email
            : newUserId || 'me';
        return this.getEventsForUserId(this.userId);
    }

    changeUserFocusTo(newUserId?: string) {
        this.router.navigate([], {queryParams: {userId: newUserId}});
    }

    ngOnInit() {
        /* FIXME: when the page is refreshed / linked to before ngOnInit can finish populating this.usersInCare,
            then the user's anonymizedId cannot be properly mapped to the correct User
         */
        this.userService.getUsersForCaregiver()
            .toPromise()
            .then((users: {users: UserType[]}) => {
                this.usersInCare = users.users;
                return users.users;
            })
            .catch(e => console.error(e));
    }

    getEventsForUserId(userId: string): Promise<CalendarEvent[]> {
        return this.eventService.query(userId)
            .then((events: CalendarEvent[]) => {
                this.eventsList = events;
                this.showAlertTemporary('calendar', 'info', `Successfully retrieved ${events.length} events for ${userId}`, 5000);
                return events;
            })
            .catch((err) => {
                console.error(err);
                this.showAlertTemporary('calendar', 'warning', `Unable to get events for ${userId}`, 5000);
                return Promise.reject('Error retrieving Events');
            });
    }

    toggleCreateEvent() {
        this.state = { ...this.state, createEvent: !this.state.createEvent };
    }

    showAlertTemporary(showFor: string, type: string, message: string, milliseconds: number) {
        this.alert = {
            showFor,
            type,
            message,
        };
        window.setTimeout(() => {
            this.clearAlert();
        }, milliseconds);
    }

    clearAlert() {
        this.alert = {
            showFor: '',
            type: '',
            message: '',
        };
    }

    handleCreateEvent(eventToCreate) {
        return this.eventService.create(eventToCreate, this.userId)
            .then((eventCreated: CalendarEvent) => {
                this.eventsList = [...this.eventsList, eventCreated] as CalendarEvent[];
                setTimeout(() => {
                    this.state = { ...this.state, createEvent: false };
                }, 3000);
            })
            .catch((err) => {
                console.error(err);
                setTimeout(() => {
                    this.state = { ...this.state, createEvent: false };
                }, 3000);
            });
    }

    handleEditEvent(editedEvent) {
        this.eventsList = this.eventsList.map((e) => editedEvent._id === e._id ? editedEvent : e) as CalendarEvent[];
    }

    handleDeleteEvent(eventId) {
        this.eventsList = this.eventsList.filter(e => e._id !== eventId);
    }
}
