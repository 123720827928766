import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProfileService {
    public baseUrl = '/api/programr';
    static parameters = [HttpClient];
    constructor(public http: HttpClient) {
        this.http = http;
    }

    getMyProfile() {
        return this.http.get(`${this.baseUrl}/me`).toPromise();
    }

    upsertMyProfile(profile) {
        return this.http.put(`${this.baseUrl}/me`, profile).toPromise();
    }
}
