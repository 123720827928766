import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { faPen, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import {CalendarEventService, CalendarEventType} from '../../../services/calendar-event/calendar-event.service';
import moment from 'moment';
import {CalendarEvent} from '../../interfaces/CalendarEvent';

@Component({
    selector: 'view-event-modal',
    templateUrl: './viewEventModal.html',
    providers: [],
    styleUrls: ['./viewEventModal.scss'],
})

export class ViewEventModalComponent implements OnInit {
    @Input() userId: string = 'me';
    @Input() calendarFunctions: { handleEditEvent?, handleDeleteEvent?} = undefined;
    @Input() activeEventId = undefined;
    @Output() activeEventIdChange = new EventEmitter<string>();

    icon = {
        edit: faPen,
        delete: faWindowClose,
    }
    event: CalendarEvent = undefined;
    state = {
        editing: false,
        confirmDelete: false,
    }

    static parameters = [CalendarEventService];
    constructor(public eventService: CalendarEventService) {
        this.eventService = eventService;
    }
    ngOnInit() {
        this.eventService.getEvent(this.activeEventId, this.userId)
            .then((event: CalendarEvent) => {
                this.event = event;
                return Promise.resolve(event);
            }).catch((err) => {
                console.error(err);
            });
    }

    clearActiveEvent() {
        this.activeEventId = undefined;
        this.activeEventIdChange.emit(this.activeEventId);
    }

    getFormattedDateTime(dateTime: string) {
        const date = moment(dateTime);
        return `${date.format('L')} - ${date.format('LT')}`;
    }

    getFormattedTime(dateTime: string) {
        const date = moment(dateTime);
        return date.format('LT');
    }

    handleEdit(editedEvent) {
        this.event = editedEvent;
        this.toggleEdit(false);
        this.calendarFunctions.handleEditEvent(editedEvent);
    }

    handleDelete() {
        this.eventService.remove(this.event, this.userId)
            .then((event) => {
                this.clearActiveEvent();
                this.calendarFunctions.handleDeleteEvent(this.event._id);
            }).catch((err) => {
                console.error(err);
            });
    }

    toggleEdit(bool?) {
        this.state.editing = bool ? bool : !this.state.editing;
        this.state.confirmDelete = this.state.editing ? false : this.state.confirmDelete;
    }

    toggleConfirmDelete(bool?) {
        this.state.confirmDelete = bool ? bool : !this.state.confirmDelete;
        this.state.editing = this.state.confirmDelete ? false : this.state.confirmDelete;
    }
}
