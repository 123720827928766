import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService} from '../../components/auth/auth.service';
import {MainComponent} from '../main/main.component';
import {Subscription} from 'rxjs';

@Component({
    selector: 'orgadminPages',
    templateUrl: './orgadminPages.html',
    styleUrls: ['./orgadminPages.scss'],
})

export class OrganizationAdminPagesComponent extends MainComponent implements OnInit, OnDestroy {
    subscription: Subscription;

    static parameters = [Router, ActivatedRoute, AuthService];
    constructor(public router: Router, public route: ActivatedRoute, authService: AuthService) {
        super(router, route, authService);
    }

    ngOnInit() { }

    ngOnDestroy(): void { }
}
