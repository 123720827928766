<div class="modal-lg">
    <form [formGroup]="queryForm" (ngSubmit)="handleSubmit()">
        <div class="modal-header">
            <h4 class="modal-title pull-left">
                {{modalHeader}}
            </h4>
            <button type="button" class="close pull-right" (click)="bsModalRef.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="formArea">
                <div class="form-group">
                    <label for="activityType">Activity Type</label>
                    <br>
                    <select name="form-control activityType" id="activityType" [ngModel]="queryActivity" formControlName="activityType">
                        <option *ngFor="let activity of activityTypeOptions" [ngValue]="activity">{{activity}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <b>Start date:</b>
                    <br>
                    <p>{{queryDateRange[0] | date:'medium'}}</p>
                </div>
                <div class="form-group">
                    <b>End date:</b>
                    <br>
                    <p>{{queryDateRange[1] | date:'medium'}}</p>
                </div>
            </div>

            <bs-daterangepicker-inline [bsValue]="queryDateRange" [minDate]="minAllowedDate" [maxDate]="maxAllowedDate" [bsConfig]="{
                rangeInputFormat: 'MMM. Do, YYYY', dateInputFormat: 'MMM. Do, YYYY',
                selectWeekDateRange: true, selectFromOtherMonth: true, showPreviousMonth: false,
                displayMonths: 1, containerClass: 'theme-blue' }" (bsValueChange)="datePickerUpdate($event)"></bs-daterangepicker-inline>
        </div>
        <div class="modal-footer">
            <button type="reset" (click)="bsModalRef.hide()" class="btn btn-outline-secondary" aria-label="Close">
                Cancel
            </button>
            <button type="submit" class="btn btn-primary">Submit</button>
        </div>
        <div class="fullWidth" *ngIf="alert.show">
            <alert class="fullWidth" type="{{alert.type}}">
                {{alert.message}}
            </alert>
        </div>
    </form>
</div>
