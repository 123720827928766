import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../components/auth/auth.service';

interface User {
    email: string;
    password: string;
}

@Component({
    selector: 'analytics',
    templateUrl: './analytics.html',
    styleUrls: ['./analytics.scss']
})
export class AnalyticsComponent implements OnInit {
    Router;

    alert = {
        show: false,
        type: '',
        message: '',
    };


    static parameters = [Router, AuthService];

    constructor(router: Router) {
        this.Router = router;
    }

    ngOnInit() {
    }
}
