import { Component } from '@angular/core';
import { take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export interface anonymizedNameData {
    anonymizedName?: string,
}

@Component({
    selector: 'search-anonymous',
    templateUrl: './search-anonymous.html',
    styleUrls: ['./search-anonymous.scss'],
})

export class SearchAnonymous {
    searchTerm: string = '';
    result: string = '';
    invalidResult: string = '';
    static parameters = [HttpClient];
    constructor(public http: HttpClient) {
        this.http = http;
    }

    handleSubmit(event) {
        event.preventDefault();
        if (!this.searchTerm) return;
        this.http.get(`api/users/anonymizedName/${this.searchTerm}`).pipe(take(1)).subscribe({
            next: (data: anonymizedNameData) => {
                const { anonymizedName } = data;
                if (anonymizedName) {
                    this.result = anonymizedName;
                    this.invalidResult = '';
                } else {
                    this.invalidResult = 'user not found';
                    this.result = '';
                }
            },
            error: (err) => {
                console.error(err);
                this.invalidResult = 'error finding user';
            }
        });
    }

    clear(event) {
        event.stopPropagation();
        this.searchTerm = '';
        this.result = '';
        this.invalidResult = '';
    }
}
