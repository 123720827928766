import { Inventory } from './Inventory';
import {UserType} from '../auth/user.service';

export interface Facility {
    _id: string,
    id?: string,
    name: string,
    address: string,
    contactPerson?: string,
    telephoneNumber?: string,
    city: string,
    state: string,
    country: string,
    zip: string,
    type?: string,
    website?: string,
    ryans?: Inventory[],
    administrators: any[],
    users?: FacilityUserType[],
    caregivers: FacilityCaregiverType[],
    principleInvestigators?: any[],
    email?: string,
    facilityType?: 'Assisted Living' | 'Nursing Home' | 'Independent Living' | 'Memory Care Unit',
}

export interface FacilityUserType extends UserType {
    firstName?: string,
    lastName?: string,
    email?: string,
    invitationAccepted?: boolean,
    anonymizedName?: any,
    guardians?: any[],
    caregivers?: any[],
}

export interface FacilityCaregiverType {
    email: string,
    invitationAccepted: boolean,
}

export type FacAdminData = {
    facilities?: any,
}

export type FacilityRoleName = 'facilityadmin' | 'caregiver' | 'user' | 'principleinvestigator';

//function to easily determine if a 'facility' is just a facilityId or an actual Facility
export function instanceOfFacility(object): object is Facility {
    if (object.hasOwnProperty('facilityType')) return true;
    //differentiate from FacilityUserType
    return object.hasOwnProperty('caregivers') && !object.hasOwnProperty('anonymizedName');
}

export function mapFacilityRoleNameToProperty(role: FacilityRoleName) {
    return {
        facilityadmin: 'administrators',
        caregiver: 'caregivers',
        user: 'users',
        principleinvestigator: 'principleInvestigators'
    }[role];
}
