import { Component, Input } from '@angular/core';

@Component({
    selector: 'powered-by-dft',
    templateUrl: './powered-by-dft.html',
    styleUrls: ['./powered-by-dft.scss']
})
export class PoweredByDFT {
    @Input()
    pixelWidth: number;

    @Input()
    year: number = (new Date()).getFullYear();

    constructor() {

    }

}
