<div class="modal-content" tabindex="-1" orgClaimModal role="dialog">
    <!--    TODO: validate input-->
    <form id="editOrgFac" name="editOrgFac" role="form" class="form-horizontal" #form="ngForm" (ngSubmit)="handleEdit(form)">
        <div class="modal-header">
            <h4 id="myModalLabel" class="modal-title">Edit {{editType}}</h4>
            <button type="button" (click)="bsModalRef.hide()" class="close"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </div>
        <div class="modal-body">
            <div role="alert" [hidden]="!formError" class="alert alert-danger">{{ formError }}</div>
            <div role="alert" [hidden]="!formInfo" class="alert alert-info">{{ formInfo }}</div>

            <input type="hidden" id="id" name="_id" [ngModel]="toEdit._id">

            <div class="d-flex">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <label for="name" class="control-label">{{editType}} Name</label>
                    <input id="name" name="name" required placeholder="{{editType}} Name" class="form-control" [ngModel]="toEdit.name" #name="ngModel" minlength="3">
                </div>
            </div>

            <div class="d-flex">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <label for="address" class="control-label">Address</label>
                    <input id="address" name="address" required placeholder="Address" class="form-control" [ngModel]="toEdit.address">
                </div>
            </div>

            <div class="d-flex">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <label for="city" class="control-label">City</label>
                    <input id="city" name="city" required placeholder="City" class="form-control" [ngModel]="toEdit.city">
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <label for="state" class="control-label">State</label>
                    <select id="state" name="state" required class="form-control" [ngModel]="toEdit.state">
                        <option *ngFor="let state of allStates" [value]="state.abbreviation">{{state.name}}</option>
                    </select>
                </div>
            </div>
            <div class="d-flex">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <label for="zip" class="control-label">Zip Code</label>
                    <input id="zip" name="zip" required placeholder="Zip Code" class="form-control" [ngModel]="toEdit.zip">
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="editType === 'facility'">
                    <label for="facilityType" class="control-label">Type</label>
                    <select id="facilityType" name="facilityType" required class="form-control" [ngModel]="toEdit.facilityType">
                        <option *ngFor="let facType of facilityTypes" [value]="facType">{{facType}}</option>
                    </select>
                </div>
            </div>
            <div class="d-flex">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <label for="website" class="control-label">Website</label>
                    <input id="website" name="website" placeholder="Website URL" class="form-control" [ngModel]="toEdit.website">
                </div>
            </div>
            <div class="modal-footer" [hidden]="formInfo">
                <button (click)="bsModalRef.hide()" type="button" class="btn btn-default">
                    Cancel</button>
                <button type="submit" class="btn btn-primary">Confirm Edit</button>
            </div>
        </div>
    </form>
</div>
