import { Component } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.html',
    styleUrls: ['./forgot-password.scss']
})
export class ForgotPasswordComponent {
    email: String;
    alert = {
        show: false,
        type: '',
        message: '',
    }
    static parameters = [HttpClient, Router];
    constructor(public http: HttpClient, public router: Router) {
        this.http = http;
        this.router = router;
    }
    ngOnInit() { }

    handleSubmit(form) {
        if (!form.valid) {
            this.alert = {
                show: true,
                message: 'That is not a valid email.',
                type: 'danger',
            };
            this.email = '';
            return;
        }
        this.alert = {
            show: false,
            type: '',
            message: '',
        };
        this.http.post(`/api/users/forgot/${this.email}`, {}).subscribe({
            next: (data: { message: string }) => {
                let message = 'Email sent. Please check your email.';
                if (data && data.message) message = data.message;
                this.alert = {
                    show: true,
                    message: String(message),
                    type: 'info',
                };
            },
            error: (error: HttpErrorResponse) => {
                let message = 'Error';
                if (error.statusText) message = error.statusText;
                if (error.error && error.error.message) message = error.error.message;
                if (error && error.status) {
                    this.alert = {
                        show: true,
                        message: String(message),
                        type: 'danger',
                    };
                }
            },
        });
    }
}
