<div class="mainContainer">
    <header class="mainContainer__header">
        <mainheader></mainheader>
    </header>
    <div class="mainContainer__leftNav">
        <left-nav></left-nav>
    </div>

    <div class="mainContainer__main">
        <orgadmin-dashboard *ngIf="state.pageView === 'dashboard'" [state]="state"></orgadmin-dashboard>
        <orgadmin-ryanData *ngIf="state.pageView === 'ryandata'"></orgadmin-ryanData>
        <orgAdmin-manage *ngIf="state.pageView === 'manage'"></orgAdmin-manage>
        <programrPage *ngIf="state.pageView === 'programr'"></programrPage>
        <wikiPage *ngIf="state.pageView === 'wiki'"></wikiPage>
        <settingsPage *ngIf="state.pageView === 'settings'" [viewAs]="this.state.roleView"></settingsPage>
    </div>
</div>

