<div class="page">
    <!--  ======  Loading view  ======  -->
    <div *ngIf="!isLoaded() && !errOccurred">
        Loading...
    </div>

    <!--  ======  Error view  ======  -->
    <alert type="danger" *ngIf="errOccurred">
        <strong>Whoops!</strong> We're sorry, an error occurred trying to load this page.
    </alert>

    <!--  ======  Caregiver view  ======  -->
    <div class="mainContent dash" *ngIf="currentUser.hasOwnProperty('caregiverOf')
    && (currentUser.caregiverOf.length > 0)
    && isLoaded()">
<!--        <article class="mainContent__tile">-->
<!--            <queryDateRangeControl [title]="'Control'"  [queryMinDate]="queryMinDate" [queryMaxDate]="queryMaxDate" (queryDatesUpdate)="queryDateUpdate($event)">-->
<!--            </queryDateRangeControl>-->
<!--        </article>-->

<!--        <stacked-bar-chart [graphTitle]="'User Activities'"-->
<!--                           dataName="ActivityMetricsMany"-->
<!--                           [queryDateRange]="queryDateRange"-->
<!--                           [roleView]="'caregiver'">-->
<!--        </stacked-bar-chart>-->

        <div class="events">
            <div class="mainContent__tile events__table">
                <div class="header">
                    <div class="text-area">
                        <h4 class="header__title graphTitle">Events</h4>
                    </div>
                </div>
                <div class="table-container">
                    <table class="table">
                        <thead class="table__header">
                        <tr>
                            <th>Event Name</th>
                            <th>Date</th>
                            <th>Time</th>
                        </tr>
                        </thead>
                        <tbody class="table__body" *ngIf="myEvents.length > 0">
                        <tr class="table__data" *ngFor="let ev of myEvents; let i = index;">
                            <td class="id eventName" *ngIf="i <=4"><span class="colorCode"></span>{{ev.title}}</td>
                            <td class="status eventDate" *ngIf="i <=4">{{ev.begin | date:'shortDate'}}</td>
                            <td class="community eventTime" *ngIf="i <=4">{{ev.begin | date:'shortTime'}}</td>
                        </tr>
                        </tbody>

                        <tbody class="table__body" *ngIf="myEvents.length === 0">
                        <tr class="table__data">
                            <td class="id eventName noEvents" colspan="3">No Upcoming Events</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="moreBtn">
                    <span (click)="navToCalendar()">More</span>
                </div>
            </div>

            <div class="mainContent__tile events__calendar">
                <bs-datepicker-inline [bsValue]="currentDate" [isDisabled]="true" [bsConfig]="{showWeekNumbers: false}" readonly="readonly"></bs-datepicker-inline>
                <button type="button" class="btn export" (click)="navToCalendar()">
                    Create New Event
                </button>
            </div>

        </div>

<!--        TODO: finish styling vertical-bar-chart / age data graph -->
<!--        <article class="mainContent__tile">-->
<!--            <vertical-bar-chart [graphTitle]="'Age ('+ currentUser?.caregiverOf?.length +')'"-->
<!--                                [data]="userAgeData">-->
<!--            </vertical-bar-chart>-->
<!--        </article>-->

<!--        FIXME: The conversation graph is only here temporarily until it is ready to be moved to
                    focused user page       -->
<!--        <article class="mainContent__tile">-->
<!--            <conversation-graph [graphTitle]="'Conversation'"></conversation-graph>-->
<!--        </article>-->
    </div>

    <div class="rightPanel">
        <div *ngIf="!isLoaded()">Loading Users...</div>

        <!--        Caregiver view -->
        <userlistpanel *ngIf="isLoaded()" [users]="allUsers" [title]="'Residents'" [renderedBy]="'caregiver/dashboard'" [clickCb]="navToFocusedUserActivitiesPage.bind(this)">
        </userlistpanel>

    </div>
</div>
