<div class="mainContainer">
    <header class="mainContainer__header">
        <mainheader></mainheader>
    </header>
    <div class="mainContainer__leftNav">
        <left-nav></left-nav>
    </div>

    <div class="mainContainer__main">
        <principleInvestigator-dashboard *ngIf="state.pageView === 'dashboard'"></principleInvestigator-dashboard>
        <principleInvestigator-manage *ngIf="state.pageView === 'manage'"></principleInvestigator-manage>
        <principleInvestigator-usersActivities *ngIf="state.pageView === 'usersactivities'"></principleInvestigator-usersActivities>
        <principleInvestigator-gameMetrics *ngIf="state.pageView === 'gamemetrics'"></principleInvestigator-gameMetrics>
        <wikiPage *ngIf="state.pageView === 'wiki'"></wikiPage>
        <settingsPage *ngIf="state.pageView === 'settings'" [viewAs]="this.state.roleView"></settingsPage>
    </div>
</div>
