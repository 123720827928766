import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../components/auth/auth.service';

interface User {
    email: string;
    password: string;
}

@Component({
    selector: 'login',
    templateUrl: './login.html',
    styleUrls: ['./login.scss']
})
export class LoginComponent implements OnInit {
    user: User = {
        email: '',
        password: '',
    };
    submitted = false;
    AuthService;
    Router;
    return = '/user/dashboard';
    signupQueryParams = '';

    alert = {
        show: false,
        type: '',
        message: '',
    };


    static parameters = [Router, AuthService];

    constructor(router: Router, authService: AuthService) {
        this.Router = router;
        this.AuthService = authService;
    }

    ngOnInit() {
        // Get the query params for redirect url
        if (this.Router.currentUrlTree.queryParams.return) {
            // this.signupQueryParams = '?return=' + this.Router.currentUrlTree.queryParams.return;
            this.return = this.Router.currentUrlTree.queryParams.return;
        }
        if (this.Router.currentUrlTree.queryParams.alert === 'auth-required') {
            this.alert = {
                show: true,
                message: 'You must be logged in to visit this page.',
                type: 'info'
            };
        }
        if (this.Router.currentUrlTree.queryParams.alert === 'logout') {
            this.alert = {
                show: true,
                message: 'You have been logged out.',
                type: 'info'
            };
        }
        if (this.Router.currentUrlTree.queryParams.alert === 'verifySuccess') {
            this.alert = {
                show: true,
                message: 'You have successfully verified. Please login.',
                type: 'info'
            };
        }
        if (this.Router.currentUrlTree.queryParams.alert === 'passwordResetSuccess') {
            this.alert = {
                show: true,
                message: 'Password reset successfully. Please login.',
                type: 'info'
            };
        }
        window.scrollTo(0, 0);
        this.AuthService.currentUserChanged.subscribe((user) => {
            if (user) this.Router.navigateByUrl('/user/dashboard');
        });
    }

    login(form) {
        this.clearAlert();
        if (form.invalid) {
            this.alert = {
                show: false,
                type: 'warning',
                message: 'Please complete all fields.',
            };
        }

        return this.AuthService.login({
            email: this.user.email.toLowerCase(),
            password: this.user.password
        })
            .then(data => {
                // Logged in, redirect to query param "return" or /home
                this.Router.navigateByUrl(this.return);
            })
            .catch(({ error }) => {
                form.reset();
                console.error(error);
                this.alert = {
                    show: true,
                    type: 'danger',
                    message: error.message || 'An error occurred.',
                };
            });
    }

    clearAlert() {
        this.alert = {
            show: false,
            type: '',
            message: '',
        };
    }
}
