import {Component, Input} from '@angular/core';

@Component({
    selector: 'refresh-icon',
    templateUrl: './refresh-icon.svg',
    styleUrls: ['./refresh-icon.scss']
})
export class RefreshIconComponent {

    @Input() ellipse:boolean;
    fillColor = '#E06037';
}
