import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {ResearchStudy} from '../../interfaces/ResearchStudy';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import 'quill-emoji';
import moment from 'moment';
import {UserType} from '../../auth/user.service';
import {isValidDateRange} from '../../util';
import {Facility} from '../../interfaces/Facility';
import {formatDate} from "@angular/common";

@Component({
    selector: 'create-study',
    templateUrl: './edit-study.html',
    styleUrls: ['./edit-study.scss']
})
export class EditStudyComponent implements OnInit {
    @Input() formError: String;
    @Input() formInfo: String;
    @Input()
    toEdit: any;
    @Output() confirmEdit: EventEmitter<any> = new EventEmitter<any>();

    allowedFacilities: Facility[] = [];

    public editedStudy: ResearchStudy;

    currentUser: UserType;
    styles
    summaryHtmlText;

    studyForm: FormGroup;
    submitted: boolean = false;

    quillConfig = {
        // toolbar: '.toolbar',
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                ['code-block'],
                [{ header: 1 }, { header: 2 }], // custom button values
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                [{ direction: 'rtl' }], // text direction
                [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ font: [] }],
                [{ align: [] }],
                ['clean'], // remove formatting button
                ['link']
                //['link', 'image', 'video']
            ]
        },
        'emoji-toolbar': true,
        'emoji-textarea': false,
        'emoji-shortname': true,
        keyboard: {
            bindings: {
                shiftEnter: {
                    key: 13,
                    shiftKey: true,
                    handler: (range, context) => {
                        // Handle shift+enter
                        console.log('shift+enter');
                    }
                },
                enter: {
                    key: 13,
                    handler: (range, context) => {
                        console.log('enter');
                        return true;
                    }
                }
            }
        }
    };

    static parameters = [BsModalRef, FormBuilder];

    constructor(public bsModalRef: BsModalRef, public formBuilder: FormBuilder) {
        this.formBuilder = formBuilder;
        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserType;
    }

    ngOnInit(): void {
        this.studyForm = this.formBuilder.group({
            title: [this.toEdit.title || undefined, [
                Validators.required,
                Validators.minLength(1)
            ]],
            irbNumber: [this.toEdit.irbNumber || undefined, []],
            description: [this.toEdit.description || undefined, []],
            startDate: [formatDate(this.toEdit.startDate, 'yyyy-MM-dd', 'en') || undefined, [
                Validators.required
            ]],
            endDate: [formatDate(this.toEdit.endDate, 'yyyy-MM-dd', 'en') || undefined, [

                Validators.required
            ]],
        });
        console.log(this.toEdit);
        // console.log(this.studyForm);
    }

    editStudy() {
        this.submitted = true;
        if (!this.studyForm.valid) {
            this.formError = 'Failed to Submit: Invalid Form';
            return;
        }
        this.editedStudy = {
            ...this.toEdit,
            ...this.studyForm.value,
            description: this.studyForm.value.description ? this.studyForm.value.description : 'None',
            irbNumber: this.studyForm.value.irbNumber ? this.studyForm.value.irbNumber : undefined,
            startDate: moment(this.studyForm.value.startDate, 'YYYY-MM-DD').toDate() as Date,
            endDate: moment(this.studyForm.value.endDate, 'YYYY-MM-DD').toDate() as Date
        };
        if (!isValidDateRange(this.editedStudy.startDate, this.editedStudy.endDate)) {
            console.error('Invalid Date Range!');
            this.formError = 'Invalid Date Range!';
            return;
        }
        console.log(this.editedStudy);
        this.confirmEdit.emit(this.editedStudy);
    }
}

