import { Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { CalendarEventService } from '../../../services/calendar-event/calendar-event.service';
import { faPen, faWindowClose } from '@fortawesome/free-solid-svg-icons';

interface Event {
    _id: string;
    title: string;
    description: string;
    begin: string;
    end: string;
    eventType: string;
    recurring: object;
}

@Component({
    selector: 'edit-event',
    templateUrl: './editEvent.html',
    providers: [],
    styleUrls: ['./editEvent.scss'],
})

export class EditEventComponent implements OnInit, OnDestroy {
    @Input() userId: string = 'me';

    @Input() viewEventMethods: { eventToEdit?, handleEdit?, cancelEdit?, closeEditEvent?} = undefined;

    toIsoString(date) {
        let tzo = -date.getTimezoneOffset();
        let dif = tzo >= 0 ? '+' : '-';
        let pad = function(num) {
            let norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };

        // eslint-disable-next-line max-len
        return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
    }

    icon = {
        edit: faPen,
        delete: faWindowClose,
    }
    event: Event = {
        _id: '',
        title: '',
        description: '',
        // eslint-disable-next-line no-invalid-this
        begin: this.toIsoString(new Date()),
        // eslint-disable-next-line no-invalid-this
        end: this.toIsoString(new Date()),
        eventType: 'Activity',
        recurring: undefined,
    };

    alert = {
        showFor: '',
        type: '',
        message: ''
    }

    // selected recurrence configurations
    recurrenceTypeSelected: string = 'Weekly';
    selectedDays = [];
    // eslint-disable-next-line no-invalid-this
    recurrenceStart: string = this.toIsoString(new Date());
    duration: number = 30;
    // eslint-disable-next-line no-invalid-this
    recurrenceEnd: string = this.toIsoString(new Date());


    // allowed types
    daysInWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
    recurrenceTypesAllowed = ['Daily', 'Weekly']
    eventTypesAllowed = ['Medication', 'Appointment', 'Activity', 'Reminder'];
    // form configuration
    recurringMode: boolean = false;

    EventService;

    static parameters = [CalendarEventService];
    constructor(eventService: CalendarEventService) {
        this.EventService = eventService;
    }

    handleSubmit({ value, valid }) {
        if (!valid) {
            console.error('invalid form');
            return;
        }

        if (new Date(value.begin) > new Date(value.end) || new Date(value.recurrenceStart) > new Date(value.recurrenceEnd)) {
            this.showAlertTemporary('editEvent', 'warning', 'Invalid dates', 3000);
            return;
        }
        let eventToCreate;
        if (this.recurringMode) {
            eventToCreate = {
                title: value.title,
                caption: value.description,
                eventType: value.eventType,
                begin: new Date(value.recurrenceStart).toISOString(),
                end: new Date(value.recurrenceEnd).toISOString(),
                recurring: {
                    rule: value.recurrence,
                    dow: value.recurrence === 'Daily' ? this.daysInWeek : this.selectedDays,
                }
                // duration ?
            };
        } else {
            eventToCreate = {
                title: value.title,
                caption: value.description,
                eventType: value.eventType,
                begin: new Date(value.begin).toISOString(),
                end: new Date(value.end).toISOString()
            };
        }
        eventToCreate._id = this.event._id;
        this.EventService.upsert(eventToCreate, this.userId)
            .then((editedEvent) => {
                this.handleIncomingEvent(editedEvent);
                this.viewEventMethods.handleEdit(editedEvent);
            })
            .catch((err) => {
                this.showAlertTemporary('editEvent', 'warning', 'unable to edit event', 3000);
            });
    }

    updateSelectedDays(event) {
        if (this.daysInWeek.indexOf(event.target.name) !== -1) {
            if (event.target.checked) {
                this.selectedDays.push(event.target.name);
            } else {
                this.selectedDays.splice(this.selectedDays.indexOf(event.target.name), 1);
            }
        }
    }

    showAlertTemporary(showFor: string, type: string, message: string, milliseconds: number) {
        this.alert = {
            showFor,
            type,
            message,
        };
        window.setTimeout(() => {
            this.clearAlert();
        }, milliseconds);
    }

    clearAlert() {
        this.alert = {
            showFor: '',
            type: '',
            message: '',
        };
    }

    public ngOnInit(): void {
        this.handleIncomingEvent();
    }
    public ngOnDestroy(): void { }

    handleIncomingEvent(eventObj?) {
        const editEvent = eventObj ? eventObj : this.viewEventMethods.eventToEdit;

        this.event = {
            _id: editEvent._id || '',
            title: editEvent.title || '',
            description: editEvent.caption || '',
            begin: this.toIsoString(new Date(editEvent.begin)),
            end: this.toIsoString(new Date(editEvent.end)),
            eventType: editEvent.eventType,
            recurring: undefined,
        };

        if (editEvent.recurring) {
            this.recurringMode = !!editEvent.recurring;
            this.recurrenceTypeSelected = editEvent.recurring.rule;
            this.selectedDays = editEvent.recurring.dow;
            this.recurrenceStart = this.toIsoString(new Date(editEvent.begin));
            this.duration = editEvent.duration;
            this.recurrenceEnd = this.toIsoString(new Date(editEvent.end));
        }
    }
}

