<div class="" xmlns="http://www.w3.org/1999/html">
    <ng-container>
        <div class="rowMain">
            <h2>
                Wiki
            </h2>
        </div>
    </ng-container>
    <br>
    <ng-container>
        <div class="rowMain col-lg-2">
            <div class="tableOfContents">
                <h3>Table of Contents</h3>
                <ol>
                    <li>Organizational Structure</li>
                    <li>Creating Organizations</li>
                    <li>Adding Organization admins</li>
                    <li>Creating Facilities</li>
                    <li>Adding Facility Admins</li>
                    <li>Adding Users to a facility</li>
                    <li>Studies</li>
                    <li>Adding Researchers to organization</li>
                    <li>Adding eligible PIs to a study </li>
                </ol>
            </div>
        </div>
        <div class="rowMain col-lg-9">
            <div class="tableOfContents">
                <h3>Table of Contents</h3>
                <ol>
                    <li>Organizational Structure</li>
                    <li>Creating Organizations</li>
                    <li>Adding Organization admins</li>
                    <li>Creating Facilities</li>
                    <li>Adding Facility Admins</li>
                    <li>Adding Users to a facility</li>
                    <li>Studies</li>
                    <li>Adding Researchers to organization</li>
                    <li>Adding eligible PIs to a study </li>
                </ol>
            </div>
        </div>
    </ng-container>
    <ng-container>
        <div class="rowMain col-lg-2">
            <div class="tableOfContents">
                <h3>Table of Contents</h3>
                <ol>
                    <li>Organizational Structure</li>
                    <li>Creating Organizations</li>
                    <li>Adding Organization admins</li>
                    <li>Creating Facilities</li>
                    <li>Adding Facility Admins</li>
                    <li>Adding Users to a facility</li>
                    <li>Studies</li>
                    <li>Adding Researchers to organization</li>
                    <li>Adding eligible PIs to a study </li>
                </ol>
            </div>
        </div>
    </ng-container>
    <ng-container>
        <div class="rowMain col-lg-2">
            <div class="tableOfContents">
                <h3>Table of Contents</h3>
                <ol>
                    <li>Organizational Structure</li>
                    <li>Creating Organizations</li>
                    <li>Adding Organization admins</li>
                    <li>Creating Facilities</li>
                    <li>Adding Facility Admins</li>
                    <li>Adding Users to a facility</li>
                    <li>Studies</li>
                    <li>Adding Researchers to organization</li>
                    <li>Adding eligible PIs to a study </li>
                </ol>
            </div>
        </div>
    </ng-container>
    <ng-container>
        <div class="rowMain col-lg-2">
            <div class="tableOfContents">
                <h3>Table of Contents</h3>
                <ol>
                    <li>Organizational Structure</li>
                    <li>Creating Organizations</li>
                    <li>Adding Organization admins</li>
                    <li>Creating Facilities</li>
                    <li>Adding Facility Admins</li>
                    <li>Adding Users to a facility</li>
                    <li>Studies</li>
                    <li>Adding Researchers to organization</li>
                    <li>Adding eligible PIs to a study </li>
                </ol>
            </div>
        </div>
    </ng-container>
    <ng-container>
        <div class="rowMain col-lg-2">
            <div class="tableOfContents">
                <h3>Table of Contents</h3>
                <ol>
                    <li>Organizational Structure</li>
                    <li>Creating Organizations</li>
                    <li>Adding Organization admins</li>
                    <li>Creating Facilities</li>
                    <li>Adding Facility Admins</li>
                    <li>Adding Users to a facility</li>
                    <li>Studies</li>
                    <li>Adding Researchers to organization</li>
                    <li>Adding eligible PIs to a study </li>
                </ol>
            </div>
        </div>
    </ng-container>

</div>
