import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {forkJoin} from 'rxjs';
import {tap} from 'rxjs/operators';
import {UserService} from '../../components/auth/user.service';
import {FacAdminData, Facility, FacilityCaregiverType, FacilityRoleName} from '../../components/interfaces/Facility';
import {Organization, OrganizationData} from '../../components/interfaces/Organization';
import {Inventory} from '../../components/interfaces/Inventory';
import {CustomEncoder, encodeEmail} from '../encoder/customEncoder';

@Injectable({
    providedIn: 'root'
})
export class FacilityService {
    public baseUrl = '/api/facility';
    static parameters = [HttpClient, UserService];
    constructor(public http: HttpClient, public userService: UserService) { }

    getFacilityById(id: string) {
        return this.http.get(`${this.baseUrl}/${id}`);
    }

    getPublicInfoFacility(facilityId: string) {
        return this.http.get(`${this.baseUrl}/name/${facilityId}`);
    }

    /**
     * getMyFacilitiesForRole: retrieves all Facilities in which the user has the specified role
     * @param { FacilityRoleName } role
     */
    getMyFacilitiesForRole(role: FacilityRoleName) {
        var parsedRole: string = role;
        if (role === 'principleinvestigator') parsedRole = 'principleInvestigator';
        if (role === 'facilityadmin') parsedRole = 'admin';
        return this.http.get(`${this.baseUrl}/me/${parsedRole}`);
    }
    /**
     * getFacilitiesForAdmin: get Facilities I administer
     * @deprecated use getMyFacilitiesForRole('admin') instead
     */
    getFacilitiesForAdmin() {
        return this.http.get(`${this.baseUrl}/me/admin`);
    }

    /**
     *  getMyFacilities: get facilities I belong to
     *  @deprecated use getMyFacilitiesForRole('user') instead
     */
    getMyFacilities() {
        return this.http.get(`${this.baseUrl}/me/facility`);
    }

    /**
     * getFacilitiesForCaregiver: get Facilities I'm a caregiver in
     * @deprecated use getMyFacilitiesForRole('caregiver') instead
     */
    getFacilitiesForCaregiver() {
        return this.http.get(`${this.baseUrl}/me/caregiver`);
    }

    /**
     * getFacilitiesForPrincipleInvestigator: get Facilities I'm a principle investigator in
     * @deprecated use getMyFacilitiesForRole('principleinvestigator') instead
     */
    getFacilitiesForPrincipleInvestigator() {
        return this.http.get(`${this.baseUrl}/me/principleInvestigator`).toPromise();
    }

    /**
     * getAllUserDetailsInFacility: get details about all users in a Facility
     * @param {String} facilityId
     */
    getAllUserDetailsInFacility(facilityId: string) {
        return this.http.get(`${this.baseUrl}/${facilityId}/users`);
    }

    /**
     * getUserDetailsInFacility: get details about a single user in a facility
     * @param {String} facilityId
     * @param {String} userId: the id (email or anonymizedName) of the target user
     */
    getUserDetailsInFacility(facilityId: string, userId: string) {
        var encodedUserId: string = userId;
        //Check if email address, if so, encode it
        if (userId.includes('@')) {
            const encoder = new CustomEncoder();
            encodedUserId = encoder.encodeKey(userId);
        }
        return this.http.get(`${this.baseUrl}/${facilityId}/${encodedUserId}`);
    }

    getUsersForCaregiver() {
        return this.http.get(`${this.baseUrl}/caregiver/users`);
    }

    // ====  Update routes  ====

    updateFacility(facility) {
        return this.http.put(`${this.baseUrl}/${facility._id || facility.id}`, facility, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }).pipe(
            tap(_ => {
                console.log('updated facility');
                console.log(facility);
            })
        );
    }

    addFacilityAdmin(facility: Facility, adminEmail: string) {
        return this.getFacilityById(facility.id || facility._id).toPromise()
            .then((facilityData: Facility) => {
                const { administrators } = facilityData;
                if (!administrators) {
                    return Promise.reject('Unable to add administrator to facility.');
                }
                const newAdministratorSet = new Set(administrators);
                if (adminEmail) newAdministratorSet.add(adminEmail);
                return Promise.resolve(this.updateFacility({ _id: facility._id || facility.id, administrators: Array.from(newAdministratorSet) }).toPromise());
            })
            .catch((e) => Promise.reject(String(e)));
    }

    addPrincipleInvestigatorToFacility(principleInvestigatorEmail: string, facility: Facility) {
        let encoder = new CustomEncoder();
        const encodedEmail = encoder.encodeValue(principleInvestigatorEmail);
        return this.http.put(`${this.baseUrl}/${facility._id}/researcher/${encodedEmail}`, {
            params: new HttpParams({encoder: new CustomEncoder()})
                .set('principleInvestigatorEmail', principleInvestigatorEmail)
        }).toPromise();
    }

    addUserToFacility(userEmail: string, facilityId: string) {
        const encodedEmail = encodeEmail(userEmail);
        // console.log(encodedEmail)
        return this.http.put(`${this.baseUrl}/${facilityId}/user/${encodedEmail}`, {
            params: new HttpParams({encoder: new CustomEncoder()})
                .set('userEmail', userEmail)
        });
    }

    addCaregiverToFacility(caregiverEmail: string, facilityId: string) {
        let encoder = new CustomEncoder();
        const encodedEmail = encoder.encodeValue(caregiverEmail);
        // console.log(encodedEmail)
        return this.http.put(`${this.baseUrl}/${facilityId}/caregiver/${caregiverEmail}`, {});
    }

    removeFacilityAdmin(facilityId, adminEmail) {
        return this.getFacilityById(facilityId).toPromise()
            .then((facilityData: Facility) => {
                const { administrators } = facilityData;
                if (!administrators) {
                    return Promise.reject('Unable to delete administrator from facility.');
                }
                const newAdministrators = administrators.filter((e) => e !== adminEmail);
                return Promise.resolve(this.updateFacility({ _id: facilityId, administrators: newAdministrators }).toPromise());
            })
            .catch((e) => Promise.reject(String(e)));
    }

    removePrincipleInvestigatorFromFacility(facility: Facility, principleInvestigator) {
        if (principleInvestigator.hasOwnProperty('email')) principleInvestigator = principleInvestigator.email;
        return this.getFacilityById(facility._id).toPromise()
            .then((facilityData: Facility) => {
                const { principleInvestigators } = facilityData;
                if (!principleInvestigators) {
                    return Promise.reject('Unable to delete principle investigator from facility.');
                }
                const newPrincipleInvestigators = principleInvestigators.filter(pi => pi.email !== principleInvestigator);
                return Promise.resolve(this.updateFacility({ _id: facility._id, principleInvestigators: newPrincipleInvestigators }).toPromise());
            })
            .catch((e) => Promise.reject(String(e)));
    }

    removeUserFromFacility(userId: string, facility: Facility) {
        return this.getFacilityById(facility._id).toPromise()
            .then((facilityToUpdate: Facility) => {
                const userSet = new Set(facilityToUpdate.users.filter(u => u.email !== userId));
                return this.updateFacility({ _id: facilityToUpdate._id, users: Array.from(userSet) }).toPromise();
            });
    }

    removeCaregiverFromFacility(caregiver: FacilityCaregiverType, facility: Facility) {
        // can only be called by facilityAdmin
        return this.getMyFacilitiesForRole('facilityadmin').toPromise()
            .then((data: FacAdminData) => {
                const facilityToUpdate = data.facilities.find((fac) => fac._id === facility._id);
                const caregiverSet = new Set(facilityToUpdate.caregivers);
                caregiverSet.delete(caregiver);
                return this.updateFacility({ _id: facilityToUpdate._id, caregivers: Array.from(caregiverSet) }).toPromise();
            });
    }

    verifyUserOrCaregiver(facilityId: string, role: string, userId: string) {
        role = role.toLowerCase();
        if (!['caregiver', 'user'].includes(role)) {
            throw new Error('invalid role');
        }
        const encodedEmail = encodeEmail(userId);
        return this.http.put(`${this.baseUrl}/${facilityId}/verify/${role}/${encodedEmail}`, {});
    }

    /**
     * assignCaregiverOrGuardianToUserInFacility: assign a caregiver or guardian to a user in a facility
     * @param facilityId
     * @param userId
     * @param addType
     * @param assignee: the email of the caregiver/guardian who is being assigned to a user
     */
    assignCaregiverOrGuardianToUserInFacility(facilityId: string, userId: string, addType: 'guardian' | 'caregiver', assignee: string) {
        let encoder = new CustomEncoder();
        const encodedUserEmail = encoder.encodeKey(userId.toLowerCase());
        const encodedAssigneeEmail = encoder.encodeKey(assignee.toLowerCase());
        return this.http.put(`${this.baseUrl}/${facilityId}/${addType}/${encodedAssigneeEmail}/user/${encodedUserEmail}`, {});
    }
}
