<div class="page">
    <!--  ======  Loading view  ======  -->
    <div *ngIf="(!loaded || !isLoaded()) && !errOccurred">
        Loading...
    </div>

    <!--  ======  Error view  ======  -->
    <alert type="danger" *ngIf="errOccurred">
        <strong>Whoops!</strong> We're sorry, an error occurred trying to load this page.
    </alert>

    <!--  ======  Caregiver view  ======  -->
    <div class="mainContent dash" *ngIf="currentUser.hasOwnProperty('caregiverOf')
    && (currentUser.caregiverOf.length > 0)
    && (isLoaded() || loaded)">
        <!--        <article class="mainContent__tile control">-->
        <!--            control-->
        <!--        </article>-->

        <!--        <article class="mainContent__tile dash__total-usage"></article>-->
        <!--        <totalusagegraph class="dash__total-usage"></totalusagegraph>-->
        <stacked-bar-chart [graphTitle]="'Activities'"></stacked-bar-chart>

        <div class="events">
            <div class="mainContent__tile events__table">
                <div class="header">
                    <div class="text-area">
                        <h4 class="header__title graphTitle">Events</h4>
                    </div>
                </div>
                <div class="table-container">
                    <table class="table">
                        <thead class="table__header">
                        <tr>
                            <th>Event Name</th>
                            <th>Date</th>
                            <th>Time</th>
                        </tr>
                        </thead>
                        <tbody class="table__body" *ngIf="myEvents.length > 0">
                        <tr class="table__data" *ngFor="let ev of myEvents; let i = index;">
                            <td class="id eventName" *ngIf="i <=4"><span class="colorCode"></span>{{ev.title}}</td>
                            <td class="status eventDate" *ngIf="i <=4">{{ev.end | date:'shortDate'}}</td>
                            <td class="community eventTime" *ngIf="i <=4">{{ev.end | date:'shortTime'}}</td>
                        </tr>
                        </tbody>

                        <!--     TODO: This is sample data for the sake of demonstration-->
                        <tbody class="table__body" *ngIf="myEvents.length === 0">
                        <tr class="table__data">
                            <td class="id eventName"><span class="colorCode"></span>Medicine (fake)</td>
                            <td class="status eventDate">8/30/21</td>
                            <td class="community eventTime">10:00 AM</td>
                        </tr>
                        <tr class="table__data">
                            <td class="id eventName"><span class="colorCode"></span>Call my Son (fake)</td>
                            <td class="status eventDate">5/27/21</td>
                            <td class="community eventTime">6:30pm</td>
                        </tr>
                        <tr class="table__data">
                            <td class="id eventName"><span class="colorCode"></span>Medicine (fake)</td>
                            <td class="status eventDate">8/16/21</td>
                            <td class="community eventTime">8:06am</td>
                        </tr>
                        <tr class="table__data">
                            <td class="id eventName"><span class="colorCode"></span>Call my Son (fake)</td>
                            <td class="status eventDate">5/27/21</td>
                            <td class="community eventTime">3:30pm</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="moreBtn">
                    <span (click)="navToCalendar()">More</span>
                </div>
            </div>

            <div class="mainContent__tile events__calendar">
                <!--                TODO: this-->
                Calendar placeholder
            </div>

        </div>
    </div>

    <!--  ======  Admin view ====== -->
    <div class="mainContent dash" *ngIf="currentUser.role === 'admin' && (isLoaded() || loaded)">

        <article class="dash__summary summary">
            <section class="summary__numRobots">
                <h3 class="metric">Active Robots</h3>
                <h2 class="value">
                    <span class="bots--active">{{numBots.active}}</span>
                    <span class="bots--all">/ {{numBots.all}}</span>
                </h2>
            </section>
            <section class="summary__numOrgs">
                <h3 class="metric">Organizations</h3>
                <h2 class="value">{{numOrgs}}</h2>
            </section>
            <section class="summary__numUsers">
                <h3 class="metric">Users</h3>
                <h2 class="value">{{numUsers}}</h2>
            </section>
        </article>

<!--        <totalusagegraph class="dash__total-usage"></totalusagegraph>-->

        <!--  =============  Table of Ryans =============  -->
        <article class="mainContent__tile ryanTable">
            <div class="header">
                <div class="text-area">
                    <h4 class="header__title graphTitle">Ryans</h4>
                    <small class="lastUpdated">Total {{numBots.all}} Ryans</small>
                </div>

                <div class="buttons">
                    <button class="emptyBtn" (click)="navToRyanDataPage()">
                        Show All
                    </button>
                </div>
            </div>
            <div class="table-container">
                <table class="table">
                    <thead class="table__header">
                    <tr>
                        <th>ID</th>
                        <th>Status</th>
                        <th>Community</th>
                        <th>Last Login ID</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody class="table__body">
                    <tr class="table__data" *ngFor="let bot of bots.all.slice(0,4); let i = index;" (click)="navToBotFocus(bot._id)">
                        <td class="id">
                            <span class="bg-logo-blue">R{{bot._id.slice(bot._id.length -3)}}</span>
                        </td>
                        <td class="status">{{bot.ryanState}}</td>
                        <td class="community">Mulroy</td>
                        <td class="lastLogin">First Last</td>
                        <td class="more" (click)="navToBotFocus(bot._id)">more</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </article>

        <!-- ============= Org Table ============= -->
        <article class="mainContent__tile orgTable">
            <div class="header">
                <div class="text-area">
                    <h4 class="header__title graphTitle">Organizations</h4>
                    <small class="lastUpdated">Total {{numBots.all}} Ryans</small>
                </div>

                <div class="buttons">
                    <button class="emptyBtn" (click)="navToAllOrgPage()">
                        Show All
                    </button>
                </div>
            </div>
            <div class="table-container">
                <table class="table">
                    <thead class="table__header">
                    <tr>
                        <th class="orgName-header">Name</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Num. Ryans</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody class="table__body">
                    <tr class="table__data" *ngFor="let org of allOrgs.slice(0,4); let i = index;" (click)="navToOrgFocus(org._id)">
                        <td class="orgName">
                            {{org.name}}
                        </td>
                        <td class="orgCity">{{org.city}}</td>
                        <td class="orgState">{{org.state}}</td>
                        <td class="orgRyans">{{org.ryans.length || '0'}}</td>
                        <td class="more" (click)="navToOrgFocus(org._id)">more</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </article>
    </div>

    <div class="rightPanel">
        <!--        TODO: check user acct permissions to decide what content to display -->
        <!--        <userlistpanel [users]="(distinctUsers)"></userlistpanel>-->
        <div *ngIf="currentUser.hasOwnProperty('caregiverOf') && (!isLoaded() || !loaded)">Loading Users...</div>

        <!--        Caregiver view -->
        <userlistpanel [users]="allUsers" *ngIf="currentUser.hasOwnProperty('caregiverOf') && (isLoaded() || loaded)"></userlistpanel>

        <!--        Site Admin view -->
        <div class="addRyan-btn" *ngIf="currentUser.role === 'admin' && (isLoaded() || loaded)" (click)="addBot()">
            <robot-icon class="btn-icon" [ellipse]="true"></robot-icon>
            <span class="btn-title">New Robot</span>
            <span class="btn-desc">Add a new Ryan</span>
        </div>

        <div class="addOrg-btn" *ngIf="currentUser.role === 'admin' && (isLoaded() || loaded)" (click)="addOrganization()">
            <org-icon class="btn-icon" [ellipse]="true"></org-icon>
            <span class="btn-title">Add an Organization</span>
        </div>
    </div>
</div>
