// // @flow
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class FileService {
    Http;
    // api/users/:userId/files
    static parameters = [HttpClient];
    constructor(public http: HttpClient) {
        this.Http = http;
    }

    get(userId: string = 'me', page?: number, numResults?: number): Observable<any> {
        // TODO: handle page and numResults
        return this.http.get(`api/users/${userId}/files`);
    }

    delete(userId: string = 'me', fileKey: string): Observable<any> {
        // TODO: handle page and numResults
        return this.http.delete(`api/users/${userId}/files/${fileKey}`);
    }

    upload(file, forUserId: string = 'me'): Observable<any> {
        // Create form data
        const formData = new FormData();

        // Store form name as "file" with file data
        formData.append("file", file, file.name);

        // Make http post request over api
        // with formData as req
        return this.http.post(`api/users/${forUserId}/files`, formData, {
            reportProgress: true,
            observe: 'events',
        }).pipe(catchError(this.errorMgmt));
    }

    download(fileKey, forUserId: string = 'me'): Observable<any> {
        return this.http.get(`api/users/${forUserId}/files/${fileKey}`, { responseType: 'blob' }).pipe(catchError(this.errorMgmt));
    }

    // rename(fileToDelete, fileToUpload, forUserId: string = 'me'): Observable<any> {
    //     //basically just deletes then re-uploads the file
    //     console.log('fileToDelete: ', fileToDelete);
    //     console.log('fileToUpload: ', fileToUpload);
    //     return this.delete(fileToDelete, forUserId).subscribe(res => {
    //         console.log(res);
    //         return this.upload(fileToUpload, forUserId).subscribe();
    //     });
    //
    //     // return this.http.put(`api/users/${forUserId}/files`, {responseType: 'blob'}).pipe(catchError(this.errorMgmt));
    // }

    public errorMgmt(error: HttpErrorResponse) {
        let errorMessage = '';
        console.error('Error from FileService: ', error);
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            if (error.error.error && typeof error.error.error === 'string') {
                errorMessage = error.error.error;
            } else {
                errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
        }
        return throwError(errorMessage);
    }

}
// ======Usage======
// https://www.positronx.io/angular-file-upload-with-progress-bar-tutorial/
