import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit,
    EventEmitter,
    Output,
} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {AuthService} from '../auth/auth.service';
import {AnalyticQuery, AnalyticsService} from '../../services/analytics/analytics.service';
import moment from 'moment';
import {isValidDate} from '../util';
import {ResearchStudy} from '../interfaces/ResearchStudy';

@Component({
    selector: 'queryDateRangeControl',
    templateUrl: './queryDateRangeControl.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./queryDateRangeControl.scss']
})


export class QueryDateRangeControlComponent implements OnInit, OnDestroy {
    @Input('title') title?: string = 'Control';
    @Input('queryMinDate') queryMinDate: Date;
    @Input('queryMaxDate') queryMaxDate: Date;
    @Input('queryDateRange') queryDateRange: [Date, Date];
    @Input('downloadBtnLabel') downloadBtnLabel?: string = 'Download';
    @Input('downloadModalHeader') downloadModalHeader?: string = 'Download Data';
    @Input('researchStudy') study: ResearchStudy;
    @Output('queryDatesUpdate') queryDatesUpdate = new EventEmitter<[Date, Date]>();

    showAlert = false;
    alertType = '';
    alertMessage = '';
    faCalendarAlt = faCalendarAlt;
    dataReady: boolean = false;

    //Date picker
    currentDate = new Date();
    bsConfig: Partial<BsDatepickerConfig>;

    icon = {
        calendar: faCalendarAlt
    };

    dateControl = new FormGroup({
        dateYMD: new FormControl(new Date()),
        dateFull: new FormControl(new Date()),
        dateMDY: new FormControl(new Date()),
        dateRange: new FormControl([
            new Date(new Date().getDate() - 7),
            new Date()
        ])
    });
    dateParams = {
        minAllowed: new Date('November 1, 2020 01:00:00'),
        maxAllowed: this.currentDate,
        minParam: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7),
        maxParam: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        ranges: [
            {
                value: [new Date(new Date().setDate(new Date().getDate() - 7)),
                    new Date()],
                label: 'Last 7 days'
            },
            {
                value: [new Date(new Date().setDate(new Date().getDate() - 14)),
                    new Date()],
                label: 'Last 14 days'
            },
            {
                value: [new Date(new Date().setDate(new Date().getDate() - 30)),
                    new Date()],
                label: 'Last 30 days'
            },
            {
                value: [new Date(new Date().setDate(new Date().getDate() - 45)),
                    new Date()],
                label: 'Last 60 days'
            },
            {
                value: [new Date(new Date().setDate(new Date().getDate() - 90)),
                    new Date()],
                label: 'Last 90 days'
            }]
    };
    selectedRange: Date[] = this.dateParams.ranges[1].value;

    static parameters = [Router, AuthService, AnalyticsService];
    constructor(public router: Router, public authService: AuthService, public analyticService: AnalyticsService) {
        this.router = router;
        this.authService = authService;
        this.analyticService = analyticService;
        let weekAgo = new Date();
        weekAgo.setDate(weekAgo.getDate() - 7);
    }

    public ngOnInit(): void {
        //remove range options that exceed the scope of the study
        if (this.study) {
            this.dateParams.minAllowed = new Date(this.study.startDate);
            this.dateParams.maxAllowed = new Date(Math.min(new Date(this.study.endDate).getTime(), this.dateParams.maxAllowed.getTime()));
            console.log('study range: ', [this.study.startDate, this.study.endDate]);
            this.dateParams.ranges = this.dateParams.ranges.filter(range => {
                return moment(range.value[0]).isSameOrAfter(moment.utc(this.study.startDate), 'date')
                && moment(range.value[1]).isSameOrBefore(moment.utc(this.study.endDate), 'date');
            });
            this.queryDateRange = [this.dateParams.minAllowed, this.dateParams.maxAllowed];
            // const studyStart = moment.utc(this.study.startDate);
            // const studyStart2 = moment(this.study.startDate);
            // console.log(studyStart.toDate());
            // console.log(studyStart.utc().toDate());
            // console.log(studyStart2.toDate());
            // console.log(studyStart2.utc().toDate());
            // console.log(studyStart.get('year'), studyStart.get('day'), studyStart.get('day'));
            // console.log(new Date(studyStart.toDate()));
            // console.log(new Date(Date.UTC(studyStart.get('year'), studyStart.get('month'), studyStart.get('date'), studyStart.get('hours'))));

            //FIXME: study-relative pre-set ranges
            // this.dateParams.ranges.push({
            //     value: [new Date(Date.UTC(studyStart.get('year'), studyStart.get('month'), studyStart.get('date'))),
            //         moment.utc(this.study.endDate).toDate()],
            //     label: 'Duration of Study'
            // });

            // console.log(this.dateParams);
        }
    }

    /**
     * initDatePicker: initialize the values used to control the datePicker
     */
    public initDatePicker() {
        this.currentDate = new Date();
        //set date params without timestamp
        this.queryMinDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() - 7);
        this.queryMaxDate = new Date(this.currentDate);
        this.dateParams = {...this.dateParams,
            minAllowed: new Date('November 1, 2020 01:00:00'),
            maxAllowed: this.currentDate,
            minParam: this.queryMinDate,
            maxParam: this.queryMaxDate
        };
    }


    /**
     * datePickerUpdate: handles updates to the datePicker date range, and EMITs an event to the parent
     *                      (which, in turn, should send it back down to each of the child graphs on the page)
     * @param newDates: an array of the new [startTime, endTime] for the query
     */
    datePickerUpdate(newDates: [Date, Date]) {
        //check the year of the first date to handle the bsDateRangePicker init value of Dec 31, 1969
        if (newDates[0].getFullYear() < 1971 || newDates[0] === this.dateParams.minAllowed ||
            !isValidDate(newDates[0]) || !isValidDate(newDates[1])) return;
        //format the dates to be the UTC absolute beginning and absolute end of a day
        //  ex: 2021-02-15T00:00:00.000Z  -  2021-03-20T23:59:59.999Z
        const newStart = moment(newDates[0]).startOf('day').toDate();
        const newEnd = moment(newDates[1]).endOf('day').toDate();

        if (newStart !== this.queryMinDate || newEnd !== this.queryMaxDate) {
            this.queryMinDate = newStart;
            this.queryMaxDate = newEnd;
            this.queryDateRange = [this.queryMinDate, this.queryMaxDate];
            this.queryDatesUpdate.emit([this.queryMinDate, this.queryMaxDate]);
        }
    }

    public ngOnDestroy(): void { }
}


