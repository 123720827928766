import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { RouterModule, Routes } from '@angular/router';
import { WikiPageComponent } from './wiki.component';
import { DirectivesModule } from '../../components/directives.module';
import {AuthService} from "../../components/auth/auth.service";
import {UserService} from "../../components/auth/user.service";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        CollapseModule,
        RouterModule,
        AlertModule,
        DirectivesModule,
    ],
    declarations: [
        WikiPageComponent
    ],
    exports: [
        WikiPageComponent
    ],
    providers: [
        AuthService,
        UserService,
    ],
})
export class WikiModule { }
