import { Component, Input } from '@angular/core';
import { CalendarEventService } from '../../../services/calendar-event/calendar-event.service';
import { CalendarEvent } from '../../interfaces/CalendarEvent';
import moment from 'moment';
import {weekdays} from '../../../../server/config/environment/shared';
import {FormArray} from "@angular/forms";

@Component({
    selector: 'create-event',
    templateUrl: './createEvent.html',
    providers: [],
    styleUrls: ['./createEvent.scss'],
})

export class CreateEventComponent {
    @Input() calendarFunctions: { handleCreateEvent?, closeCreateEvent?} = undefined;
    event: CalendarEvent = {
        title: '',
        caption: '',
        begin: moment().format('YYYY-MM-DDTkk:mm'),
        end: moment().set('hour', moment().get('hour') + 1).format('YYYY-MM-DDTkk:mm'),
        eventType: 'Activity',
        recurring: undefined,
    };

    // selected recurrence configurations
    recurrenceTypeSelected: string = 'Weekly';
    selectedDays = [];
    recurrenceStart: string = moment().format('YYYY-MM-DDTkk:mm');
    duration: number = 30;
    recurrenceEnd: string = moment().set('dayOfYear', moment().get('dayOfYear') + 7).format('YYYY-MM-DDTkk:mm');

    // allowed types
    daysInWeek = weekdays;
    recurrenceTypesAllowed = ['Daily', 'Weekly']
    eventTypesAllowed = ['Activity', 'Medicine', 'Appointment', 'Exercise'];
    // form configuration
    recurringMode: boolean = false;

    alert = {
        showFor: '',
        type: '',
        message: ''
    };

    static parameters = [CalendarEventService];
    constructor(public eventService: CalendarEventService) {
        this.eventService = eventService;
    }

    handleSubmit({ value, valid }) {
        if (!valid) {
            console.error('Invalid form');
            this.showAlertTemporary('create-event', 'warning', 'Invalid Event', 5000);
            return;
        }

        if (moment(value.begin) > moment(value.end) || moment(value.recurrenceStart) > moment(value.recurrenceEnd)) {
            console.error('Invalid Dates. Event start-date most be before event end-date');
            this.showAlertTemporary('create-event', 'warning', 'Invalid Dates', 5000);
            return;
        }
        let eventToCreate;
        if (this.recurringMode) {
            eventToCreate = {
                title: value.title,
                caption: value.description,
                eventType: value.eventType,
                begin: moment(value.recurrenceStart).toISOString(),
                end: moment(value.recurrenceEnd).toISOString(),
                recurring: {
                    rule: value.recurrence,
                    dow: value.recurrence === 'Daily' ? this.daysInWeek : this.selectedDays,
                }
            };
        } else {
            eventToCreate = {
                title: value.title,
                caption: value.description,
                eventType: value.eventType,
                begin: moment(value.begin).toISOString(),
                end: moment(value.end).toISOString()
            };
        }
        if (this.calendarFunctions.handleCreateEvent) {
            this.calendarFunctions.handleCreateEvent(eventToCreate)
                .then(() => {
                    this.showAlertTemporary('create-event', 'info', 'Created Event', 3000);
                })
                .catch((e) => {
                    let message;
                    if (typeof e === 'string') message = e;
                    else if (e.message && typeof e.message === 'string') message = e.message;
                    else message = 'Error creating event';
                    console.error(e);
                    this.showAlertTemporary('create-event', 'warning', message, 3000);
                });
        }
    }

    updateSelectedDays(event) {
        if (this.daysInWeek.indexOf(event.target.name) !== -1) {
            if (event.target.checked) {
                this.selectedDays.push(event.target.name);
            } else {
                this.selectedDays.splice(this.selectedDays.indexOf(event.target.name), 1);
            }
        }
    }

    showAlertTemporary(showFor: string, type: string, message: string, milliseconds: number) {
        this.alert = {
            showFor,
            type,
            message,
        };
        window.setTimeout(() => {
            this.clearAlert();
        }, milliseconds);
    }

    clearAlert() {
        this.alert = {
            showFor: '',
            type: '',
            message: '',
        };
    }
}

