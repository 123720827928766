import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, OnChanges} from '@angular/core';
import {
    AnalyticsService,
} from '../../../services/analytics/analytics.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {formatSingleConversationTopicName} from '../../util';

@Component({
    selector: 'conversation-graph',
    templateUrl: './conversation-graph.html',
    styleUrls: ['./conversation-graph.scss']
})

export class ConversationGraphComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @Input() graphTitle: string;
    @Input() conversationDataReady: boolean = false;
    @Input() conversationTopics: string[] = ['Kids', 'Pets', 'TVShows', 'Sciences', 'Music', 'Nature', 'Foods', 'Travel']
    @Input() conversationSummary: {numberOfQuestions: number, topic: string, percentShare: number}[];
    @Input() convoDetails: {userWordCount: number, ryanWordCount: number,
        day: Date, topic: string, response: string, sentiment: number}[];
    convoDetailsMatTable: MatTableDataSource<any>;
    displayedColumns: string[] = ['day', 'userWordCount', 'ryanWordCount', 'topic', 'response', 'sentiment'];
    private convoDetailsPaginator: MatPaginator;
    @ViewChild('convoDetailsPaginator') set matPaginator(mp: MatPaginator) {
        if (this.convoDetails && !this.convoDetailsMatTable) {
            this.convoDetailsMatTable = new MatTableDataSource<any>(this.convoDetails);
        }
        this.convoDetailsPaginator = mp;
        if (this.convoDetailsMatTable) {
            this.convoDetailsMatTable.paginator = this.convoDetailsPaginator;
        }
    }

    @ViewChild(MatSort, {static: false}) set sort(value: MatSort) {
        if (this.convoDetailsMatTable) {
            this.convoDetailsMatTable.sort = value;
        }
    }

    static parameters = [AnalyticsService];
    constructor(public analyticService: AnalyticsService) {
        this.analyticService = analyticService;
    }

    ngOnInit() {
        if (this.convoDetails) {
            this.convoDetailsMatTable = new MatTableDataSource<any>(this.convoDetails);
        }
    }

    ngAfterViewInit(): void {
        if (this.convoDetails) {
            this.convoDetailsMatTable = new MatTableDataSource<any>(this.convoDetails);
        }
    }

    ngOnChanges() {
        if (this.convoDetails) {
            this.convoDetailsMatTable = new MatTableDataSource<any>(this.convoDetails);
        }
    }

    /**
     * formatSingleConversationTopicName: given a delimiter and a topicName, replaces the delimiter with a space and
     *          capitalizes the first letter of each word -- uses util.formatSingleConversationTopicName
     * @param delimiter
     * @param topicName
     */
    formatSingleConversationTopicName(delimiter: string, topicName: string): string {
        if (!topicName || topicName === '*') return 'Unspecified';
        if (topicName === 'you') return 'Ryan';
        return formatSingleConversationTopicName(delimiter, topicName);
    }

    // getConversationAnalytics() {
    //     const query: AnalyticQuery = {
    //         email: 'user1@example.com',
    //         startTime: new Date('2021-04-01T16:10:31.029Z'),
    //         endTime: new Date(),
    //         role: 'facilityAdmin'
    //     };
    //     console.log(query);
    //
    //     this.analyticService.getConversationAnalysisForSingleUser(query)
    //         .toPromise()
    //         .then((res: ConversationAnalysisResponse) => {
    //             console.log(res);
    //             return res;
    //         })
    //         .then((res2) => {
    //             console.log(res2.topicSummarization);
    //             this.conversationSummary = res2.topicSummarization;
    //             this.conversationSummary = this.conversationTopics.map((topic) => {
    //                 const exists = this.conversationSummary.find((elem) => elem.topic === topic);
    //                 if (!exists) {
    //                     return {numberOfQuestions: 0, topic: topic, percentShare: 0};
    //                 }
    //                 return exists;
    //             })
    //                 .sort((a, b) => b.percentShare - a.percentShare);
    //             console.log(this.conversationSummary);
    //             this.convoDetails = res2.conversationDetails.map((elem) => {
    //                 var newDate = new Date(elem.day);
    //                 console.log(newDate);
    //                 newDate.setMinutes(2 * newDate.getTimezoneOffset());
    //                 console.log(newDate);
    //                 return {...elem, day: newDate};
    //             })
    //                 .sort((a, b) => b.day.getTime() - a.day.getTime());
    //
    //             console.log(this.convoDetails);
    //             this.conversationDataReady = true;
    //         })
    //         .catch((err) => console.error(err));
    // }


    public ngOnDestroy(): void { }
}
