import {Component, EventEmitter, Input, Output} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {Facility} from '../../interfaces/Facility';
import {ResearchRoleName, ResearchStudy} from '../../interfaces/ResearchStudy';
import {UserType} from '../../auth/user.service';
import {AuthService} from '../../auth/auth.service';
import {Organization} from '../../interfaces/Organization';

@Component({
    selector: 'addResearcherToStudy',
    templateUrl: './addResearcherToFacilityOrStudy.html',
    styleUrls: ['./addResearcherToFacilityOrStudy.scss']
})
/**
 * TODO: allow for the selection of multiple Researchers at a time (Blocked by server-side update route),
 *          the code required for this capability purposely commented-out
 */
export class AddResearcherToFacilityOrStudyComponent {
    @Input('role') role: ResearchRoleName;
    @Input('entity') entity: ResearchStudy | Facility;
    @Input('optionOrganizations') optionOrganizations: Organization[] = [];
    @Input('optionResearchers') optionResearchers: string[] = [];
    @Input('alert') alert: {
        type: string,
        show: boolean,
        message: string,
    };
    // @Output('addedResearchers') addedResearchers: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output('addedResearcher') addedResearcher: EventEmitter<string> = new EventEmitter<string>();

    currentUser: UserType;
    // researchersToBeAdded: string[] = [];
    researcherToBeAdded: string;
    static parameters = [BsModalRef, AuthService];

    constructor(public bsModalRef: BsModalRef) {}

    toggleResearcherMembership(email: string) {
        // const participantIsMember = this.participantsToBeAdded.includes(email);
        // if (participantIsMember) {
        //     this.participantsToBeAdded = this.participantsToBeAdded.filter(p => p !== email);
        // } else {
        //     this.participantsToBeAdded.push(email);
        // }
        this.researcherToBeAdded = this.researcherToBeAdded ? undefined : email;
    }

    isSelected(email: string): boolean {
        // return this.participantsToBeAdded.includes(email);
        return this.researcherToBeAdded === email;
    }

    getOrganizationsForResearcher(email: string): Organization[] {
        return this.optionOrganizations.filter(o => o.researchers.includes(email));
    }

    submitResearcherAssignment() {
        this.addedResearcher.emit(this.researcherToBeAdded);
        this.optionResearchers = this.optionResearchers.filter(r => r !== this.researcherToBeAdded);
        this.researcherToBeAdded = undefined;
        // this.optionResearchers = this.optionResearchers.filter(p => !this.researcherToBeAdded.includes(p.email));
        // this.participantsToBeAdded = [];
    }
}

