import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Organization } from '../../interfaces/Organization';
import { states } from '../../../../server/config/environment/shared';

@Component({
    selector: 'create-org',
    templateUrl: './create-organization.html'
})
export class CreateOrganizationComponent {
    @Input() formError: String;

    @Input() formInfo: String;

    @Output() newOrgCreation: EventEmitter<Organization> = new EventEmitter<Organization>();

    public newOrg: Organization = {
        _id: undefined,
        id: undefined,
        name: undefined,
        address: undefined,
        contactPerson: undefined,
        telephoneNumber: undefined,
        city: undefined,
        state: undefined,
        country: undefined,
        zip: undefined,
        website: undefined,
        administrators: undefined,
        facilities: undefined,
        researchers: undefined,
        ryans: undefined,
        email: undefined,
    };

    allStates = states;
    static parameters = [BsModalRef];

    constructor(public bsModalRef: BsModalRef) { }

    submitOrgCreation() {
        //TODO: Address API to validate address
        this.newOrgCreation.emit(this.newOrg);
    }
}

