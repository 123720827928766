<div class="modal-content">
    <form id="addAdmin" name="addAdmin" role="form" class="form-horizontal" (ngSubmit)="submitEmail()">
        <div class="modal-header">
            <h4 id="myModalLabel" class="modal-title">Add {{addToType | formatRole | lowercase}} to: {{ addTo.name || addTo.title}}</h4>
            <button type="button" (click)="bsModalRef.hide()" class="close"><span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>

        <div class="modal-body" *ngIf="!(addToType === 'principleinvestigator' && isFacility(addTo))">
            <div class="form-group">
                <label for="addToEmail" class="control-label">{{addToType | formatRole}} Email Address
                    <input id="addToEmail" type="email" name="addToEmail" required placeholder="{{ addToType | formatRole}} email" class="form-control" [(ngModel)]="email" #name="ngModel" minlength="3" (ngModelChange)="validateEmail()">
                </label>
            </div>
        </div>

        <div class="modal-body" *ngIf="addToType === 'principleinvestigator' && isFacility(addTo)">
            <div class="form-group">
                <label for="orgName" class="control-label">{{addToType | formatRole}} Email Address
<!--                    TODO: make this a typeahead select dropdown only listing researchers who have been added to parent org-->
                    <input id="orgName" type="email" name="orgName" required placeholder="{{addToType | formatRole}} email" class="form-control" [(ngModel)]="email" #name="ngModel" minlength="3">
                </label>
            </div>
        </div>
        <!--  ======  Alert  ======  -->
        <alert *ngIf="!!alert && alert?.show && alert?.message" [type]="alert.type" [hidden]="!alert || !alert?.show" [dismissible]="true" (onClosed)="alert.show = false">
            <strong *ngIf="(alert.type | lowercase) === 'success'">Success: </strong>
            <strong *ngIf="(alert.type | lowercase) === 'danger'">Error: </strong>
            {{ alert.message }}
        </alert>

        <div class="modal-footer">
            <button (click)="bsModalRef.hide()" type="reset" class="btn btn-default">Cancel</button>
            <button type="submit" class="btn btn-primary" [disabled]="!validEmail() || alert?.show">Confirm</button>
        </div>
    </form>
</div>
