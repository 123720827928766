<div class="mainContainer">
    <header class="mainContainer__header">
        <mainheader></mainheader>
    </header>
    <div class="mainContainer__leftNav">
        <left-nav></left-nav>
    </div>

    <div class="mainContainer__main">
        <facilityAdmin-dashboard *ngIf="state.pageView === 'dashboard'"></facilityAdmin-dashboard>
        <facilityAdmin-usersActivities *ngIf="state.pageView === 'usersactivities'"></facilityAdmin-usersActivities>
        <facilityAdmin-gameMetrics *ngIf="state.pageView === 'gamemetrics'"></facilityAdmin-gameMetrics>
        <!--        <calendarPage *ngIf="state.pageView === 'calendar'"></calendarPage>-->
        <facilityAdmin-manage *ngIf="state.pageView === 'manage'"></facilityAdmin-manage>
        <wikiPage *ngIf="state.pageView === 'wiki'"></wikiPage>
        <settingsPage *ngIf="state.pageView === 'settings'" [viewAs]="state.roleView"></settingsPage>
    </div>
</div>

