<div class="modal-content orgClaimModal">
    <!--    TODO: validate input-->
    <form id="createOrg" name="createOrg" role="form" class="form-horizontal" (ngSubmit)="editStudy()">
        <div class="modal-header">
            <h4 id="myModalLabel" class="modal-title">Create a new Study</h4>
            <button type="button" (click)="bsModalRef.hide()" class="close"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </div>
        <div class="modal-body">
            <div role="alert" [hidden]="!formError" class="alert alert-danger">{{ formError }}</div>
            <div role="alert" [hidden]="!formInfo" class="alert alert-info">{{ formInfo }}</div>

            <form [formGroup]="studyForm">
                <div class="form-group">
                    <div class="col-xs-12 col-sm-10 offset-sm-1">
                        <label for="title" class="control-label">Study Title</label>
                        <input id="title" name="studyTitle" required placeholder="Study Title" class="form-control" formControlName="title" minlength="3">
                        <div *ngIf="studyForm.get('title').errors && (!studyForm.get('title').pristine || submitted)">
                            <small class="help-block form-text form-text--error" *ngIf="studyForm.get('title').errors.required">
                                A title for your study is required
                            </small>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-xs-12 col-sm-10 offset-sm-1">
                        <label for="irbNum" class="control-label">IRB Number</label>
                        <input id="irbNum" name="irbNum" placeholder="IRB Number" class="form-control" formControlName="irbNumber">
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-xs-12 col-sm-10 offset-sm-1">
                        <label for="description" class="control-label">Description</label>
                        <quill-editor id="description" formControlName="description" placeholder="A brief description of your study" [modules]="quillConfig">
                        </quill-editor>
                    </div>
                </div>

                <div class="form-group form-row">
                    <div class="col-xs-12 col-sm-5 offset-sm-1">
                        <label for="startDate" class="control-label">Start Date</label>
                        <input id="startDate" name="startDate" type="date" required class="form-control" formControlName="startDate">
                        <div *ngIf="studyForm.get('startDate').errors && (!studyForm.get('startDate').pristine || submitted)">
                            <small class="help-block form-text form-text--error" *ngIf="studyForm.get('startDate').errors.required">
                                A Start Date is required
                            </small>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-5">
                        <label for="endDate" class="control-label">End Date</label>
                        <input id="endDate" name="endDate" type="date" required class="form-control" formControlName="endDate">
                        <div *ngIf="studyForm.get('endDate').errors && (!studyForm.get('endDate').pristine || submitted)">
                            <small class="help-block form-text form-text--error" *ngIf="studyForm.get('endDate').errors.required">
                                An End Date is required
                            </small>
                        </div>
                    </div>
                </div>
            </form>

        </div>
        <div class="modal-footer" [hidden]="formInfo">
            <button (click)="bsModalRef.hide()" type="reset" class="btn btndefault">
                Cancel</button>
            <button type="submit" class="btn btn-primary">Save</button>
        </div>
    </form>
</div>
