import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from "../../auth/auth.service";
import { Facility } from "../../interfaces/Facility";
import { UserService } from "../../auth/user.service";
import { states, facilityTypes } from "../../../../server/config/environment/shared";

@Component({
    selector: 'create-facility',
    templateUrl: './create-facility.html'
})
export class CreateFacilityComponent {

    @Input()
    formError: String;

    @Input()
    formInfo: String;

    @Output()
    newFacilityCreation: EventEmitter<Facility> = new EventEmitter<Facility>();
    public newFacility: Facility = {
        _id: undefined,
        id: undefined,
        name: undefined,
        address: undefined,
        contactPerson: undefined,
        telephoneNumber: undefined,
        city: undefined,
        state: undefined,
        country: undefined,
        zip: undefined,
        website: undefined,
        ryans: undefined,
        administrators: undefined,
        users: undefined,
        caregivers: undefined,
        email: undefined,
        facilityType: undefined,
    }

    facilityTypes = facilityTypes;
    allStates = states;

    static parameters = [BsModalRef];

    constructor(public bsModalRef: BsModalRef, public authService: AuthService) {
        this.authService = authService;
    }

    submitFacilityCreation() {
        //TODO: Address API to validate address
        this.newFacilityCreation.emit(this.newFacility);
    }
}

