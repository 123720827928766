// // @flow
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CalendarEvent } from '../../components/interfaces/CalendarEvent';
import moment from "moment";

export type CalendarEventType = {
    _id: string,
    begin: string,
    end: string,
    recurring: {
        dow: object,
        rule: object,
    },
    eventType: string,
    title: string,
    description: string,
    caption: string,
    duration?: number
};

export type CalendarEventQueryResponse = {
    docs: CalendarEvent[],
    bookmark: string
}

@Injectable()
export class CalendarEventService {
    public baseUrl = '/api/events';
    static parameters = [HttpClient];

    constructor(public http: HttpClient) {
        this.http = http;
    }

    getEvent(eventId: string, email?: string) {
        return (
            this.http.get(`${this.baseUrl}/${email || 'me'}/${eventId}`).toPromise()
                .then((docs: object) => Promise.resolve(docs))
                .catch((err) => Promise.reject(err))
        );
    }

    query(email: string, startTime?, endTime?) {
        // search over a span of two years. 1 year to and from today's date
        let today = moment();
        let yearAgo = moment(today).set('date', today.date() - 100);
        let defaultStartTime = moment(today).set('date', today.date() - 100).toISOString();
        let defaultEndTime = moment(today).set('date', today.date() + 260).toISOString();
        return this.http.get(`${this.baseUrl}/${email || 'me'}`, {
            params: {
                activeStart: startTime || defaultStartTime,
                activeEnd: endTime || defaultEndTime,
            }
        }).toPromise()
            .then((data: { docs: CalendarEvent[] }) => Promise.resolve(data.docs))
            .catch((err) => Promise.reject(err));
    }


    create(event: CalendarEventType, email: string) {
        return this.http.post(`${this.baseUrl}/${email || 'me'}`, event)
            .toPromise()
            .then((data) => Promise.resolve(data))
            .catch((err) => Promise.reject(err));
    }

    upsert(event: CalendarEventType, email: string) {
        return this.http.put(`${this.baseUrl}/${email || 'me'}/${event._id}`, event)
            .toPromise()
            .then((data) => Promise.resolve(data))
            .catch((err) => Promise.reject(err));
    }

    remove(event: CalendarEventType | CalendarEvent, email: string) {
        return this.http.delete(`${this.baseUrl}/${email || 'me'}/${event._id}`)
            .toPromise()
            .then((data) => Promise.resolve(data))
            .catch((err) => Promise.reject(err));
    }
}
