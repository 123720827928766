<div class="mainContainer">
    <mainheader class="mainContainer__header"></mainheader>
    <div class="mainContainer__leftNav">
        <left-nav></left-nav>
    </div>

    <div class="mainContainer__main">
        <user-dashboard *ngIf="state.pageView === 'dashboard'"></user-dashboard>
        <user-calendar *ngIf="state.pageView === 'calendar'"></user-calendar>
        <user-media *ngIf="state.pageView === 'media'"></user-media>
        <programrPage *ngIf="state.pageView === 'programr'"></programrPage>
        <settingsPage *ngIf="state.pageView === 'settings'" [viewAs]="this.state.roleView"></settingsPage>
    </div>
</div>

