import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AnalyticQuery, AnalyticsService} from '../../../services/analytics/analytics.service';
import {faPhone, faUserCircle} from '@fortawesome/free-solid-svg-icons';
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch';
import {Facility} from '../../../components/interfaces/Facility';
import {
    convertMillisToHoursMinutesSecondsAndMillis,
    convertMillisToMinutes,
    getAge,
    isValidDate
} from '../../../components/util';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import moment from 'moment';
import {CustomLinerChartService} from '../../../services/custom-dots-ngx-line-chart/CustomLinerChartService';
import {FacilityService} from '../../../services/facility/facility.service';
import {granularityOptions} from '../../../../server/config/environment/shared';
import {Subscription} from 'rxjs';
import {ResearchStudy} from '../../../components/interfaces/ResearchStudy';
import {ResearchService} from '../../../services/research/research.service';
import {GranularityOptions} from '../../../components/graphs/granularityOptions';

@Component({
    selector: 'researcher-gameMetrics',
    templateUrl: './researcherGameMetrics.html',
    styleUrls: ['../../../assets/sharedStyles/pages/gameMetrics.scss', '../researcherPages.scss', './researcherGameMetrics.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})

export class ResearcherGameMetricsComponent implements OnInit, OnDestroy {
    @Input() selectedGranularity: GranularityOptions = 'daily';
    granularityOptions = granularityOptions;

    queryParamSubscription: Subscription;
    focusedStudyId: string;
    focusedUserId: string;

    icons = {
        search: faSearch,
        user: faUserCircle,
        phone: faPhone,
    }

    allFacilities: Facility[];
    allStudies: ResearchStudy[];
    search: string;

    currentDate = new Date();

    selectedRange: Date[];

    gameUsageData : any[] ;
    gameActivityGraphMultipleUsers = [];
    ticTacToeGraphMultipleUsers = [];
    flowGameGraphMultipleUsers = [];
    picturePuzzleGraphMultipleUsers = [];
    pokerGraphMultipleUsers = [];
    wordPuzzleGraphMultipleUsers = [];
    flightGraphMultipleUsers = [];
    ryanRunsGraphMultipleUsers = [];
    birdCountingGraphMultipleUsers = [];
    checkersGraphMultipleUsers = [];
    chessGraphMultipleUsers = [];
    solitaireGraphMultipleUsers = [];

    gameActivityGraphCurrentUser = [];
    ticTacToeDataCurrentUser: any = {};
    ticTacToeColumns = ['Date', 'Robot', 'Win', 'Duration'];
    expandedTicTacToeDetailElement: any;

    flowGameDataCurrentUser: any = {};
    flowGameColumns = ['Date', 'Robot', 'Win', 'Duration'];
    expandedFlowGameDetailElement: any;

    ryanRunsDataCurrentUser: any = {};
    ryanRunsColumns = ['Date', 'Robot', 'Win', 'Duration'];
    expandedRyanRunsDetailElement: any;

    birdCountingDataCurrentUser: any = {};
    birdCountingColumns = ['Date', 'Robot', 'Win', 'Duration'];
    expandedBirdCountingDetailElement: any;

    picturePuzzleDataCurrentUser: any = {};
    picturePuzzleColumns = ['Date', 'Robot', 'Win', 'Duration'];
    expandedPicturePuzzleDetailElement: any;

    pokerDataCurrentUser: any = {};
    pokerColumns = ['Date', 'Robot', 'Win', 'Duration'];
    expandedPokerDetailElement: any;

    wordPuzzleDataCurrentUser: any = {};
    wordPuzzleColumns = ['Date', 'Robot', 'Win', 'Duration'];
    expandedWordPuzzleDetailElement: any;

    flightDataCurrentUser: any = {};
    flightColumns = ['Date', 'Robot', 'Win', 'Duration'];
    expandedFlightDetailElement: any;


    checkersDataCurrentUser: any = {};
    checkersColumns = ['Date', 'Robot', 'Win', 'Duration'];
    expandedCheckersDetailElement: any;


    chessDataCurrentUser: any = {};
    chessColumns = ['Date', 'Robot', 'Win', 'Duration'];
    expandedChessDetailElement: any;

    solitaireDataCurrentUser: any = {};
    solitaireColumns = ['Date', 'Robot', 'Duration'];
    expandedSolitaireDetailElement: any;

    //Graph options
    view: any[] = [700, 400];
    // options for general graphs
    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = false;
    showXAxisLabel = true;
    xAxisLabel = 'Day';
    showYAxisLabel = true;
    showGridLines = true;
    roundDomains = false;
    yAxisLabel = 'Time (Minutes)';
    noBarWhenZero = false;
    schemeType= 'ordinal';

    colorScheme = {
        domain: ['#FDD660']
    };
    autoScale = true;
    ryanRunsLineGraphColorScheme = {
        domain: ['#0066FF', '#56CCF2']
    };

    WinRateColorScheme = {
        domain: ['#F8906E', '#A71414', '#F5BD14']
    }
    flightAverageRangeOfMotionColorScheme = {
        domain: ['#0066FF', '#56CCF2']
    }
    flightAverageJerkColorScheme = {
        domain: ['#F8906E', '#A71414']
    };

    usageGraphView: any[] = [300, 200];
    usageGraphColorScheme = {
        domain: ['#F5BD14']
    };

    private ticTacToePaginator: MatPaginator;
    @ViewChild('ticTacToePaginator') set matPaginator(mp: MatPaginator) {
        this.ticTacToePaginator = mp;
        if (this.ticTacToeDataCurrentUser && this.ticTacToeDataCurrentUser.Breakdown) {
            this.ticTacToeDataCurrentUser.Breakdown.paginator = this.ticTacToePaginator;
        }
    }

    private flowGamePaginator: MatPaginator;
    @ViewChild('flowGamePaginator') set matPaginator2(mp: MatPaginator) {
        this.flowGamePaginator = mp;
        if (this.flowGameDataCurrentUser && this.flowGameDataCurrentUser.Breakdown) {
            this.flowGameDataCurrentUser.Breakdown.paginator = this.flowGamePaginator;
        }
    }

    private ryanRunsPaginator: MatPaginator;
    @ViewChild('ryanRunsPaginator') set matPaginator3(mp: MatPaginator) {
        this.ryanRunsPaginator = mp;
        if (this.ryanRunsDataCurrentUser && this.ryanRunsDataCurrentUser.Breakdown) {
            this.ryanRunsDataCurrentUser.Breakdown.paginator = this.ryanRunsPaginator;
        }
    }

    private birdCountingPaginator: MatPaginator;
    @ViewChild('birdCountingPaginator') set matPaginator4(mp: MatPaginator) {
        this.birdCountingPaginator = mp;
        if (this.birdCountingDataCurrentUser && this.birdCountingDataCurrentUser.Breakdown) {
            this.birdCountingDataCurrentUser.Breakdown.paginator = this.birdCountingPaginator;
        }
    }

    private picturePuzzlePaginator: MatPaginator;
    @ViewChild('picturePuzzlePaginator') set matPaginator5(mp: MatPaginator) {
        this.picturePuzzlePaginator = mp;
        if (this.picturePuzzleDataCurrentUser && this.picturePuzzleDataCurrentUser.Breakdown) {
            this.picturePuzzleDataCurrentUser.Breakdown.paginator = this.picturePuzzlePaginator;
        }
    }

    private pokerPaginator: MatPaginator;
    @ViewChild('pokerPaginator') set matPaginator11(mp: MatPaginator) {
        this.pokerPaginator = mp;
        if (this.pokerDataCurrentUser && this.pokerDataCurrentUser.Breakdown) {
            this.picturePuzzleDataCurrentUser.Breakdown.paginator = this.picturePuzzlePaginator;
        }
    }

    private wordPuzzlePaginator: MatPaginator;
    @ViewChild('wordPuzzlePaginator') set matPaginator6(mp: MatPaginator) {
        this.wordPuzzlePaginator = mp;
        if (this.wordPuzzleDataCurrentUser && this.wordPuzzleDataCurrentUser.Breakdown) {
            this.wordPuzzleDataCurrentUser.Breakdown.paginator = this.wordPuzzlePaginator;
        }
    }

    private flightPaginator: MatPaginator;
    @ViewChild('flightPaginator') set matPaginator7(mp: MatPaginator) {
        this.flightPaginator = mp;
        if (this.flightDataCurrentUser && this.flightDataCurrentUser.Breakdown) {
            this.flightDataCurrentUser.Breakdown.paginator = this.flightPaginator;
        }
    }

    private checkersPaginator: MatPaginator;
    @ViewChild('checkersPaginator') set matPaginator8(mp: MatPaginator) {
        this.checkersPaginator = mp;
        if (this.checkersDataCurrentUser && this.checkersDataCurrentUser.Breakdown) {
            this.checkersDataCurrentUser.Breakdown.paginator = this.checkersPaginator;
        }
    }
    private chessPaginator: MatPaginator;
    @ViewChild('chessPaginator') set matPaginator9(mp: MatPaginator) {
        this.chessPaginator = mp;
        if (this.chessDataCurrentUser && this.chessDataCurrentUser.Breakdown) {
            this.chessDataCurrentUser.Breakdown.paginator = this.chessPaginator;
        }
    }

    private solitairePaginator: MatPaginator;
    @ViewChild('solitairePaginator') set matPaginator10(mp: MatPaginator) {
        this.solitairePaginator = mp;
        if (this.solitaireDataCurrentUser && this.solitaireDataCurrentUser.Breakdown) {
            this.solitaireDataCurrentUser.Breakdown.paginator = this.solitairePaginator;
        }
    }

    static parameters = [Router, ActivatedRoute, FacilityService, AnalyticsService, ResearchService, CustomLinerChartService];

    constructor(public router: Router, public route: ActivatedRoute, public facilityService: FacilityService,
                public analyticService: AnalyticsService, public researchService: ResearchService, public dotsLineChart: CustomLinerChartService) {
        this.analyticService = analyticService;
        this.router = router;
        this.route = route;
        this.researchService = researchService;
        this.facilityService = facilityService;
        this.selectedRange = [
            moment(new Date(new Date().setDate(new Date().getDate() - 14))).startOf('day').toDate(),
            moment().endOf('day').toDate()];
        this.dotsLineChart = dotsLineChart;

        this.queryParamSubscription = this.route.queryParamMap.subscribe(queryParams => {
            this.focusedStudyId = queryParams.has('studyId') ? queryParams.get('studyId') : undefined;
            this.focusedUserId = queryParams.has('userId') ? queryParams.get('userId') : undefined;
        });
    }

    private flightAverageRangeOfMotionChart : any;
    @ViewChild('flightAverageRangeOfMotionChart') set dots3(chart: any) {
        this.flightAverageRangeOfMotionChart = chart;
        if (this.flightDataCurrentUser && this.flightDataCurrentUser.AverageRangeOfMotion) {
            this.dotsLineChart.showDotsSingle(this.flightAverageRangeOfMotionChart);
        }
    }

    private flightAverageJerkChart : any;
    @ViewChild('flightAverageJerkChart') set dots2(chart: any) {
        this.flightAverageJerkChart = chart;
        if (this.flightDataCurrentUser && this.flightDataCurrentUser.AverageJerk) {
            this.dotsLineChart.showDotsSingle(this.flightAverageJerkChart);
        }
    }

    private ryanRunsLineChart : any;
    @ViewChild('ryanRunsLineCharts') set dots(chart: any) {
        this.ryanRunsLineChart = chart;
        if (this.ryanRunsDataCurrentUser && this.ryanRunsDataCurrentUser.RatioLineGraphs) {
            this.dotsLineChart.showDots(this.ryanRunsLineChart);
        }
    }

    ngOnInit() {
        this.getAllResearchStudies()
            .then((studies: ResearchStudy[]) => {
                this.allStudies = studies as ResearchStudy[];
            })
            .then(() => {
                this.triggerQuery();
            })
            .catch(err => {
                console.error(err);
            });
    }

    triggerQuery() {
        if (!this.focusedStudyId) return;
        if (this.focusedUserId) this.getSingleUserData();
        else this.getMultipleUsersData();
    }

    getAllResearchStudies(): Promise<ResearchStudy[]> {
        return this.researchService.getStudiesForPrincipleInvestigator()
            .then((res: {studies: ResearchStudy[]}) => {
                const sortedStudies: ResearchStudy[] = res.studies
                    .map(s => ({...s, participants: s.participants.filter(u => u.invitationAccepted)}))
                    .sort((a, b) => a.title.localeCompare(b.title));
                console.log('studies: ', sortedStudies);
                return Promise.resolve(sortedStudies as ResearchStudy[]);
            })
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
    }

    formatUsageDataLabel = value => `${Math.round(value / 60000.0)}min`;

    millisToMinutesAndSeconds(ms) {
        const duration = convertMillisToHoursMinutesSecondsAndMillis(ms);
        let milliseconds = duration.millis;
        let seconds: string | number = duration.seconds;
        let minutes: string | number = duration.minutes;
        let hours: string | number = duration.hours;

        hours = hours < 10 ? `0${hours}` : hours;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        return `${hours}:${minutes}:${seconds}.${milliseconds}`;
    }
    millisToMinutes(ms) {
        return Math.round(convertMillisToMinutes(ms));
    }

    focusOnUser(userId: string = undefined) {
        const focusedStudy = this.getCurrentStudy();
        this.router.navigate([], {queryParams: {studyId: focusedStudy._id, userId: userId}});
        if (userId) {
            this.getSingleUserData(userId);
        }
    }
    focusOnStudy(studyId: string = undefined) {
        this.focusedStudyId = studyId;
        this.focusedUserId = undefined;
        this.router.navigate([], {queryParams: {studyId: studyId}});
        if (studyId) {
            this.getMultipleUsersData();
        }
    }

    getParticipantsInStudy(anonymized: boolean = true) {
        const users = this.getCurrentStudy().participants.map(u => {
            if (anonymized) {
                delete u.email;
                return u;
            }
            delete u.anonymizedName;
            return u;
        })
            .sort((a, b) => a.dateAddedToStudy - b.dateAddedToStudy);
        return users || [];
    }

    getCurrentStudy() {
        return this.allStudies.find(s => s._id === this.focusedStudyId);
    }
    getCurrentUser() {
        const currentStudy = this.getCurrentStudy();
        if (!currentStudy) return;
        return currentStudy.participants.find(p => [p.email, p.anonymizedName].includes(this.focusedUserId));
    }
    getUserAge(birthday: string) {
        if (!birthday) return '';
        return getAge(birthday);
    }

    changeSelectedRange(newDates: Date[]) {
        //check the year of the first date to handle the bsDateRangePicker init value of Dec 31, 1969
        if (newDates[0].getFullYear() < 1971 || !isValidDate(newDates[0]) || !isValidDate(newDates[1])) return;
        //ensure not redundant update
        if (newDates[0].getTime() === this.selectedRange[0].getTime() && newDates[1].getTime() === this.selectedRange[1].getTime()) return;
        this.selectedRange = [...newDates];
        this.triggerQuery();
    }

    changeGranularity(newValue: string) {
        newValue = newValue.toLowerCase();
        this.selectedGranularity = newValue as GranularityOptions;
        this.triggerQuery();
    }

    getMultipleUsersData() {
        console.log('getMultipleUsersData');
        this.getGameUsageDataForMultipleUsers();
        this.getAverageGameActivityDataForMultipleUsers();
        this.getTicTacToeDataForMultipleUsers();
        this.getFlowGameDataForMultipleUsers();
        this.getPicturePuzzleDataForMultipleUsers();
        this.getWordPuzzleDataForMultipleUsers();
        this.getPokerDataForMultipleUsers();
        this.getFlightDataForMultipleUsers();
        this.getRyanRunsDataForMultipleUsers();
        this.getBirdCountingDataForMultipleUsers();
        this.getCheckersDataForMultipleUsers();
        this.getChessDataForMultipleUsers();
        this.getSolitaireDataForMultipleUsers();
        this.formatLabelsForGraphs();
    }

    getSingleUserData(user?: string) {
        console.log('getSingleUserData');
        this.getGameUsageDataForSingleUser(user);
        this.getAverageGameActivityDataForSingleUser(user);
        this.getTicTacToeDataForSingleUser(user);
        this.getFlowGameDataForSingleUser(user);
        this.getPicturePuzzleDataForSingleUser(user);
        this.getWordPuzzleDataForSingleUser(user);
        this.getPokerDataForSingleUser(user);
        this.getFlightDataForSingleUser(user);
        this.getRyanRunsDataForSingleUser(user);
        this.getBirdCountingDataForSingleUser(user);
        this.getCheckersDataForSingleUser(user);
        this.getChessDataForSingleUser(user);
        this.getSolitaireDataForSingleUser(user);
        this.formatLabelsForGraphs();
    }

    private setupQueryForMultipleUsers(): AnalyticQuery {
        const currentStudy = this.getCurrentStudy();
        let start = new Date(this.selectedRange[0]);
        let end = new Date(this.selectedRange[1]);
        return {
            startTime: start,
            endTime: end,
            role: 'researcher',
            granularity: this.selectedGranularity,
            study: currentStudy._id
        };
    }

    private formatLabelsForGraphs(): void {
        if (this.selectedGranularity === 'hourly') {
            this.xAxisLabel = 'Hour';
        }
        if (this.selectedGranularity === 'daily') {
            this.xAxisLabel = 'Day';
        }
        if (this.selectedGranularity === 'weekly') {
            this.xAxisLabel = 'Week Of';
        }
        if (this.selectedGranularity === 'monthly') {
            this.xAxisLabel = 'Month';
        }
        this.yAxisLabel = 'Time (Minutes)';
    }

    private formatDataForBarGraph(analytic: any) : void {
        if (this.selectedGranularity === 'hourly') {
            analytic.name = moment(analytic.name).format('MMM D, LT');
        }
        if (this.selectedGranularity === 'daily') {
            analytic.name = moment(analytic.name).add(1, 'days').format('ll');
        }

        if (this.selectedGranularity === 'weekly') {
            analytic.name = moment(analytic.name).add(1, 'days').format('ll');
        }

        if (this.selectedGranularity === 'monthly') {
            analytic.name = moment(analytic.name).format('MMM');
        }
        analytic.value = Math.round(analytic.value * 100.0 / 60000) / 100;
    }

    getGameUsageDataForMultipleUsers(): any {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getGameUsageAnalyticsForMultipleUsers(query)
            .then((analytics:any) => {
                this.gameUsageData = analytics.UsageData;
            })
            .catch(err => console.error(err));
    }

    getAverageGameActivityDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getGameActivityAnalyticsForMultipleUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.Breakdown;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.gameActivityGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }

    getTicTacToeDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getTicTacToeDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.ticTacToeGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }

    getCheckersDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getCheckersDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.checkersGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }
    getChessDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getChessDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.chessGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }
    getSolitaireDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getSolitaireDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.solitaireGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }
    getFlowGameDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getFlowGameDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.flowGameGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }

    getPicturePuzzleDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getPicturePuzzleDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.picturePuzzleGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }

    getPokerDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getPokerDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.pokerGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }

    getWordPuzzleDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getWordPuzzleDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.wordPuzzleGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }

    getFlightDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getFlightDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.flightGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }

    getRyanRunsDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getRyanRunsDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.ryanRunsGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }

    getBirdCountingDataForMultipleUsers() {
        let query = this.setupQueryForMultipleUsers();
        this.analyticService.getBirdCountingDataForUsers(query)
            .then((analytics:any) => {
                let analytics_ = analytics.BarGraph[0].series;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                this.birdCountingGraphMultipleUsers = analytics_;
            })
            .catch(err => console.error(err));
    }

    private setupQueryForSingleUser(user? : any): AnalyticQuery {
        const currentUser = this.getCurrentUser();
        let start = new Date(this.selectedRange[0]);
        let end = new Date(this.selectedRange[1]);
        return {
            startTime: start,
            endTime: end,
            role: 'researcher',
            granularity: this.selectedGranularity,
            email: user || currentUser.anonymizedName,
            study: this.focusedStudyId
        };
    }

    getGameUsageDataForSingleUser(user?): any {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getGameUsageAnalyticsForSingleUser(query)
            .then((analytics:any) => {
                this.gameUsageData = analytics.UsageData;
            })
            .catch(err => console.error(err));
    }
    getAverageGameActivityDataForSingleUser(user?) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getGameActivityAnalyticsForSingleUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics.Breakdown;
                analytics_.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });

                this.gameActivityGraphCurrentUser = analytics_;
            })
            .catch(err => console.error(err));
    }

    private formatBreakdownData(analytic: any) : void {
        analytic.date = moment(analytic.startTime).format('ll');
        analytic.duration = this.millisToMinutesAndSeconds(analytic.gameDuration);
    }

    getTicTacToeDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getTicTacToeDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                analytics_.BarGraph[0].series.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                analytics_.Breakdown.forEach((analytic: any) => {
                    this.formatBreakdownData(analytic);
                });
                this.ticTacToeDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                this.ticTacToeDataCurrentUser.Summary = analytics_.Summary;
                this.ticTacToeDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
                this.ticTacToeDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
            })
            .catch(err => console.error(err));
    }

    getFlowGameDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getFlowGameDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                analytics_.BarGraph[0].series.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                analytics_.Breakdown.forEach((analytic: any) => {
                    this.formatBreakdownData(analytic);
                });
                this.flowGameDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                this.flowGameDataCurrentUser.Summary = analytics_.Summary;
                this.flowGameDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
                this.flowGameDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
            })
            .catch(err => console.error(err));
    }

    getRyanRunsDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getRyanRunsDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                //FIXME: handle empty analytic response (forEach of undefined)
                analytics_.BarGraph[0].series.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                analytics_.Breakdown.forEach((analytic: any) => {
                    this.formatBreakdownData(analytic);
                });
                analytics_.RatioLineGraphs.forEach(graph => {
                    graph.series.forEach(analytic => {
                        analytic.value = Math.round(analytic.value * 100.0) / 100.0;
                        analytic.name = moment(analytic.name).toDate();
                    });
                });
                analytics_.WinRate.forEach(graph => {
                    graph.series.forEach(analytic => {
                        analytic.value = Math.round(analytic.value * 100.0) / 100.0;
                        analytic.name = moment(analytic.name).toDate();
                    });
                });

                analytics_.CompletionRate.forEach(graph => {
                    graph.series.forEach(analytic => {
                        analytic.value = Math.round(analytic.value * 100.0) / 100.0;
                        analytic.name = moment(analytic.name).toDate();
                    });
                });
                this.ryanRunsDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                this.ryanRunsDataCurrentUser.Summary = analytics_.Summary;
                this.ryanRunsDataCurrentUser.RatioLineGraphs = analytics_.RatioLineGraphs;
                this.ryanRunsDataCurrentUser.WinRate = analytics_.WinRate;
                this.ryanRunsDataCurrentUser.CompletionRate = analytics_.CompletionRate;
                this.ryanRunsDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
                this.ryanRunsDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
            })
            .catch(err => console.error(err));
    }

    getBirdCountingDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getBirdCountingDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                analytics_.BarGraph[0].series.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                analytics_.Breakdown.forEach((analytic: any) => {
                    this.formatBreakdownData(analytic);
                });
                this.birdCountingDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                this.birdCountingDataCurrentUser.Summary = analytics_.Summary;
                this.birdCountingDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
                this.birdCountingDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
            })
            .catch(err => console.error(err));
    }

    getCheckersDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getCheckersDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                analytics_.BarGraph[0].series.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                analytics_.Breakdown.forEach((analytic: any) => {
                    this.formatBreakdownData(analytic);
                });
                this.checkersDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                this.checkersDataCurrentUser.Summary = analytics_.Summary;
                this.checkersDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
                this.checkersDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
            })
            .catch(err => console.error(err));
    }

    getChessDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getChessDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                analytics_.BarGraph[0].series.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                analytics_.Breakdown.forEach((analytic: any) => {
                    this.formatBreakdownData(analytic);
                });
                this.chessDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                this.chessDataCurrentUser.Summary = analytics_.Summary;
                this.chessDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
                this.chessDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
            })
            .catch(err => console.error(err));
    }
    getSolitaireDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getSolitaireDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                analytics_.BarGraph[0].series.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                analytics_.Breakdown.forEach((analytic: any) => {
                    this.formatBreakdownData(analytic);
                });
                this.solitaireDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                this.solitaireDataCurrentUser.Summary = analytics_.Summary;
                this.solitaireDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
                this.solitaireDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
            })
            .catch(err => console.error(err));
    }
    getPicturePuzzleDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getPicturePuzzleDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                analytics_.BarGraph[0].series.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                analytics_.Breakdown.forEach((analytic: any) => {
                    this.formatBreakdownData(analytic);
                });
                this.picturePuzzleDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                this.picturePuzzleDataCurrentUser.Summary = analytics_.Summary;
                this.picturePuzzleDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
                this.picturePuzzleDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
            })
            .catch(err => console.error(err));
    }

    getPokerDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getPokerDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                analytics_.BarGraph[0].series.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                analytics_.Breakdown.forEach((analytic: any) => {
                    this.formatBreakdownData(analytic);
                });
                this.pokerDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                this.pokerDataCurrentUser.Summary = analytics_.Summary;
                this.pokerDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
                this.pokerDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
            })
            .catch(err => console.error(err));
    }

    getWordPuzzleDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getWordPuzzleDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                analytics_.BarGraph[0].series.forEach(analytic => {
                    this.formatDataForBarGraph(analytic);
                });
                analytics_.Breakdown.forEach((analytic: any) => {
                    this.formatBreakdownData(analytic);
                });
                this.wordPuzzleDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                this.wordPuzzleDataCurrentUser.Summary = analytics_.Summary;
                this.wordPuzzleDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
                this.wordPuzzleDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
            })
            .catch(err => console.error(err));
    }

    getFlightDataForSingleUser(user?: any) {
        let query = this.setupQueryForSingleUser(user);
        this.analyticService.getFlightDataForUser(query)
            .then((analytics:any) => {
                let analytics_ = analytics;
                if (analytics_.BarGraph[0]?.series?.length > 0) {
                    analytics_.BarGraph[0].series.forEach(analytic => {
                        this.formatDataForBarGraph(analytic);
                    });
                    this.flightDataCurrentUser.BarGraph = analytics_.BarGraph[0].series;
                } else {
                    this.flightDataCurrentUser.BarGraph = [];
                }

                if (analytics_.Breakdown.length > 0) {
                    analytics_.Breakdown.forEach((analytic: any) => {
                        this.formatBreakdownData(analytic);
                    });
                    this.flightDataCurrentUser.Breakdown = new MatTableDataSource<any>(analytics_.Breakdown);
                } else {
                    this.flightDataCurrentUser.Breakdown = undefined;
                }

                if (analytics_.AverageRangeOfMotion[0].series.length > 0) {
                    analytics_.AverageRangeOfMotion[0].series.forEach(analytic => {
                        analytic.value = Math.round(analytic.value * 100.0) / 100.0;
                        analytic.name = moment(analytic.name).toDate();
                    });
                    this.flightDataCurrentUser.AverageRangeOfMotion = analytics_.AverageRangeOfMotion;
                } else {
                    this.flightDataCurrentUser.AverageRangeOfMotion = [];
                }

                if (analytics_.AverageJerk[0].series.length > 0) {
                    analytics_.AverageJerk[0].series.forEach(analytic => {
                        analytic.value = Math.round(analytic.value * 100.0) / 100.0;
                        analytic.name = moment(analytic.name).toDate();
                    });
                    this.flightDataCurrentUser.AverageJerk = analytics_.AverageJerk;
                } else {
                    this.flightDataCurrentUser.AverageJerk = [];
                }
                this.flightDataCurrentUser.Summary = analytics_.Summary;
                this.flightDataCurrentUser.SummaryForEntireTimePeriod = analytics_.SummaryForEntireTimePeriod[0];
            })
            .catch(err => console.error(err));
    }

    onSelect(event) {
        console.log(event);
    }

    userSelect(evt) {
        console.log(evt);
    }

    exportData() {
        console.log('Export Data');
    }
    ngOnDestroy(): void {
        this.queryParamSubscription.unsubscribe();
    }
}
