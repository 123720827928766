import { Component } from '@angular/core';

@Component({
    selector: 'background',
    templateUrl: './background.html',
    styleUrls: ['./background.scss']
})
export class Background {

}
