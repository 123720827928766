<div class="header">
    <div class="header__left">
        <ryan-logo></ryan-logo>
    </div>
    <div class="header__middle">
        <h4>Viewing as&nbsp;</h4>
        <select name="role" [ngModel]="currentRoleView" (change)="changeRoleViewTo($event.target.value)">
            <option *ngIf="isSiteAdmin" value="admin">Administrator</option>
            <option *ngIf="isOrgAdmin" value="organizationadmin">Organization Admin</option>
            <option *ngIf="isFacAdmin" value="facilityadmin">Facility Admin</option>
            <option *ngIf="isPrincipleInvestigator" value="principleinvestigator">
                Principle Investigator
            </option>
            <option *ngIf="isResearchCoordinator" value="researchcoordinator">
                Research Coordinator
            </option>
            <option *ngIf="isResearcher" value="researcher">
                Researcher
            </option>
            <option *ngIf="isCaregiver" value="caregiver">Caregiver</option>
            <option *ngIf="isGuardian" value="guardian">Guardian</option>
            <option value="user">User</option>
        </select>

        <!-- <p>{{this.authService.currentUser.role || 'n/a'}}</p> -->

    </div>
    <div class="header__right">
        <fa-icon [icon]="icon.bell"></fa-icon>
    </div>
</div>
